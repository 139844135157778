import L from 'leaflet';
import rawData from './locations.json';

function easeOutQuad(x) {
    return 1 - (1 - x) * (1 - x);
}

// Log counts of "source" and "type" attributes on the data
const sourceCounts = {};
const typeCounts = {};
rawData.forEach((record) => {
    sourceCounts[record.source] = (sourceCounts[record.source] || 0) + 1;
    typeCounts[record.type] = (typeCounts[record.type] || 0) + 1;
});
console.log(`sourceCounts: ${JSON.stringify(sourceCounts)}`);
console.log(`typeCounts: ${JSON.stringify(typeCounts)}`);


export const dataById = {};
const periodicOnlyFilter = (item) => item.type === "Periodical";
const cutoffDate = new Date('2024-11-01 8:45 EDT');
const dateCutoffFilter = (item) => item.ts.getTime() < cutoffDate.getTime();
export const normalizedData = rawData.filter(periodicOnlyFilter).map(({id, latitude, longitude, accuracy, ts, type}) => {
    return dataById[id] = {
        id,
        loc: L.latLng(latitude, longitude),
        ts: new Date(ts),
        accuracy,
        type
    };
}).filter(dateCutoffFilter);

// Sort normalizeData by date
normalizedData.sort((a, b) => a.ts - b.ts);
console.log(normalizedData);

export const minDate = normalizedData[0].ts;
export const maxDate = normalizedData[normalizedData.length - 1].ts;

console.log(minDate.getTime(), maxDate.getTime())

const countLatLngs = (data) => {
    const latLngCounts = {};
    data.forEach(({loc}) => {
        const {lat, lng} = loc;
        const key = `${lat},${lng}`;
        latLngCounts[key] = (latLngCounts[key] || 0) + 1
    });
    return latLngCounts;
};

const maxCount = Math.max(...Object.values(countLatLngs(normalizedData)));
console.log(`maxCount: ${maxCount}`);

export const createHeatData = (filteredNormalizedData) => {
    const latLngCounts = countLatLngs(filteredNormalizedData);

    return Object.keys(latLngCounts).map( key => {
        const [lat, lng] = key.split(',');
        const count = latLngCounts[key];
        return [Number(lat), Number(lng), easeOutQuad((maxCount+count)/(2*maxCount))]
    });
}

export const filterByDate = (min = minDate, max = maxDate) => {
    return normalizedData.filter(record => record.ts.getTime() >= min.getTime() && record.ts.getTime() <= max.getTime());
}

export const heatData = (min = minDate, max = maxDate) => {
    return createHeatData(filterByDate(min, max));
}

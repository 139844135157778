[
    {
        "id": 498663743,
        "latitude": 40.7615883,
        "longitude": -73.94951,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-07T02:22:45.598Z",
        "type": "Periodical"
    },
    {
        "id": 498645397,
        "latitude": 40.7615883,
        "longitude": -73.94951,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-07T01:49:11.883Z",
        "type": "Periodical"
    },
    {
        "id": 498625661,
        "latitude": 40.7624033,
        "longitude": -73.9493417,
        "accuracy": 168,
        "source": "Fused",
        "ts": "2024-11-07T01:13:00.776Z",
        "type": "Periodical"
    },
    {
        "id": 498612633,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-07T00:48:45.291Z",
        "type": "Periodical"
    },
    {
        "id": 498592173,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-07T00:10:09.030Z",
        "type": "Periodical"
    },
    {
        "id": 498579398,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-06T23:46:45.215Z",
        "type": "Periodical"
    },
    {
        "id": 498564665,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-06T23:19:45.184Z",
        "type": "Periodical"
    },
    {
        "id": 498551381,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-06T22:54:45.147Z",
        "type": "Periodical"
    },
    {
        "id": 498541569,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-11-06T22:35:45.056Z",
        "type": "Periodical"
    },
    {
        "id": 498520193,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-06T21:55:45.037Z",
        "type": "Periodical"
    },
    {
        "id": 498502346,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-06T21:21:44.957Z",
        "type": "Periodical"
    },
    {
        "id": 498486545,
        "latitude": 40.7623483,
        "longitude": -73.9493167,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-06T20:50:34.796Z",
        "type": "Periodical"
    },
    {
        "id": 498473233,
        "latitude": 40.7628483,
        "longitude": -73.9505517,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-06T20:22:54.257Z",
        "type": "Periodical"
    },
    {
        "id": 498457599,
        "latitude": 40.7635567,
        "longitude": -73.9529067,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T19:47:29.736Z",
        "type": "Periodical"
    },
    {
        "id": 498446099,
        "latitude": 40.7621933,
        "longitude": -73.95025,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-11-06T19:17:49.962Z",
        "type": "Periodical"
    },
    {
        "id": 498433734,
        "latitude": 40.76742,
        "longitude": -73.94008,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-06T18:43:17.203Z",
        "type": "Periodical"
    },
    {
        "id": 498421747,
        "latitude": 40.76742,
        "longitude": -73.94008,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-06T18:09:47.489Z",
        "type": "Periodical"
    },
    {
        "id": 498408634,
        "latitude": 40.7625133,
        "longitude": -73.9503867,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T17:33:30.799Z",
        "type": "Periodical"
    },
    {
        "id": 498396172,
        "latitude": 40.76173,
        "longitude": -73.9502367,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-11-06T16:52:50.251Z",
        "type": "Periodical"
    },
    {
        "id": 498385786,
        "latitude": 40.7619967,
        "longitude": -73.949725,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-06T16:28:24.850Z",
        "type": "Periodical"
    },
    {
        "id": 498374906,
        "latitude": 40.7618933,
        "longitude": -73.9512633,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-11-06T15:58:15.771Z",
        "type": "Periodical"
    },
    {
        "id": 498362429,
        "latitude": 40.763075,
        "longitude": -73.9497583,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-06T15:23:52.629Z",
        "type": "Periodical"
    },
    {
        "id": 498350959,
        "latitude": 40.7622167,
        "longitude": -73.94999,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-06T14:53:01.796Z",
        "type": "Periodical"
    },
    {
        "id": 498337492,
        "latitude": 40.7621333,
        "longitude": -73.9494,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-06T14:27:19.248Z",
        "type": "Periodical"
    },
    {
        "id": 498321262,
        "latitude": 40.7618233,
        "longitude": -73.9495,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-11-06T13:43:38.798Z",
        "type": "Periodical"
    },
    {
        "id": 498304479,
        "latitude": 40.7620417,
        "longitude": -73.9495383,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T13:01:09.894Z",
        "type": "Periodical"
    },
    {
        "id": 498290050,
        "latitude": 40.7623367,
        "longitude": -73.9491883,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-06T12:27:44.926Z",
        "type": "Periodical"
    },
    {
        "id": 498277560,
        "latitude": 40.762185,
        "longitude": -73.948985,
        "accuracy": 76,
        "source": "Fused",
        "ts": "2024-11-06T11:57:31.979Z",
        "type": "Periodical"
    },
    {
        "id": 498262658,
        "latitude": 40.7622533,
        "longitude": -73.9491783,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-06T11:18:32.579Z",
        "type": "Periodical"
    },
    {
        "id": 498249571,
        "latitude": 40.7621983,
        "longitude": -73.949465,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-06T10:43:12.681Z",
        "type": "Periodical"
    },
    {
        "id": 498226296,
        "latitude": 40.7621983,
        "longitude": -73.949465,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-06T09:37:05.954Z",
        "type": "Periodical"
    },
    {
        "id": 498207140,
        "latitude": 40.7621983,
        "longitude": -73.949465,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-06T08:43:10.204Z",
        "type": "Periodical"
    },
    {
        "id": 498185080,
        "latitude": 40.7621983,
        "longitude": -73.949465,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-06T07:43:08.216Z",
        "type": "Periodical"
    },
    {
        "id": 498162067,
        "latitude": 40.7621983,
        "longitude": -73.949465,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-06T06:43:06.631Z",
        "type": "Periodical"
    },
    {
        "id": 498132420,
        "latitude": 40.76242,
        "longitude": -73.9493133,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-11-06T05:30:16.794Z",
        "type": "Periodical"
    },
    {
        "id": 498116368,
        "latitude": 40.76231,
        "longitude": -73.9494283,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T04:54:22.858Z",
        "type": "Periodical"
    },
    {
        "id": 498100261,
        "latitude": 40.76235,
        "longitude": -73.949455,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-11-06T04:20:16.796Z",
        "type": "Periodical"
    },
    {
        "id": 498085574,
        "latitude": 40.7623583,
        "longitude": -73.9493317,
        "accuracy": 159,
        "source": "Fused",
        "ts": "2024-11-06T03:48:20.064Z",
        "type": "Periodical"
    },
    {
        "id": 498075929,
        "latitude": 40.7621667,
        "longitude": -73.949425,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T03:28:47.687Z",
        "type": "Periodical"
    },
    {
        "id": 498063592,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T03:03:35.583Z",
        "type": "Periodical"
    },
    {
        "id": 498049249,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T02:35:35.540Z",
        "type": "Periodical"
    },
    {
        "id": 498030980,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T01:59:35.424Z",
        "type": "Periodical"
    },
    {
        "id": 498012590,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T01:24:35.356Z",
        "type": "Periodical"
    },
    {
        "id": 497996166,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T00:53:35.259Z",
        "type": "Periodical"
    },
    {
        "id": 497981258,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-06T00:24:35.150Z",
        "type": "Periodical"
    },
    {
        "id": 497965107,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-05T23:53:35.070Z",
        "type": "Periodical"
    },
    {
        "id": 497952239,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-05T23:29:34.967Z",
        "type": "Periodical"
    },
    {
        "id": 497937851,
        "latitude": 40.7628733,
        "longitude": -73.9507733,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-05T23:03:46.797Z",
        "type": "Periodical"
    },
    {
        "id": 497923088,
        "latitude": 40.7630267,
        "longitude": -73.950295,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-05T22:36:31.701Z",
        "type": "Periodical"
    },
    {
        "id": 497893395,
        "latitude": 40.7630267,
        "longitude": -73.950295,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-05T21:43:04.258Z",
        "type": "Periodical"
    },
    {
        "id": 497864129,
        "latitude": 40.7630267,
        "longitude": -73.950295,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-05T20:43:03.639Z",
        "type": "Periodical"
    },
    {
        "id": 497834093,
        "latitude": 40.763905,
        "longitude": -73.956895,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-05T19:43:50.775Z",
        "type": "Periodical"
    },
    {
        "id": 497816618,
        "latitude": 40.76192,
        "longitude": -73.948815,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-05T19:04:52.412Z",
        "type": "Periodical"
    },
    {
        "id": 497810550,
        "latitude": 40.7591383,
        "longitude": -73.9533817,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-11-05T18:50:35.468Z",
        "type": "Periodical"
    },
    {
        "id": 497791651,
        "latitude": 40.7352167,
        "longitude": -73.991515,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-05T18:04:41.456Z",
        "type": "Periodical"
    },
    {
        "id": 497780235,
        "latitude": 40.7341417,
        "longitude": -73.9904983,
        "accuracy": 73,
        "source": "Fused",
        "ts": "2024-11-05T17:36:33.797Z",
        "type": "Periodical"
    },
    {
        "id": 497766505,
        "latitude": 40.723665,
        "longitude": -73.9982017,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-05T17:02:53.418Z",
        "type": "Periodical"
    },
    {
        "id": 497751007,
        "latitude": 40.7230267,
        "longitude": -73.9911667,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-05T16:24:48.446Z",
        "type": "Periodical"
    },
    {
        "id": 497739008,
        "latitude": 40.72319,
        "longitude": -73.9915433,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-11-05T15:55:48.259Z",
        "type": "Periodical"
    },
    {
        "id": 497730404,
        "latitude": 40.723865,
        "longitude": -73.9914083,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-05T15:34:38.324Z",
        "type": "Periodical"
    },
    {
        "id": 497712518,
        "latitude": 40.7589983,
        "longitude": -73.9536833,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-05T14:51:50.133Z",
        "type": "Periodical"
    },
    {
        "id": 497698721,
        "latitude": 40.7629267,
        "longitude": -73.9507833,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-05T14:19:13.796Z",
        "type": "Periodical"
    },
    {
        "id": 497686789,
        "latitude": 40.7632883,
        "longitude": -73.9536,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-11-05T13:50:59.057Z",
        "type": "Periodical"
    },
    {
        "id": 497670457,
        "latitude": 40.7634633,
        "longitude": -73.953065,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-05T13:12:21.414Z",
        "type": "Periodical"
    },
    {
        "id": 497656439,
        "latitude": 40.7637483,
        "longitude": -73.9457317,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T12:39:30.113Z",
        "type": "Periodical"
    },
    {
        "id": 497638377,
        "latitude": 40.7621,
        "longitude": -73.9501117,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-05T11:55:09.012Z",
        "type": "Periodical"
    },
    {
        "id": 497627653,
        "latitude": 40.7634983,
        "longitude": -73.9474283,
        "accuracy": 143,
        "source": "Fused",
        "ts": "2024-11-05T11:27:16.796Z",
        "type": "Periodical"
    },
    {
        "id": 497611996,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T10:44:27.265Z",
        "type": "Periodical"
    },
    {
        "id": 497598887,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T10:07:19.010Z",
        "type": "Periodical"
    },
    {
        "id": 497582456,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T09:21:11.926Z",
        "type": "Periodical"
    },
    {
        "id": 497569147,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T08:44:26.036Z",
        "type": "Periodical"
    },
    {
        "id": 497545814,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T07:44:25.218Z",
        "type": "Periodical"
    },
    {
        "id": 497523093,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T06:44:23.377Z",
        "type": "Periodical"
    },
    {
        "id": 497506065,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T06:02:37.951Z",
        "type": "Periodical"
    },
    {
        "id": 497488816,
        "latitude": 40.7630467,
        "longitude": -73.95056,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-05T05:23:46.796Z",
        "type": "Periodical"
    },
    {
        "id": 497474890,
        "latitude": 40.7627983,
        "longitude": -73.9511417,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-05T04:53:52.001Z",
        "type": "Periodical"
    },
    {
        "id": 497462491,
        "latitude": 40.7631067,
        "longitude": -73.9504867,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-05T04:28:22.798Z",
        "type": "Periodical"
    },
    {
        "id": 497442668,
        "latitude": 40.7611333,
        "longitude": -73.9504867,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-11-05T03:47:35.151Z",
        "type": "Periodical"
    },
    {
        "id": 497411969,
        "latitude": 40.7611333,
        "longitude": -73.9504867,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-11-05T02:48:41.328Z",
        "type": "Periodical"
    },
    {
        "id": 497376083,
        "latitude": 40.7611333,
        "longitude": -73.9504867,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-11-05T01:44:47.256Z",
        "type": "Periodical"
    },
    {
        "id": 497344955,
        "latitude": 40.7611333,
        "longitude": -73.9504867,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-11-05T00:50:29.157Z",
        "type": "Periodical"
    },
    {
        "id": 497314342,
        "latitude": 40.7611333,
        "longitude": -73.9504867,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-11-04T23:53:22.797Z",
        "type": "Periodical"
    },
    {
        "id": 497301402,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T23:30:44.594Z",
        "type": "Periodical"
    },
    {
        "id": 497286053,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T23:02:44.522Z",
        "type": "Periodical"
    },
    {
        "id": 497266137,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T22:28:44.482Z",
        "type": "Periodical"
    },
    {
        "id": 497252119,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T22:03:44.461Z",
        "type": "Periodical"
    },
    {
        "id": 497237736,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T21:37:44.375Z",
        "type": "Periodical"
    },
    {
        "id": 497219864,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T21:02:44.370Z",
        "type": "Periodical"
    },
    {
        "id": 497204757,
        "latitude": 40.76292,
        "longitude": -73.9503067,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-11-04T20:32:36.796Z",
        "type": "Periodical"
    },
    {
        "id": 497189474,
        "latitude": 40.7625567,
        "longitude": -73.9499217,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-04T20:03:28.797Z",
        "type": "Periodical"
    },
    {
        "id": 497180496,
        "latitude": 40.7622483,
        "longitude": -73.9493183,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-11-04T18:43:23.890Z",
        "type": "Periodical"
    },
    {
        "id": 497180495,
        "latitude": 40.7622483,
        "longitude": -73.9493183,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-11-04T16:33:19.165Z",
        "type": "Periodical"
    },
    {
        "id": 497180494,
        "latitude": 40.7622483,
        "longitude": -73.9493183,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-11-04T15:32:42.626Z",
        "type": "Periodical"
    },
    {
        "id": 497180493,
        "latitude": 40.7622483,
        "longitude": -73.9493183,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-11-04T15:12:52.796Z",
        "type": "Periodical"
    },
    {
        "id": 497180492,
        "latitude": 40.76177,
        "longitude": -73.94738,
        "accuracy": 186,
        "source": "Fused",
        "ts": "2024-11-04T14:48:42.796Z",
        "type": "Periodical"
    },
    {
        "id": 497180491,
        "latitude": 40.7619817,
        "longitude": -73.9493833,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-04T14:04:35.442Z",
        "type": "Periodical"
    },
    {
        "id": 497180490,
        "latitude": 40.7592317,
        "longitude": -73.897545,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-11-04T13:09:03.795Z",
        "type": "Periodical"
    },
    {
        "id": 497180489,
        "latitude": 40.7622083,
        "longitude": -73.9485733,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-11-04T12:53:09.794Z",
        "type": "Periodical"
    },
    {
        "id": 497180488,
        "latitude": 40.7610783,
        "longitude": -73.9463583,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-11-04T10:42:33.383Z",
        "type": "Periodical"
    },
    {
        "id": 497180487,
        "latitude": 40.7610783,
        "longitude": -73.9463583,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-11-04T09:18:13.713Z",
        "type": "Periodical"
    },
    {
        "id": 497180486,
        "latitude": 40.7610783,
        "longitude": -73.9463583,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-11-04T07:08:09.585Z",
        "type": "Periodical"
    },
    {
        "id": 497180485,
        "latitude": 40.7610783,
        "longitude": -73.9463583,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-11-04T06:02:08.476Z",
        "type": "Periodical"
    },
    {
        "id": 497180484,
        "latitude": 40.7610783,
        "longitude": -73.9463583,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-11-04T02:41:55.697Z",
        "type": "Periodical"
    },
    {
        "id": 497180483,
        "latitude": 40.7610783,
        "longitude": -73.9463583,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-11-04T01:09:52.796Z",
        "type": "Periodical"
    },
    {
        "id": 497180482,
        "latitude": 40.7622367,
        "longitude": -73.94947,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-03T23:34:45.796Z",
        "type": "Periodical"
    },
    {
        "id": 497180481,
        "latitude": 40.76293,
        "longitude": -73.9502033,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-03T22:24:46.796Z",
        "type": "Periodical"
    },
    {
        "id": 497180480,
        "latitude": 40.762385,
        "longitude": -73.9488567,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-03T21:56:16.797Z",
        "type": "Periodical"
    },
    {
        "id": 497180479,
        "latitude": 40.758815,
        "longitude": -73.9555167,
        "accuracy": 186,
        "source": "Fused",
        "ts": "2024-11-03T21:09:28.210Z",
        "type": "Periodical"
    },
    {
        "id": 497180478,
        "latitude": 40.7657217,
        "longitude": -73.9568417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-03T20:37:29.527Z",
        "type": "Periodical"
    },
    {
        "id": 497180477,
        "latitude": 40.7626083,
        "longitude": -73.9491833,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-03T20:02:51.853Z",
        "type": "Periodical"
    },
    {
        "id": 497180476,
        "latitude": 40.7625383,
        "longitude": -73.9493733,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-11-03T19:37:57.753Z",
        "type": "Periodical"
    },
    {
        "id": 497180475,
        "latitude": 40.7622917,
        "longitude": -73.9496567,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-03T18:52:26.795Z",
        "type": "Periodical"
    },
    {
        "id": 497180474,
        "latitude": 40.7625417,
        "longitude": -73.9516333,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-03T18:09:07.302Z",
        "type": "Periodical"
    },
    {
        "id": 497180473,
        "latitude": 40.7622917,
        "longitude": -73.9505917,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-03T17:15:20.285Z",
        "type": "Periodical"
    },
    {
        "id": 497180472,
        "latitude": 40.7622617,
        "longitude": -73.9506983,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-03T16:14:22.942Z",
        "type": "Periodical"
    },
    {
        "id": 497180471,
        "latitude": 40.7622983,
        "longitude": -73.95017,
        "accuracy": 170,
        "source": "Fused",
        "ts": "2024-11-03T15:32:28.996Z",
        "type": "Periodical"
    },
    {
        "id": 497180470,
        "latitude": 40.7622083,
        "longitude": -73.9503667,
        "accuracy": 61,
        "source": "Fused",
        "ts": "2024-11-03T15:07:37.515Z",
        "type": "Periodical"
    },
    {
        "id": 497180469,
        "latitude": 40.7620283,
        "longitude": -73.95176,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-11-03T14:39:14.928Z",
        "type": "Periodical"
    },
    {
        "id": 497180468,
        "latitude": 40.7619283,
        "longitude": -73.95035,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-03T14:08:13.979Z",
        "type": "Periodical"
    },
    {
        "id": 497180467,
        "latitude": 40.7618317,
        "longitude": -73.950245,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-11-03T13:26:50.467Z",
        "type": "Periodical"
    },
    {
        "id": 497180466,
        "latitude": 40.7622267,
        "longitude": -73.9495983,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-03T12:51:17.796Z",
        "type": "Periodical"
    },
    {
        "id": 497180465,
        "latitude": 40.7645217,
        "longitude": -73.94752,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-03T12:18:49.170Z",
        "type": "Periodical"
    },
    {
        "id": 497180464,
        "latitude": 40.7621467,
        "longitude": -73.94938,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-03T11:42:29.795Z",
        "type": "Periodical"
    },
    {
        "id": 497180463,
        "latitude": 40.7617517,
        "longitude": -73.9489517,
        "accuracy": 126,
        "source": "Fused",
        "ts": "2024-11-03T10:50:16.151Z",
        "type": "Periodical"
    },
    {
        "id": 497180462,
        "latitude": 40.7617517,
        "longitude": -73.9489517,
        "accuracy": 126,
        "source": "Fused",
        "ts": "2024-11-03T10:02:06.175Z",
        "type": "Periodical"
    },
    {
        "id": 497180461,
        "latitude": 40.7617517,
        "longitude": -73.9489517,
        "accuracy": 126,
        "source": "Fused",
        "ts": "2024-11-03T09:03:48.033Z",
        "type": "Periodical"
    },
    {
        "id": 497180460,
        "latitude": 40.7622517,
        "longitude": -73.9497483,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-03T07:03:18.839Z",
        "type": "Periodical"
    },
    {
        "id": 497180459,
        "latitude": 40.7622517,
        "longitude": -73.9497483,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-03T06:02:47.565Z",
        "type": "Periodical"
    },
    {
        "id": 497180458,
        "latitude": 40.7622517,
        "longitude": -73.9497483,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-03T05:33:02.797Z",
        "type": "Periodical"
    },
    {
        "id": 497180457,
        "latitude": 40.762185,
        "longitude": -73.9495783,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-03T04:47:19.797Z",
        "type": "Periodical"
    },
    {
        "id": 497180456,
        "latitude": 40.7624317,
        "longitude": -73.949405,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-03T02:07:41.947Z",
        "type": "Periodical"
    },
    {
        "id": 497180455,
        "latitude": 40.7622483,
        "longitude": -73.9494783,
        "accuracy": 99,
        "source": "Fused",
        "ts": "2024-11-03T00:01:37.217Z",
        "type": "Periodical"
    },
    {
        "id": 497180454,
        "latitude": 40.7605267,
        "longitude": -73.9479983,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-02T23:01:21.742Z",
        "type": "Periodical"
    },
    {
        "id": 497180453,
        "latitude": 40.7619,
        "longitude": -73.949685,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T22:18:18.581Z",
        "type": "Periodical"
    },
    {
        "id": 495924426,
        "latitude": 40.7615217,
        "longitude": -73.9511733,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-02T21:05:46.689Z",
        "type": "Periodical"
    },
    {
        "id": 495912215,
        "latitude": 40.7627133,
        "longitude": -73.9493833,
        "accuracy": 147,
        "source": "Fused",
        "ts": "2024-11-02T20:41:09.552Z",
        "type": "Periodical"
    },
    {
        "id": 495901084,
        "latitude": 40.7627133,
        "longitude": -73.9493833,
        "accuracy": 147,
        "source": "Fused",
        "ts": "2024-11-02T20:20:09.427Z",
        "type": "Periodical"
    },
    {
        "id": 495884539,
        "latitude": 40.7627133,
        "longitude": -73.9493833,
        "accuracy": 147,
        "source": "Fused",
        "ts": "2024-11-02T19:49:09.360Z",
        "type": "Periodical"
    },
    {
        "id": 495867924,
        "latitude": 40.7627133,
        "longitude": -73.9493833,
        "accuracy": 147,
        "source": "Fused",
        "ts": "2024-11-02T19:19:09.559Z",
        "type": "Periodical"
    },
    {
        "id": 495856056,
        "latitude": 40.7630833,
        "longitude": -73.9498383,
        "accuracy": 185,
        "source": "Fused",
        "ts": "2024-11-02T18:56:04.795Z",
        "type": "Periodical"
    },
    {
        "id": 495835526,
        "latitude": 40.7622167,
        "longitude": -73.9492883,
        "accuracy": 156,
        "source": "Fused",
        "ts": "2024-11-02T18:15:09.072Z",
        "type": "Periodical"
    },
    {
        "id": 495818371,
        "latitude": 40.7622167,
        "longitude": -73.9492883,
        "accuracy": 156,
        "source": "Fused",
        "ts": "2024-11-02T17:41:08.977Z",
        "type": "Periodical"
    },
    {
        "id": 495808126,
        "latitude": 40.7622167,
        "longitude": -73.9492883,
        "accuracy": 156,
        "source": "Fused",
        "ts": "2024-11-02T17:21:09.257Z",
        "type": "Periodical"
    },
    {
        "id": 495793400,
        "latitude": 40.762415,
        "longitude": -73.949335,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-02T16:52:35.225Z",
        "type": "Periodical"
    },
    {
        "id": 495780448,
        "latitude": 40.7586483,
        "longitude": -73.95274,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-02T16:27:23.048Z",
        "type": "Periodical"
    },
    {
        "id": 495769780,
        "latitude": 40.7511183,
        "longitude": -73.9835983,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-02T16:05:12.287Z",
        "type": "Periodical"
    },
    {
        "id": 495741410,
        "latitude": 40.755025,
        "longitude": -73.9834,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-02T15:05:31.062Z",
        "type": "Periodical"
    },
    {
        "id": 495729099,
        "latitude": 40.7531467,
        "longitude": -73.9794867,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-02T14:38:35.586Z",
        "type": "Periodical"
    },
    {
        "id": 495715977,
        "latitude": 40.7554883,
        "longitude": -73.98404,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T14:09:29.796Z",
        "type": "Periodical"
    },
    {
        "id": 495692815,
        "latitude": 40.7633567,
        "longitude": -73.953295,
        "accuracy": 174,
        "source": "Fused",
        "ts": "2024-11-02T13:16:21.306Z",
        "type": "Periodical"
    },
    {
        "id": 495682471,
        "latitude": 40.763675,
        "longitude": -73.94864,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-02T12:52:18.799Z",
        "type": "Periodical"
    },
    {
        "id": 495668989,
        "latitude": 40.7622117,
        "longitude": -73.95159,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-02T12:20:52.506Z",
        "type": "Periodical"
    },
    {
        "id": 495659671,
        "latitude": 40.7604417,
        "longitude": -73.9479517,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-02T11:57:16.545Z",
        "type": "Periodical"
    },
    {
        "id": 495646378,
        "latitude": 40.768165,
        "longitude": -73.950385,
        "accuracy": 135,
        "source": "Fused",
        "ts": "2024-11-02T11:21:34.913Z",
        "type": "Periodical"
    },
    {
        "id": 495638979,
        "latitude": 40.7617733,
        "longitude": -73.9491867,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-02T11:01:13.859Z",
        "type": "Periodical"
    },
    {
        "id": 495627940,
        "latitude": 40.76276,
        "longitude": -73.9490667,
        "accuracy": 82,
        "source": "Fused",
        "ts": "2024-11-02T10:30:38.796Z",
        "type": "Periodical"
    },
    {
        "id": 495614874,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T09:52:10.733Z",
        "type": "Periodical"
    },
    {
        "id": 495594199,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T08:52:10.190Z",
        "type": "Periodical"
    },
    {
        "id": 495577778,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T08:03:44.686Z",
        "type": "Periodical"
    },
    {
        "id": 495564929,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T07:28:16.129Z",
        "type": "Periodical"
    },
    {
        "id": 495551740,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T06:52:07.062Z",
        "type": "Periodical"
    },
    {
        "id": 495528349,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T05:52:05.753Z",
        "type": "Periodical"
    },
    {
        "id": 495501798,
        "latitude": 40.76228,
        "longitude": -73.9490683,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-02T04:49:40.796Z",
        "type": "Periodical"
    },
    {
        "id": 495490040,
        "latitude": 40.7622983,
        "longitude": -73.9492817,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-11-02T04:24:17.799Z",
        "type": "Periodical"
    },
    {
        "id": 495468313,
        "latitude": 40.7631133,
        "longitude": -73.9482633,
        "accuracy": 174,
        "source": "Fused",
        "ts": "2024-11-02T03:39:23.912Z",
        "type": "Periodical"
    },
    {
        "id": 495451008,
        "latitude": 40.7622133,
        "longitude": -73.94982,
        "accuracy": 121,
        "source": "Fused",
        "ts": "2024-11-02T03:04:34.690Z",
        "type": "Periodical"
    },
    {
        "id": 495434404,
        "latitude": 40.7630633,
        "longitude": -73.94935,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-02T02:33:21.104Z",
        "type": "Periodical"
    },
    {
        "id": 495418746,
        "latitude": 40.7618383,
        "longitude": -73.9496633,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-02T02:04:25.796Z",
        "type": "Periodical"
    },
    {
        "id": 495400682,
        "latitude": 40.7618883,
        "longitude": -73.9494267,
        "accuracy": 122,
        "source": "Fused",
        "ts": "2024-11-02T01:32:24.411Z",
        "type": "Periodical"
    },
    {
        "id": 495376010,
        "latitude": 40.762365,
        "longitude": -73.9510883,
        "accuracy": 78,
        "source": "Fused",
        "ts": "2024-11-02T00:48:45.190Z",
        "type": "Periodical"
    },
    {
        "id": 495361168,
        "latitude": 40.7616883,
        "longitude": -73.9501567,
        "accuracy": 76,
        "source": "Fused",
        "ts": "2024-11-02T00:21:17.736Z",
        "type": "Periodical"
    },
    {
        "id": 495345620,
        "latitude": 40.7624117,
        "longitude": -73.949255,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-11-01T23:52:02.192Z",
        "type": "Periodical"
    },
    {
        "id": 495317733,
        "latitude": 40.7624117,
        "longitude": -73.949255,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-11-01T23:05:22.500Z",
        "type": "Periodical"
    },
    {
        "id": 495297725,
        "latitude": 40.7624117,
        "longitude": -73.949255,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-11-01T22:32:22.381Z",
        "type": "Periodical"
    },
    {
        "id": 495279117,
        "latitude": 40.7624117,
        "longitude": -73.949255,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-11-01T21:59:22.241Z",
        "type": "Periodical"
    },
    {
        "id": 495259484,
        "latitude": 40.7624117,
        "longitude": -73.949255,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-11-01T21:24:22.726Z",
        "type": "Periodical"
    },
    {
        "id": 495247012,
        "latitude": 40.7624117,
        "longitude": -73.949255,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-11-01T21:02:24.796Z",
        "type": "Periodical"
    },
    {
        "id": 495227992,
        "latitude": 40.7629833,
        "longitude": -73.9512333,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-11-01T20:28:06.799Z",
        "type": "Periodical"
    },
    {
        "id": 495219600,
        "latitude": 40.7629133,
        "longitude": -73.950685,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-01T20:12:09.906Z",
        "type": "Periodical"
    },
    {
        "id": 495196209,
        "latitude": 40.76218,
        "longitude": -73.950205,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-01T19:26:43.928Z",
        "type": "Periodical"
    },
    {
        "id": 495171398,
        "latitude": 40.762465,
        "longitude": -73.9498217,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-11-01T18:41:22.267Z",
        "type": "Periodical"
    },
    {
        "id": 495144326,
        "latitude": 40.7621617,
        "longitude": -73.9497083,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-11-01T17:36:34.078Z",
        "type": "Periodical"
    },
    {
        "id": 495128944,
        "latitude": 40.7628683,
        "longitude": -73.9494967,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-01T17:05:41.765Z",
        "type": "Periodical"
    },
    {
        "id": 495126347,
        "latitude": 40.7620467,
        "longitude": -73.950095,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-01T16:59:53.947Z",
        "type": "Periodical"
    },
    {
        "id": 495080105,
        "latitude": 40.7669583,
        "longitude": -73.9264283,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-01T15:09:14.533Z",
        "type": "Periodical"
    },
    {
        "id": 495067254,
        "latitude": 40.7637433,
        "longitude": -73.94751,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-11-01T14:38:12.388Z",
        "type": "Periodical"
    },
    {
        "id": 495051537,
        "latitude": 40.7696617,
        "longitude": -73.94507,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-11-01T13:58:41.380Z",
        "type": "Periodical"
    },
    {
        "id": 495047885,
        "latitude": 40.7626783,
        "longitude": -73.9507067,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-01T13:49:00.795Z",
        "type": "Periodical"
    },
    {
        "id": 495030726,
        "latitude": 40.7629183,
        "longitude": -73.9493633,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-01T13:05:51.411Z",
        "type": "Periodical"
    },
    {
        "id": 495017312,
        "latitude": 40.7618883,
        "longitude": -73.9494983,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-11-01T12:33:24.549Z",
        "type": "Periodical"
    },
    {
        "id": 495002917,
        "latitude": 40.76269,
        "longitude": -73.9497733,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-11-01T11:58:35.101Z",
        "type": "Periodical"
    },
    {
        "id": 495002929,
        "latitude": 40.76269,
        "longitude": -73.9497733,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-11-01T11:58:35.101Z",
        "type": "Periodical"
    },
    {
        "id": 494974145,
        "latitude": 40.76173,
        "longitude": -73.94308,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-11-01T10:52:00.928Z",
        "type": "Periodical"
    },
    {
        "id": 494951790,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T09:54:46.116Z",
        "type": "Periodical"
    },
    {
        "id": 494930025,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T08:54:44.009Z",
        "type": "Periodical"
    },
    {
        "id": 494907913,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T07:54:42.110Z",
        "type": "Periodical"
    },
    {
        "id": 494884271,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T06:54:41.150Z",
        "type": "Periodical"
    },
    {
        "id": 494862220,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T05:51:31.413Z",
        "type": "Periodical"
    },
    {
        "id": 494831107,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T05:02:40.155Z",
        "type": "Periodical"
    },
    {
        "id": 494738085,
        "latitude": 40.7625617,
        "longitude": -73.9494783,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-11-01T02:18:23.701Z",
        "type": "Periodical"
    },
    {
        "id": 494718503,
        "latitude": 40.7623017,
        "longitude": -73.9491983,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-11-01T01:47:25.795Z",
        "type": "Periodical"
    },
    {
        "id": 494704761,
        "latitude": 40.7617517,
        "longitude": -73.9494483,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-11-01T01:25:39.796Z",
        "type": "Periodical"
    },
    {
        "id": 494684960,
        "latitude": 40.7622517,
        "longitude": -73.9497033,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-01T00:54:36.998Z",
        "type": "Periodical"
    },
    {
        "id": 494654431,
        "latitude": 40.7622517,
        "longitude": -73.9497033,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-11-01T00:09:56.795Z",
        "type": "Periodical"
    },
    {
        "id": 494634946,
        "latitude": 40.76253,
        "longitude": -73.9495033,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-31T23:40:59.797Z",
        "type": "Periodical"
    },
    {
        "id": 494614793,
        "latitude": 40.762755,
        "longitude": -73.94992,
        "accuracy": 193,
        "source": "Fused",
        "ts": "2024-10-31T23:09:52.795Z",
        "type": "Periodical"
    },
    {
        "id": 494598419,
        "latitude": 40.762355,
        "longitude": -73.9497283,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T22:43:31.796Z",
        "type": "Periodical"
    },
    {
        "id": 494582320,
        "latitude": 40.7625483,
        "longitude": -73.94918,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-31T22:17:33.336Z",
        "type": "Periodical"
    },
    {
        "id": 494570535,
        "latitude": 40.76145,
        "longitude": -73.9497367,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-31T21:57:43.285Z",
        "type": "Periodical"
    },
    {
        "id": 494553279,
        "latitude": 40.7631167,
        "longitude": -73.945985,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-31T21:28:04.208Z",
        "type": "Periodical"
    },
    {
        "id": 494538254,
        "latitude": 40.7633733,
        "longitude": -73.9498133,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T21:02:08.190Z",
        "type": "Periodical"
    },
    {
        "id": 494527387,
        "latitude": 40.7616417,
        "longitude": -73.9478833,
        "accuracy": 129,
        "source": "Fused",
        "ts": "2024-10-31T20:42:51.170Z",
        "type": "Periodical"
    },
    {
        "id": 494508894,
        "latitude": 40.763875,
        "longitude": -73.9525267,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-31T20:08:07.400Z",
        "type": "Periodical"
    },
    {
        "id": 494487756,
        "latitude": 40.7623683,
        "longitude": -73.94959,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T19:25:22.046Z",
        "type": "Periodical"
    },
    {
        "id": 494473170,
        "latitude": 40.7621933,
        "longitude": -73.9503783,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T18:54:35.476Z",
        "type": "Periodical"
    },
    {
        "id": 494460537,
        "latitude": 40.7629883,
        "longitude": -73.94892,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-31T18:24:24.495Z",
        "type": "Periodical"
    },
    {
        "id": 494435810,
        "latitude": 40.7632467,
        "longitude": -73.9500617,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-31T17:18:48.796Z",
        "type": "Periodical"
    },
    {
        "id": 494430880,
        "latitude": 40.7615583,
        "longitude": -73.9493083,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-31T17:04:55.897Z",
        "type": "Periodical"
    },
    {
        "id": 494418091,
        "latitude": 40.7595733,
        "longitude": -73.9527617,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-31T16:29:57.788Z",
        "type": "Periodical"
    },
    {
        "id": 494405173,
        "latitude": 40.7622817,
        "longitude": -73.9490883,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-31T15:55:27.684Z",
        "type": "Periodical"
    },
    {
        "id": 494391486,
        "latitude": 40.7627717,
        "longitude": -73.94926,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-31T15:19:22.242Z",
        "type": "Periodical"
    },
    {
        "id": 494380284,
        "latitude": 40.7620017,
        "longitude": -73.9497217,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T14:50:05.774Z",
        "type": "Periodical"
    },
    {
        "id": 494373617,
        "latitude": 40.7620017,
        "longitude": -73.9497217,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T14:32:40.162Z",
        "type": "Periodical"
    },
    {
        "id": 494325346,
        "latitude": 40.7620017,
        "longitude": -73.9497217,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-31T12:31:26.106Z",
        "type": "Periodical"
    },
    {
        "id": 494321492,
        "latitude": 40.762295,
        "longitude": -73.95003,
        "accuracy": 169,
        "source": "Fused",
        "ts": "2024-10-31T12:22:01.527Z",
        "type": "Periodical"
    },
    {
        "id": 494293854,
        "latitude": 40.762275,
        "longitude": -73.9516717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-31T11:20:19.356Z",
        "type": "Periodical"
    },
    {
        "id": 494266229,
        "latitude": 40.769255,
        "longitude": -73.9444183,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-31T10:14:25.394Z",
        "type": "Periodical"
    },
    {
        "id": 494252970,
        "latitude": 40.764425,
        "longitude": -73.948295,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-31T09:40:07.426Z",
        "type": "Periodical"
    },
    {
        "id": 494229092,
        "latitude": 40.7626383,
        "longitude": -73.949665,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-10-31T08:35:11.910Z",
        "type": "Periodical"
    },
    {
        "id": 494198755,
        "latitude": 40.76235,
        "longitude": -73.9490483,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-31T07:14:07.798Z",
        "type": "Periodical"
    },
    {
        "id": 494192519,
        "latitude": 40.76187,
        "longitude": -73.9498917,
        "accuracy": 97,
        "source": "Fused",
        "ts": "2024-10-31T06:57:32.355Z",
        "type": "Periodical"
    },
    {
        "id": 494142968,
        "latitude": 40.7608983,
        "longitude": -73.949505,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-31T04:55:58.070Z",
        "type": "Periodical"
    },
    {
        "id": 494095184,
        "latitude": 40.7624267,
        "longitude": -73.9498833,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-31T03:15:55.108Z",
        "type": "Periodical"
    },
    {
        "id": 494085924,
        "latitude": 40.7622167,
        "longitude": -73.948855,
        "accuracy": 149,
        "source": "Fused",
        "ts": "2024-10-31T02:57:17.048Z",
        "type": "Periodical"
    },
    {
        "id": 494064952,
        "latitude": 40.7620333,
        "longitude": -73.949385,
        "accuracy": 174,
        "source": "Fused",
        "ts": "2024-10-31T02:16:47.797Z",
        "type": "Periodical"
    },
    {
        "id": 494049466,
        "latitude": 40.7622617,
        "longitude": -73.9496567,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-31T01:48:47.063Z",
        "type": "Periodical"
    },
    {
        "id": 494035233,
        "latitude": 40.76234,
        "longitude": -73.9490483,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-31T01:22:27.948Z",
        "type": "Periodical"
    },
    {
        "id": 494009360,
        "latitude": 40.7528517,
        "longitude": -73.9430383,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-31T00:35:32.173Z",
        "type": "Periodical"
    },
    {
        "id": 493998352,
        "latitude": 40.744445,
        "longitude": -73.9182017,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-31T00:20:45.797Z",
        "type": "Periodical"
    },
    {
        "id": 493874990,
        "latitude": 40.7515133,
        "longitude": -73.9266133,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-30T20:47:41.196Z",
        "type": "Periodical"
    },
    {
        "id": 493858324,
        "latitude": 40.762325,
        "longitude": -73.9496517,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-30T20:16:18.314Z",
        "type": "Periodical"
    },
    {
        "id": 493841675,
        "latitude": 40.7630283,
        "longitude": -73.9507317,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-10-30T19:42:22.756Z",
        "type": "Periodical"
    },
    {
        "id": 493828840,
        "latitude": 40.7635783,
        "longitude": -73.950055,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-30T19:15:20.116Z",
        "type": "Periodical"
    },
    {
        "id": 493802528,
        "latitude": 40.7622817,
        "longitude": -73.9496467,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-30T18:10:03.676Z",
        "type": "Periodical"
    },
    {
        "id": 493785966,
        "latitude": 40.76217,
        "longitude": -73.9489483,
        "accuracy": 135,
        "source": "Fused",
        "ts": "2024-10-30T17:24:01.612Z",
        "type": "Periodical"
    },
    {
        "id": 493772348,
        "latitude": 40.76217,
        "longitude": -73.9489483,
        "accuracy": 135,
        "source": "Fused",
        "ts": "2024-10-30T16:51:01.504Z",
        "type": "Periodical"
    },
    {
        "id": 493758378,
        "latitude": 40.76217,
        "longitude": -73.9489483,
        "accuracy": 135,
        "source": "Fused",
        "ts": "2024-10-30T16:11:15.700Z",
        "type": "Periodical"
    },
    {
        "id": 493748262,
        "latitude": 40.7625817,
        "longitude": -73.94835,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-30T15:47:44.602Z",
        "type": "Periodical"
    },
    {
        "id": 493736051,
        "latitude": 40.7618617,
        "longitude": -73.951695,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-30T15:12:35.421Z",
        "type": "Periodical"
    },
    {
        "id": 493729183,
        "latitude": 40.7618183,
        "longitude": -73.9506917,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-30T14:53:59.553Z",
        "type": "Periodical"
    },
    {
        "id": 493698678,
        "latitude": 40.76239,
        "longitude": -73.949675,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-30T13:35:42.553Z",
        "type": "Periodical"
    },
    {
        "id": 493684875,
        "latitude": 40.7630667,
        "longitude": -73.9503617,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-30T12:59:36.551Z",
        "type": "Periodical"
    },
    {
        "id": 493683629,
        "latitude": 40.7619233,
        "longitude": -73.9491933,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-10-30T12:56:30.551Z",
        "type": "Periodical"
    },
    {
        "id": 493667285,
        "latitude": 40.762895,
        "longitude": -73.949535,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-30T12:16:21.046Z",
        "type": "Periodical"
    },
    {
        "id": 493652206,
        "latitude": 40.762335,
        "longitude": -73.94963,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-10-30T11:42:04.074Z",
        "type": "Periodical"
    },
    {
        "id": 493641040,
        "latitude": 40.765305,
        "longitude": -73.9425917,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-30T11:15:46.570Z",
        "type": "Periodical"
    },
    {
        "id": 493578700,
        "latitude": 40.76364,
        "longitude": -73.9497233,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-10-30T08:32:41.705Z",
        "type": "Periodical"
    },
    {
        "id": 493315394,
        "latitude": 40.7624883,
        "longitude": -73.9512317,
        "accuracy": 195,
        "source": "Fused",
        "ts": "2024-10-29T23:19:23.535Z",
        "type": "Periodical"
    },
    {
        "id": 493306274,
        "latitude": 40.7624883,
        "longitude": -73.9512317,
        "accuracy": 195,
        "source": "Fused",
        "ts": "2024-10-29T22:57:59.681Z",
        "type": "Periodical"
    },
    {
        "id": 493288218,
        "latitude": 40.7614633,
        "longitude": -73.949855,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-29T22:32:54.651Z",
        "type": "Periodical"
    },
    {
        "id": 493264896,
        "latitude": 40.76219,
        "longitude": -73.9499167,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T21:50:38.526Z",
        "type": "Periodical"
    },
    {
        "id": 493248837,
        "latitude": 40.7617617,
        "longitude": -73.9502617,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-10-29T21:22:08.366Z",
        "type": "Periodical"
    },
    {
        "id": 493237067,
        "latitude": 40.7690283,
        "longitude": -73.94644,
        "accuracy": 188,
        "source": "Fused",
        "ts": "2024-10-29T20:59:00.885Z",
        "type": "Periodical"
    },
    {
        "id": 493173321,
        "latitude": 40.7626267,
        "longitude": -73.94867,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T18:45:15.342Z",
        "type": "Periodical"
    },
    {
        "id": 493162897,
        "latitude": 40.7620683,
        "longitude": -73.953665,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T18:18:05.678Z",
        "type": "Periodical"
    },
    {
        "id": 493152111,
        "latitude": 40.762825,
        "longitude": -73.9487967,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-29T17:48:23.493Z",
        "type": "Periodical"
    },
    {
        "id": 493147535,
        "latitude": 40.762825,
        "longitude": -73.9487967,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T17:28:40.063Z",
        "type": "Periodical"
    },
    {
        "id": 493136890,
        "latitude": 40.762745,
        "longitude": -73.9495583,
        "accuracy": 111,
        "source": "Fused",
        "ts": "2024-10-29T17:05:23.174Z",
        "type": "Periodical"
    },
    {
        "id": 493122487,
        "latitude": 40.7623683,
        "longitude": -73.949385,
        "accuracy": 186,
        "source": "Fused",
        "ts": "2024-10-29T16:24:25.678Z",
        "type": "Periodical"
    },
    {
        "id": 493112943,
        "latitude": 40.7626567,
        "longitude": -73.9491017,
        "accuracy": 153,
        "source": "Fused",
        "ts": "2024-10-29T15:56:31.438Z",
        "type": "Periodical"
    },
    {
        "id": 493102992,
        "latitude": 40.7623283,
        "longitude": -73.9488067,
        "accuracy": 123,
        "source": "Fused",
        "ts": "2024-10-29T15:28:12.944Z",
        "type": "Periodical"
    },
    {
        "id": 493094759,
        "latitude": 40.76296,
        "longitude": -73.94923,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-29T15:04:59.677Z",
        "type": "Periodical"
    },
    {
        "id": 493083196,
        "latitude": 40.7628617,
        "longitude": -73.94941,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-29T14:34:02.468Z",
        "type": "Periodical"
    },
    {
        "id": 493074016,
        "latitude": 40.7629183,
        "longitude": -73.9491117,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T14:12:39.678Z",
        "type": "Periodical"
    },
    {
        "id": 493064309,
        "latitude": 40.7625417,
        "longitude": -73.94999,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-29T13:46:31.315Z",
        "type": "Periodical"
    },
    {
        "id": 493052480,
        "latitude": 40.7613167,
        "longitude": -73.950715,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-29T13:15:42.864Z",
        "type": "Periodical"
    },
    {
        "id": 493039477,
        "latitude": 40.758505,
        "longitude": -73.945285,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-29T12:42:46.651Z",
        "type": "Periodical"
    },
    {
        "id": 493025877,
        "latitude": 40.76256,
        "longitude": -73.94961,
        "accuracy": 99,
        "source": "Fused",
        "ts": "2024-10-29T12:12:22.679Z",
        "type": "Periodical"
    },
    {
        "id": 493021248,
        "latitude": 40.76246,
        "longitude": -73.9494433,
        "accuracy": 110,
        "source": "Fused",
        "ts": "2024-10-29T12:02:09.149Z",
        "type": "Periodical"
    },
    {
        "id": 492994593,
        "latitude": 40.7622417,
        "longitude": -73.9497417,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-29T11:02:01.504Z",
        "type": "Periodical"
    },
    {
        "id": 492977969,
        "latitude": 40.762565,
        "longitude": -73.9493283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T10:21:52.676Z",
        "type": "Periodical"
    },
    {
        "id": 492957117,
        "latitude": 40.7625817,
        "longitude": -73.9490283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T09:27:34.465Z",
        "type": "Periodical"
    },
    {
        "id": 492931872,
        "latitude": 40.7625817,
        "longitude": -73.9490283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T08:20:02.339Z",
        "type": "Periodical"
    },
    {
        "id": 492914712,
        "latitude": 40.7625817,
        "longitude": -73.9490283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T07:36:07.107Z",
        "type": "Periodical"
    },
    {
        "id": 492891434,
        "latitude": 40.7625817,
        "longitude": -73.9490283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T06:36:05.721Z",
        "type": "Periodical"
    },
    {
        "id": 492878048,
        "latitude": 40.7625817,
        "longitude": -73.9490283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T06:02:19.680Z",
        "type": "Periodical"
    },
    {
        "id": 492860540,
        "latitude": 40.7621167,
        "longitude": -73.9488067,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-29T05:21:17.619Z",
        "type": "Periodical"
    },
    {
        "id": 492841146,
        "latitude": 40.7622533,
        "longitude": -73.9494633,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-10-29T04:38:19.677Z",
        "type": "Periodical"
    },
    {
        "id": 492830935,
        "latitude": 40.76252,
        "longitude": -73.9495767,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T04:16:20.368Z",
        "type": "Periodical"
    },
    {
        "id": 492821503,
        "latitude": 40.762115,
        "longitude": -73.9496317,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-29T03:56:05.677Z",
        "type": "Periodical"
    },
    {
        "id": 492807660,
        "latitude": 40.7630883,
        "longitude": -73.94944,
        "accuracy": 74,
        "source": "Fused",
        "ts": "2024-10-29T03:28:26.676Z",
        "type": "Periodical"
    },
    {
        "id": 492797311,
        "latitude": 40.76273,
        "longitude": -73.94961,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T03:08:13.202Z",
        "type": "Periodical"
    },
    {
        "id": 492782181,
        "latitude": 40.7626933,
        "longitude": -73.9495067,
        "accuracy": 186,
        "source": "Fused",
        "ts": "2024-10-29T02:39:00.254Z",
        "type": "Periodical"
    },
    {
        "id": 492771056,
        "latitude": 40.7626417,
        "longitude": -73.9495767,
        "accuracy": 98,
        "source": "Fused",
        "ts": "2024-10-29T02:18:47.005Z",
        "type": "Periodical"
    },
    {
        "id": 492759080,
        "latitude": 40.762915,
        "longitude": -73.9492817,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-29T01:56:51.967Z",
        "type": "Periodical"
    },
    {
        "id": 492743069,
        "latitude": 40.7633333,
        "longitude": -73.95033,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-29T01:28:18.455Z",
        "type": "Periodical"
    },
    {
        "id": 492734077,
        "latitude": 40.76224,
        "longitude": -73.950255,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-29T01:12:49.810Z",
        "type": "Periodical"
    },
    {
        "id": 492653924,
        "latitude": 40.76193,
        "longitude": -73.94939,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-28T22:51:04.912Z",
        "type": "Periodical"
    },
    {
        "id": 492632832,
        "latitude": 40.7575317,
        "longitude": -73.9545717,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-28T22:13:31.331Z",
        "type": "Periodical"
    },
    {
        "id": 492616102,
        "latitude": 40.7522583,
        "longitude": -73.9643517,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-28T21:44:21.618Z",
        "type": "Periodical"
    },
    {
        "id": 492601139,
        "latitude": 40.7213783,
        "longitude": -73.9736583,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-28T21:18:20.656Z",
        "type": "Periodical"
    },
    {
        "id": 492583627,
        "latitude": 40.7213033,
        "longitude": -73.9731317,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-28T20:45:20.598Z",
        "type": "Periodical"
    },
    {
        "id": 492573250,
        "latitude": 40.7217317,
        "longitude": -73.9741467,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-28T20:25:20.964Z",
        "type": "Periodical"
    },
    {
        "id": 492556847,
        "latitude": 40.72186,
        "longitude": -73.9738717,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-10-28T19:52:20.412Z",
        "type": "Periodical"
    },
    {
        "id": 492538251,
        "latitude": 40.71944,
        "longitude": -73.9775617,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-28T19:14:20.911Z",
        "type": "Periodical"
    },
    {
        "id": 492524275,
        "latitude": 40.7505467,
        "longitude": -73.9894133,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-28T18:42:21.774Z",
        "type": "Periodical"
    },
    {
        "id": 492517306,
        "latitude": 40.7488433,
        "longitude": -73.9865317,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-28T18:24:47.540Z",
        "type": "Periodical"
    },
    {
        "id": 492509053,
        "latitude": 40.759675,
        "longitude": -73.95272,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-28T18:02:54.741Z",
        "type": "Periodical"
    },
    {
        "id": 492187607,
        "latitude": 40.7638117,
        "longitude": -73.9300867,
        "accuracy": 171,
        "source": "Fused",
        "ts": "2024-10-28T04:30:37.031Z",
        "type": "Periodical"
    },
    {
        "id": 492093547,
        "latitude": 40.7630517,
        "longitude": -73.9478517,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-10-28T01:24:51.543Z",
        "type": "Periodical"
    },
    {
        "id": 492075246,
        "latitude": 40.76005,
        "longitude": -73.9528917,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-28T00:52:42.333Z",
        "type": "Periodical"
    },
    {
        "id": 492055782,
        "latitude": 40.7647467,
        "longitude": -73.9444483,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-28T00:17:50.495Z",
        "type": "Periodical"
    },
    {
        "id": 492037595,
        "latitude": 40.7640333,
        "longitude": -73.951345,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-10-27T23:43:27.680Z",
        "type": "Periodical"
    },
    {
        "id": 492020766,
        "latitude": 40.7634033,
        "longitude": -73.94842,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-27T23:11:06.453Z",
        "type": "Periodical"
    },
    {
        "id": 492008184,
        "latitude": 40.7634033,
        "longitude": -73.94842,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-27T22:46:06.341Z",
        "type": "Periodical"
    },
    {
        "id": 491988155,
        "latitude": 40.7653933,
        "longitude": -73.9429433,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-27T22:07:22.034Z",
        "type": "Periodical"
    },
    {
        "id": 491970062,
        "latitude": 40.7620767,
        "longitude": -73.9494233,
        "accuracy": 160,
        "source": "Fused",
        "ts": "2024-10-27T21:32:44.677Z",
        "type": "Periodical"
    },
    {
        "id": 491957085,
        "latitude": 40.7621267,
        "longitude": -73.949665,
        "accuracy": 105,
        "source": "Fused",
        "ts": "2024-10-27T21:07:48.678Z",
        "type": "Periodical"
    },
    {
        "id": 491943551,
        "latitude": 40.761965,
        "longitude": -73.951005,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-27T20:46:12.797Z",
        "type": "Periodical"
    },
    {
        "id": 491927563,
        "latitude": 40.7623,
        "longitude": -73.9511417,
        "accuracy": 93,
        "source": "Fused",
        "ts": "2024-10-27T20:15:13.665Z",
        "type": "Periodical"
    },
    {
        "id": 491909840,
        "latitude": 40.762055,
        "longitude": -73.9511167,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-27T19:41:13.456Z",
        "type": "Periodical"
    },
    {
        "id": 491890805,
        "latitude": 40.7622,
        "longitude": -73.94951,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-27T19:03:50.466Z",
        "type": "Periodical"
    },
    {
        "id": 491882356,
        "latitude": 40.7620033,
        "longitude": -73.9509517,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-27T18:47:32.785Z",
        "type": "Periodical"
    },
    {
        "id": 491865611,
        "latitude": 40.76267,
        "longitude": -73.9506817,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-27T18:15:05.564Z",
        "type": "Periodical"
    },
    {
        "id": 491844089,
        "latitude": 40.7620933,
        "longitude": -73.9487883,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-27T17:34:58.679Z",
        "type": "Periodical"
    },
    {
        "id": 491829488,
        "latitude": 40.761695,
        "longitude": -73.9501083,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-27T17:06:01.920Z",
        "type": "Periodical"
    },
    {
        "id": 491812834,
        "latitude": 40.76179,
        "longitude": -73.9503283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-27T16:33:06.206Z",
        "type": "Periodical"
    },
    {
        "id": 491800972,
        "latitude": 40.7618333,
        "longitude": -73.9502267,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-27T16:09:48.657Z",
        "type": "Periodical"
    },
    {
        "id": 491786980,
        "latitude": 40.76231,
        "longitude": -73.949895,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-27T15:41:40.589Z",
        "type": "Periodical"
    },
    {
        "id": 491768115,
        "latitude": 40.7617067,
        "longitude": -73.9501567,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-27T15:04:42.182Z",
        "type": "Periodical"
    },
    {
        "id": 491750655,
        "latitude": 40.7622967,
        "longitude": -73.9495667,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-27T14:28:05.973Z",
        "type": "Periodical"
    },
    {
        "id": 491735004,
        "latitude": 40.7618717,
        "longitude": -73.9497233,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-27T13:55:21.728Z",
        "type": "Periodical"
    },
    {
        "id": 491722392,
        "latitude": 40.7623083,
        "longitude": -73.949805,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-27T13:26:18.232Z",
        "type": "Periodical"
    },
    {
        "id": 491706055,
        "latitude": 40.7625733,
        "longitude": -73.9492167,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-27T12:48:06.522Z",
        "type": "Periodical"
    },
    {
        "id": 491698351,
        "latitude": 40.7624567,
        "longitude": -73.9498333,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-10-27T12:28:59.243Z",
        "type": "Periodical"
    },
    {
        "id": 491674459,
        "latitude": 40.762905,
        "longitude": -73.9508,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-27T11:27:28.103Z",
        "type": "Periodical"
    },
    {
        "id": 491665525,
        "latitude": 40.7622817,
        "longitude": -73.9494683,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-27T11:03:04.911Z",
        "type": "Periodical"
    },
    {
        "id": 491653195,
        "latitude": 40.7628983,
        "longitude": -73.94872,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-27T10:29:19.761Z",
        "type": "Periodical"
    },
    {
        "id": 491641157,
        "latitude": 40.7622033,
        "longitude": -73.949525,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-27T09:55:16.678Z",
        "type": "Periodical"
    },
    {
        "id": 491633092,
        "latitude": 40.7623283,
        "longitude": -73.949295,
        "accuracy": 138,
        "source": "Fused",
        "ts": "2024-10-27T09:32:37.915Z",
        "type": "Periodical"
    },
    {
        "id": 491615983,
        "latitude": 40.7626817,
        "longitude": -73.9496867,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-27T08:44:13.242Z",
        "type": "Periodical"
    },
    {
        "id": 491606460,
        "latitude": 40.7597,
        "longitude": -73.943285,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-27T08:16:37.740Z",
        "type": "Periodical"
    },
    {
        "id": 491592328,
        "latitude": 40.7601117,
        "longitude": -73.9540983,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-10-27T07:38:22.776Z",
        "type": "Periodical"
    },
    {
        "id": 491577471,
        "latitude": 40.7625433,
        "longitude": -73.9506783,
        "accuracy": 191,
        "source": "Fused",
        "ts": "2024-10-27T06:59:08.021Z",
        "type": "Periodical"
    },
    {
        "id": 491550641,
        "latitude": 40.7624283,
        "longitude": -73.95,
        "accuracy": 63,
        "source": "Fused",
        "ts": "2024-10-27T05:53:03.258Z",
        "type": "Periodical"
    },
    {
        "id": 491531912,
        "latitude": 40.7656983,
        "longitude": -73.961385,
        "accuracy": 182,
        "source": "Fused",
        "ts": "2024-10-27T05:10:37.755Z",
        "type": "Periodical"
    },
    {
        "id": 491510095,
        "latitude": 40.7665183,
        "longitude": -73.9511967,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-10-27T04:27:20.904Z",
        "type": "Periodical"
    },
    {
        "id": 491437253,
        "latitude": 40.7589733,
        "longitude": -73.9414167,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-27T02:11:33.794Z",
        "type": "Periodical"
    },
    {
        "id": 491419609,
        "latitude": 40.7625533,
        "longitude": -73.9487267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-27T01:39:49.082Z",
        "type": "Periodical"
    },
    {
        "id": 491383700,
        "latitude": 40.7625533,
        "longitude": -73.9487267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-27T00:39:47.140Z",
        "type": "Periodical"
    },
    {
        "id": 491352182,
        "latitude": 40.7625533,
        "longitude": -73.9487267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-26T23:39:45.011Z",
        "type": "Periodical"
    },
    {
        "id": 491333457,
        "latitude": 40.7625533,
        "longitude": -73.9487267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-26T23:03:50.122Z",
        "type": "Periodical"
    },
    {
        "id": 491322544,
        "latitude": 40.7625533,
        "longitude": -73.9487267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-26T22:42:40.899Z",
        "type": "Periodical"
    },
    {
        "id": 491307081,
        "latitude": 40.762525,
        "longitude": -73.949465,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-10-26T22:13:12.846Z",
        "type": "Periodical"
    },
    {
        "id": 491289254,
        "latitude": 40.7631233,
        "longitude": -73.9704817,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-26T21:40:37.129Z",
        "type": "Periodical"
    },
    {
        "id": 491271970,
        "latitude": 40.8029517,
        "longitude": -73.972555,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-26T21:08:57.347Z",
        "type": "Periodical"
    },
    {
        "id": 491261735,
        "latitude": 40.8035767,
        "longitude": -73.971135,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-26T20:50:28.788Z",
        "type": "Periodical"
    },
    {
        "id": 491243034,
        "latitude": 40.8037967,
        "longitude": -73.9711783,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-26T20:13:54.174Z",
        "type": "Periodical"
    },
    {
        "id": 491228972,
        "latitude": 40.8037617,
        "longitude": -73.97171,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-26T19:48:53.932Z",
        "type": "Periodical"
    },
    {
        "id": 491213337,
        "latitude": 40.7930233,
        "longitude": -73.9708617,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-26T19:17:58.812Z",
        "type": "Periodical"
    },
    {
        "id": 491189740,
        "latitude": 40.7616967,
        "longitude": -73.952775,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-26T18:34:17.150Z",
        "type": "Periodical"
    },
    {
        "id": 491170843,
        "latitude": 40.7627567,
        "longitude": -73.9496983,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-26T17:58:00.034Z",
        "type": "Periodical"
    },
    {
        "id": 491165274,
        "latitude": 40.7605433,
        "longitude": -73.9488517,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-26T17:47:12.678Z",
        "type": "Periodical"
    },
    {
        "id": 491139545,
        "latitude": 40.7635117,
        "longitude": -73.94907,
        "accuracy": 154,
        "source": "Fused",
        "ts": "2024-10-26T16:58:37.519Z",
        "type": "Periodical"
    },
    {
        "id": 491124172,
        "latitude": 40.7623667,
        "longitude": -73.949875,
        "accuracy": 81,
        "source": "Fused",
        "ts": "2024-10-26T16:29:56.678Z",
        "type": "Periodical"
    },
    {
        "id": 491108790,
        "latitude": 40.7623833,
        "longitude": -73.9492283,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-26T16:00:16.681Z",
        "type": "Periodical"
    },
    {
        "id": 491108793,
        "latitude": 40.752665,
        "longitude": -73.9529267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-26T15:37:22.420Z",
        "type": "Periodical"
    },
    {
        "id": 491081985,
        "latitude": 40.7614617,
        "longitude": -73.9496483,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-26T15:06:38.768Z",
        "type": "Periodical"
    },
    {
        "id": 490632624,
        "latitude": 40.7624617,
        "longitude": -73.9492,
        "accuracy": 163,
        "source": "Fused",
        "ts": "2024-10-25T23:00:47.180Z",
        "type": "Periodical"
    },
    {
        "id": 490609221,
        "latitude": 40.7630333,
        "longitude": -73.9494983,
        "accuracy": 102,
        "source": "Fused",
        "ts": "2024-10-25T22:20:30.814Z",
        "type": "Periodical"
    },
    {
        "id": 490587160,
        "latitude": 40.7629767,
        "longitude": -73.94819,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-10-25T21:42:57.680Z",
        "type": "Periodical"
    },
    {
        "id": 490566410,
        "latitude": 40.76247,
        "longitude": -73.9493383,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-25T21:07:00.443Z",
        "type": "Periodical"
    },
    {
        "id": 490555394,
        "latitude": 40.7618083,
        "longitude": -73.9502083,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-10-25T20:51:27.677Z",
        "type": "Periodical"
    },
    {
        "id": 490521453,
        "latitude": 40.762545,
        "longitude": -73.95045,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-25T19:51:00.272Z",
        "type": "Periodical"
    },
    {
        "id": 490509999,
        "latitude": 40.76301,
        "longitude": -73.9473633,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-25T19:28:05.634Z",
        "type": "Periodical"
    },
    {
        "id": 490496393,
        "latitude": 40.7613417,
        "longitude": -73.94928,
        "accuracy": 98,
        "source": "Fused",
        "ts": "2024-10-25T19:00:47.985Z",
        "type": "Periodical"
    },
    {
        "id": 490483039,
        "latitude": 40.76322,
        "longitude": -73.9516283,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-25T18:30:27.958Z",
        "type": "Periodical"
    },
    {
        "id": 490470610,
        "latitude": 40.76224,
        "longitude": -73.9488333,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-25T18:00:21.190Z",
        "type": "Periodical"
    },
    {
        "id": 490461427,
        "latitude": 40.762195,
        "longitude": -73.94914,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-25T17:37:27.010Z",
        "type": "Periodical"
    },
    {
        "id": 490446768,
        "latitude": 40.7624933,
        "longitude": -73.94933,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-25T17:00:55.989Z",
        "type": "Periodical"
    },
    {
        "id": 490436057,
        "latitude": 40.7618983,
        "longitude": -73.9482117,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-10-25T16:33:17.866Z",
        "type": "Periodical"
    },
    {
        "id": 490426043,
        "latitude": 40.7618983,
        "longitude": -73.9482117,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-10-25T16:06:22.011Z",
        "type": "Periodical"
    },
    {
        "id": 490417834,
        "latitude": 40.761935,
        "longitude": -73.9507733,
        "accuracy": 106,
        "source": "Fused",
        "ts": "2024-10-25T15:45:30.816Z",
        "type": "Periodical"
    },
    {
        "id": 490088106,
        "latitude": 40.7603733,
        "longitude": -73.9496267,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-25T02:37:27.808Z",
        "type": "Periodical"
    },
    {
        "id": 490063679,
        "latitude": 40.7693783,
        "longitude": -73.9482733,
        "accuracy": 125,
        "source": "Fused",
        "ts": "2024-10-25T01:52:13.623Z",
        "type": "Periodical"
    },
    {
        "id": 490043236,
        "latitude": 40.7622817,
        "longitude": -73.9495967,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-25T01:15:58.258Z",
        "type": "Periodical"
    },
    {
        "id": 490023295,
        "latitude": 40.7622817,
        "longitude": -73.9495967,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-25T00:45:58.240Z",
        "type": "Periodical"
    },
    {
        "id": 490003651,
        "latitude": 40.7616267,
        "longitude": -73.947595,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-10-25T00:12:23.633Z",
        "type": "Periodical"
    },
    {
        "id": 490004080,
        "latitude": 40.7616267,
        "longitude": -73.947595,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-10-25T00:12:23.633Z",
        "type": "Periodical"
    },
    {
        "id": 489980988,
        "latitude": 40.7628283,
        "longitude": -73.9493867,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-24T23:31:45.835Z",
        "type": "Periodical"
    },
    {
        "id": 489974033,
        "latitude": 40.7623317,
        "longitude": -73.949355,
        "accuracy": 78,
        "source": "Fused",
        "ts": "2024-10-24T23:19:12.730Z",
        "type": "Periodical"
    },
    {
        "id": 489952427,
        "latitude": 40.7623517,
        "longitude": -73.9495233,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-10-24T22:43:20.228Z",
        "type": "Periodical"
    },
    {
        "id": 489934152,
        "latitude": 40.7623517,
        "longitude": -73.9495233,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-24T22:10:40.188Z",
        "type": "Periodical"
    },
    {
        "id": 489923002,
        "latitude": 40.7623517,
        "longitude": -73.9495233,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-10-24T21:50:40.180Z",
        "type": "Periodical"
    },
    {
        "id": 489908275,
        "latitude": 40.7623283,
        "longitude": -73.948785,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-24T21:24:35.010Z",
        "type": "Periodical"
    },
    {
        "id": 489893457,
        "latitude": 40.762575,
        "longitude": -73.9495033,
        "accuracy": 94,
        "source": "Fused",
        "ts": "2024-10-24T20:58:03.543Z",
        "type": "Periodical"
    },
    {
        "id": 489877453,
        "latitude": 40.7622983,
        "longitude": -73.9501583,
        "accuracy": 98,
        "source": "Fused",
        "ts": "2024-10-24T20:29:45.011Z",
        "type": "Periodical"
    },
    {
        "id": 489831616,
        "latitude": 40.7596833,
        "longitude": -73.937365,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-24T19:03:09.021Z",
        "type": "Periodical"
    },
    {
        "id": 489815197,
        "latitude": 40.8544117,
        "longitude": -73.9688567,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-24T18:31:42.665Z",
        "type": "Periodical"
    },
    {
        "id": 489802613,
        "latitude": 40.8945167,
        "longitude": -74.264005,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-24T18:00:56.801Z",
        "type": "Periodical"
    },
    {
        "id": 489790841,
        "latitude": 40.9292933,
        "longitude": -74.9063817,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-24T17:30:56.521Z",
        "type": "Periodical"
    },
    {
        "id": 489783407,
        "latitude": 40.9976117,
        "longitude": -75.269495,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-24T17:10:56.617Z",
        "type": "Periodical"
    },
    {
        "id": 489768706,
        "latitude": 41.0692217,
        "longitude": -75.3995233,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-24T16:30:56.539Z",
        "type": "Periodical"
    },
    {
        "id": 489759281,
        "latitude": 41.160365,
        "longitude": -75.60335,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-24T16:04:56.356Z",
        "type": "Periodical"
    },
    {
        "id": 489746713,
        "latitude": 41.15959,
        "longitude": -75.6011633,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-24T15:30:56.094Z",
        "type": "Periodical"
    },
    {
        "id": 489739203,
        "latitude": 41.1386467,
        "longitude": -75.641855,
        "accuracy": 73,
        "source": "Fused",
        "ts": "2024-10-24T15:12:09.009Z",
        "type": "Periodical"
    },
    {
        "id": 489724921,
        "latitude": 41.0791367,
        "longitude": -75.5802367,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-24T14:34:47.267Z",
        "type": "Periodical"
    },
    {
        "id": 489714263,
        "latitude": 41.0790617,
        "longitude": -75.5790383,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-24T14:07:47.207Z",
        "type": "Periodical"
    },
    {
        "id": 489703352,
        "latitude": 40.99388,
        "longitude": -75.2563467,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-24T13:39:44.210Z",
        "type": "Periodical"
    },
    {
        "id": 489690843,
        "latitude": 41.0315583,
        "longitude": -75.20913,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-24T13:07:43.504Z",
        "type": "Periodical"
    },
    {
        "id": 489674361,
        "latitude": 41.0320733,
        "longitude": -75.208365,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-24T12:27:53.475Z",
        "type": "Periodical"
    },
    {
        "id": 489651817,
        "latitude": 41.0238967,
        "longitude": -75.188075,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-24T11:48:52.301Z",
        "type": "Periodical"
    },
    {
        "id": 489641181,
        "latitude": 40.92514,
        "longitude": -74.7852767,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-24T11:24:52.225Z",
        "type": "Periodical"
    },
    {
        "id": 489631127,
        "latitude": 40.8917733,
        "longitude": -74.3089717,
        "accuracy": 89,
        "source": "Fused",
        "ts": "2024-10-24T11:01:47.849Z",
        "type": "Periodical"
    },
    {
        "id": 489618851,
        "latitude": 40.7643317,
        "longitude": -74.0243467,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-24T10:32:23.932Z",
        "type": "Periodical"
    },
    {
        "id": 489610269,
        "latitude": 40.7585433,
        "longitude": -73.9428233,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-24T10:10:56.007Z",
        "type": "Periodical"
    },
    {
        "id": 489338771,
        "latitude": 40.7640417,
        "longitude": -73.9466983,
        "accuracy": 163,
        "source": "Fused",
        "ts": "2024-10-24T00:22:07.715Z",
        "type": "Periodical"
    },
    {
        "id": 489320397,
        "latitude": 40.7620867,
        "longitude": -73.9494083,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-23T23:49:46.129Z",
        "type": "Periodical"
    },
    {
        "id": 489303169,
        "latitude": 40.7620867,
        "longitude": -73.9494083,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-23T23:18:29.149Z",
        "type": "Periodical"
    },
    {
        "id": 489292208,
        "latitude": 40.7618283,
        "longitude": -73.9493567,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-23T22:58:46.011Z",
        "type": "Periodical"
    },
    {
        "id": 489268018,
        "latitude": 40.7621367,
        "longitude": -73.9493017,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-23T22:18:06.674Z",
        "type": "Periodical"
    },
    {
        "id": 489254604,
        "latitude": 40.7616833,
        "longitude": -73.9496517,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-23T21:56:40.928Z",
        "type": "Periodical"
    },
    {
        "id": 489241569,
        "latitude": 40.7621717,
        "longitude": -73.9492617,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-23T21:34:31.789Z",
        "type": "Periodical"
    },
    {
        "id": 489222983,
        "latitude": 40.7621517,
        "longitude": -73.9494717,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-23T21:01:44.037Z",
        "type": "Periodical"
    },
    {
        "id": 489211001,
        "latitude": 40.7630383,
        "longitude": -73.94914,
        "accuracy": 83,
        "source": "Fused",
        "ts": "2024-10-23T20:40:07.482Z",
        "type": "Periodical"
    },
    {
        "id": 489196966,
        "latitude": 40.7626233,
        "longitude": -73.9492467,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-23T20:14:26.013Z",
        "type": "Periodical"
    },
    {
        "id": 489175978,
        "latitude": 40.762135,
        "longitude": -73.94926,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-23T19:33:40.582Z",
        "type": "Periodical"
    },
    {
        "id": 489159012,
        "latitude": 40.7625117,
        "longitude": -73.9489833,
        "accuracy": 61,
        "source": "Fused",
        "ts": "2024-10-23T18:58:13.794Z",
        "type": "Periodical"
    },
    {
        "id": 489140900,
        "latitude": 40.7635383,
        "longitude": -73.9518667,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-23T18:16:52.439Z",
        "type": "Periodical"
    },
    {
        "id": 489127444,
        "latitude": 40.7599317,
        "longitude": -73.9526283,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-23T17:42:58.715Z",
        "type": "Periodical"
    },
    {
        "id": 489115601,
        "latitude": 40.7622833,
        "longitude": -73.94923,
        "accuracy": 62,
        "source": "Fused",
        "ts": "2024-10-23T17:10:50.008Z",
        "type": "Periodical"
    },
    {
        "id": 489104589,
        "latitude": 40.7624117,
        "longitude": -73.9494967,
        "accuracy": 104,
        "source": "Fused",
        "ts": "2024-10-23T16:42:30.188Z",
        "type": "Periodical"
    },
    {
        "id": 489088957,
        "latitude": 40.7624117,
        "longitude": -73.9494967,
        "accuracy": 104,
        "source": "Fused",
        "ts": "2024-10-23T16:01:09.859Z",
        "type": "Periodical"
    },
    {
        "id": 489082199,
        "latitude": 40.76666,
        "longitude": -73.944875,
        "accuracy": 95,
        "source": "Fused",
        "ts": "2024-10-23T15:42:26.755Z",
        "type": "Periodical"
    },
    {
        "id": 489071053,
        "latitude": 40.7628967,
        "longitude": -73.9491067,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-23T15:11:32.494Z",
        "type": "Periodical"
    },
    {
        "id": 489063309,
        "latitude": 40.7628967,
        "longitude": -73.9491067,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-23T14:51:32.485Z",
        "type": "Periodical"
    },
    {
        "id": 489051409,
        "latitude": 40.7635133,
        "longitude": -73.95001,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-23T14:19:51.763Z",
        "type": "Periodical"
    },
    {
        "id": 489051466,
        "latitude": 40.7635133,
        "longitude": -73.95001,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-23T14:19:51.763Z",
        "type": "Periodical"
    },
    {
        "id": 489030298,
        "latitude": 40.763015,
        "longitude": -73.95011,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-23T13:26:40.009Z",
        "type": "Periodical"
    },
    {
        "id": 489024688,
        "latitude": 40.7626633,
        "longitude": -73.9475617,
        "accuracy": 182,
        "source": "Fused",
        "ts": "2024-10-23T13:12:07.977Z",
        "type": "Periodical"
    },
    {
        "id": 489004127,
        "latitude": 40.7628917,
        "longitude": -73.9498367,
        "accuracy": 109,
        "source": "Fused",
        "ts": "2024-10-23T12:22:31.878Z",
        "type": "Periodical"
    },
    {
        "id": 488993517,
        "latitude": 40.7576767,
        "longitude": -73.92385,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-10-23T11:57:42.102Z",
        "type": "Periodical"
    },
    {
        "id": 488990749,
        "latitude": 40.76528,
        "longitude": -73.9442267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-23T11:51:27.082Z",
        "type": "Periodical"
    },
    {
        "id": 488945769,
        "latitude": 40.7683467,
        "longitude": -73.948095,
        "accuracy": 108,
        "source": "Fused",
        "ts": "2024-10-23T10:03:04.227Z",
        "type": "Periodical"
    },
    {
        "id": 488896093,
        "latitude": 40.7624833,
        "longitude": -73.94904,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-23T07:51:15.102Z",
        "type": "Periodical"
    },
    {
        "id": 488840320,
        "latitude": 40.7626583,
        "longitude": -73.9501783,
        "accuracy": 185,
        "source": "Fused",
        "ts": "2024-10-23T05:31:19.606Z",
        "type": "Periodical"
    },
    {
        "id": 488757544,
        "latitude": 40.761895,
        "longitude": -73.9499617,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-23T02:40:14.158Z",
        "type": "Periodical"
    },
    {
        "id": 488719427,
        "latitude": 40.7625683,
        "longitude": -73.9504783,
        "accuracy": 157,
        "source": "Fused",
        "ts": "2024-10-23T01:32:42.024Z",
        "type": "Periodical"
    },
    {
        "id": 488703071,
        "latitude": 40.7594067,
        "longitude": -73.9501133,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-10-23T01:03:13.292Z",
        "type": "Periodical"
    },
    {
        "id": 488697721,
        "latitude": 40.7622933,
        "longitude": -73.9494167,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-23T00:55:45.830Z",
        "type": "Periodical"
    },
    {
        "id": 488685690,
        "latitude": 40.756945,
        "longitude": -73.9520033,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-23T00:36:59.542Z",
        "type": "Periodical"
    },
    {
        "id": 488632613,
        "latitude": 40.756945,
        "longitude": -73.9520033,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-22T23:02:24.712Z",
        "type": "Periodical"
    },
    {
        "id": 488616796,
        "latitude": 40.756945,
        "longitude": -73.9520033,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-22T22:36:24.631Z",
        "type": "Periodical"
    },
    {
        "id": 488592689,
        "latitude": 40.756945,
        "longitude": -73.9520033,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-22T21:54:56.610Z",
        "type": "Periodical"
    },
    {
        "id": 488572448,
        "latitude": 40.756945,
        "longitude": -73.9520033,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-22T21:19:49.324Z",
        "type": "Periodical"
    },
    {
        "id": 488558961,
        "latitude": 40.7609583,
        "longitude": -73.949465,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-22T20:56:31.012Z",
        "type": "Periodical"
    },
    {
        "id": 488540587,
        "latitude": 40.7545233,
        "longitude": -73.95025,
        "accuracy": 198,
        "source": "Fused",
        "ts": "2024-10-22T20:26:34.528Z",
        "type": "Periodical"
    },
    {
        "id": 488525513,
        "latitude": 40.7611617,
        "longitude": -73.9504117,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-22T19:58:13.412Z",
        "type": "Periodical"
    },
    {
        "id": 488510195,
        "latitude": 40.7616533,
        "longitude": -73.9460133,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-10-22T19:27:23.462Z",
        "type": "Periodical"
    },
    {
        "id": 488495790,
        "latitude": 40.7599417,
        "longitude": -73.9519917,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-22T18:57:01.862Z",
        "type": "Periodical"
    },
    {
        "id": 488486450,
        "latitude": 40.7634233,
        "longitude": -73.951385,
        "accuracy": 83,
        "source": "Fused",
        "ts": "2024-10-22T18:35:55.185Z",
        "type": "Periodical"
    },
    {
        "id": 488470153,
        "latitude": 40.7634233,
        "longitude": -73.951385,
        "accuracy": 83,
        "source": "Fused",
        "ts": "2024-10-22T17:53:07.160Z",
        "type": "Periodical"
    },
    {
        "id": 488461459,
        "latitude": 40.7619117,
        "longitude": -73.9481567,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-22T17:30:23.135Z",
        "type": "Periodical"
    },
    {
        "id": 488448070,
        "latitude": 40.762455,
        "longitude": -73.95094,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-10-22T16:54:15.010Z",
        "type": "Periodical"
    },
    {
        "id": 488438541,
        "latitude": 40.7659317,
        "longitude": -73.9434167,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-22T16:28:19.340Z",
        "type": "Periodical"
    },
    {
        "id": 488425345,
        "latitude": 40.763445,
        "longitude": -73.949075,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-22T15:51:44.339Z",
        "type": "Periodical"
    },
    {
        "id": 488418948,
        "latitude": 40.7634583,
        "longitude": -73.94969,
        "accuracy": 129,
        "source": "Fused",
        "ts": "2024-10-22T15:33:58.165Z",
        "type": "Periodical"
    },
    {
        "id": 488410375,
        "latitude": 40.76285,
        "longitude": -73.9499033,
        "accuracy": 61,
        "source": "Fused",
        "ts": "2024-10-22T15:10:39.012Z",
        "type": "Periodical"
    },
    {
        "id": 488402940,
        "latitude": 40.7624067,
        "longitude": -73.9493433,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-22T14:50:45.008Z",
        "type": "Periodical"
    },
    {
        "id": 488396642,
        "latitude": 40.7617383,
        "longitude": -73.9499617,
        "accuracy": 172,
        "source": "Fused",
        "ts": "2024-10-22T14:34:31.599Z",
        "type": "Periodical"
    },
    {
        "id": 488071696,
        "latitude": 40.7629917,
        "longitude": -73.9495967,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-10-22T02:00:17.483Z",
        "type": "Periodical"
    },
    {
        "id": 488055476,
        "latitude": 40.7622633,
        "longitude": -73.9498167,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-22T01:32:08.009Z",
        "type": "Periodical"
    },
    {
        "id": 488033101,
        "latitude": 40.76312,
        "longitude": -73.9506467,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-10-22T00:53:22.686Z",
        "type": "Periodical"
    },
    {
        "id": 488018164,
        "latitude": 40.7630283,
        "longitude": -73.9491717,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-22T00:27:43.633Z",
        "type": "Periodical"
    },
    {
        "id": 487972431,
        "latitude": 40.7623767,
        "longitude": -73.9496267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T23:07:47.273Z",
        "type": "Periodical"
    },
    {
        "id": 487959735,
        "latitude": 40.7623767,
        "longitude": -73.9496267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T22:45:47.187Z",
        "type": "Periodical"
    },
    {
        "id": 487945015,
        "latitude": 40.7623767,
        "longitude": -73.9496267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T22:19:47.116Z",
        "type": "Periodical"
    },
    {
        "id": 487931709,
        "latitude": 40.7623767,
        "longitude": -73.9496267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T21:57:17.066Z",
        "type": "Periodical"
    },
    {
        "id": 487914997,
        "latitude": 40.76293,
        "longitude": -73.95047,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-21T21:28:19.461Z",
        "type": "Periodical"
    },
    {
        "id": 487900218,
        "latitude": 40.7617183,
        "longitude": -73.9502567,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T21:02:45.010Z",
        "type": "Periodical"
    },
    {
        "id": 487884937,
        "latitude": 40.7656083,
        "longitude": -73.95139,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-21T20:36:32.365Z",
        "type": "Periodical"
    },
    {
        "id": 487870348,
        "latitude": 40.7613383,
        "longitude": -73.9506817,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-21T20:09:19.942Z",
        "type": "Periodical"
    },
    {
        "id": 487857738,
        "latitude": 40.7624667,
        "longitude": -73.9502467,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-21T19:44:39.814Z",
        "type": "Periodical"
    },
    {
        "id": 487839629,
        "latitude": 40.7678617,
        "longitude": -73.9489317,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-21T19:10:27.779Z",
        "type": "Periodical"
    },
    {
        "id": 487821047,
        "latitude": 40.7678617,
        "longitude": -73.9489317,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-21T18:30:27.762Z",
        "type": "Periodical"
    },
    {
        "id": 487806202,
        "latitude": 40.7678617,
        "longitude": -73.9489317,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-21T17:55:27.623Z",
        "type": "Periodical"
    },
    {
        "id": 487797259,
        "latitude": 40.7678617,
        "longitude": -73.9489317,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-21T17:33:14.831Z",
        "type": "Periodical"
    },
    {
        "id": 487785444,
        "latitude": 40.7543383,
        "longitude": -73.9491417,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-21T17:03:42.291Z",
        "type": "Periodical"
    },
    {
        "id": 487775627,
        "latitude": 40.762115,
        "longitude": -73.9499433,
        "accuracy": 102,
        "source": "Fused",
        "ts": "2024-10-21T16:37:35.663Z",
        "type": "Periodical"
    },
    {
        "id": 487768097,
        "latitude": 40.7613733,
        "longitude": -73.9478883,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-21T16:17:14.526Z",
        "type": "Periodical"
    },
    {
        "id": 487758509,
        "latitude": 40.7639483,
        "longitude": -73.9466433,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T15:51:32.968Z",
        "type": "Periodical"
    },
    {
        "id": 487734119,
        "latitude": 40.761165,
        "longitude": -73.9496017,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T14:48:43.008Z",
        "type": "Periodical"
    },
    {
        "id": 487723024,
        "latitude": 40.7617783,
        "longitude": -73.949855,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-21T14:20:09.262Z",
        "type": "Periodical"
    },
    {
        "id": 487712882,
        "latitude": 40.7623467,
        "longitude": -73.949715,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-21T13:52:58.813Z",
        "type": "Periodical"
    },
    {
        "id": 487698150,
        "latitude": 40.7625967,
        "longitude": -73.9498567,
        "accuracy": 91,
        "source": "Fused",
        "ts": "2024-10-21T13:19:01.008Z",
        "type": "Periodical"
    },
    {
        "id": 487693547,
        "latitude": 40.7638567,
        "longitude": -73.94575,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-21T13:07:28.745Z",
        "type": "Periodical"
    },
    {
        "id": 487663448,
        "latitude": 40.765495,
        "longitude": -73.944665,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-21T11:54:28.937Z",
        "type": "Periodical"
    },
    {
        "id": 487629218,
        "latitude": 40.7666583,
        "longitude": -73.9473117,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-21T10:33:18.698Z",
        "type": "Periodical"
    },
    {
        "id": 487623010,
        "latitude": 40.7674783,
        "longitude": -73.9497267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T10:17:03.653Z",
        "type": "Periodical"
    },
    {
        "id": 487611417,
        "latitude": 40.762385,
        "longitude": -73.9497667,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-21T09:46:12.202Z",
        "type": "Periodical"
    },
    {
        "id": 487587270,
        "latitude": 40.7614017,
        "longitude": -73.9474067,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-21T08:39:23.676Z",
        "type": "Periodical"
    },
    {
        "id": 487570829,
        "latitude": 40.7611733,
        "longitude": -73.9495633,
        "accuracy": 153,
        "source": "Fused",
        "ts": "2024-10-21T07:55:23.193Z",
        "type": "Periodical"
    },
    {
        "id": 487563140,
        "latitude": 40.7613867,
        "longitude": -73.9500767,
        "accuracy": 188,
        "source": "Fused",
        "ts": "2024-10-21T07:34:41.640Z",
        "type": "Periodical"
    },
    {
        "id": 487549727,
        "latitude": 40.7624433,
        "longitude": -73.9494233,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-21T06:59:20.589Z",
        "type": "Periodical"
    },
    {
        "id": 487523584,
        "latitude": 40.7627583,
        "longitude": -73.9499383,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-21T05:53:01.678Z",
        "type": "Periodical"
    },
    {
        "id": 487516389,
        "latitude": 40.7627683,
        "longitude": -73.9498417,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-21T05:35:51.589Z",
        "type": "Periodical"
    },
    {
        "id": 487501965,
        "latitude": 40.7625883,
        "longitude": -73.9496117,
        "accuracy": 76,
        "source": "Fused",
        "ts": "2024-10-21T05:01:37.656Z",
        "type": "Periodical"
    },
    {
        "id": 487488386,
        "latitude": 40.76079,
        "longitude": -73.9501183,
        "accuracy": 97,
        "source": "Fused",
        "ts": "2024-10-21T04:32:21.224Z",
        "type": "Periodical"
    },
    {
        "id": 487446398,
        "latitude": 40.760455,
        "longitude": -73.9484033,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T03:02:36.075Z",
        "type": "Periodical"
    },
    {
        "id": 487431441,
        "latitude": 40.7623583,
        "longitude": -73.949285,
        "accuracy": 58,
        "source": "Fused",
        "ts": "2024-10-21T02:33:11.975Z",
        "type": "Periodical"
    },
    {
        "id": 487413558,
        "latitude": 40.7622833,
        "longitude": -73.9494567,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-21T02:01:58.095Z",
        "type": "Periodical"
    },
    {
        "id": 487389360,
        "latitude": 40.7637267,
        "longitude": -73.9501433,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T01:18:05.695Z",
        "type": "Periodical"
    },
    {
        "id": 487371965,
        "latitude": 40.7637267,
        "longitude": -73.9501433,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T00:47:05.584Z",
        "type": "Periodical"
    },
    {
        "id": 487357614,
        "latitude": 40.7637267,
        "longitude": -73.9501433,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T00:19:44.809Z",
        "type": "Periodical"
    },
    {
        "id": 487352733,
        "latitude": 40.7637267,
        "longitude": -73.9501433,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-21T00:10:24.011Z",
        "type": "Periodical"
    },
    {
        "id": 487313119,
        "latitude": 40.7653283,
        "longitude": -73.9476083,
        "accuracy": 90,
        "source": "Fused",
        "ts": "2024-10-20T22:58:06.455Z",
        "type": "Periodical"
    },
    {
        "id": 487299229,
        "latitude": 40.7653283,
        "longitude": -73.9476083,
        "accuracy": 90,
        "source": "Fused",
        "ts": "2024-10-20T22:31:52.825Z",
        "type": "Periodical"
    },
    {
        "id": 487285442,
        "latitude": 40.76514,
        "longitude": -73.9477267,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-20T22:06:10.635Z",
        "type": "Periodical"
    },
    {
        "id": 487272603,
        "latitude": 40.7655867,
        "longitude": -73.9474117,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-20T21:42:10.370Z",
        "type": "Periodical"
    },
    {
        "id": 487250979,
        "latitude": 40.7654233,
        "longitude": -73.9475867,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-20T21:02:10.375Z",
        "type": "Periodical"
    },
    {
        "id": 487236268,
        "latitude": 40.765375,
        "longitude": -73.947455,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-20T20:37:04.436Z",
        "type": "Periodical"
    },
    {
        "id": 487215845,
        "latitude": 40.7628133,
        "longitude": -73.949625,
        "accuracy": 191,
        "source": "Fused",
        "ts": "2024-10-20T20:02:37.141Z",
        "type": "Periodical"
    },
    {
        "id": 487197920,
        "latitude": 40.7617233,
        "longitude": -73.950055,
        "accuracy": 109,
        "source": "Fused",
        "ts": "2024-10-20T19:27:41.014Z",
        "type": "Periodical"
    },
    {
        "id": 487188234,
        "latitude": 40.7632317,
        "longitude": -73.9501933,
        "accuracy": 59,
        "source": "Fused",
        "ts": "2024-10-20T19:09:23.111Z",
        "type": "Periodical"
    },
    {
        "id": 487170659,
        "latitude": 40.762465,
        "longitude": -73.9497433,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-10-20T18:35:07.041Z",
        "type": "Periodical"
    },
    {
        "id": 487152741,
        "latitude": 40.761935,
        "longitude": -73.95063,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-20T18:00:04.422Z",
        "type": "Periodical"
    },
    {
        "id": 487120765,
        "latitude": 40.761935,
        "longitude": -73.95063,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-20T17:00:02.429Z",
        "type": "Periodical"
    },
    {
        "id": 487120762,
        "latitude": 40.761935,
        "longitude": -73.95063,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-20T16:41:59.011Z",
        "type": "Periodical"
    },
    {
        "id": 486724243,
        "latitude": 40.76551,
        "longitude": -73.9436433,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-20T01:57:13.814Z",
        "type": "Periodical"
    },
    {
        "id": 486688465,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-20T00:53:38.066Z",
        "type": "Periodical"
    },
    {
        "id": 486667647,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-20T00:15:41.214Z",
        "type": "Periodical"
    },
    {
        "id": 486654336,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-19T23:51:41.173Z",
        "type": "Periodical"
    },
    {
        "id": 486643073,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-19T23:30:41.141Z",
        "type": "Periodical"
    },
    {
        "id": 486629814,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-19T23:04:23.652Z",
        "type": "Periodical"
    },
    {
        "id": 486624367,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-19T22:54:23.649Z",
        "type": "Periodical"
    },
    {
        "id": 486579803,
        "latitude": 40.7633483,
        "longitude": -73.9523417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-19T21:35:27.010Z",
        "type": "Periodical"
    },
    {
        "id": 486571317,
        "latitude": 40.7626217,
        "longitude": -73.9494883,
        "accuracy": 110,
        "source": "Fused",
        "ts": "2024-10-19T21:19:19.233Z",
        "type": "Periodical"
    },
    {
        "id": 486531273,
        "latitude": 40.7629833,
        "longitude": -73.95216,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-19T20:04:29.618Z",
        "type": "Periodical"
    },
    {
        "id": 486516793,
        "latitude": 40.76308,
        "longitude": -73.94999,
        "accuracy": 156,
        "source": "Fused",
        "ts": "2024-10-19T19:36:53.010Z",
        "type": "Periodical"
    },
    {
        "id": 486498189,
        "latitude": 40.7657133,
        "longitude": -73.947415,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-19T19:06:10.455Z",
        "type": "Periodical"
    },
    {
        "id": 486485574,
        "latitude": 40.762985,
        "longitude": -73.951085,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-19T18:43:32.511Z",
        "type": "Periodical"
    },
    {
        "id": 486465326,
        "latitude": 40.7625633,
        "longitude": -73.9503833,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-19T18:06:17.954Z",
        "type": "Periodical"
    },
    {
        "id": 486454004,
        "latitude": 40.7620333,
        "longitude": -73.9488667,
        "accuracy": 149,
        "source": "Fused",
        "ts": "2024-10-19T17:44:40.572Z",
        "type": "Periodical"
    },
    {
        "id": 486444214,
        "latitude": 40.7635017,
        "longitude": -73.9483483,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-10-19T17:25:26.341Z",
        "type": "Periodical"
    },
    {
        "id": 486432959,
        "latitude": 40.7623267,
        "longitude": -73.949575,
        "accuracy": 88,
        "source": "Fused",
        "ts": "2024-10-19T17:02:53.009Z",
        "type": "Periodical"
    },
    {
        "id": 486420360,
        "latitude": 40.762125,
        "longitude": -73.949465,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-10-19T16:37:40.560Z",
        "type": "Periodical"
    },
    {
        "id": 486401046,
        "latitude": 40.7625917,
        "longitude": -73.9494767,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-19T15:57:24.211Z",
        "type": "Periodical"
    },
    {
        "id": 486385539,
        "latitude": 40.7626833,
        "longitude": -73.9501,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-19T15:26:02.173Z",
        "type": "Periodical"
    },
    {
        "id": 486369920,
        "latitude": 40.75304,
        "longitude": -73.92757,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-19T14:53:36.012Z",
        "type": "Periodical"
    },
    {
        "id": 486274067,
        "latitude": 40.761445,
        "longitude": -73.935585,
        "accuracy": 153,
        "source": "Fused",
        "ts": "2024-10-19T11:12:44.550Z",
        "type": "Periodical"
    },
    {
        "id": 486265155,
        "latitude": 40.7660067,
        "longitude": -73.93958,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-19T10:48:51.096Z",
        "type": "Periodical"
    },
    {
        "id": 486236137,
        "latitude": 40.766425,
        "longitude": -73.942295,
        "accuracy": 62,
        "source": "Fused",
        "ts": "2024-10-19T09:25:32.071Z",
        "type": "Periodical"
    },
    {
        "id": 486225415,
        "latitude": 40.7621067,
        "longitude": -73.94976,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-19T08:54:42.531Z",
        "type": "Periodical"
    },
    {
        "id": 486213221,
        "latitude": 40.7595783,
        "longitude": -73.9488917,
        "accuracy": 55,
        "source": "Fused",
        "ts": "2024-10-19T08:19:40.495Z",
        "type": "Periodical"
    },
    {
        "id": 486199071,
        "latitude": 40.7537367,
        "longitude": -73.9499717,
        "accuracy": 67,
        "source": "Fused",
        "ts": "2024-10-19T07:47:13.972Z",
        "type": "Periodical"
    },
    {
        "id": 486196356,
        "latitude": 40.7625567,
        "longitude": -73.9509583,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-10-19T07:39:52.011Z",
        "type": "Periodical"
    },
    {
        "id": 486161110,
        "latitude": 40.7629917,
        "longitude": -73.9501233,
        "accuracy": 181,
        "source": "Fused",
        "ts": "2024-10-19T06:06:40.010Z",
        "type": "Periodical"
    },
    {
        "id": 486149643,
        "latitude": 40.7658683,
        "longitude": -73.95557,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-19T05:39:19.161Z",
        "type": "Periodical"
    },
    {
        "id": 486026612,
        "latitude": 40.7653617,
        "longitude": -73.9467467,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-19T01:40:10.161Z",
        "type": "Periodical"
    },
    {
        "id": 486007581,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-19T01:06:54.885Z",
        "type": "Periodical"
    },
    {
        "id": 485977457,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-19T00:11:29.419Z",
        "type": "Periodical"
    },
    {
        "id": 485944931,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-18T23:12:30.502Z",
        "type": "Periodical"
    },
    {
        "id": 485925571,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-18T22:38:31.213Z",
        "type": "Periodical"
    },
    {
        "id": 485906018,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-18T22:07:10.625Z",
        "type": "Periodical"
    },
    {
        "id": 485888756,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-18T21:37:59.509Z",
        "type": "Periodical"
    },
    {
        "id": 485878083,
        "latitude": 40.7621917,
        "longitude": -73.949565,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-18T21:19:15.378Z",
        "type": "Periodical"
    },
    {
        "id": 485841407,
        "latitude": 40.7585783,
        "longitude": -73.9288283,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-18T20:13:32.352Z",
        "type": "Periodical"
    },
    {
        "id": 485823090,
        "latitude": 40.7569817,
        "longitude": -73.9208367,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-18T19:39:06.422Z",
        "type": "Periodical"
    },
    {
        "id": 485806115,
        "latitude": 40.7607317,
        "longitude": -73.9173483,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-18T19:07:17.715Z",
        "type": "Periodical"
    },
    {
        "id": 485790405,
        "latitude": 40.763085,
        "longitude": -73.914785,
        "accuracy": 153,
        "source": "Fused",
        "ts": "2024-10-18T18:36:13.125Z",
        "type": "Periodical"
    },
    {
        "id": 485778496,
        "latitude": 40.7636233,
        "longitude": -73.9150183,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-10-18T18:08:36.621Z",
        "type": "Periodical"
    },
    {
        "id": 485763501,
        "latitude": 40.7477267,
        "longitude": -73.92537,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-18T17:33:30.666Z",
        "type": "Periodical"
    },
    {
        "id": 485748438,
        "latitude": 40.7578917,
        "longitude": -73.9365017,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-18T16:57:28.426Z",
        "type": "Periodical"
    },
    {
        "id": 485739491,
        "latitude": 40.7680217,
        "longitude": -73.938935,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-10-18T16:34:58.456Z",
        "type": "Periodical"
    },
    {
        "id": 485722811,
        "latitude": 40.763695,
        "longitude": -73.9482417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-18T15:53:55.502Z",
        "type": "Periodical"
    },
    {
        "id": 485713489,
        "latitude": 40.7623696,
        "longitude": -73.9493178,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-10-18T15:31:47.944Z",
        "type": "Periodical"
    },
    {
        "id": 485703556,
        "latitude": 40.76193,
        "longitude": -73.9494817,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-18T15:07:48.013Z",
        "type": "Periodical"
    },
    {
        "id": 485689546,
        "latitude": 40.7621617,
        "longitude": -73.94896,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-18T14:34:04.374Z",
        "type": "Periodical"
    },
    {
        "id": 485676340,
        "latitude": 40.76139,
        "longitude": -73.9538317,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-10-18T14:02:04.730Z",
        "type": "Periodical"
    },
    {
        "id": 485667039,
        "latitude": 40.7603633,
        "longitude": -73.9369567,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-18T13:38:42.617Z",
        "type": "Periodical"
    },
    {
        "id": 485657076,
        "latitude": 40.7435133,
        "longitude": -73.9196033,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-18T13:14:29.755Z",
        "type": "Periodical"
    },
    {
        "id": 485639393,
        "latitude": 40.7629483,
        "longitude": -73.9494517,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-18T12:31:05.862Z",
        "type": "Periodical"
    },
    {
        "id": 485629902,
        "latitude": 40.7623383,
        "longitude": -73.9491767,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-18T12:07:14.718Z",
        "type": "Periodical"
    },
    {
        "id": 485621572,
        "latitude": 40.76275,
        "longitude": -73.9493467,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-18T11:47:08.751Z",
        "type": "Periodical"
    },
    {
        "id": 485619039,
        "latitude": 40.76235,
        "longitude": -73.9493283,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-18T11:40:49.144Z",
        "type": "Periodical"
    },
    {
        "id": 485106153,
        "latitude": 40.7630783,
        "longitude": -73.951825,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-17T17:40:39.573Z",
        "type": "Periodical"
    },
    {
        "id": 485097107,
        "latitude": 40.7629483,
        "longitude": -73.950025,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-10-17T17:17:39.768Z",
        "type": "Periodical"
    },
    {
        "id": 485080662,
        "latitude": 40.7606913,
        "longitude": -73.9512685,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-17T16:36:19.341Z",
        "type": "Periodical"
    },
    {
        "id": 485072924,
        "latitude": 40.7467933,
        "longitude": -73.891964,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-17T16:17:18.573Z",
        "type": "Periodical"
    },
    {
        "id": 485068906,
        "latitude": 40.7446933,
        "longitude": -73.9185167,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-17T16:06:48.724Z",
        "type": "Periodical"
    },
    {
        "id": 485052822,
        "latitude": 40.7453,
        "longitude": -73.9186033,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-17T15:24:57.717Z",
        "type": "Periodical"
    },
    {
        "id": 485045913,
        "latitude": 40.7448933,
        "longitude": -73.9181167,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-17T15:07:20.787Z",
        "type": "Periodical"
    },
    {
        "id": 485020814,
        "latitude": 40.7448517,
        "longitude": -73.9181417,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-17T14:05:57.233Z",
        "type": "Periodical"
    },
    {
        "id": 485006942,
        "latitude": 40.7449367,
        "longitude": -73.9179583,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-10-17T13:30:52.573Z",
        "type": "Periodical"
    },
    {
        "id": 484993856,
        "latitude": 40.7591783,
        "longitude": -73.93993,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-17T12:57:58.009Z",
        "type": "Periodical"
    },
    {
        "id": 484993250,
        "latitude": 40.762,
        "longitude": -73.9426917,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-17T12:56:23.668Z",
        "type": "Periodical"
    },
    {
        "id": 484835235,
        "latitude": 40.7626933,
        "longitude": -73.950035,
        "accuracy": 70,
        "source": "Fused",
        "ts": "2024-10-17T06:11:54.194Z",
        "type": "Periodical"
    },
    {
        "id": 484737840,
        "latitude": 40.762365,
        "longitude": -73.949585,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-17T02:43:25.496Z",
        "type": "Periodical"
    },
    {
        "id": 484719308,
        "latitude": 40.762185,
        "longitude": -73.949355,
        "accuracy": 61,
        "source": "Fused",
        "ts": "2024-10-17T02:09:12.576Z",
        "type": "Periodical"
    },
    {
        "id": 484706703,
        "latitude": 40.7624933,
        "longitude": -73.9513267,
        "accuracy": 172,
        "source": "Fused",
        "ts": "2024-10-17T01:46:37.575Z",
        "type": "Periodical"
    },
    {
        "id": 484692984,
        "latitude": 40.7625217,
        "longitude": -73.949215,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-17T01:21:24.667Z",
        "type": "Periodical"
    },
    {
        "id": 484676556,
        "latitude": 40.7621883,
        "longitude": -73.9494017,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-17T00:50:46.577Z",
        "type": "Periodical"
    },
    {
        "id": 484658708,
        "latitude": 40.76287,
        "longitude": -73.9498217,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-17T00:19:41.301Z",
        "type": "Periodical"
    },
    {
        "id": 484640887,
        "latitude": 40.7626033,
        "longitude": -73.94931,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-16T23:47:13.577Z",
        "type": "Periodical"
    },
    {
        "id": 484619880,
        "latitude": 40.7626217,
        "longitude": -73.949,
        "accuracy": 82,
        "source": "Fused",
        "ts": "2024-10-16T23:09:33.742Z",
        "type": "Periodical"
    },
    {
        "id": 484595728,
        "latitude": 40.7626217,
        "longitude": -73.949,
        "accuracy": 82,
        "source": "Fused",
        "ts": "2024-10-16T22:27:14.801Z",
        "type": "Periodical"
    },
    {
        "id": 484583912,
        "latitude": 40.7619783,
        "longitude": -73.9499117,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T22:05:46.358Z",
        "type": "Periodical"
    },
    {
        "id": 484565909,
        "latitude": 40.7619833,
        "longitude": -73.9492133,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T21:32:34.573Z",
        "type": "Periodical"
    },
    {
        "id": 484553952,
        "latitude": 40.7622817,
        "longitude": -73.9495367,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-16T21:11:15.575Z",
        "type": "Periodical"
    },
    {
        "id": 484540529,
        "latitude": 40.76226,
        "longitude": -73.94939,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-10-16T20:46:02.575Z",
        "type": "Periodical"
    },
    {
        "id": 484521313,
        "latitude": 40.76263,
        "longitude": -73.9494833,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-16T20:09:38.667Z",
        "type": "Periodical"
    },
    {
        "id": 484504994,
        "latitude": 40.7628833,
        "longitude": -73.9491583,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-16T19:38:38.576Z",
        "type": "Periodical"
    },
    {
        "id": 484447564,
        "latitude": 40.7638683,
        "longitude": -73.948005,
        "accuracy": 91,
        "source": "Fused",
        "ts": "2024-10-16T17:28:48.122Z",
        "type": "Periodical"
    },
    {
        "id": 484433399,
        "latitude": 40.7604833,
        "longitude": -73.9220217,
        "accuracy": 165,
        "source": "Fused",
        "ts": "2024-10-16T16:52:50.217Z",
        "type": "Periodical"
    },
    {
        "id": 484426509,
        "latitude": 40.765895,
        "longitude": -73.9218583,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-16T16:34:37.432Z",
        "type": "Periodical"
    },
    {
        "id": 484414805,
        "latitude": 40.778905,
        "longitude": -73.9250467,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-16T16:03:16.484Z",
        "type": "Periodical"
    },
    {
        "id": 484405879,
        "latitude": 40.7632633,
        "longitude": -73.948765,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-16T15:44:41.575Z",
        "type": "Periodical"
    },
    {
        "id": 484352921,
        "latitude": 40.7618883,
        "longitude": -73.9492283,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T13:25:31.780Z",
        "type": "Periodical"
    },
    {
        "id": 484343178,
        "latitude": 40.7623717,
        "longitude": -73.9497633,
        "accuracy": 144,
        "source": "Fused",
        "ts": "2024-10-16T13:01:33.577Z",
        "type": "Periodical"
    },
    {
        "id": 484328753,
        "latitude": 40.7626717,
        "longitude": -73.948495,
        "accuracy": 89,
        "source": "Fused",
        "ts": "2024-10-16T12:26:56.575Z",
        "type": "Periodical"
    },
    {
        "id": 484317880,
        "latitude": 40.762045,
        "longitude": -73.9502717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-16T12:00:38.460Z",
        "type": "Periodical"
    },
    {
        "id": 484300973,
        "latitude": 40.76404,
        "longitude": -73.9508033,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-16T11:22:13.807Z",
        "type": "Periodical"
    },
    {
        "id": 484283928,
        "latitude": 40.764925,
        "longitude": -73.9452967,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-16T10:43:43.513Z",
        "type": "Periodical"
    },
    {
        "id": 484271187,
        "latitude": 40.7637233,
        "longitude": -73.9528017,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T10:10:40.692Z",
        "type": "Periodical"
    },
    {
        "id": 484240861,
        "latitude": 40.7637233,
        "longitude": -73.9528017,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T08:50:23.947Z",
        "type": "Periodical"
    },
    {
        "id": 484223921,
        "latitude": 40.7637233,
        "longitude": -73.9528017,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T08:04:35.265Z",
        "type": "Periodical"
    },
    {
        "id": 484202805,
        "latitude": 40.7637233,
        "longitude": -73.9528017,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T07:10:37.532Z",
        "type": "Periodical"
    },
    {
        "id": 484175840,
        "latitude": 40.7637233,
        "longitude": -73.9528017,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-16T06:03:18.574Z",
        "type": "Periodical"
    },
    {
        "id": 484150586,
        "latitude": 40.7628733,
        "longitude": -73.9504,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-16T05:02:42.271Z",
        "type": "Periodical"
    },
    {
        "id": 484135203,
        "latitude": 40.7628733,
        "longitude": -73.9504,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-16T04:28:18.576Z",
        "type": "Periodical"
    },
    {
        "id": 484116804,
        "latitude": 40.7577283,
        "longitude": -73.9501883,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-10-16T03:52:16.063Z",
        "type": "Periodical"
    },
    {
        "id": 484091479,
        "latitude": 40.7576183,
        "longitude": -73.9509917,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-16T03:00:13.576Z",
        "type": "Periodical"
    },
    {
        "id": 484077680,
        "latitude": 40.7597583,
        "longitude": -73.9509933,
        "accuracy": 130,
        "source": "Fused",
        "ts": "2024-10-16T02:31:22.576Z",
        "type": "Periodical"
    },
    {
        "id": 484052910,
        "latitude": 40.7599383,
        "longitude": -73.9459867,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-10-16T01:49:45.576Z",
        "type": "Periodical"
    },
    {
        "id": 484031063,
        "latitude": 40.762775,
        "longitude": -73.9495683,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-10-16T01:10:28.890Z",
        "type": "Periodical"
    },
    {
        "id": 483991061,
        "latitude": 40.762775,
        "longitude": -73.9495683,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-10-16T00:01:18.393Z",
        "type": "Periodical"
    },
    {
        "id": 483973523,
        "latitude": 40.762775,
        "longitude": -73.9495683,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-10-15T23:31:07.264Z",
        "type": "Periodical"
    },
    {
        "id": 483958511,
        "latitude": 40.762775,
        "longitude": -73.9495683,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-10-15T23:04:07.252Z",
        "type": "Periodical"
    },
    {
        "id": 483941957,
        "latitude": 40.762775,
        "longitude": -73.9495683,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-10-15T22:36:12.575Z",
        "type": "Periodical"
    },
    {
        "id": 483930618,
        "latitude": 40.763645,
        "longitude": -73.948465,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-15T22:15:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 483909474,
        "latitude": 40.7819,
        "longitude": -73.9291817,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-15T21:37:52.972Z",
        "type": "Periodical"
    },
    {
        "id": 483893546,
        "latitude": 40.78187,
        "longitude": -73.9284067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-15T21:12:52.674Z",
        "type": "Periodical"
    },
    {
        "id": 483883585,
        "latitude": 40.7843455,
        "longitude": -73.9273617,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-15T20:53:23.800Z",
        "type": "Periodical"
    },
    {
        "id": 483861904,
        "latitude": 40.798755,
        "longitude": -73.9211583,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-15T20:13:52.246Z",
        "type": "Periodical"
    },
    {
        "id": 483851512,
        "latitude": 40.77214,
        "longitude": -73.917555,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-15T19:54:53.156Z",
        "type": "Periodical"
    },
    {
        "id": 483836640,
        "latitude": 40.764715,
        "longitude": -73.9463483,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-15T19:25:58.283Z",
        "type": "Periodical"
    },
    {
        "id": 483818752,
        "latitude": 40.7627233,
        "longitude": -73.95027,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-15T18:48:59.218Z",
        "type": "Periodical"
    },
    {
        "id": 483817723,
        "latitude": 40.762785,
        "longitude": -73.9496633,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-15T18:46:34.575Z",
        "type": "Periodical"
    },
    {
        "id": 483796865,
        "latitude": 40.760175,
        "longitude": -73.953615,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-15T17:56:56.994Z",
        "type": "Periodical"
    },
    {
        "id": 483782013,
        "latitude": 40.7472383,
        "longitude": -73.9906,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-15T17:26:02.576Z",
        "type": "Periodical"
    },
    {
        "id": 483764717,
        "latitude": 40.7514995,
        "longitude": -73.9913658,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-15T16:41:37.836Z",
        "type": "Periodical"
    },
    {
        "id": 483752852,
        "latitude": 40.7589383,
        "longitude": -73.9533533,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-15T16:10:25.663Z",
        "type": "Periodical"
    },
    {
        "id": 483748669,
        "latitude": 40.76127,
        "longitude": -73.9501367,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-15T15:59:38.646Z",
        "type": "Periodical"
    },
    {
        "id": 483716905,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T14:43:16.896Z",
        "type": "Periodical"
    },
    {
        "id": 483712141,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T14:30:46.105Z",
        "type": "Periodical"
    },
    {
        "id": 483601707,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T10:09:58.266Z",
        "type": "Periodical"
    },
    {
        "id": 483583112,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T09:20:38.502Z",
        "type": "Periodical"
    },
    {
        "id": 483555579,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T08:05:34.508Z",
        "type": "Periodical"
    },
    {
        "id": 483540591,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T07:26:21.356Z",
        "type": "Periodical"
    },
    {
        "id": 483509644,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T06:04:54.664Z",
        "type": "Periodical"
    },
    {
        "id": 483483911,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T05:02:40.238Z",
        "type": "Periodical"
    },
    {
        "id": 483413161,
        "latitude": 40.7643733,
        "longitude": -73.9500983,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-15T02:37:42.578Z",
        "type": "Periodical"
    },
    {
        "id": 483400508,
        "latitude": 40.7619183,
        "longitude": -73.949405,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-10-15T02:13:23.753Z",
        "type": "Periodical"
    },
    {
        "id": 483087091,
        "latitude": 40.8524317,
        "longitude": -73.8758317,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-14T16:30:36.486Z",
        "type": "Periodical"
    },
    {
        "id": 483076528,
        "latitude": 40.8528983,
        "longitude": -73.8738917,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-14T16:08:20.914Z",
        "type": "Periodical"
    },
    {
        "id": 483061751,
        "latitude": 40.8120983,
        "longitude": -73.9005317,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-14T15:35:01.294Z",
        "type": "Periodical"
    },
    {
        "id": 483045333,
        "latitude": 40.7621083,
        "longitude": -73.949095,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-14T14:57:16.080Z",
        "type": "Periodical"
    },
    {
        "id": 483044221,
        "latitude": 40.7626717,
        "longitude": -73.9492683,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T14:54:37.290Z",
        "type": "Periodical"
    },
    {
        "id": 482980784,
        "latitude": 40.762765,
        "longitude": -73.9503717,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-14T12:25:48.288Z",
        "type": "Periodical"
    },
    {
        "id": 482962286,
        "latitude": 40.762635,
        "longitude": -73.95016,
        "accuracy": 154,
        "source": "Fused",
        "ts": "2024-10-14T11:43:37.781Z",
        "type": "Periodical"
    },
    {
        "id": 482949095,
        "latitude": 40.7631283,
        "longitude": -73.9520783,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-14T11:11:03.780Z",
        "type": "Periodical"
    },
    {
        "id": 482933278,
        "latitude": 40.7622483,
        "longitude": -73.95188,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-14T10:31:22.289Z",
        "type": "Periodical"
    },
    {
        "id": 482920187,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T09:55:38.317Z",
        "type": "Periodical"
    },
    {
        "id": 482898598,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T08:55:37.234Z",
        "type": "Periodical"
    },
    {
        "id": 482877025,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T07:55:36.142Z",
        "type": "Periodical"
    },
    {
        "id": 482854648,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T06:55:35.134Z",
        "type": "Periodical"
    },
    {
        "id": 482831475,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T05:55:33.850Z",
        "type": "Periodical"
    },
    {
        "id": 482810084,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T05:02:40.191Z",
        "type": "Periodical"
    },
    {
        "id": 482794733,
        "latitude": 40.7632017,
        "longitude": -73.9507067,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T04:27:32.287Z",
        "type": "Periodical"
    },
    {
        "id": 482780134,
        "latitude": 40.7638117,
        "longitude": -73.9529483,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-14T03:56:33.504Z",
        "type": "Periodical"
    },
    {
        "id": 482769446,
        "latitude": 40.7628417,
        "longitude": -73.95053,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-14T03:33:35.290Z",
        "type": "Periodical"
    },
    {
        "id": 482760259,
        "latitude": 40.7627433,
        "longitude": -73.9509967,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-14T03:14:56.289Z",
        "type": "Periodical"
    },
    {
        "id": 482414012,
        "latitude": 40.7620467,
        "longitude": -73.9507883,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-13T16:31:18.305Z",
        "type": "Periodical"
    },
    {
        "id": 482392901,
        "latitude": 40.7609067,
        "longitude": -73.95036,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-13T15:50:47.287Z",
        "type": "Periodical"
    },
    {
        "id": 482376245,
        "latitude": 40.761575,
        "longitude": -73.9499133,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-10-13T15:14:03.079Z",
        "type": "Periodical"
    },
    {
        "id": 482360584,
        "latitude": 40.76254,
        "longitude": -73.94959,
        "accuracy": 154,
        "source": "Fused",
        "ts": "2024-10-13T14:42:23.956Z",
        "type": "Periodical"
    },
    {
        "id": 482344302,
        "latitude": 40.76344,
        "longitude": -73.9508767,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-13T14:07:14.868Z",
        "type": "Periodical"
    },
    {
        "id": 482332169,
        "latitude": 40.7617083,
        "longitude": -73.949945,
        "accuracy": 119,
        "source": "Fused",
        "ts": "2024-10-13T13:39:57.293Z",
        "type": "Periodical"
    },
    {
        "id": 482322394,
        "latitude": 40.7693933,
        "longitude": -73.948285,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-13T13:16:52.288Z",
        "type": "Periodical"
    },
    {
        "id": 482309105,
        "latitude": 40.7645417,
        "longitude": -73.9553367,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-13T12:46:08.288Z",
        "type": "Periodical"
    },
    {
        "id": 482296273,
        "latitude": 40.7717067,
        "longitude": -73.9607867,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-13T12:13:42.719Z",
        "type": "Periodical"
    },
    {
        "id": 482287335,
        "latitude": 40.7629717,
        "longitude": -73.9494117,
        "accuracy": 126,
        "source": "Fused",
        "ts": "2024-10-13T11:51:17.888Z",
        "type": "Periodical"
    },
    {
        "id": 482275787,
        "latitude": 40.7623067,
        "longitude": -73.949875,
        "accuracy": 125,
        "source": "Fused",
        "ts": "2024-10-13T11:20:15.687Z",
        "type": "Periodical"
    },
    {
        "id": 482264244,
        "latitude": 40.7624333,
        "longitude": -73.949975,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-13T10:49:46.900Z",
        "type": "Periodical"
    },
    {
        "id": 482252582,
        "latitude": 40.762675,
        "longitude": -73.94943,
        "accuracy": 74,
        "source": "Fused",
        "ts": "2024-10-13T10:16:38.290Z",
        "type": "Periodical"
    },
    {
        "id": 482238392,
        "latitude": 40.7630933,
        "longitude": -73.9498717,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-10-13T09:35:51.289Z",
        "type": "Periodical"
    },
    {
        "id": 482225623,
        "latitude": 40.7637567,
        "longitude": -73.950125,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-13T08:58:21.289Z",
        "type": "Periodical"
    },
    {
        "id": 482214253,
        "latitude": 40.7674733,
        "longitude": -73.9486017,
        "accuracy": 191,
        "source": "Fused",
        "ts": "2024-10-13T08:25:22.140Z",
        "type": "Periodical"
    },
    {
        "id": 482206756,
        "latitude": 40.7628233,
        "longitude": -73.94949,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-13T08:03:40.971Z",
        "type": "Periodical"
    },
    {
        "id": 482163015,
        "latitude": 40.7630883,
        "longitude": -73.9510533,
        "accuracy": 129,
        "source": "Fused",
        "ts": "2024-10-13T06:12:04.288Z",
        "type": "Periodical"
    },
    {
        "id": 482152173,
        "latitude": 40.7535,
        "longitude": -73.953255,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-13T05:46:22.660Z",
        "type": "Periodical"
    },
    {
        "id": 482131483,
        "latitude": 40.762945,
        "longitude": -73.9496333,
        "accuracy": 91,
        "source": "Fused",
        "ts": "2024-10-13T05:03:55.935Z",
        "type": "Periodical"
    },
    {
        "id": 482111708,
        "latitude": 40.761815,
        "longitude": -73.9494817,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-13T04:22:56.807Z",
        "type": "Periodical"
    },
    {
        "id": 482098406,
        "latitude": 40.7628067,
        "longitude": -73.9500017,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-13T03:54:49.287Z",
        "type": "Periodical"
    },
    {
        "id": 482087377,
        "latitude": 40.7632033,
        "longitude": -73.9499183,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-13T03:32:40.560Z",
        "type": "Periodical"
    },
    {
        "id": 482075558,
        "latitude": 40.762875,
        "longitude": -73.9500967,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-13T03:08:33.289Z",
        "type": "Periodical"
    },
    {
        "id": 482054315,
        "latitude": 40.7594383,
        "longitude": -73.9609283,
        "accuracy": 138,
        "source": "Fused",
        "ts": "2024-10-13T02:29:52.337Z",
        "type": "Periodical"
    },
    {
        "id": 482033460,
        "latitude": 40.7629983,
        "longitude": -73.9511683,
        "accuracy": 133,
        "source": "Fused",
        "ts": "2024-10-13T01:50:45.259Z",
        "type": "Periodical"
    },
    {
        "id": 481997719,
        "latitude": 40.763065,
        "longitude": -73.9521767,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-13T00:42:24.954Z",
        "type": "Periodical"
    },
    {
        "id": 481974462,
        "latitude": 40.763065,
        "longitude": -73.9521767,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-12T23:57:14.160Z",
        "type": "Periodical"
    },
    {
        "id": 481931104,
        "latitude": 40.763065,
        "longitude": -73.9521767,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-12T22:33:14.316Z",
        "type": "Periodical"
    },
    {
        "id": 481911387,
        "latitude": 40.763065,
        "longitude": -73.9521767,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-12T21:54:29.288Z",
        "type": "Periodical"
    },
    {
        "id": 481687016,
        "latitude": 40.762265,
        "longitude": -73.9489717,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-12T14:39:58.483Z",
        "type": "Periodical"
    },
    {
        "id": 481666718,
        "latitude": 40.74991,
        "longitude": -73.9629933,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-12T14:02:43.759Z",
        "type": "Periodical"
    },
    {
        "id": 481655051,
        "latitude": 40.762225,
        "longitude": -73.9496233,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-12T13:36:01.289Z",
        "type": "Periodical"
    },
    {
        "id": 481652370,
        "latitude": 40.7611,
        "longitude": -73.95133,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-12T13:30:10.493Z",
        "type": "Periodical"
    },
    {
        "id": 481626668,
        "latitude": 40.76212,
        "longitude": -73.9495717,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-12T12:29:16.568Z",
        "type": "Periodical"
    },
    {
        "id": 481612886,
        "latitude": 40.76295,
        "longitude": -73.949085,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-10-12T11:54:38.289Z",
        "type": "Periodical"
    },
    {
        "id": 481411776,
        "latitude": 40.7628333,
        "longitude": -73.9500817,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-12T03:24:59.617Z",
        "type": "Periodical"
    },
    {
        "id": 481398568,
        "latitude": 40.7629933,
        "longitude": -73.94969,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-12T02:59:32.577Z",
        "type": "Periodical"
    },
    {
        "id": 481383562,
        "latitude": 40.7616217,
        "longitude": -73.9583583,
        "accuracy": 179,
        "source": "Fused",
        "ts": "2024-10-12T02:30:36.726Z",
        "type": "Periodical"
    },
    {
        "id": 481339964,
        "latitude": 40.762625,
        "longitude": -73.9497467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-12T01:11:18.946Z",
        "type": "Periodical"
    },
    {
        "id": 481321707,
        "latitude": 40.762625,
        "longitude": -73.9497467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-12T00:38:08.659Z",
        "type": "Periodical"
    },
    {
        "id": 481288436,
        "latitude": 40.762625,
        "longitude": -73.9497467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-11T23:37:36.959Z",
        "type": "Periodical"
    },
    {
        "id": 481244158,
        "latitude": 40.762625,
        "longitude": -73.9497467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-11T22:22:37.502Z",
        "type": "Periodical"
    },
    {
        "id": 481232360,
        "latitude": 40.762625,
        "longitude": -73.9497467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-11T22:01:37.426Z",
        "type": "Periodical"
    },
    {
        "id": 481225161,
        "latitude": 40.7618817,
        "longitude": -73.9485217,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-11T21:48:42.290Z",
        "type": "Periodical"
    },
    {
        "id": 481190093,
        "latitude": 40.7626817,
        "longitude": -73.9503433,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-11T20:46:28.289Z",
        "type": "Periodical"
    },
    {
        "id": 481167964,
        "latitude": 40.7623533,
        "longitude": -73.9495633,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-11T20:06:37.053Z",
        "type": "Periodical"
    },
    {
        "id": 481148772,
        "latitude": 40.7623533,
        "longitude": -73.9495633,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-11T19:30:36.969Z",
        "type": "Periodical"
    },
    {
        "id": 481118270,
        "latitude": 40.7623533,
        "longitude": -73.9495633,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-11T18:31:36.520Z",
        "type": "Periodical"
    },
    {
        "id": 481102664,
        "latitude": 40.7623533,
        "longitude": -73.9495633,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-11T17:57:36.485Z",
        "type": "Periodical"
    },
    {
        "id": 481087653,
        "latitude": 40.7623533,
        "longitude": -73.9495633,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-11T17:23:57.781Z",
        "type": "Periodical"
    },
    {
        "id": 481076457,
        "latitude": 40.7620217,
        "longitude": -73.948925,
        "accuracy": 161,
        "source": "Fused",
        "ts": "2024-10-11T16:57:55.289Z",
        "type": "Periodical"
    },
    {
        "id": 481063306,
        "latitude": 40.7634612,
        "longitude": -73.9457684,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-11T16:28:21.128Z",
        "type": "Periodical"
    },
    {
        "id": 481049764,
        "latitude": 40.7327632,
        "longitude": -73.8627477,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-11T15:52:06.339Z",
        "type": "Periodical"
    },
    {
        "id": 481044640,
        "latitude": 40.73334,
        "longitude": -73.8626667,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-11T15:45:07.043Z",
        "type": "Periodical"
    },
    {
        "id": 481022609,
        "latitude": 40.7410791,
        "longitude": -73.9529574,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-11T14:53:12.428Z",
        "type": "Periodical"
    },
    {
        "id": 481018016,
        "latitude": 40.765087,
        "longitude": -73.9462196,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-11T14:42:55.643Z",
        "type": "Periodical"
    },
    {
        "id": 480931024,
        "latitude": 40.7621483,
        "longitude": -73.9498217,
        "accuracy": 179,
        "source": "Fused",
        "ts": "2024-10-11T11:14:22.609Z",
        "type": "Periodical"
    },
    {
        "id": 480913195,
        "latitude": 40.763345,
        "longitude": -73.949585,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-11T10:29:50.562Z",
        "type": "Periodical"
    },
    {
        "id": 480904194,
        "latitude": 40.7623183,
        "longitude": -73.94944,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-11T10:04:51.289Z",
        "type": "Periodical"
    },
    {
        "id": 480889428,
        "latitude": 40.762535,
        "longitude": -73.9496883,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-11T09:23:32.289Z",
        "type": "Periodical"
    },
    {
        "id": 480877562,
        "latitude": 40.7629233,
        "longitude": -73.9501317,
        "accuracy": 170,
        "source": "Fused",
        "ts": "2024-10-11T08:50:14.881Z",
        "type": "Periodical"
    },
    {
        "id": 480865769,
        "latitude": 40.7637683,
        "longitude": -73.9425683,
        "accuracy": 181,
        "source": "Fused",
        "ts": "2024-10-11T08:16:11.290Z",
        "type": "Periodical"
    },
    {
        "id": 480851923,
        "latitude": 40.7626417,
        "longitude": -73.9491017,
        "accuracy": 108,
        "source": "Fused",
        "ts": "2024-10-11T07:42:25.842Z",
        "type": "Periodical"
    },
    {
        "id": 480843369,
        "latitude": 40.76225,
        "longitude": -73.94936,
        "accuracy": 110,
        "source": "Fused",
        "ts": "2024-10-11T07:19:06.795Z",
        "type": "Periodical"
    },
    {
        "id": 480829576,
        "latitude": 40.7617683,
        "longitude": -73.9492367,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-11T06:44:12.291Z",
        "type": "Periodical"
    },
    {
        "id": 480818306,
        "latitude": 40.7621067,
        "longitude": -73.9492217,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-11T06:14:03.290Z",
        "type": "Periodical"
    },
    {
        "id": 480803111,
        "latitude": 40.762025,
        "longitude": -73.9490717,
        "accuracy": 94,
        "source": "Fused",
        "ts": "2024-10-11T05:36:13.834Z",
        "type": "Periodical"
    },
    {
        "id": 480795129,
        "latitude": 40.7622033,
        "longitude": -73.9494333,
        "accuracy": 105,
        "source": "Fused",
        "ts": "2024-10-11T05:16:57.931Z",
        "type": "Periodical"
    },
    {
        "id": 480780789,
        "latitude": 40.7625383,
        "longitude": -73.9493717,
        "accuracy": 103,
        "source": "Fused",
        "ts": "2024-10-11T04:45:00.900Z",
        "type": "Periodical"
    },
    {
        "id": 480766417,
        "latitude": 40.7624767,
        "longitude": -73.9495367,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-11T04:13:25.361Z",
        "type": "Periodical"
    },
    {
        "id": 480751525,
        "latitude": 40.762695,
        "longitude": -73.9494267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-11T03:42:12.288Z",
        "type": "Periodical"
    },
    {
        "id": 480737031,
        "latitude": 40.76146,
        "longitude": -73.951245,
        "accuracy": 153,
        "source": "Fused",
        "ts": "2024-10-11T03:12:17.287Z",
        "type": "Periodical"
    },
    {
        "id": 480724609,
        "latitude": 40.762405,
        "longitude": -73.9498367,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-11T02:48:49.289Z",
        "type": "Periodical"
    },
    {
        "id": 480720498,
        "latitude": 40.7620633,
        "longitude": -73.9491283,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-11T02:41:42.738Z",
        "type": "Periodical"
    },
    {
        "id": 480665981,
        "latitude": 40.7649083,
        "longitude": -73.9484483,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-11T01:10:54.647Z",
        "type": "Periodical"
    },
    {
        "id": 480654793,
        "latitude": 40.7640333,
        "longitude": -73.9497167,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-11T00:52:00.299Z",
        "type": "Periodical"
    },
    {
        "id": 480643633,
        "latitude": 40.7620517,
        "longitude": -73.94958,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-11T00:32:16.632Z",
        "type": "Periodical"
    },
    {
        "id": 480624785,
        "latitude": 40.7620517,
        "longitude": -73.94958,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-10T23:58:29.183Z",
        "type": "Periodical"
    },
    {
        "id": 480598491,
        "latitude": 40.7620517,
        "longitude": -73.94958,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-10T23:12:43.610Z",
        "type": "Periodical"
    },
    {
        "id": 480586148,
        "latitude": 40.763725,
        "longitude": -73.94835,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-10T22:50:33.546Z",
        "type": "Periodical"
    },
    {
        "id": 480565152,
        "latitude": 40.792612,
        "longitude": -73.9244086,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-10T22:11:10.028Z",
        "type": "Periodical"
    },
    {
        "id": 480544419,
        "latitude": 40.7828417,
        "longitude": -73.9273517,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-10-10T21:36:27.778Z",
        "type": "Periodical"
    },
    {
        "id": 480523780,
        "latitude": 40.7828417,
        "longitude": -73.9273517,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-10-10T20:58:27.287Z",
        "type": "Periodical"
    },
    {
        "id": 480503471,
        "latitude": 40.7828,
        "longitude": -73.9273817,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-10-10T20:22:27.286Z",
        "type": "Periodical"
    },
    {
        "id": 480488738,
        "latitude": 40.7821322,
        "longitude": -73.9294497,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-10-10T19:56:04.287Z",
        "type": "Periodical"
    },
    {
        "id": 480477788,
        "latitude": 40.7707033,
        "longitude": -73.920965,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-10T19:33:32.824Z",
        "type": "Periodical"
    },
    {
        "id": 480465868,
        "latitude": 40.763005,
        "longitude": -73.949405,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-10T19:09:29.288Z",
        "type": "Periodical"
    },
    {
        "id": 480432101,
        "latitude": 40.7627517,
        "longitude": -73.9493917,
        "accuracy": 150,
        "source": "Fused",
        "ts": "2024-10-10T17:48:50.603Z",
        "type": "Periodical"
    },
    {
        "id": 480423479,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T17:27:26.921Z",
        "type": "Periodical"
    },
    {
        "id": 480406978,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T16:48:26.800Z",
        "type": "Periodical"
    },
    {
        "id": 480396432,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T16:21:26.693Z",
        "type": "Periodical"
    },
    {
        "id": 480385294,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-10T15:50:26.515Z",
        "type": "Periodical"
    },
    {
        "id": 480377753,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T15:30:26.505Z",
        "type": "Periodical"
    },
    {
        "id": 480371215,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T15:12:55.153Z",
        "type": "Periodical"
    },
    {
        "id": 480355612,
        "latitude": 40.76198,
        "longitude": -73.949445,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T14:33:46.793Z",
        "type": "Periodical"
    },
    {
        "id": 480341029,
        "latitude": 40.7617333,
        "longitude": -73.94947,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-10-10T13:56:07.524Z",
        "type": "Periodical"
    },
    {
        "id": 480337631,
        "latitude": 40.7622783,
        "longitude": -73.9491317,
        "accuracy": 143,
        "source": "Fused",
        "ts": "2024-10-10T13:47:36.462Z",
        "type": "Periodical"
    },
    {
        "id": 480319886,
        "latitude": 40.7625317,
        "longitude": -73.9494467,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-10T13:02:12.833Z",
        "type": "Periodical"
    },
    {
        "id": 480308241,
        "latitude": 40.7623967,
        "longitude": -73.9492383,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-10T12:33:26.289Z",
        "type": "Periodical"
    },
    {
        "id": 480293880,
        "latitude": 40.7626517,
        "longitude": -73.94917,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-10T11:59:13.189Z",
        "type": "Periodical"
    },
    {
        "id": 480277262,
        "latitude": 40.7625467,
        "longitude": -73.9496333,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-10-10T11:23:01.290Z",
        "type": "Periodical"
    },
    {
        "id": 480277035,
        "latitude": 40.7623917,
        "longitude": -73.9497667,
        "accuracy": 103,
        "source": "Fused",
        "ts": "2024-10-10T11:22:24.289Z",
        "type": "Periodical"
    },
    {
        "id": 480084310,
        "latitude": 40.7620967,
        "longitude": -73.947985,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-10T03:25:58.291Z",
        "type": "Periodical"
    },
    {
        "id": 480067423,
        "latitude": 40.7618583,
        "longitude": -73.9495667,
        "accuracy": 89,
        "source": "Fused",
        "ts": "2024-10-10T02:52:49.819Z",
        "type": "Periodical"
    },
    {
        "id": 480049475,
        "latitude": 40.7597117,
        "longitude": -73.9513317,
        "accuracy": 98,
        "source": "Fused",
        "ts": "2024-10-10T02:18:51.626Z",
        "type": "Periodical"
    },
    {
        "id": 480030344,
        "latitude": 40.762425,
        "longitude": -73.9500633,
        "accuracy": 73,
        "source": "Fused",
        "ts": "2024-10-10T01:45:47.864Z",
        "type": "Periodical"
    },
    {
        "id": 480022510,
        "latitude": 40.762355,
        "longitude": -73.9491733,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T01:32:06.801Z",
        "type": "Periodical"
    },
    {
        "id": 479982394,
        "latitude": 40.7628883,
        "longitude": -73.9491833,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-10T00:22:15.291Z",
        "type": "Periodical"
    },
    {
        "id": 479873231,
        "latitude": 40.763775,
        "longitude": -73.9516917,
        "accuracy": 177,
        "source": "Fused",
        "ts": "2024-10-09T21:18:35.625Z",
        "type": "Periodical"
    },
    {
        "id": 479854930,
        "latitude": 40.7624717,
        "longitude": -73.9494233,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-10-09T20:43:17.288Z",
        "type": "Periodical"
    },
    {
        "id": 479840425,
        "latitude": 40.76503,
        "longitude": -73.9460667,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-10-09T20:18:16.288Z",
        "type": "Periodical"
    },
    {
        "id": 479828808,
        "latitude": 40.7707519,
        "longitude": -73.9205159,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-10-09T19:57:09.288Z",
        "type": "Periodical"
    },
    {
        "id": 479809986,
        "latitude": 40.903735,
        "longitude": -73.8585033,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-09T19:20:21.609Z",
        "type": "Periodical"
    },
    {
        "id": 479793960,
        "latitude": 41.3257917,
        "longitude": -73.821645,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-09T18:47:21.500Z",
        "type": "Periodical"
    },
    {
        "id": 479781698,
        "latitude": 41.626285,
        "longitude": -73.7737733,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T18:18:21.378Z",
        "type": "Periodical"
    },
    {
        "id": 479769677,
        "latitude": 42.0058197,
        "longitude": -73.7389768,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T17:47:11.591Z",
        "type": "Periodical"
    },
    {
        "id": 479753793,
        "latitude": 42.3248617,
        "longitude": -74.1004491,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T17:08:55.375Z",
        "type": "Periodical"
    },
    {
        "id": 479742889,
        "latitude": 42.3073367,
        "longitude": -74.253885,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-09T16:44:20.988Z",
        "type": "Periodical"
    },
    {
        "id": 479729407,
        "latitude": 42.3073122,
        "longitude": -74.2533946,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T16:09:07.721Z",
        "type": "Periodical"
    },
    {
        "id": 479721625,
        "latitude": 42.227263,
        "longitude": -74.1361946,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T15:48:04.158Z",
        "type": "Periodical"
    },
    {
        "id": 479721617,
        "latitude": 42.226628,
        "longitude": -74.1356077,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T15:47:56.401Z",
        "type": "Periodical"
    },
    {
        "id": 479713873,
        "latitude": 42.1765924,
        "longitude": -74.0376469,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-09T15:27:57.906Z",
        "type": "Periodical"
    },
    {
        "id": 479706843,
        "latitude": 42.004285,
        "longitude": -74.002005,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-09T15:09:06.729Z",
        "type": "Periodical"
    },
    {
        "id": 479696604,
        "latitude": 41.5989033,
        "longitude": -74.08517,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-09T14:43:06.351Z",
        "type": "Periodical"
    },
    {
        "id": 479688504,
        "latitude": 41.267925,
        "longitude": -74.1529317,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-09T14:22:07.198Z",
        "type": "Periodical"
    },
    {
        "id": 479680757,
        "latitude": 41.003115,
        "longitude": -74.1015833,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-09T14:02:17.288Z",
        "type": "Periodical"
    },
    {
        "id": 479671819,
        "latitude": 40.8526956,
        "longitude": -73.955702,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-09T13:41:01.290Z",
        "type": "Periodical"
    },
    {
        "id": 479656109,
        "latitude": 40.7596,
        "longitude": -73.9273617,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-09T13:00:59.275Z",
        "type": "Periodical"
    },
    {
        "id": 479648908,
        "latitude": 40.76276,
        "longitude": -73.9490617,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-09T12:42:39.859Z",
        "type": "Periodical"
    },
    {
        "id": 479636380,
        "latitude": 40.7628467,
        "longitude": -73.9496067,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-09T12:12:05.928Z",
        "type": "Periodical"
    },
    {
        "id": 479625328,
        "latitude": 40.7624667,
        "longitude": -73.9494633,
        "accuracy": 126,
        "source": "Fused",
        "ts": "2024-10-09T11:47:18.026Z",
        "type": "Periodical"
    },
    {
        "id": 479610811,
        "latitude": 40.7621067,
        "longitude": -73.9495167,
        "accuracy": 94,
        "source": "Fused",
        "ts": "2024-10-09T11:12:20.290Z",
        "type": "Periodical"
    },
    {
        "id": 479596715,
        "latitude": 40.7626833,
        "longitude": -73.9494067,
        "accuracy": 152,
        "source": "Fused",
        "ts": "2024-10-09T10:38:19.290Z",
        "type": "Periodical"
    },
    {
        "id": 479583462,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T10:03:44.507Z",
        "type": "Periodical"
    },
    {
        "id": 479558872,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T09:01:38.357Z",
        "type": "Periodical"
    },
    {
        "id": 479537323,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T08:02:59.367Z",
        "type": "Periodical"
    },
    {
        "id": 479516804,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T07:08:58.988Z",
        "type": "Periodical"
    },
    {
        "id": 479496442,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T06:17:10.106Z",
        "type": "Periodical"
    },
    {
        "id": 479481936,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T05:43:09.961Z",
        "type": "Periodical"
    },
    {
        "id": 479472610,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T05:20:09.882Z",
        "type": "Periodical"
    },
    {
        "id": 479457845,
        "latitude": 40.7622167,
        "longitude": -73.949455,
        "accuracy": 164,
        "source": "Fused",
        "ts": "2024-10-09T04:46:05.290Z",
        "type": "Periodical"
    },
    {
        "id": 479443498,
        "latitude": 40.7627217,
        "longitude": -73.95003,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-10-09T04:14:58.985Z",
        "type": "Periodical"
    },
    {
        "id": 479426277,
        "latitude": 40.7616117,
        "longitude": -73.947295,
        "accuracy": 186,
        "source": "Fused",
        "ts": "2024-10-09T03:39:38.811Z",
        "type": "Periodical"
    },
    {
        "id": 479412476,
        "latitude": 40.7624733,
        "longitude": -73.9498233,
        "accuracy": 169,
        "source": "Fused",
        "ts": "2024-10-09T03:12:30.747Z",
        "type": "Periodical"
    },
    {
        "id": 479399915,
        "latitude": 40.762525,
        "longitude": -73.9487367,
        "accuracy": 143,
        "source": "Fused",
        "ts": "2024-10-09T02:49:37.172Z",
        "type": "Periodical"
    },
    {
        "id": 479396086,
        "latitude": 40.762195,
        "longitude": -73.9494983,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-09T02:41:58.288Z",
        "type": "Periodical"
    },
    {
        "id": 479291460,
        "latitude": 40.7624767,
        "longitude": -73.9495667,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-08T23:35:03.105Z",
        "type": "Periodical"
    },
    {
        "id": 479282250,
        "latitude": 40.7594233,
        "longitude": -73.9532767,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-08T23:22:00.840Z",
        "type": "Periodical"
    },
    {
        "id": 479268388,
        "latitude": 40.757175,
        "longitude": -73.9893133,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-08T22:58:05.290Z",
        "type": "Periodical"
    },
    {
        "id": 479201700,
        "latitude": 40.75525,
        "longitude": -73.9896133,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-10-08T21:07:47.289Z",
        "type": "Periodical"
    },
    {
        "id": 479180953,
        "latitude": 40.755225,
        "longitude": -73.9899233,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-08T20:33:02.572Z",
        "type": "Periodical"
    },
    {
        "id": 479164897,
        "latitude": 40.7613117,
        "longitude": -73.9516,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-08T20:04:00.835Z",
        "type": "Periodical"
    },
    {
        "id": 479148641,
        "latitude": 40.7621183,
        "longitude": -73.94944,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-08T19:31:35.555Z",
        "type": "Periodical"
    },
    {
        "id": 479136507,
        "latitude": 40.7624333,
        "longitude": -73.949825,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-08T19:05:39.288Z",
        "type": "Periodical"
    },
    {
        "id": 479130578,
        "latitude": 40.7629267,
        "longitude": -73.9499167,
        "accuracy": 176,
        "source": "Fused",
        "ts": "2024-10-08T18:52:32.240Z",
        "type": "Periodical"
    },
    {
        "id": 479110874,
        "latitude": 40.7628283,
        "longitude": -73.9496883,
        "accuracy": 118,
        "source": "Fused",
        "ts": "2024-10-08T18:05:23.775Z",
        "type": "Periodical"
    },
    {
        "id": 479100838,
        "latitude": 40.7624417,
        "longitude": -73.9494783,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-08T17:40:10.792Z",
        "type": "Periodical"
    },
    {
        "id": 479083883,
        "latitude": 40.762325,
        "longitude": -73.9490167,
        "accuracy": 146,
        "source": "Fused",
        "ts": "2024-10-08T16:58:59.717Z",
        "type": "Periodical"
    },
    {
        "id": 479073713,
        "latitude": 40.762385,
        "longitude": -73.9503967,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-08T16:31:02.500Z",
        "type": "Periodical"
    },
    {
        "id": 479061858,
        "latitude": 40.762475,
        "longitude": -73.9493917,
        "accuracy": 196,
        "source": "Fused",
        "ts": "2024-10-08T15:58:22.299Z",
        "type": "Periodical"
    },
    {
        "id": 479048962,
        "latitude": 40.764095,
        "longitude": -73.947445,
        "accuracy": 141,
        "source": "Fused",
        "ts": "2024-10-08T15:22:30.400Z",
        "type": "Periodical"
    },
    {
        "id": 479039314,
        "latitude": 40.7625917,
        "longitude": -73.9506883,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-08T14:55:24.227Z",
        "type": "Periodical"
    },
    {
        "id": 479022747,
        "latitude": 40.7622683,
        "longitude": -73.9490017,
        "accuracy": 99,
        "source": "Fused",
        "ts": "2024-10-08T14:12:38.417Z",
        "type": "Periodical"
    },
    {
        "id": 479016469,
        "latitude": 40.7622767,
        "longitude": -73.9495417,
        "accuracy": 138,
        "source": "Fused",
        "ts": "2024-10-08T13:55:37.290Z",
        "type": "Periodical"
    },
    {
        "id": 478975073,
        "latitude": 40.7623067,
        "longitude": -73.9496267,
        "accuracy": 148,
        "source": "Fused",
        "ts": "2024-10-08T12:12:05.290Z",
        "type": "Periodical"
    },
    {
        "id": 478962544,
        "latitude": 40.7628483,
        "longitude": -73.9495867,
        "accuracy": 124,
        "source": "Fused",
        "ts": "2024-10-08T11:42:20.515Z",
        "type": "Periodical"
    },
    {
        "id": 478951594,
        "latitude": 40.7631183,
        "longitude": -73.9502617,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-08T11:16:39.289Z",
        "type": "Periodical"
    },
    {
        "id": 478937260,
        "latitude": 40.7622667,
        "longitude": -73.9493917,
        "accuracy": 125,
        "source": "Fused",
        "ts": "2024-10-08T10:42:22.816Z",
        "type": "Periodical"
    },
    {
        "id": 478926549,
        "latitude": 40.7625567,
        "longitude": -73.949115,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-08T10:15:18.819Z",
        "type": "Periodical"
    },
    {
        "id": 478911334,
        "latitude": 40.761585,
        "longitude": -73.9491367,
        "accuracy": 93,
        "source": "Fused",
        "ts": "2024-10-08T09:35:00.783Z",
        "type": "Periodical"
    },
    {
        "id": 478897153,
        "latitude": 40.7612,
        "longitude": -73.949715,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-08T08:56:29.464Z",
        "type": "Periodical"
    },
    {
        "id": 478886465,
        "latitude": 40.7633033,
        "longitude": -73.9488717,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-08T08:27:28.293Z",
        "type": "Periodical"
    },
    {
        "id": 478875929,
        "latitude": 40.763405,
        "longitude": -73.9493383,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-08T07:59:24.089Z",
        "type": "Periodical"
    },
    {
        "id": 478862438,
        "latitude": 40.7627417,
        "longitude": -73.950735,
        "accuracy": 108,
        "source": "Fused",
        "ts": "2024-10-08T07:24:25.764Z",
        "type": "Periodical"
    },
    {
        "id": 478848965,
        "latitude": 40.7626683,
        "longitude": -73.9503,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-10-08T06:49:49.290Z",
        "type": "Periodical"
    },
    {
        "id": 478835151,
        "latitude": 40.7624233,
        "longitude": -73.9497967,
        "accuracy": 133,
        "source": "Fused",
        "ts": "2024-10-08T06:14:26.290Z",
        "type": "Periodical"
    },
    {
        "id": 478815540,
        "latitude": 40.7621067,
        "longitude": -73.9491683,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-08T05:27:04.290Z",
        "type": "Periodical"
    },
    {
        "id": 478800545,
        "latitude": 40.7627567,
        "longitude": -73.949745,
        "accuracy": 102,
        "source": "Fused",
        "ts": "2024-10-08T04:52:32.009Z",
        "type": "Periodical"
    },
    {
        "id": 478788646,
        "latitude": 40.7632983,
        "longitude": -73.9500667,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-08T04:25:47.294Z",
        "type": "Periodical"
    },
    {
        "id": 478781802,
        "latitude": 40.7629167,
        "longitude": -73.9496083,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-08T04:05:51.320Z",
        "type": "Periodical"
    },
    {
        "id": 478763393,
        "latitude": 40.76279,
        "longitude": -73.9489883,
        "accuracy": 142,
        "source": "Fused",
        "ts": "2024-10-08T03:34:22.753Z",
        "type": "Periodical"
    },
    {
        "id": 478749871,
        "latitude": 40.7621317,
        "longitude": -73.9493517,
        "accuracy": 137,
        "source": "Fused",
        "ts": "2024-10-08T03:06:45.483Z",
        "type": "Periodical"
    },
    {
        "id": 478731331,
        "latitude": 40.7621267,
        "longitude": -73.9486633,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-08T02:30:56.952Z",
        "type": "Periodical"
    },
    {
        "id": 478709643,
        "latitude": 40.7621267,
        "longitude": -73.9486633,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-08T01:52:12.021Z",
        "type": "Periodical"
    },
    {
        "id": 478691433,
        "latitude": 40.7621267,
        "longitude": -73.9486633,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-10-08T01:22:12.008Z",
        "type": "Periodical"
    },
    {
        "id": 478680815,
        "latitude": 40.76241,
        "longitude": -73.9496833,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-08T01:03:40.380Z",
        "type": "Periodical"
    },
    {
        "id": 478610152,
        "latitude": 40.76208,
        "longitude": -73.94793,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-10-07T22:58:07.043Z",
        "type": "Periodical"
    },
    {
        "id": 478593312,
        "latitude": 40.76208,
        "longitude": -73.94793,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-10-07T22:30:24.087Z",
        "type": "Periodical"
    },
    {
        "id": 478570126,
        "latitude": 40.76208,
        "longitude": -73.94793,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-10-07T21:51:23.870Z",
        "type": "Periodical"
    },
    {
        "id": 478544207,
        "latitude": 40.76208,
        "longitude": -73.94793,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-10-07T21:05:57.250Z",
        "type": "Periodical"
    },
    {
        "id": 478529053,
        "latitude": 40.763005,
        "longitude": -73.949555,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-07T20:38:28.287Z",
        "type": "Periodical"
    },
    {
        "id": 478506738,
        "latitude": 40.76805,
        "longitude": -73.9458667,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-07T19:58:07.073Z",
        "type": "Periodical"
    },
    {
        "id": 478494834,
        "latitude": 40.76221,
        "longitude": -73.9503517,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-07T19:34:06.927Z",
        "type": "Periodical"
    },
    {
        "id": 478492796,
        "latitude": 40.76275,
        "longitude": -73.950175,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-07T19:30:03.594Z",
        "type": "Periodical"
    },
    {
        "id": 478388060,
        "latitude": 40.76286,
        "longitude": -73.9498133,
        "accuracy": 97,
        "source": "Fused",
        "ts": "2024-10-07T15:12:06.156Z",
        "type": "Periodical"
    },
    {
        "id": 478373792,
        "latitude": 40.7623733,
        "longitude": -73.94945,
        "accuracy": 146,
        "source": "Fused",
        "ts": "2024-10-07T14:35:29.270Z",
        "type": "Periodical"
    },
    {
        "id": 478360513,
        "latitude": 40.7612267,
        "longitude": -73.9500467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-07T14:00:38.818Z",
        "type": "Periodical"
    },
    {
        "id": 478353848,
        "latitude": 40.7619967,
        "longitude": -73.948855,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-07T13:44:07.291Z",
        "type": "Periodical"
    },
    {
        "id": 478321196,
        "latitude": 40.7625883,
        "longitude": -73.9494933,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-07T12:24:30.991Z",
        "type": "Periodical"
    },
    {
        "id": 478307259,
        "latitude": 40.76246,
        "longitude": -73.9515,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-07T11:51:35.290Z",
        "type": "Periodical"
    },
    {
        "id": 478298031,
        "latitude": 40.7625283,
        "longitude": -73.94954,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-10-07T11:29:20.671Z",
        "type": "Periodical"
    },
    {
        "id": 478289141,
        "latitude": 40.762,
        "longitude": -73.949875,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-07T11:08:29.834Z",
        "type": "Periodical"
    },
    {
        "id": 478272237,
        "latitude": 40.7619883,
        "longitude": -73.95029,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-07T10:27:52.472Z",
        "type": "Periodical"
    },
    {
        "id": 478262369,
        "latitude": 40.762385,
        "longitude": -73.9490667,
        "accuracy": 154,
        "source": "Fused",
        "ts": "2024-10-07T10:01:56.535Z",
        "type": "Periodical"
    },
    {
        "id": 478252578,
        "latitude": 40.7626633,
        "longitude": -73.94959,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-07T09:35:22.504Z",
        "type": "Periodical"
    },
    {
        "id": 478244686,
        "latitude": 40.7603717,
        "longitude": -73.9513283,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-07T09:13:28.654Z",
        "type": "Periodical"
    },
    {
        "id": 478230131,
        "latitude": 40.7621817,
        "longitude": -73.94989,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-07T08:34:05.920Z",
        "type": "Periodical"
    },
    {
        "id": 478222377,
        "latitude": 40.7629233,
        "longitude": -73.94917,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-07T08:13:00.289Z",
        "type": "Periodical"
    },
    {
        "id": 478214650,
        "latitude": 40.7620783,
        "longitude": -73.9499917,
        "accuracy": 175,
        "source": "Fused",
        "ts": "2024-10-07T07:52:56.752Z",
        "type": "Periodical"
    },
    {
        "id": 478205815,
        "latitude": 40.7626183,
        "longitude": -73.9500133,
        "accuracy": 145,
        "source": "Fused",
        "ts": "2024-10-07T07:29:09.624Z",
        "type": "Periodical"
    },
    {
        "id": 478192781,
        "latitude": 40.7624017,
        "longitude": -73.949245,
        "accuracy": 173,
        "source": "Fused",
        "ts": "2024-10-07T06:55:09.288Z",
        "type": "Periodical"
    },
    {
        "id": 478184409,
        "latitude": 40.7623,
        "longitude": -73.9492417,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-07T06:33:07.289Z",
        "type": "Periodical"
    },
    {
        "id": 478169064,
        "latitude": 40.7625617,
        "longitude": -73.9496967,
        "accuracy": 133,
        "source": "Fused",
        "ts": "2024-10-07T05:54:40.974Z",
        "type": "Periodical"
    },
    {
        "id": 478160058,
        "latitude": 40.7628533,
        "longitude": -73.94819,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-07T05:32:15.287Z",
        "type": "Periodical"
    },
    {
        "id": 478147738,
        "latitude": 40.7627633,
        "longitude": -73.9498167,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-07T05:03:03.289Z",
        "type": "Periodical"
    },
    {
        "id": 478136906,
        "latitude": 40.7619283,
        "longitude": -73.9483783,
        "accuracy": 90,
        "source": "Fused",
        "ts": "2024-10-07T04:39:10.050Z",
        "type": "Periodical"
    },
    {
        "id": 478123104,
        "latitude": 40.7623317,
        "longitude": -73.9495983,
        "accuracy": 107,
        "source": "Fused",
        "ts": "2024-10-07T04:09:04.131Z",
        "type": "Periodical"
    },
    {
        "id": 478103832,
        "latitude": 40.7587333,
        "longitude": -73.955785,
        "accuracy": 110,
        "source": "Fused",
        "ts": "2024-10-07T03:29:55.445Z",
        "type": "Periodical"
    },
    {
        "id": 478099025,
        "latitude": 40.7626667,
        "longitude": -73.9493767,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-07T03:20:16.287Z",
        "type": "Periodical"
    },
    {
        "id": 478039985,
        "latitude": 40.7617383,
        "longitude": -73.9497233,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-10-07T01:28:28.290Z",
        "type": "Periodical"
    },
    {
        "id": 477940474,
        "latitude": 40.7627517,
        "longitude": -73.9494967,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-06T22:29:20.061Z",
        "type": "Periodical"
    },
    {
        "id": 477926588,
        "latitude": 40.7625783,
        "longitude": -73.949675,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T22:05:12.588Z",
        "type": "Periodical"
    },
    {
        "id": 477903318,
        "latitude": 40.7644133,
        "longitude": -73.94761,
        "accuracy": 94,
        "source": "Fused",
        "ts": "2024-10-06T21:22:27.361Z",
        "type": "Periodical"
    },
    {
        "id": 477895625,
        "latitude": 40.7629967,
        "longitude": -73.9496267,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T21:07:59.287Z",
        "type": "Periodical"
    },
    {
        "id": 477844223,
        "latitude": 40.7626433,
        "longitude": -73.9494417,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-06T19:29:10.977Z",
        "type": "Periodical"
    },
    {
        "id": 477836279,
        "latitude": 40.7591617,
        "longitude": -73.953685,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-06T19:15:57.908Z",
        "type": "Periodical"
    },
    {
        "id": 477817003,
        "latitude": 40.7519517,
        "longitude": -73.9806583,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-06T18:40:18.641Z",
        "type": "Periodical"
    },
    {
        "id": 477804476,
        "latitude": 40.7537033,
        "longitude": -73.9846933,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-06T18:15:34.492Z",
        "type": "Periodical"
    },
    {
        "id": 477793055,
        "latitude": 40.7539617,
        "longitude": -73.9817583,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-06T17:52:08.254Z",
        "type": "Periodical"
    },
    {
        "id": 477763447,
        "latitude": 40.761415,
        "longitude": -73.9504467,
        "accuracy": 150,
        "source": "Fused",
        "ts": "2024-10-06T16:54:58.983Z",
        "type": "Periodical"
    },
    {
        "id": 477750039,
        "latitude": 40.7616083,
        "longitude": -73.95047,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-06T16:28:13.235Z",
        "type": "Periodical"
    },
    {
        "id": 477733852,
        "latitude": 40.7618133,
        "longitude": -73.9500733,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-10-06T15:56:24.228Z",
        "type": "Periodical"
    },
    {
        "id": 477715326,
        "latitude": 40.7617017,
        "longitude": -73.950535,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-06T15:18:14.109Z",
        "type": "Periodical"
    },
    {
        "id": 477703651,
        "latitude": 40.7619783,
        "longitude": -73.9504533,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-06T14:53:49.609Z",
        "type": "Periodical"
    },
    {
        "id": 477694830,
        "latitude": 40.758365,
        "longitude": -73.9476133,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T14:34:32.745Z",
        "type": "Periodical"
    },
    {
        "id": 477679870,
        "latitude": 40.762085,
        "longitude": -73.9508983,
        "accuracy": 80,
        "source": "Fused",
        "ts": "2024-10-06T14:02:22.263Z",
        "type": "Periodical"
    },
    {
        "id": 477666837,
        "latitude": 40.76167,
        "longitude": -73.9498367,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-06T13:37:10.235Z",
        "type": "Periodical"
    },
    {
        "id": 477656183,
        "latitude": 40.7619533,
        "longitude": -73.9485917,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-06T13:13:24.235Z",
        "type": "Periodical"
    },
    {
        "id": 477605531,
        "latitude": 40.7610183,
        "longitude": -73.950455,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-10-06T11:09:46.235Z",
        "type": "Periodical"
    },
    {
        "id": 477591205,
        "latitude": 40.7629217,
        "longitude": -73.9506883,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-06T10:31:59.796Z",
        "type": "Periodical"
    },
    {
        "id": 477580210,
        "latitude": 40.7628517,
        "longitude": -73.9492967,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T10:00:01.659Z",
        "type": "Periodical"
    },
    {
        "id": 477563775,
        "latitude": 40.7628517,
        "longitude": -73.9492967,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T09:10:55.778Z",
        "type": "Periodical"
    },
    {
        "id": 477548511,
        "latitude": 40.7628517,
        "longitude": -73.9492967,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T08:26:25.788Z",
        "type": "Periodical"
    },
    {
        "id": 477536696,
        "latitude": 40.7628517,
        "longitude": -73.9492967,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-06T07:52:40.065Z",
        "type": "Periodical"
    },
    {
        "id": 477528364,
        "latitude": 40.762625,
        "longitude": -73.9490883,
        "accuracy": 182,
        "source": "Fused",
        "ts": "2024-10-06T07:29:24.795Z",
        "type": "Periodical"
    },
    {
        "id": 477514445,
        "latitude": 40.7623583,
        "longitude": -73.9497767,
        "accuracy": 182,
        "source": "Fused",
        "ts": "2024-10-06T06:51:05.727Z",
        "type": "Periodical"
    },
    {
        "id": 477504569,
        "latitude": 40.76239,
        "longitude": -73.949795,
        "accuracy": 112,
        "source": "Fused",
        "ts": "2024-10-06T06:24:45.795Z",
        "type": "Periodical"
    },
    {
        "id": 477493571,
        "latitude": 40.7620283,
        "longitude": -73.94941,
        "accuracy": 173,
        "source": "Fused",
        "ts": "2024-10-06T05:56:44.234Z",
        "type": "Periodical"
    },
    {
        "id": 477474057,
        "latitude": 40.7624283,
        "longitude": -73.9491,
        "accuracy": 116,
        "source": "Fused",
        "ts": "2024-10-06T05:09:55.761Z",
        "type": "Periodical"
    },
    {
        "id": 477453570,
        "latitude": 40.76195,
        "longitude": -73.948015,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-06T04:28:11.847Z",
        "type": "Periodical"
    },
    {
        "id": 477442617,
        "latitude": 40.7625783,
        "longitude": -73.949845,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-06T04:04:58.343Z",
        "type": "Periodical"
    },
    {
        "id": 477431190,
        "latitude": 40.76253,
        "longitude": -73.949265,
        "accuracy": 106,
        "source": "Fused",
        "ts": "2024-10-06T03:41:12.538Z",
        "type": "Periodical"
    },
    {
        "id": 477418725,
        "latitude": 40.762185,
        "longitude": -73.9496967,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-06T03:18:04.089Z",
        "type": "Periodical"
    },
    {
        "id": 477411544,
        "latitude": 40.7618617,
        "longitude": -73.9489417,
        "accuracy": 119,
        "source": "Fused",
        "ts": "2024-10-06T03:03:24.656Z",
        "type": "Periodical"
    },
    {
        "id": 477140415,
        "latitude": 40.76289,
        "longitude": -73.9497483,
        "accuracy": 117,
        "source": "Fused",
        "ts": "2024-10-05T18:51:10.320Z",
        "type": "Periodical"
    },
    {
        "id": 477120616,
        "latitude": 40.76283,
        "longitude": -73.949805,
        "accuracy": 173,
        "source": "Fused",
        "ts": "2024-10-05T18:13:51.800Z",
        "type": "Periodical"
    },
    {
        "id": 477110737,
        "latitude": 40.7625683,
        "longitude": -73.9492167,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T17:55:27.234Z",
        "type": "Periodical"
    },
    {
        "id": 477091955,
        "latitude": 40.76241,
        "longitude": -73.9490733,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-10-05T17:20:17.233Z",
        "type": "Periodical"
    },
    {
        "id": 477077213,
        "latitude": 40.7624083,
        "longitude": -73.9486267,
        "accuracy": 84,
        "source": "Fused",
        "ts": "2024-10-05T16:53:36.159Z",
        "type": "Periodical"
    },
    {
        "id": 477060245,
        "latitude": 40.7628633,
        "longitude": -73.95011,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T16:19:54.236Z",
        "type": "Periodical"
    },
    {
        "id": 477047453,
        "latitude": 40.7655083,
        "longitude": -73.948255,
        "accuracy": 176,
        "source": "Fused",
        "ts": "2024-10-05T15:56:02.236Z",
        "type": "Periodical"
    },
    {
        "id": 477037207,
        "latitude": 40.76218,
        "longitude": -73.9444367,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-05T15:35:29.947Z",
        "type": "Periodical"
    },
    {
        "id": 477023777,
        "latitude": 40.7448983,
        "longitude": -73.9181533,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T15:09:38.233Z",
        "type": "Periodical"
    },
    {
        "id": 477016406,
        "latitude": 40.7446567,
        "longitude": -73.9183183,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T14:55:10.235Z",
        "type": "Periodical"
    },
    {
        "id": 476960840,
        "latitude": 40.7450167,
        "longitude": -73.917925,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T13:00:30.531Z",
        "type": "Periodical"
    },
    {
        "id": 476893862,
        "latitude": 40.7627083,
        "longitude": -73.9496483,
        "accuracy": 73,
        "source": "Fused",
        "ts": "2024-10-05T10:15:02.062Z",
        "type": "Periodical"
    },
    {
        "id": 476882508,
        "latitude": 40.762425,
        "longitude": -73.9495483,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-05T09:43:28.235Z",
        "type": "Periodical"
    },
    {
        "id": 476867717,
        "latitude": 40.7628017,
        "longitude": -73.9486533,
        "accuracy": 186,
        "source": "Fused",
        "ts": "2024-10-05T09:04:54.234Z",
        "type": "Periodical"
    },
    {
        "id": 476856527,
        "latitude": 40.7635283,
        "longitude": -73.9502067,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-05T08:34:08.810Z",
        "type": "Periodical"
    },
    {
        "id": 476842976,
        "latitude": 40.76243,
        "longitude": -73.94963,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-05T07:56:51.234Z",
        "type": "Periodical"
    },
    {
        "id": 476830919,
        "latitude": 40.7625117,
        "longitude": -73.94993,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-05T07:24:01.234Z",
        "type": "Periodical"
    },
    {
        "id": 476819370,
        "latitude": 40.7624167,
        "longitude": -73.9492567,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-05T06:54:11.234Z",
        "type": "Periodical"
    },
    {
        "id": 476804351,
        "latitude": 40.762855,
        "longitude": -73.9498667,
        "accuracy": 86,
        "source": "Fused",
        "ts": "2024-10-05T06:20:49.366Z",
        "type": "Periodical"
    },
    {
        "id": 476787634,
        "latitude": 40.762315,
        "longitude": -73.94833,
        "accuracy": 109,
        "source": "Fused",
        "ts": "2024-10-05T05:41:13.270Z",
        "type": "Periodical"
    },
    {
        "id": 476772741,
        "latitude": 40.7628267,
        "longitude": -73.9494667,
        "accuracy": 118,
        "source": "Fused",
        "ts": "2024-10-05T05:09:06.192Z",
        "type": "Periodical"
    },
    {
        "id": 476754112,
        "latitude": 40.7622117,
        "longitude": -73.9481133,
        "accuracy": 172,
        "source": "Fused",
        "ts": "2024-10-05T04:29:51.084Z",
        "type": "Periodical"
    },
    {
        "id": 476741752,
        "latitude": 40.76336,
        "longitude": -73.9496467,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-05T04:04:52.026Z",
        "type": "Periodical"
    },
    {
        "id": 476722206,
        "latitude": 40.7613883,
        "longitude": -73.9498767,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-05T03:31:29.718Z",
        "type": "Periodical"
    },
    {
        "id": 476704928,
        "latitude": 40.7625983,
        "longitude": -73.94989,
        "accuracy": 158,
        "source": "Fused",
        "ts": "2024-10-05T03:03:51.458Z",
        "type": "Periodical"
    },
    {
        "id": 476703569,
        "latitude": 40.7593767,
        "longitude": -73.95088,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T02:56:34.885Z",
        "type": "Periodical"
    },
    {
        "id": 476679797,
        "latitude": 40.76198,
        "longitude": -73.9480967,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-05T02:21:39.234Z",
        "type": "Periodical"
    },
    {
        "id": 476662959,
        "latitude": 40.7623817,
        "longitude": -73.9498883,
        "accuracy": 173,
        "source": "Fused",
        "ts": "2024-10-05T01:54:06.574Z",
        "type": "Periodical"
    },
    {
        "id": 476653970,
        "latitude": 40.762355,
        "longitude": -73.94927,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-05T01:38:30.234Z",
        "type": "Periodical"
    },
    {
        "id": 476632686,
        "latitude": 40.7613933,
        "longitude": -73.9481167,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-05T01:02:03.236Z",
        "type": "Periodical"
    },
    {
        "id": 476437168,
        "latitude": 40.763045,
        "longitude": -73.9496783,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-04T19:21:19.611Z",
        "type": "Periodical"
    },
    {
        "id": 476381388,
        "latitude": 40.7628967,
        "longitude": -73.9494033,
        "accuracy": 168,
        "source": "Fused",
        "ts": "2024-10-04T17:14:30.440Z",
        "type": "Periodical"
    },
    {
        "id": 476379242,
        "latitude": 40.7623917,
        "longitude": -73.9495317,
        "accuracy": 194,
        "source": "Fused",
        "ts": "2024-10-04T17:09:04.536Z",
        "type": "Periodical"
    },
    {
        "id": 476369158,
        "latitude": 40.7629383,
        "longitude": -73.9504133,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-04T16:42:03.298Z",
        "type": "Periodical"
    },
    {
        "id": 476350125,
        "latitude": 40.763015,
        "longitude": -73.9447883,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-04T15:55:28.961Z",
        "type": "Periodical"
    },
    {
        "id": 476342301,
        "latitude": 40.7450217,
        "longitude": -73.9181567,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-04T15:35:13.272Z",
        "type": "Periodical"
    },
    {
        "id": 476333413,
        "latitude": 40.7450217,
        "longitude": -73.9181567,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-04T15:12:30.235Z",
        "type": "Periodical"
    },
    {
        "id": 476322004,
        "latitude": 40.7502783,
        "longitude": -73.9249317,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-04T14:43:36.163Z",
        "type": "Periodical"
    },
    {
        "id": 476309172,
        "latitude": 40.7674033,
        "longitude": -73.9368217,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-04T14:11:02.235Z",
        "type": "Periodical"
    },
    {
        "id": 476297262,
        "latitude": 40.7614733,
        "longitude": -73.9500733,
        "accuracy": 150,
        "source": "Fused",
        "ts": "2024-10-04T13:42:12.256Z",
        "type": "Periodical"
    },
    {
        "id": 476287481,
        "latitude": 40.7619767,
        "longitude": -73.9497133,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-04T13:18:02.231Z",
        "type": "Periodical"
    },
    {
        "id": 476259521,
        "latitude": 40.763315,
        "longitude": -73.95406,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-04T12:15:38.236Z",
        "type": "Periodical"
    },
    {
        "id": 476243361,
        "latitude": 40.7621133,
        "longitude": -73.9495,
        "accuracy": 123,
        "source": "Fused",
        "ts": "2024-10-04T11:37:20.267Z",
        "type": "Periodical"
    },
    {
        "id": 476231350,
        "latitude": 40.7622433,
        "longitude": -73.9495267,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-04T11:08:27.234Z",
        "type": "Periodical"
    },
    {
        "id": 476205833,
        "latitude": 40.762,
        "longitude": -73.9493,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-04T10:05:09.029Z",
        "type": "Periodical"
    },
    {
        "id": 476194793,
        "latitude": 40.7625167,
        "longitude": -73.9490683,
        "accuracy": 153,
        "source": "Fused",
        "ts": "2024-10-04T09:36:25.780Z",
        "type": "Periodical"
    },
    {
        "id": 476187313,
        "latitude": 40.7628117,
        "longitude": -73.9486833,
        "accuracy": 157,
        "source": "Fused",
        "ts": "2024-10-04T09:15:16.812Z",
        "type": "Periodical"
    },
    {
        "id": 476174742,
        "latitude": 40.76267,
        "longitude": -73.9487567,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-04T08:40:54.710Z",
        "type": "Periodical"
    },
    {
        "id": 476159797,
        "latitude": 40.761345,
        "longitude": -73.9497367,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-04T08:00:35.234Z",
        "type": "Periodical"
    },
    {
        "id": 476148515,
        "latitude": 40.7624967,
        "longitude": -73.9504933,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-10-04T07:30:06.782Z",
        "type": "Periodical"
    },
    {
        "id": 476133343,
        "latitude": 40.76234,
        "longitude": -73.94918,
        "accuracy": 145,
        "source": "Fused",
        "ts": "2024-10-04T06:50:49.750Z",
        "type": "Periodical"
    },
    {
        "id": 476119955,
        "latitude": 40.762645,
        "longitude": -73.94923,
        "accuracy": 105,
        "source": "Fused",
        "ts": "2024-10-04T06:16:29.235Z",
        "type": "Periodical"
    },
    {
        "id": 476102974,
        "latitude": 40.7622383,
        "longitude": -73.9496917,
        "accuracy": 146,
        "source": "Fused",
        "ts": "2024-10-04T05:34:56.784Z",
        "type": "Periodical"
    },
    {
        "id": 476092959,
        "latitude": 40.762645,
        "longitude": -73.9496817,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-04T05:11:15.235Z",
        "type": "Periodical"
    },
    {
        "id": 476072562,
        "latitude": 40.7626,
        "longitude": -73.94944,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-04T04:30:02.828Z",
        "type": "Periodical"
    },
    {
        "id": 476054800,
        "latitude": 40.7623717,
        "longitude": -73.9494683,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-04T03:59:04.547Z",
        "type": "Periodical"
    },
    {
        "id": 476040892,
        "latitude": 40.7623967,
        "longitude": -73.94921,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-04T03:33:41.897Z",
        "type": "Periodical"
    },
    {
        "id": 476035054,
        "latitude": 40.7617067,
        "longitude": -73.9488617,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-04T03:22:12.235Z",
        "type": "Periodical"
    },
    {
        "id": 475953022,
        "latitude": 40.7624633,
        "longitude": -73.9504033,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-04T01:07:01.540Z",
        "type": "Periodical"
    },
    {
        "id": 475918765,
        "latitude": 40.7616133,
        "longitude": -73.9497183,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-04T00:08:35.695Z",
        "type": "Periodical"
    },
    {
        "id": 475896571,
        "latitude": 40.7635033,
        "longitude": -73.9495617,
        "accuracy": 174,
        "source": "Fused",
        "ts": "2024-10-03T23:30:15.739Z",
        "type": "Periodical"
    },
    {
        "id": 475882510,
        "latitude": 40.7635017,
        "longitude": -73.9497617,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-03T23:07:11.601Z",
        "type": "Periodical"
    },
    {
        "id": 475866161,
        "latitude": 40.7636583,
        "longitude": -73.9490367,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-03T22:42:02.238Z",
        "type": "Periodical"
    },
    {
        "id": 475843464,
        "latitude": 40.7622817,
        "longitude": -73.9493833,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-03T22:04:18.694Z",
        "type": "Periodical"
    },
    {
        "id": 475825036,
        "latitude": 40.7619517,
        "longitude": -73.949675,
        "accuracy": 109,
        "source": "Fused",
        "ts": "2024-10-03T21:34:48.766Z",
        "type": "Periodical"
    },
    {
        "id": 475799537,
        "latitude": 40.7628567,
        "longitude": -73.9501133,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-03T20:50:43.695Z",
        "type": "Periodical"
    },
    {
        "id": 475776975,
        "latitude": 40.76314,
        "longitude": -73.9504783,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-03T20:10:58.318Z",
        "type": "Periodical"
    },
    {
        "id": 475768130,
        "latitude": 40.759805,
        "longitude": -73.9526917,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-03T19:53:40.015Z",
        "type": "Periodical"
    },
    {
        "id": 475765971,
        "latitude": 40.75608,
        "longitude": -73.9566983,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-03T19:44:04.653Z",
        "type": "Periodical"
    },
    {
        "id": 475692536,
        "latitude": 40.7612317,
        "longitude": -73.951425,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-03T17:09:48.246Z",
        "type": "Periodical"
    },
    {
        "id": 475685507,
        "latitude": 40.7619883,
        "longitude": -73.9484583,
        "accuracy": 139,
        "source": "Fused",
        "ts": "2024-10-03T16:51:17.653Z",
        "type": "Periodical"
    },
    {
        "id": 475678738,
        "latitude": 40.7569233,
        "longitude": -73.9555867,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-03T16:36:39.652Z",
        "type": "Periodical"
    },
    {
        "id": 475589518,
        "latitude": 40.7550917,
        "longitude": -73.957005,
        "accuracy": 59,
        "source": "Fused",
        "ts": "2024-10-03T12:59:19.787Z",
        "type": "Periodical"
    },
    {
        "id": 475575614,
        "latitude": 40.7624983,
        "longitude": -73.9492683,
        "accuracy": 111,
        "source": "Fused",
        "ts": "2024-10-03T12:24:52.187Z",
        "type": "Periodical"
    },
    {
        "id": 475562333,
        "latitude": 40.7620333,
        "longitude": -73.9488933,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-03T11:54:27.235Z",
        "type": "Periodical"
    },
    {
        "id": 475545064,
        "latitude": 40.7623917,
        "longitude": -73.94906,
        "accuracy": 86,
        "source": "Fused",
        "ts": "2024-10-03T11:15:17.118Z",
        "type": "Periodical"
    },
    {
        "id": 475535042,
        "latitude": 40.7630283,
        "longitude": -73.94939,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-03T10:51:59.055Z",
        "type": "Periodical"
    },
    {
        "id": 475520381,
        "latitude": 40.762555,
        "longitude": -73.9493767,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-03T10:14:40.654Z",
        "type": "Periodical"
    },
    {
        "id": 475508885,
        "latitude": 40.76272,
        "longitude": -73.9496433,
        "accuracy": 78,
        "source": "Fused",
        "ts": "2024-10-03T09:44:54.829Z",
        "type": "Periodical"
    },
    {
        "id": 475496814,
        "latitude": 40.76305,
        "longitude": -73.9499767,
        "accuracy": 79,
        "source": "Fused",
        "ts": "2024-10-03T09:11:46.337Z",
        "type": "Periodical"
    },
    {
        "id": 475480864,
        "latitude": 40.7612233,
        "longitude": -73.949835,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-03T08:29:24.658Z",
        "type": "Periodical"
    },
    {
        "id": 475472006,
        "latitude": 40.7620617,
        "longitude": -73.9493833,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-03T08:05:09.527Z",
        "type": "Periodical"
    },
    {
        "id": 475463699,
        "latitude": 40.7623017,
        "longitude": -73.9484867,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-03T07:44:00.569Z",
        "type": "Periodical"
    },
    {
        "id": 475453616,
        "latitude": 40.7598383,
        "longitude": -73.947865,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-03T07:17:10.434Z",
        "type": "Periodical"
    },
    {
        "id": 475440436,
        "latitude": 40.7622967,
        "longitude": -73.9494767,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-03T06:44:23.656Z",
        "type": "Periodical"
    },
    {
        "id": 475431611,
        "latitude": 40.7621817,
        "longitude": -73.949325,
        "accuracy": 99,
        "source": "Fused",
        "ts": "2024-10-03T06:21:33.657Z",
        "type": "Periodical"
    },
    {
        "id": 475422324,
        "latitude": 40.76212,
        "longitude": -73.9495283,
        "accuracy": 116,
        "source": "Fused",
        "ts": "2024-10-03T05:58:21.655Z",
        "type": "Periodical"
    },
    {
        "id": 475412367,
        "latitude": 40.76198,
        "longitude": -73.9497783,
        "accuracy": 129,
        "source": "Fused",
        "ts": "2024-10-03T05:36:24.058Z",
        "type": "Periodical"
    },
    {
        "id": 475396256,
        "latitude": 40.7620783,
        "longitude": -73.9492267,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-03T05:00:33.418Z",
        "type": "Periodical"
    },
    {
        "id": 475375797,
        "latitude": 40.7624483,
        "longitude": -73.9493933,
        "accuracy": 63,
        "source": "Fused",
        "ts": "2024-10-03T04:21:16.963Z",
        "type": "Periodical"
    },
    {
        "id": 475360870,
        "latitude": 40.7623317,
        "longitude": -73.94946,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-03T03:50:18.657Z",
        "type": "Periodical"
    },
    {
        "id": 475351238,
        "latitude": 40.7626417,
        "longitude": -73.9492033,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-03T03:31:12.523Z",
        "type": "Periodical"
    },
    {
        "id": 475331296,
        "latitude": 40.7626317,
        "longitude": -73.9504367,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-10-03T02:59:54.441Z",
        "type": "Periodical"
    },
    {
        "id": 475294959,
        "latitude": 40.762355,
        "longitude": -73.9495633,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-03T02:01:19.308Z",
        "type": "Periodical"
    },
    {
        "id": 475258092,
        "latitude": 40.762595,
        "longitude": -73.9505967,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-03T01:00:01.081Z",
        "type": "Periodical"
    },
    {
        "id": 475246379,
        "latitude": 40.7625217,
        "longitude": -73.9499033,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-10-03T00:40:02.087Z",
        "type": "Periodical"
    },
    {
        "id": 475232914,
        "latitude": 40.74482,
        "longitude": -73.91734,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-03T00:17:11.916Z",
        "type": "Periodical"
    },
    {
        "id": 475232041,
        "latitude": 40.7449267,
        "longitude": -73.9180867,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-03T00:15:36.656Z",
        "type": "Periodical"
    },
    {
        "id": 475169430,
        "latitude": 40.74516,
        "longitude": -73.9176183,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-02T22:28:29.736Z",
        "type": "Periodical"
    },
    {
        "id": 475145089,
        "latitude": 40.74704,
        "longitude": -73.9256333,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-02T21:49:11.654Z",
        "type": "Periodical"
    },
    {
        "id": 475128942,
        "latitude": 40.7632433,
        "longitude": -73.9495267,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-02T21:22:15.580Z",
        "type": "Periodical"
    },
    {
        "id": 475119594,
        "latitude": 40.7626783,
        "longitude": -73.9500233,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-02T21:06:50.818Z",
        "type": "Periodical"
    },
    {
        "id": 475069022,
        "latitude": 40.7624917,
        "longitude": -73.94965,
        "accuracy": 141,
        "source": "Fused",
        "ts": "2024-10-02T19:40:28.171Z",
        "type": "Periodical"
    },
    {
        "id": 475054525,
        "latitude": 40.7626967,
        "longitude": -73.949375,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-10-02T19:14:12.606Z",
        "type": "Periodical"
    },
    {
        "id": 475039592,
        "latitude": 40.7622,
        "longitude": -73.9498733,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-10-02T18:49:56.318Z",
        "type": "Periodical"
    },
    {
        "id": 475026763,
        "latitude": 40.7627183,
        "longitude": -73.9505817,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-02T18:18:07.319Z",
        "type": "Periodical"
    },
    {
        "id": 475018468,
        "latitude": 40.75649,
        "longitude": -73.9548783,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-02T17:56:05.416Z",
        "type": "Periodical"
    },
    {
        "id": 475005460,
        "latitude": 40.7507217,
        "longitude": -73.9981583,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-02T17:23:06.809Z",
        "type": "Periodical"
    },
    {
        "id": 474998720,
        "latitude": 40.7500467,
        "longitude": -73.9994317,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-02T17:04:09.916Z",
        "type": "Periodical"
    },
    {
        "id": 474988418,
        "latitude": 40.7499833,
        "longitude": -73.99976,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-02T16:34:53.245Z",
        "type": "Periodical"
    },
    {
        "id": 474976533,
        "latitude": 40.7499733,
        "longitude": -74.0001233,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-02T16:01:02.297Z",
        "type": "Periodical"
    },
    {
        "id": 474963188,
        "latitude": 40.749815,
        "longitude": -73.9994733,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-02T15:28:10.570Z",
        "type": "Periodical"
    },
    {
        "id": 474948607,
        "latitude": 40.749845,
        "longitude": -73.9997617,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-02T14:52:12.491Z",
        "type": "Periodical"
    },
    {
        "id": 474935480,
        "latitude": 40.748145,
        "longitude": -73.996135,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-02T14:18:13.969Z",
        "type": "Periodical"
    },
    {
        "id": 474924115,
        "latitude": 40.7473933,
        "longitude": -73.9970267,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-02T13:52:48.713Z",
        "type": "Periodical"
    },
    {
        "id": 474908560,
        "latitude": 40.7480917,
        "longitude": -73.9986,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-02T13:13:45.553Z",
        "type": "Periodical"
    },
    {
        "id": 474892706,
        "latitude": 40.7471433,
        "longitude": -73.9971717,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-02T12:35:15.466Z",
        "type": "Periodical"
    },
    {
        "id": 474875515,
        "latitude": 40.74395,
        "longitude": -73.9925767,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-02T11:56:23.697Z",
        "type": "Periodical"
    },
    {
        "id": 474858933,
        "latitude": 40.7624233,
        "longitude": -73.9491783,
        "accuracy": 115,
        "source": "Fused",
        "ts": "2024-10-02T11:21:49.657Z",
        "type": "Periodical"
    },
    {
        "id": 474847755,
        "latitude": 40.7626433,
        "longitude": -73.949095,
        "accuracy": 112,
        "source": "Fused",
        "ts": "2024-10-02T10:57:27.050Z",
        "type": "Periodical"
    },
    {
        "id": 474837405,
        "latitude": 40.7624633,
        "longitude": -73.9491267,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-02T10:33:00.418Z",
        "type": "Periodical"
    },
    {
        "id": 474825162,
        "latitude": 40.7631267,
        "longitude": -73.9499517,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-02T10:04:12.418Z",
        "type": "Periodical"
    },
    {
        "id": 474812124,
        "latitude": 40.7630617,
        "longitude": -73.949505,
        "accuracy": 90,
        "source": "Fused",
        "ts": "2024-10-02T09:29:44.501Z",
        "type": "Periodical"
    },
    {
        "id": 474800782,
        "latitude": 40.7626867,
        "longitude": -73.9505517,
        "accuracy": 150,
        "source": "Fused",
        "ts": "2024-10-02T08:58:36.418Z",
        "type": "Periodical"
    },
    {
        "id": 474785881,
        "latitude": 40.7630783,
        "longitude": -73.94962,
        "accuracy": 159,
        "source": "Fused",
        "ts": "2024-10-02T08:17:51.421Z",
        "type": "Periodical"
    },
    {
        "id": 474774489,
        "latitude": 40.762315,
        "longitude": -73.9485883,
        "accuracy": 136,
        "source": "Fused",
        "ts": "2024-10-02T07:47:27.929Z",
        "type": "Periodical"
    },
    {
        "id": 474759027,
        "latitude": 40.76443,
        "longitude": -73.95936,
        "accuracy": 196,
        "source": "Fused",
        "ts": "2024-10-02T07:06:23.996Z",
        "type": "Periodical"
    },
    {
        "id": 474747306,
        "latitude": 40.762415,
        "longitude": -73.9493967,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-02T06:38:06.420Z",
        "type": "Periodical"
    },
    {
        "id": 474733417,
        "latitude": 40.7622983,
        "longitude": -73.949225,
        "accuracy": 163,
        "source": "Fused",
        "ts": "2024-10-02T06:03:12.335Z",
        "type": "Periodical"
    },
    {
        "id": 474723347,
        "latitude": 40.7618017,
        "longitude": -73.9490983,
        "accuracy": 150,
        "source": "Fused",
        "ts": "2024-10-02T05:39:27.963Z",
        "type": "Periodical"
    },
    {
        "id": 474699468,
        "latitude": 40.7626467,
        "longitude": -73.9499617,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-02T05:14:14.981Z",
        "type": "Periodical"
    },
    {
        "id": 474683581,
        "latitude": 40.762475,
        "longitude": -73.949485,
        "accuracy": 83,
        "source": "Fused",
        "ts": "2024-10-02T04:39:34.268Z",
        "type": "Periodical"
    },
    {
        "id": 474668857,
        "latitude": 40.762665,
        "longitude": -73.9495133,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-02T04:09:40.928Z",
        "type": "Periodical"
    },
    {
        "id": 474656108,
        "latitude": 40.7626817,
        "longitude": -73.94977,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-02T03:47:22.931Z",
        "type": "Periodical"
    },
    {
        "id": 474642399,
        "latitude": 40.76237,
        "longitude": -73.9494767,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-02T03:19:17.418Z",
        "type": "Periodical"
    },
    {
        "id": 474638799,
        "latitude": 40.762605,
        "longitude": -73.9505383,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-10-02T03:11:42.419Z",
        "type": "Periodical"
    },
    {
        "id": 474568548,
        "latitude": 40.7621967,
        "longitude": -73.9493133,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-10-02T01:24:41.425Z",
        "type": "Periodical"
    },
    {
        "id": 474555486,
        "latitude": 40.7621967,
        "longitude": -73.9493133,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-02T01:04:41.421Z",
        "type": "Periodical"
    },
    {
        "id": 474535065,
        "latitude": 40.7625783,
        "longitude": -73.9492433,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-02T00:34:51.618Z",
        "type": "Periodical"
    },
    {
        "id": 474455713,
        "latitude": 40.7625733,
        "longitude": -73.9494617,
        "accuracy": 131,
        "source": "Fused",
        "ts": "2024-10-01T22:36:08.249Z",
        "type": "Periodical"
    },
    {
        "id": 474424782,
        "latitude": 40.78282,
        "longitude": -73.9307183,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-01T21:52:48.870Z",
        "type": "Periodical"
    },
    {
        "id": 474398046,
        "latitude": 40.7834967,
        "longitude": -73.9283767,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-10-01T21:15:48.613Z",
        "type": "Periodical"
    },
    {
        "id": 474377906,
        "latitude": 40.7834917,
        "longitude": -73.92825,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-01T20:49:49.646Z",
        "type": "Periodical"
    },
    {
        "id": 474348908,
        "latitude": 40.783515,
        "longitude": -73.9281183,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-01T20:14:48.362Z",
        "type": "Periodical"
    },
    {
        "id": 474336475,
        "latitude": 40.7880983,
        "longitude": -73.928015,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-10-01T19:52:35.818Z",
        "type": "Periodical"
    },
    {
        "id": 474321643,
        "latitude": 40.7733617,
        "longitude": -73.9171383,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-01T19:27:54.028Z",
        "type": "Periodical"
    },
    {
        "id": 474307799,
        "latitude": 40.7610667,
        "longitude": -73.935495,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-01T19:01:22.832Z",
        "type": "Periodical"
    },
    {
        "id": 474288662,
        "latitude": 40.8810883,
        "longitude": -74.06555,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-01T18:24:50.141Z",
        "type": "Periodical"
    },
    {
        "id": 474288666,
        "latitude": 40.88932,
        "longitude": -74.1501883,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-01T17:44:38.810Z",
        "type": "Periodical"
    },
    {
        "id": 474288665,
        "latitude": 40.8891867,
        "longitude": -74.149835,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-01T17:41:37.423Z",
        "type": "Periodical"
    },
    {
        "id": 474246774,
        "latitude": 40.8795667,
        "longitude": -74.1614833,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-01T17:04:16.968Z",
        "type": "Periodical"
    },
    {
        "id": 474235560,
        "latitude": 40.8795583,
        "longitude": -74.16115,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-01T16:36:42.407Z",
        "type": "Periodical"
    },
    {
        "id": 474225988,
        "latitude": 40.8795933,
        "longitude": -74.161175,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-10-01T16:12:16.973Z",
        "type": "Periodical"
    },
    {
        "id": 474214800,
        "latitude": 40.87977,
        "longitude": -74.1611183,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-01T15:45:43.442Z",
        "type": "Periodical"
    },
    {
        "id": 474206010,
        "latitude": 40.879765,
        "longitude": -74.1612033,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-01T15:24:43.168Z",
        "type": "Periodical"
    },
    {
        "id": 474184114,
        "latitude": 40.8796241,
        "longitude": -74.1613781,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-10-01T14:47:23.014Z",
        "type": "Periodical"
    },
    {
        "id": 474163380,
        "latitude": 40.8796117,
        "longitude": -74.1611483,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-01T14:18:44.025Z",
        "type": "Periodical"
    },
    {
        "id": 474143530,
        "latitude": 40.8794,
        "longitude": -74.1612933,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-01T13:49:41.677Z",
        "type": "Periodical"
    },
    {
        "id": 474115554,
        "latitude": 40.8794217,
        "longitude": -74.1612517,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-01T13:15:41.653Z",
        "type": "Periodical"
    },
    {
        "id": 474101523,
        "latitude": 40.8794133,
        "longitude": -74.161325,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-10-01T12:51:53.786Z",
        "type": "Periodical"
    },
    {
        "id": 474071539,
        "latitude": 40.8753667,
        "longitude": -73.9854517,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-10-01T12:17:53.710Z",
        "type": "Periodical"
    },
    {
        "id": 474051369,
        "latitude": 40.76084,
        "longitude": -73.9629333,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-10-01T11:52:28.103Z",
        "type": "Periodical"
    },
    {
        "id": 474031703,
        "latitude": 40.7593333,
        "longitude": -73.953375,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-01T11:25:48.469Z",
        "type": "Periodical"
    },
    {
        "id": 474002966,
        "latitude": 40.76286,
        "longitude": -73.9491783,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-10-01T10:46:31.017Z",
        "type": "Periodical"
    },
    {
        "id": 473986493,
        "latitude": 40.76319,
        "longitude": -73.949795,
        "accuracy": 172,
        "source": "Fused",
        "ts": "2024-10-01T10:24:06.017Z",
        "type": "Periodical"
    },
    {
        "id": 473964018,
        "latitude": 40.762425,
        "longitude": -73.9485867,
        "accuracy": 174,
        "source": "Fused",
        "ts": "2024-10-01T09:37:14.044Z",
        "type": "Periodical"
    },
    {
        "id": 473943693,
        "latitude": 40.76168,
        "longitude": -73.948765,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-01T09:08:13.461Z",
        "type": "Periodical"
    },
    {
        "id": 473929374,
        "latitude": 40.762225,
        "longitude": -73.949675,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-01T08:41:49.542Z",
        "type": "Periodical"
    },
    {
        "id": 473901212,
        "latitude": 40.7622317,
        "longitude": -73.949065,
        "accuracy": 168,
        "source": "Fused",
        "ts": "2024-10-01T08:15:02.594Z",
        "type": "Periodical"
    },
    {
        "id": 473805350,
        "latitude": 40.7657533,
        "longitude": -73.9495817,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-01T07:34:00.545Z",
        "type": "Periodical"
    },
    {
        "id": 473654192,
        "latitude": 40.76244,
        "longitude": -73.949715,
        "accuracy": 93,
        "source": "Fused",
        "ts": "2024-10-01T06:56:10.126Z",
        "type": "Periodical"
    },
    {
        "id": 473532449,
        "latitude": 40.76195,
        "longitude": -73.949225,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-10-01T06:23:44.109Z",
        "type": "Periodical"
    },
    {
        "id": 473415120,
        "latitude": 40.7621433,
        "longitude": -73.9493717,
        "accuracy": 131,
        "source": "Fused",
        "ts": "2024-10-01T05:54:23.918Z",
        "type": "Periodical"
    },
    {
        "id": 473354210,
        "latitude": 40.76264,
        "longitude": -73.9493567,
        "accuracy": 163,
        "source": "Fused",
        "ts": "2024-10-01T05:32:27.870Z",
        "type": "Periodical"
    },
    {
        "id": 473206530,
        "latitude": 40.76249,
        "longitude": -73.949945,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-10-01T04:59:51.019Z",
        "type": "Periodical"
    },
    {
        "id": 473051286,
        "latitude": 40.7622717,
        "longitude": -73.9483817,
        "accuracy": 172,
        "source": "Fused",
        "ts": "2024-10-01T04:26:47.069Z",
        "type": "Periodical"
    },
    {
        "id": 472956612,
        "latitude": 40.7620217,
        "longitude": -73.9494067,
        "accuracy": 115,
        "source": "Fused",
        "ts": "2024-10-01T04:06:19.653Z",
        "type": "Periodical"
    },
    {
        "id": 472923946,
        "latitude": 40.7610967,
        "longitude": -73.9482733,
        "accuracy": 157,
        "source": "Fused",
        "ts": "2024-10-01T03:32:19.384Z",
        "type": "Periodical"
    },
    {
        "id": 472912188,
        "latitude": 40.7632183,
        "longitude": -73.9478217,
        "accuracy": 129,
        "source": "Fused",
        "ts": "2024-10-01T03:06:53.744Z",
        "type": "Periodical"
    },
    {
        "id": 472898566,
        "latitude": 40.7604267,
        "longitude": -73.9474583,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-10-01T02:36:55.349Z",
        "type": "Periodical"
    },
    {
        "id": 472861589,
        "latitude": 40.7644583,
        "longitude": -73.96922,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-01T01:28:06.568Z",
        "type": "Periodical"
    },
    {
        "id": 472848537,
        "latitude": 40.7654783,
        "longitude": -73.95212,
        "accuracy": 184,
        "source": "Fused",
        "ts": "2024-10-01T01:02:40.415Z",
        "type": "Periodical"
    },
    {
        "id": 472828353,
        "latitude": 40.77293,
        "longitude": -73.9576067,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-10-01T00:27:04.909Z",
        "type": "Periodical"
    },
    {
        "id": 472805549,
        "latitude": 40.77293,
        "longitude": -73.9576067,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-30T23:45:33.970Z",
        "type": "Periodical"
    },
    {
        "id": 472791896,
        "latitude": 40.7625617,
        "longitude": -73.95508,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-09-30T23:20:03.565Z",
        "type": "Periodical"
    },
    {
        "id": 472782743,
        "latitude": 40.76282,
        "longitude": -73.9510467,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-30T23:02:13.564Z",
        "type": "Periodical"
    },
    {
        "id": 472767150,
        "latitude": 40.762925,
        "longitude": -73.950185,
        "accuracy": 107,
        "source": "Fused",
        "ts": "2024-09-30T22:33:11.866Z",
        "type": "Periodical"
    },
    {
        "id": 472728051,
        "latitude": 40.762925,
        "longitude": -73.950185,
        "accuracy": 107,
        "source": "Fused",
        "ts": "2024-09-30T21:27:39.789Z",
        "type": "Periodical"
    },
    {
        "id": 472716207,
        "latitude": 40.7627233,
        "longitude": -73.9499567,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-30T21:06:37.564Z",
        "type": "Periodical"
    },
    {
        "id": 472699530,
        "latitude": 40.7637733,
        "longitude": -73.9499367,
        "accuracy": 159,
        "source": "Fused",
        "ts": "2024-09-30T20:33:05.966Z",
        "type": "Periodical"
    },
    {
        "id": 472682928,
        "latitude": 40.76232,
        "longitude": -73.9496817,
        "accuracy": 145,
        "source": "Fused",
        "ts": "2024-09-30T19:59:04.564Z",
        "type": "Periodical"
    },
    {
        "id": 472663884,
        "latitude": 40.7625567,
        "longitude": -73.950355,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-09-30T19:18:35.506Z",
        "type": "Periodical"
    },
    {
        "id": 472651312,
        "latitude": 40.7625567,
        "longitude": -73.950355,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-09-30T18:49:31.808Z",
        "type": "Periodical"
    },
    {
        "id": 472637930,
        "latitude": 40.7623683,
        "longitude": -73.949015,
        "accuracy": 95,
        "source": "Fused",
        "ts": "2024-09-30T18:15:21.683Z",
        "type": "Periodical"
    },
    {
        "id": 472630415,
        "latitude": 40.7626667,
        "longitude": -73.95199,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-30T17:53:23.435Z",
        "type": "Periodical"
    },
    {
        "id": 472618191,
        "latitude": 40.761845,
        "longitude": -73.9431667,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-09-30T17:15:34.895Z",
        "type": "Periodical"
    },
    {
        "id": 472609718,
        "latitude": 40.761845,
        "longitude": -73.9431667,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-09-30T16:54:51.698Z",
        "type": "Periodical"
    },
    {
        "id": 472594553,
        "latitude": 40.761845,
        "longitude": -73.9431667,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-09-30T16:15:03.608Z",
        "type": "Periodical"
    },
    {
        "id": 472574075,
        "latitude": 40.761845,
        "longitude": -73.9431667,
        "accuracy": 178,
        "source": "Fused",
        "ts": "2024-09-30T15:15:38.565Z",
        "type": "Periodical"
    },
    {
        "id": 472555062,
        "latitude": 40.762265,
        "longitude": -73.9495767,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-09-30T14:23:31.564Z",
        "type": "Periodical"
    },
    {
        "id": 472539328,
        "latitude": 40.7621783,
        "longitude": -73.9489017,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-30T13:48:39.564Z",
        "type": "Periodical"
    },
    {
        "id": 472533101,
        "latitude": 40.7630317,
        "longitude": -73.94831,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-30T13:30:55.564Z",
        "type": "Periodical"
    },
    {
        "id": 472485014,
        "latitude": 40.7545467,
        "longitude": -73.9593817,
        "accuracy": 183,
        "source": "Fused",
        "ts": "2024-09-30T11:23:30.487Z",
        "type": "Periodical"
    },
    {
        "id": 472474246,
        "latitude": 40.7624083,
        "longitude": -73.9495117,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-30T11:01:10.566Z",
        "type": "Periodical"
    },
    {
        "id": 472462504,
        "latitude": 40.7615367,
        "longitude": -73.9498383,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-30T10:31:25.565Z",
        "type": "Periodical"
    },
    {
        "id": 472450471,
        "latitude": 40.7624533,
        "longitude": -73.94924,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-30T09:57:28.565Z",
        "type": "Periodical"
    },
    {
        "id": 472441563,
        "latitude": 40.7605067,
        "longitude": -73.94678,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-30T09:30:54.650Z",
        "type": "Periodical"
    },
    {
        "id": 472428538,
        "latitude": 40.7619033,
        "longitude": -73.9495167,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-30T08:52:22.068Z",
        "type": "Periodical"
    },
    {
        "id": 472420929,
        "latitude": 40.7629317,
        "longitude": -73.949275,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-30T08:29:40.565Z",
        "type": "Periodical"
    },
    {
        "id": 472413312,
        "latitude": 40.7632267,
        "longitude": -73.9493583,
        "accuracy": 133,
        "source": "Fused",
        "ts": "2024-09-30T08:06:58.986Z",
        "type": "Periodical"
    },
    {
        "id": 472399499,
        "latitude": 40.7622767,
        "longitude": -73.9492067,
        "accuracy": 131,
        "source": "Fused",
        "ts": "2024-09-30T07:27:02.564Z",
        "type": "Periodical"
    },
    {
        "id": 472391983,
        "latitude": 40.76219,
        "longitude": -73.949385,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-30T07:04:57.908Z",
        "type": "Periodical"
    },
    {
        "id": 472383981,
        "latitude": 40.7633933,
        "longitude": -73.9507467,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-09-30T06:42:52.861Z",
        "type": "Periodical"
    },
    {
        "id": 472374006,
        "latitude": 40.76164,
        "longitude": -73.949775,
        "accuracy": 93,
        "source": "Fused",
        "ts": "2024-09-30T06:14:09.072Z",
        "type": "Periodical"
    },
    {
        "id": 472359255,
        "latitude": 40.75808,
        "longitude": -73.9495967,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-30T05:33:50.421Z",
        "type": "Periodical"
    },
    {
        "id": 472344356,
        "latitude": 40.7625967,
        "longitude": -73.94983,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-30T04:57:11.276Z",
        "type": "Periodical"
    },
    {
        "id": 472333235,
        "latitude": 40.7586783,
        "longitude": -73.9548767,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-30T04:29:32.141Z",
        "type": "Periodical"
    },
    {
        "id": 472317160,
        "latitude": 40.7624633,
        "longitude": -73.9493683,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-30T03:51:52.565Z",
        "type": "Periodical"
    },
    {
        "id": 472301669,
        "latitude": 40.7544533,
        "longitude": -73.95414,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-30T03:17:01.742Z",
        "type": "Periodical"
    },
    {
        "id": 472296978,
        "latitude": 40.7624133,
        "longitude": -73.94871,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-30T03:06:56.563Z",
        "type": "Periodical"
    },
    {
        "id": 472123788,
        "latitude": 40.7626467,
        "longitude": -73.9503267,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-29T21:37:26.425Z",
        "type": "Periodical"
    },
    {
        "id": 472056520,
        "latitude": 40.7585933,
        "longitude": -73.9512483,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-09-29T19:31:28.616Z",
        "type": "Periodical"
    },
    {
        "id": 471982344,
        "latitude": 40.7616667,
        "longitude": -73.9499833,
        "accuracy": 182,
        "source": "Fused",
        "ts": "2024-09-29T17:00:38.497Z",
        "type": "Periodical"
    },
    {
        "id": 471969386,
        "latitude": 40.7615017,
        "longitude": -73.9495983,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-29T16:32:43.482Z",
        "type": "Periodical"
    },
    {
        "id": 471950670,
        "latitude": 40.7620783,
        "longitude": -73.950305,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-09-29T15:55:54.068Z",
        "type": "Periodical"
    },
    {
        "id": 471942849,
        "latitude": 40.7619683,
        "longitude": -73.9507,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-29T15:38:21.597Z",
        "type": "Periodical"
    },
    {
        "id": 471932461,
        "latitude": 40.76181,
        "longitude": -73.950695,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-29T15:16:01.537Z",
        "type": "Periodical"
    },
    {
        "id": 471920077,
        "latitude": 40.7621917,
        "longitude": -73.950765,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-29T14:47:03.428Z",
        "type": "Periodical"
    },
    {
        "id": 471905282,
        "latitude": 40.7614783,
        "longitude": -73.9498433,
        "accuracy": 66,
        "source": "Fused",
        "ts": "2024-09-29T14:11:30.020Z",
        "type": "Periodical"
    },
    {
        "id": 471890814,
        "latitude": 40.7618183,
        "longitude": -73.9504467,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-29T13:35:56.680Z",
        "type": "Periodical"
    },
    {
        "id": 471830708,
        "latitude": 40.7628333,
        "longitude": -73.949325,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T10:55:49.614Z",
        "type": "Periodical"
    },
    {
        "id": 471822044,
        "latitude": 40.76338,
        "longitude": -73.9505967,
        "accuracy": 95,
        "source": "Fused",
        "ts": "2024-09-29T10:29:50.968Z",
        "type": "Periodical"
    },
    {
        "id": 471814082,
        "latitude": 40.7624483,
        "longitude": -73.95025,
        "accuracy": 160,
        "source": "Fused",
        "ts": "2024-09-29T10:04:15.598Z",
        "type": "Periodical"
    },
    {
        "id": 471802484,
        "latitude": 40.7637417,
        "longitude": -73.95072,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T09:28:18.970Z",
        "type": "Periodical"
    },
    {
        "id": 471793135,
        "latitude": 40.7626283,
        "longitude": -73.94987,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T08:58:42.970Z",
        "type": "Periodical"
    },
    {
        "id": 471784250,
        "latitude": 40.7623217,
        "longitude": -73.95068,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-29T08:30:53.970Z",
        "type": "Periodical"
    },
    {
        "id": 471759719,
        "latitude": 40.7619283,
        "longitude": -73.9498667,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-29T07:16:31.393Z",
        "type": "Periodical"
    },
    {
        "id": 471753190,
        "latitude": 40.76315,
        "longitude": -73.9523517,
        "accuracy": 181,
        "source": "Fused",
        "ts": "2024-09-29T06:57:58.969Z",
        "type": "Periodical"
    },
    {
        "id": 471744180,
        "latitude": 40.7628383,
        "longitude": -73.949315,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-29T06:33:02.968Z",
        "type": "Periodical"
    },
    {
        "id": 471723788,
        "latitude": 40.7629283,
        "longitude": -73.9513083,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T05:41:36.973Z",
        "type": "Periodical"
    },
    {
        "id": 471710734,
        "latitude": 40.7616,
        "longitude": -73.9500283,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-29T05:09:10.971Z",
        "type": "Periodical"
    },
    {
        "id": 471698873,
        "latitude": 40.7630967,
        "longitude": -73.9502567,
        "accuracy": 157,
        "source": "Fused",
        "ts": "2024-09-29T04:42:05.826Z",
        "type": "Periodical"
    },
    {
        "id": 471682672,
        "latitude": 40.761415,
        "longitude": -73.9493467,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T04:05:36.969Z",
        "type": "Periodical"
    },
    {
        "id": 471665949,
        "latitude": 40.76248,
        "longitude": -73.949775,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T03:30:19.969Z",
        "type": "Periodical"
    },
    {
        "id": 471649541,
        "latitude": 40.7615383,
        "longitude": -73.9495567,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-29T02:56:30.969Z",
        "type": "Periodical"
    },
    {
        "id": 471647194,
        "latitude": 40.762325,
        "longitude": -73.9476183,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-29T02:51:34.968Z",
        "type": "Periodical"
    },
    {
        "id": 471576359,
        "latitude": 40.7624483,
        "longitude": -73.9491567,
        "accuracy": 113,
        "source": "Fused",
        "ts": "2024-09-29T00:35:03.332Z",
        "type": "Periodical"
    },
    {
        "id": 471500784,
        "latitude": 40.76241,
        "longitude": -73.9493417,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-28T22:12:59.833Z",
        "type": "Periodical"
    },
    {
        "id": 471492538,
        "latitude": 40.76586,
        "longitude": -73.9495467,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-09-28T21:57:45.739Z",
        "type": "Periodical"
    },
    {
        "id": 471432313,
        "latitude": 40.76314,
        "longitude": -73.9497017,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-28T19:59:29.506Z",
        "type": "Periodical"
    },
    {
        "id": 471422333,
        "latitude": 40.7595967,
        "longitude": -73.9530983,
        "accuracy": 187,
        "source": "Fused",
        "ts": "2024-09-28T19:37:40.048Z",
        "type": "Periodical"
    },
    {
        "id": 471405298,
        "latitude": 40.7595967,
        "longitude": -73.9530983,
        "accuracy": 187,
        "source": "Fused",
        "ts": "2024-09-28T19:02:39.953Z",
        "type": "Periodical"
    },
    {
        "id": 471390238,
        "latitude": 40.7595967,
        "longitude": -73.9530983,
        "accuracy": 187,
        "source": "Fused",
        "ts": "2024-09-28T18:30:39.884Z",
        "type": "Periodical"
    },
    {
        "id": 471379361,
        "latitude": 40.7595967,
        "longitude": -73.9530983,
        "accuracy": 187,
        "source": "Fused",
        "ts": "2024-09-28T18:06:51.969Z",
        "type": "Periodical"
    },
    {
        "id": 471360394,
        "latitude": 40.724915,
        "longitude": -73.9786467,
        "accuracy": 195,
        "source": "Fused",
        "ts": "2024-09-28T17:33:08.031Z",
        "type": "Periodical"
    },
    {
        "id": 471343854,
        "latitude": 40.7623417,
        "longitude": -73.9497467,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-28T16:58:09.612Z",
        "type": "Periodical"
    },
    {
        "id": 471325253,
        "latitude": 40.7623417,
        "longitude": -73.9497467,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-28T16:21:02.970Z",
        "type": "Periodical"
    },
    {
        "id": 471315568,
        "latitude": 40.7632483,
        "longitude": -73.9501617,
        "accuracy": 172,
        "source": "Fused",
        "ts": "2024-09-28T16:01:29.972Z",
        "type": "Periodical"
    },
    {
        "id": 471304079,
        "latitude": 40.759245,
        "longitude": -73.9552533,
        "accuracy": 141,
        "source": "Fused",
        "ts": "2024-09-28T15:38:14.587Z",
        "type": "Periodical"
    },
    {
        "id": 471293816,
        "latitude": 40.7623117,
        "longitude": -73.94979,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-09-28T15:15:10.969Z",
        "type": "Periodical"
    },
    {
        "id": 471279058,
        "latitude": 40.762345,
        "longitude": -73.9496283,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-09-28T14:45:13.969Z",
        "type": "Periodical"
    },
    {
        "id": 471201492,
        "latitude": 40.762255,
        "longitude": -73.950265,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-28T11:52:12.870Z",
        "type": "Periodical"
    },
    {
        "id": 471200972,
        "latitude": 40.762795,
        "longitude": -73.9507417,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-28T11:50:33.064Z",
        "type": "Periodical"
    },
    {
        "id": 470950939,
        "latitude": 40.7651717,
        "longitude": -73.95331,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-28T01:21:29.408Z",
        "type": "Periodical"
    },
    {
        "id": 470932206,
        "latitude": 40.762425,
        "longitude": -73.9494117,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-09-28T00:46:43.968Z",
        "type": "Periodical"
    },
    {
        "id": 470915599,
        "latitude": 40.76253,
        "longitude": -73.9502033,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-28T00:16:50.059Z",
        "type": "Periodical"
    },
    {
        "id": 470815518,
        "latitude": 40.7633417,
        "longitude": -73.9492533,
        "accuracy": 111,
        "source": "Fused",
        "ts": "2024-09-27T21:09:15.826Z",
        "type": "Periodical"
    },
    {
        "id": 470794117,
        "latitude": 40.7626917,
        "longitude": -73.94233,
        "accuracy": 167,
        "source": "Fused",
        "ts": "2024-09-27T20:25:28.891Z",
        "type": "Periodical"
    },
    {
        "id": 470760237,
        "latitude": 40.7655533,
        "longitude": -73.980665,
        "accuracy": 148,
        "source": "Fused",
        "ts": "2024-09-27T19:14:27.762Z",
        "type": "Periodical"
    },
    {
        "id": 470744428,
        "latitude": 40.7655267,
        "longitude": -73.9809167,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-27T18:37:53.915Z",
        "type": "Periodical"
    },
    {
        "id": 470719831,
        "latitude": 40.7673567,
        "longitude": -73.9822467,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-27T17:35:07.353Z",
        "type": "Periodical"
    },
    {
        "id": 470700844,
        "latitude": 40.7644917,
        "longitude": -73.9781717,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-27T16:48:16.341Z",
        "type": "Periodical"
    },
    {
        "id": 470689827,
        "latitude": 40.7620983,
        "longitude": -73.9493483,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-27T16:24:40.914Z",
        "type": "Periodical"
    },
    {
        "id": 470672874,
        "latitude": 40.762595,
        "longitude": -73.9492333,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-27T15:45:21.914Z",
        "type": "Periodical"
    },
    {
        "id": 470661938,
        "latitude": 40.76235,
        "longitude": -73.9493583,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-27T15:15:19.737Z",
        "type": "Periodical"
    },
    {
        "id": 470648856,
        "latitude": 40.7612617,
        "longitude": -73.9507467,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-27T14:38:55.082Z",
        "type": "Periodical"
    },
    {
        "id": 470640179,
        "latitude": 40.76257,
        "longitude": -73.9495333,
        "accuracy": 131,
        "source": "Fused",
        "ts": "2024-09-27T14:19:28.828Z",
        "type": "Periodical"
    },
    {
        "id": 470193123,
        "latitude": 40.7628733,
        "longitude": -73.9500383,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-09-26T21:37:34.730Z",
        "type": "Periodical"
    },
    {
        "id": 470170343,
        "latitude": 40.7627767,
        "longitude": -73.9493983,
        "accuracy": 112,
        "source": "Fused",
        "ts": "2024-09-26T21:02:31.907Z",
        "type": "Periodical"
    },
    {
        "id": 470154507,
        "latitude": 40.7623183,
        "longitude": -73.9489983,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-26T20:32:11.607Z",
        "type": "Periodical"
    },
    {
        "id": 470131331,
        "latitude": 40.7631367,
        "longitude": -73.949745,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-26T19:46:47.915Z",
        "type": "Periodical"
    },
    {
        "id": 470116538,
        "latitude": 40.7623933,
        "longitude": -73.9493233,
        "accuracy": 103,
        "source": "Fused",
        "ts": "2024-09-26T19:13:37.391Z",
        "type": "Periodical"
    },
    {
        "id": 470106493,
        "latitude": 40.7626133,
        "longitude": -73.9486317,
        "accuracy": 116,
        "source": "Fused",
        "ts": "2024-09-26T18:49:56.916Z",
        "type": "Periodical"
    },
    {
        "id": 470091624,
        "latitude": 40.7620567,
        "longitude": -73.9490933,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-09-26T18:15:14.917Z",
        "type": "Periodical"
    },
    {
        "id": 470085290,
        "latitude": 40.7620567,
        "longitude": -73.9490933,
        "accuracy": 180,
        "source": "Fused",
        "ts": "2024-09-26T17:56:29.177Z",
        "type": "Periodical"
    },
    {
        "id": 470074191,
        "latitude": 40.76218,
        "longitude": -73.949695,
        "accuracy": 181,
        "source": "Fused",
        "ts": "2024-09-26T17:29:20.029Z",
        "type": "Periodical"
    },
    {
        "id": 470069554,
        "latitude": 40.7614983,
        "longitude": -73.9499533,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-09-26T17:15:39.915Z",
        "type": "Periodical"
    },
    {
        "id": 469993200,
        "latitude": 40.7623219,
        "longitude": -73.9498439,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-26T13:37:23.646Z",
        "type": "Periodical"
    },
    {
        "id": 469545392,
        "latitude": 40.7623333,
        "longitude": -73.9499016,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-25T20:51:30.548Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 469530849,
        "latitude": 40.7634937,
        "longitude": -73.9778558,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-25T20:25:47.809Z",
        "type": "Periodical"
    },
    {
        "id": 469516697,
        "latitude": 40.7185046,
        "longitude": -73.9880774,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-25T20:05:17.515Z",
        "type": "Periodical"
    },
    {
        "id": 469506651,
        "latitude": 40.7143424,
        "longitude": -74.001862,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-25T19:45:17.397Z",
        "type": "Periodical"
    },
    {
        "id": 469492989,
        "latitude": 40.7140701,
        "longitude": -74.0018299,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-25T19:15:17.270Z",
        "type": "Periodical"
    },
    {
        "id": 469479017,
        "latitude": 40.7140834,
        "longitude": -74.0018356,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-25T18:42:17.143Z",
        "type": "Periodical"
    },
    {
        "id": 469464374,
        "latitude": 40.7140625,
        "longitude": -74.001886,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-09-25T18:02:13.614Z",
        "type": "Periodical"
    },
    {
        "id": 469450931,
        "latitude": 40.7153759,
        "longitude": -74.0023229,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-09-25T17:25:13.343Z",
        "type": "Periodical"
    },
    {
        "id": 469444784,
        "latitude": 40.7142487,
        "longitude": -74.0026626,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-09-25T17:05:13.302Z",
        "type": "Periodical"
    },
    {
        "id": 469436446,
        "latitude": 40.7143005,
        "longitude": -74.0017693,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-25T16:39:13.190Z",
        "type": "Periodical"
    },
    {
        "id": 469425323,
        "latitude": 40.7140711,
        "longitude": -74.0018274,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-25T16:05:13.200Z",
        "type": "Periodical"
    },
    {
        "id": 469412219,
        "latitude": 40.7140763,
        "longitude": -74.0018733,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-09-25T15:27:13.088Z",
        "type": "Periodical"
    },
    {
        "id": 469403352,
        "latitude": 40.7140834,
        "longitude": -74.0018356,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-25T15:05:13.296Z",
        "type": "Periodical"
    },
    {
        "id": 469394776,
        "latitude": 40.7148985,
        "longitude": -74.0024669,
        "accuracy": 67,
        "source": "Fused",
        "ts": "2024-09-25T14:41:13.161Z",
        "type": "Periodical"
    },
    {
        "id": 469386292,
        "latitude": 40.7151713,
        "longitude": -74.001638,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-25T14:17:12.770Z",
        "type": "Periodical"
    },
    {
        "id": 469372317,
        "latitude": 40.713972,
        "longitude": -74.0019508,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-09-25T13:40:13.045Z",
        "type": "Periodical"
    },
    {
        "id": 469362836,
        "latitude": 40.7140907,
        "longitude": -74.0017055,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-09-25T13:19:12.556Z",
        "type": "Periodical"
    },
    {
        "id": 469353114,
        "latitude": 40.7145309,
        "longitude": -74.002095,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-25T12:52:06.589Z",
        "type": "Periodical"
    },
    {
        "id": 469333861,
        "latitude": 40.7588619,
        "longitude": -73.9532318,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-25T12:09:04.939Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 468979371,
        "latitude": 40.7621534,
        "longitude": -73.9509456,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-24T22:57:59.915Z",
        "type": "Periodical"
    },
    {
        "id": 468979369,
        "latitude": 40.7621534,
        "longitude": -73.9509456,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-24T22:57:59.915Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 468964731,
        "latitude": 40.7639664,
        "longitude": -73.9774422,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-24T22:35:54.117Z",
        "type": "Periodical"
    },
    {
        "id": 468957151,
        "latitude": 40.7661624,
        "longitude": -73.9814887,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-24T22:21:55.753Z",
        "type": "Periodical"
    },
    {
        "id": 468910381,
        "latitude": 40.7661546,
        "longitude": -73.981891,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-09-24T21:16:59.562Z",
        "type": "Periodical"
    },
    {
        "id": 468891779,
        "latitude": 40.7662542,
        "longitude": -73.9822198,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-24T20:47:59.660Z",
        "type": "Periodical"
    },
    {
        "id": 468876391,
        "latitude": 40.7662132,
        "longitude": -73.9821914,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-24T20:16:43.974Z",
        "type": "Periodical"
    },
    {
        "id": 468839574,
        "latitude": 40.7662581,
        "longitude": -73.9822947,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-24T19:06:00.168Z",
        "type": "Periodical"
    },
    {
        "id": 468813648,
        "latitude": 40.7662396,
        "longitude": -73.9822596,
        "accuracy": 66,
        "source": "Fused",
        "ts": "2024-09-24T18:03:58.993Z",
        "type": "Periodical"
    },
    {
        "id": 468804512,
        "latitude": 40.7662309,
        "longitude": -73.9821998,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-09-24T17:37:58.995Z",
        "type": "Periodical"
    },
    {
        "id": 468792058,
        "latitude": 40.7662111,
        "longitude": -73.9822277,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-09-24T16:57:58.814Z",
        "type": "Periodical"
    },
    {
        "id": 468784875,
        "latitude": 40.7590456,
        "longitude": -73.9529906,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-24T16:41:55.816Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 468743758,
        "latitude": 40.7622988,
        "longitude": -73.9498397,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-24T14:56:43.110Z",
        "type": "Periodical"
    },
    {
        "id": 468743760,
        "latitude": 40.7622988,
        "longitude": -73.9498397,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-24T14:56:43.110Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 468742907,
        "latitude": 40.7623292,
        "longitude": -73.9498315,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-24T14:54:40.441Z",
        "type": "Periodical"
    },
    {
        "id": 468732617,
        "latitude": 40.7623603,
        "longitude": -73.9498776,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-24T14:24:40.395Z",
        "type": "Periodical"
    },
    {
        "id": 468713988,
        "latitude": 40.7625329,
        "longitude": -73.949835,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-24T13:47:40.509Z",
        "type": "Periodical"
    },
    {
        "id": 468707916,
        "latitude": 40.7623928,
        "longitude": -73.9498072,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-24T13:32:04.578Z",
        "type": "Periodical"
    },
    {
        "id": 468698570,
        "latitude": 40.7623984,
        "longitude": -73.9497894,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-24T13:09:04.532Z",
        "type": "Periodical"
    },
    {
        "id": 468681538,
        "latitude": 40.7623297,
        "longitude": -73.9497688,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-24T12:30:54.581Z",
        "type": "Periodical"
    },
    {
        "id": 468662424,
        "latitude": 40.7623282,
        "longitude": -73.9498327,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-24T11:53:54.456Z",
        "type": "Periodical"
    },
    {
        "id": 468645131,
        "latitude": 40.7623991,
        "longitude": -73.9496673,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-24T11:16:54.408Z",
        "type": "Periodical"
    },
    {
        "id": 468630220,
        "latitude": 40.7623629,
        "longitude": -73.9498578,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-24T10:44:54.163Z",
        "type": "Periodical"
    },
    {
        "id": 468615569,
        "latitude": 40.7623834,
        "longitude": -73.9496211,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-24T10:11:53.929Z",
        "type": "Periodical"
    },
    {
        "id": 468602342,
        "latitude": 40.762346,
        "longitude": -73.9496445,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-24T09:32:53.836Z",
        "type": "Periodical"
    },
    {
        "id": 468589194,
        "latitude": 40.7624081,
        "longitude": -73.9496231,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-24T08:53:53.675Z",
        "type": "Periodical"
    },
    {
        "id": 468576975,
        "latitude": 40.7624133,
        "longitude": -73.9496382,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-24T08:15:53.536Z",
        "type": "Periodical"
    },
    {
        "id": 468565267,
        "latitude": 40.7623289,
        "longitude": -73.949787,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-24T07:43:53.388Z",
        "type": "Periodical"
    },
    {
        "id": 468556091,
        "latitude": 40.7623858,
        "longitude": -73.9496152,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-24T07:17:53.333Z",
        "type": "Periodical"
    },
    {
        "id": 468548553,
        "latitude": 40.7623364,
        "longitude": -73.949784,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-24T06:55:53.177Z",
        "type": "Periodical"
    },
    {
        "id": 468537007,
        "latitude": 40.7624267,
        "longitude": -73.949576,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-09-24T06:21:53.071Z",
        "type": "Periodical"
    },
    {
        "id": 468527272,
        "latitude": 40.7623967,
        "longitude": -73.9496423,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-09-24T05:56:52.897Z",
        "type": "Periodical"
    },
    {
        "id": 468518837,
        "latitude": 40.7624225,
        "longitude": -73.9496778,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-24T05:35:52.954Z",
        "type": "Periodical"
    },
    {
        "id": 468507315,
        "latitude": 40.7623354,
        "longitude": -73.9496362,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-24T05:04:52.565Z",
        "type": "Periodical"
    },
    {
        "id": 468488822,
        "latitude": 40.7623162,
        "longitude": -73.9498342,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-24T04:36:52.375Z",
        "type": "Periodical"
    },
    {
        "id": 468474105,
        "latitude": 40.7623862,
        "longitude": -73.9496247,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-24T04:06:52.634Z",
        "type": "Periodical"
    },
    {
        "id": 468452312,
        "latitude": 40.7622967,
        "longitude": -73.9498952,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-24T03:32:52.269Z",
        "type": "Periodical"
    },
    {
        "id": 468437004,
        "latitude": 40.7622891,
        "longitude": -73.9498627,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-24T03:08:52.080Z",
        "type": "Periodical"
    },
    {
        "id": 468409664,
        "latitude": 40.7623454,
        "longitude": -73.94968,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-24T02:41:32.746Z",
        "type": "Periodical"
    },
    {
        "id": 468394149,
        "latitude": 40.762306,
        "longitude": -73.949844,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-24T02:20:51.814Z",
        "type": "Periodical"
    },
    {
        "id": 468356599,
        "latitude": 40.7626322,
        "longitude": -73.949724,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-24T01:48:51.654Z",
        "type": "Periodical"
    },
    {
        "id": 468331680,
        "latitude": 40.762612,
        "longitude": -73.9497066,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-24T01:19:51.519Z",
        "type": "Periodical"
    },
    {
        "id": 468280194,
        "latitude": 40.7623428,
        "longitude": -73.949801,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-24T00:47:51.321Z",
        "type": "Periodical"
    },
    {
        "id": 468235691,
        "latitude": 40.7622867,
        "longitude": -73.9498205,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-24T00:19:51.323Z",
        "type": "Periodical"
    },
    {
        "id": 468167251,
        "latitude": 40.7626218,
        "longitude": -73.9496946,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-23T23:41:51.204Z",
        "type": "Periodical"
    },
    {
        "id": 468104941,
        "latitude": 40.7626143,
        "longitude": -73.9497026,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-23T23:13:47.804Z",
        "type": "Periodical"
    },
    {
        "id": 468030069,
        "latitude": 40.762612,
        "longitude": -73.9497016,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-23T22:44:47.608Z",
        "type": "Periodical"
    },
    {
        "id": 467977714,
        "latitude": 40.7626198,
        "longitude": -73.949691,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-23T22:23:47.558Z",
        "type": "Periodical"
    },
    {
        "id": 467871980,
        "latitude": 40.7623296,
        "longitude": -73.9496162,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-09-23T21:52:48.211Z",
        "type": "Periodical"
    },
    {
        "id": 461987413,
        "latitude": 40.7623439,
        "longitude": -73.9497273,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-14T21:53:51.437Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 461984156,
        "latitude": 40.7645991,
        "longitude": -73.9466914,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-14T21:46:08.311Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 461855789,
        "latitude": 40.761372,
        "longitude": -73.9502996,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-14T17:05:28.092Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 461846910,
        "latitude": 40.7576834,
        "longitude": -73.9808235,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-14T16:45:13.078Z",
        "type": "Periodical"
    },
    {
        "id": 461833396,
        "latitude": 40.7532248,
        "longitude": -73.970861,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-14T16:15:00.171Z",
        "type": "Periodical"
    },
    {
        "id": 461824487,
        "latitude": 40.7540084,
        "longitude": -73.9709919,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-14T15:53:16.074Z",
        "type": "Periodical"
    },
    {
        "id": 461815159,
        "latitude": 40.7452117,
        "longitude": -73.9427787,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-09-14T15:25:37.731Z",
        "type": "Periodical"
    },
    {
        "id": 461796792,
        "latitude": 40.7447476,
        "longitude": -73.9181405,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-14T14:48:16.562Z",
        "type": "Periodical"
    },
    {
        "id": 461783690,
        "latitude": 40.7447682,
        "longitude": -73.9181041,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-14T14:13:16.407Z",
        "type": "Periodical"
    },
    {
        "id": 461769711,
        "latitude": 40.7448563,
        "longitude": -73.9179805,
        "accuracy": 325,
        "source": "Fused",
        "ts": "2024-09-14T13:38:16.235Z",
        "type": "Periodical"
    },
    {
        "id": 461757778,
        "latitude": 40.7448418,
        "longitude": -73.9179977,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-14T13:06:16.250Z",
        "type": "Periodical"
    },
    {
        "id": 461747083,
        "latitude": 40.7446685,
        "longitude": -73.9181066,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-14T12:35:16.093Z",
        "type": "Periodical"
    },
    {
        "id": 461738396,
        "latitude": 40.7452412,
        "longitude": -73.9198185,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-14T12:08:56.026Z",
        "type": "Periodical"
    },
    {
        "id": 461726052,
        "latitude": 40.7642957,
        "longitude": -73.9324704,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-14T11:29:27.922Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 461474634,
        "latitude": 40.7626146,
        "longitude": -73.9497432,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-14T00:20:12.621Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 461471165,
        "latitude": 40.7659343,
        "longitude": -73.9604123,
        "accuracy": 1299,
        "source": "Fused",
        "ts": "2024-09-14T00:08:13.317Z",
        "type": "Periodical"
    },
    {
        "id": 461452891,
        "latitude": 40.7662664,
        "longitude": -73.9824708,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-13T23:36:13.441Z",
        "type": "Periodical"
    },
    {
        "id": 461434212,
        "latitude": 40.7661429,
        "longitude": -73.9822826,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-13T23:06:53.188Z",
        "type": "Periodical"
    },
    {
        "id": 461416672,
        "latitude": 40.7662537,
        "longitude": -73.9824212,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-13T22:33:13.356Z",
        "type": "Periodical"
    },
    {
        "id": 461398648,
        "latitude": 40.7662524,
        "longitude": -73.9825693,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-09-13T21:58:13.200Z",
        "type": "Periodical"
    },
    {
        "id": 461387466,
        "latitude": 40.7660724,
        "longitude": -73.9822132,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-09-13T21:35:12.902Z",
        "type": "Periodical"
    },
    {
        "id": 461367075,
        "latitude": 40.7661633,
        "longitude": -73.9818302,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-09-13T20:59:00.714Z",
        "type": "Periodical"
    },
    {
        "id": 461356437,
        "latitude": 40.7662374,
        "longitude": -73.9823769,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-13T20:38:12.814Z",
        "type": "Periodical"
    },
    {
        "id": 461344341,
        "latitude": 40.7664044,
        "longitude": -73.9827689,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-13T20:15:12.663Z",
        "type": "Periodical"
    },
    {
        "id": 461325483,
        "latitude": 40.766251,
        "longitude": -73.9824106,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-13T19:36:12.591Z",
        "type": "Periodical"
    },
    {
        "id": 461317050,
        "latitude": 40.7662538,
        "longitude": -73.9824261,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-13T19:16:12.386Z",
        "type": "Periodical"
    },
    {
        "id": 461307047,
        "latitude": 40.7663414,
        "longitude": -73.9825107,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-13T18:54:12.335Z",
        "type": "Periodical"
    },
    {
        "id": 461296025,
        "latitude": 40.7662428,
        "longitude": -73.9823759,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-13T18:25:12.340Z",
        "type": "Periodical"
    },
    {
        "id": 461289344,
        "latitude": 40.7661923,
        "longitude": -73.982345,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-13T18:06:12.318Z",
        "type": "Periodical"
    },
    {
        "id": 461279832,
        "latitude": 40.7599983,
        "longitude": -73.9523253,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-13T17:36:35.775Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 461266427,
        "latitude": 40.762789,
        "longitude": -73.9492879,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-13T16:56:05.161Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 461262110,
        "latitude": 40.7671569,
        "longitude": -73.9448871,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-09-13T16:43:51.631Z",
        "type": "Periodical"
    },
    {
        "id": 461255064,
        "latitude": 40.7672135,
        "longitude": -73.9448491,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:19:32.193Z",
        "type": "Periodical"
    },
    {
        "id": 461255011,
        "latitude": 40.7672135,
        "longitude": -73.9448491,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:19:32.193Z",
        "type": "Periodical"
    },
    {
        "id": 461255009,
        "latitude": 40.7672135,
        "longitude": -73.9448491,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:19:32.193Z",
        "type": "Periodical"
    },
    {
        "id": 461255023,
        "latitude": 40.7672135,
        "longitude": -73.9448491,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:19:32.193Z",
        "type": "Periodical"
    },
    {
        "id": 461255044,
        "latitude": 40.7672135,
        "longitude": -73.9448491,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:19:32.193Z",
        "type": "Periodical"
    },
    {
        "id": 461255056,
        "latitude": 40.7672135,
        "longitude": -73.9448491,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:19:32.193Z",
        "type": "Periodical"
    },
    {
        "id": 461254936,
        "latitude": 40.7672418,
        "longitude": -73.9448634,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-13T16:19:05.195Z",
        "type": "Periodical"
    },
    {
        "id": 461254849,
        "latitude": 40.7672418,
        "longitude": -73.9448634,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-13T16:19:05.195Z",
        "type": "Periodical"
    },
    {
        "id": 461254846,
        "latitude": 40.7672418,
        "longitude": -73.9448634,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-13T16:19:05.195Z",
        "type": "Periodical"
    },
    {
        "id": 461254703,
        "latitude": 40.7673815,
        "longitude": -73.9445594,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:18:34.749Z",
        "type": "Periodical"
    },
    {
        "id": 461254723,
        "latitude": 40.7673815,
        "longitude": -73.9445594,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:18:34.749Z",
        "type": "Periodical"
    },
    {
        "id": 461254721,
        "latitude": 40.7673815,
        "longitude": -73.9445594,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:18:34.749Z",
        "type": "Periodical"
    },
    {
        "id": 461254786,
        "latitude": 40.7673815,
        "longitude": -73.9445594,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:18:34.749Z",
        "type": "Periodical"
    },
    {
        "id": 461254833,
        "latitude": 40.7673815,
        "longitude": -73.9445594,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:18:34.749Z",
        "type": "Periodical"
    },
    {
        "id": 461254493,
        "latitude": 40.767376,
        "longitude": -73.9445066,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-13T16:18:00.032Z",
        "type": "Periodical"
    },
    {
        "id": 461254500,
        "latitude": 40.767376,
        "longitude": -73.9445066,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-13T16:18:00.032Z",
        "type": "Periodical"
    },
    {
        "id": 461254494,
        "latitude": 40.7672126,
        "longitude": -73.9452326,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-13T16:17:58.493Z",
        "type": "Periodical"
    },
    {
        "id": 461254235,
        "latitude": 40.7672723,
        "longitude": -73.9445455,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:17:15.392Z",
        "type": "Periodical"
    },
    {
        "id": 461254261,
        "latitude": 40.7672723,
        "longitude": -73.9445455,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:17:15.392Z",
        "type": "Periodical"
    },
    {
        "id": 461254259,
        "latitude": 40.7672723,
        "longitude": -73.9445455,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:17:15.392Z",
        "type": "Periodical"
    },
    {
        "id": 461254238,
        "latitude": 40.7672723,
        "longitude": -73.9445455,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-09-13T16:17:15.392Z",
        "type": "Periodical"
    },
    {
        "id": 461254095,
        "latitude": 40.7672492,
        "longitude": -73.9448908,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-13T16:16:35.150Z",
        "type": "Periodical"
    },
    {
        "id": 461254096,
        "latitude": 40.7672492,
        "longitude": -73.9448908,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-13T16:16:35.150Z",
        "type": "Periodical"
    },
    {
        "id": 461254107,
        "latitude": 40.7672492,
        "longitude": -73.9448908,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-13T16:16:35.150Z",
        "type": "Periodical"
    },
    {
        "id": 461254007,
        "latitude": 40.7672463,
        "longitude": -73.9448204,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:16:15.232Z",
        "type": "Periodical"
    },
    {
        "id": 461254016,
        "latitude": 40.7672463,
        "longitude": -73.9448204,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-13T16:16:15.232Z",
        "type": "Periodical"
    },
    {
        "id": 461253999,
        "latitude": 40.7673997,
        "longitude": -73.9445306,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-13T16:15:52.447Z",
        "type": "Periodical"
    },
    {
        "id": 461253983,
        "latitude": 40.7672571,
        "longitude": -73.9445846,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-13T16:15:09.325Z",
        "type": "Periodical"
    },
    {
        "id": 461253606,
        "latitude": 40.767257,
        "longitude": -73.9445847,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-13T16:15:09.109Z",
        "type": "Periodical"
    },
    {
        "id": 461253598,
        "latitude": 40.767257,
        "longitude": -73.9445847,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-13T16:15:09.109Z",
        "type": "Periodical"
    },
    {
        "id": 461253555,
        "latitude": 40.7672373,
        "longitude": -73.9448365,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-13T16:14:38.758Z",
        "type": "Periodical"
    },
    {
        "id": 461245838,
        "latitude": 40.7664996,
        "longitude": -73.9517861,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-09-13T15:49:52.715Z",
        "type": "Periodical"
    },
    {
        "id": 461242147,
        "latitude": 40.7645893,
        "longitude": -73.9467007,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-13T15:39:13.654Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 460891857,
        "latitude": 40.762843,
        "longitude": -73.9495399,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-13T00:11:06.699Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 460879032,
        "latitude": 40.7516495,
        "longitude": -73.9900303,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-09-12T23:44:19.508Z",
        "type": "Periodical"
    },
    {
        "id": 460863583,
        "latitude": 40.7512463,
        "longitude": -73.9914385,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-12T23:12:19.506Z",
        "type": "Periodical"
    },
    {
        "id": 460850068,
        "latitude": 40.7504383,
        "longitude": -73.9913944,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-12T22:50:56.680Z",
        "type": "Periodical"
    },
    {
        "id": 460840323,
        "latitude": 40.7523989,
        "longitude": -73.9939722,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-09-12T22:32:50.999Z",
        "type": "Periodical"
    },
    {
        "id": 460803296,
        "latitude": 40.7524283,
        "longitude": -73.9943536,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-09-12T21:21:19.375Z",
        "type": "Periodical"
    },
    {
        "id": 460788597,
        "latitude": 40.7524402,
        "longitude": -73.9945375,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-09-12T20:52:19.205Z",
        "type": "Periodical"
    },
    {
        "id": 460775603,
        "latitude": 40.7509228,
        "longitude": -73.9904724,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-12T20:23:19.125Z",
        "type": "Periodical"
    },
    {
        "id": 460764746,
        "latitude": 40.7599201,
        "longitude": -73.9521332,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-12T20:00:01.460Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 460723751,
        "latitude": 40.7628362,
        "longitude": -73.9494051,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-12T18:22:12.736Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 460719226,
        "latitude": 40.7639333,
        "longitude": -73.9156219,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-09-12T18:07:18.547Z",
        "type": "Periodical"
    },
    {
        "id": 460709299,
        "latitude": 40.7636383,
        "longitude": -73.9150784,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-12T17:40:18.434Z",
        "type": "Periodical"
    },
    {
        "id": 460700877,
        "latitude": 40.7636252,
        "longitude": -73.9150556,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-12T17:20:04.069Z",
        "type": "Periodical"
    },
    {
        "id": 460692393,
        "latitude": 40.7628954,
        "longitude": -73.9157994,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-12T16:52:18.357Z",
        "type": "Periodical"
    },
    {
        "id": 460680936,
        "latitude": 40.744733,
        "longitude": -73.9182515,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-12T16:13:18.292Z",
        "type": "Periodical"
    },
    {
        "id": 460672300,
        "latitude": 40.7549611,
        "longitude": -73.9311665,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-12T15:44:12.741Z",
        "type": "Periodical"
    },
    {
        "id": 460660716,
        "latitude": 40.767373,
        "longitude": -73.9386589,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-12T15:06:18.167Z",
        "type": "Periodical"
    },
    {
        "id": 460653243,
        "latitude": 40.7674376,
        "longitude": -73.9381873,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-09-12T14:41:57.054Z",
        "type": "Periodical"
    },
    {
        "id": 460653246,
        "latitude": 40.7678412,
        "longitude": -73.9378139,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-12T14:40:39.325Z",
        "type": "Periodical"
    },
    {
        "id": 460653247,
        "latitude": 40.7678412,
        "longitude": -73.9378139,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-12T14:40:39.325Z",
        "type": "Periodical"
    },
    {
        "id": 460652713,
        "latitude": 40.7678955,
        "longitude": -73.9374973,
        "accuracy": 737,
        "source": "Fused",
        "ts": "2024-09-12T14:40:23.243Z",
        "type": "Periodical"
    },
    {
        "id": 460653245,
        "latitude": 40.7678955,
        "longitude": -73.9374973,
        "accuracy": 737,
        "source": "Fused",
        "ts": "2024-09-12T14:40:23.243Z",
        "type": "Periodical"
    },
    {
        "id": 460652705,
        "latitude": 40.7678955,
        "longitude": -73.9374973,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-12T14:40:03.592Z",
        "type": "Periodical"
    },
    {
        "id": 460652700,
        "latitude": 40.7678955,
        "longitude": -73.9374973,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-12T14:40:03.592Z",
        "type": "Periodical"
    },
    {
        "id": 460652338,
        "latitude": 40.7680457,
        "longitude": -73.9375947,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-12T14:39:14.822Z",
        "type": "Periodical"
    },
    {
        "id": 460652594,
        "latitude": 40.7680457,
        "longitude": -73.9375947,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-12T14:39:14.822Z",
        "type": "Periodical"
    },
    {
        "id": 460648543,
        "latitude": 40.7608166,
        "longitude": -73.9296642,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-12T14:27:42.838Z",
        "type": "Periodical"
    },
    {
        "id": 460636658,
        "latitude": 40.7447702,
        "longitude": -73.9184165,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-12T13:49:42.754Z",
        "type": "Periodical"
    },
    {
        "id": 460628952,
        "latitude": 40.7646684,
        "longitude": -73.9474381,
        "accuracy": 104,
        "source": "Fused",
        "ts": "2024-09-12T13:27:53.318Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 460268741,
        "latitude": 40.7627868,
        "longitude": -73.9493156,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-09-11T22:31:05.876Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 460261760,
        "latitude": 40.7638516,
        "longitude": -73.9330268,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-11T22:19:03.781Z",
        "type": "Periodical"
    },
    {
        "id": 460243971,
        "latitude": 40.7875552,
        "longitude": -73.9549345,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-11T21:51:03.716Z",
        "type": "Periodical"
    },
    {
        "id": 460227031,
        "latitude": 40.7904102,
        "longitude": -73.9735518,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-11T21:19:59.820Z",
        "type": "Periodical"
    },
    {
        "id": 460213935,
        "latitude": 40.7669023,
        "longitude": -73.9690111,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-11T20:52:49.606Z",
        "type": "Periodical"
    },
    {
        "id": 460194738,
        "latitude": 40.7645988,
        "longitude": -73.9472794,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-09-11T20:25:37.226Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 460186981,
        "latitude": 40.7622991,
        "longitude": -73.9508732,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-11T20:08:41.179Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 460180676,
        "latitude": 40.7568862,
        "longitude": -73.95499,
        "accuracy": 62,
        "source": "Fused",
        "ts": "2024-09-11T19:59:41.477Z",
        "type": "Periodical"
    },
    {
        "id": 460169525,
        "latitude": 40.7600768,
        "longitude": -73.9520547,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-09-11T19:39:26.289Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 459462411,
        "latitude": 40.7616191,
        "longitude": -73.9500885,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-10T21:48:18.408Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 459445801,
        "latitude": 40.76705,
        "longitude": -73.9830162,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-09-10T21:24:12.217Z",
        "type": "Periodical"
    },
    {
        "id": 459445639,
        "latitude": 40.7669763,
        "longitude": -73.9828217,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-10T21:23:48.250Z",
        "type": "Periodical"
    },
    {
        "id": 459445560,
        "latitude": 40.7669128,
        "longitude": -73.9827618,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-10T21:23:31.628Z",
        "type": "Periodical"
    },
    {
        "id": 459428418,
        "latitude": 40.7662322,
        "longitude": -73.9819848,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-09-10T21:02:01.677Z",
        "type": "Periodical"
    },
    {
        "id": 459406723,
        "latitude": 40.7660846,
        "longitude": -73.9818467,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-10T20:30:01.681Z",
        "type": "Periodical"
    },
    {
        "id": 459379436,
        "latitude": 40.7661328,
        "longitude": -73.981994,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-09-10T19:54:01.440Z",
        "type": "Periodical"
    },
    {
        "id": 459354857,
        "latitude": 40.766306,
        "longitude": -73.982466,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-10T19:19:59.319Z",
        "type": "Periodical"
    },
    {
        "id": 459332482,
        "latitude": 40.7662182,
        "longitude": -73.9824699,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-10T18:50:51.078Z",
        "type": "Periodical"
    },
    {
        "id": 459305896,
        "latitude": 40.766275,
        "longitude": -73.9825955,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-10T18:16:01.184Z",
        "type": "Periodical"
    },
    {
        "id": 459265999,
        "latitude": 40.7663574,
        "longitude": -73.9827909,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-10T17:47:01.035Z",
        "type": "Periodical"
    },
    {
        "id": 459221438,
        "latitude": 40.7663259,
        "longitude": -73.9826932,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-10T17:16:00.839Z",
        "type": "Periodical"
    },
    {
        "id": 459144773,
        "latitude": 40.7663119,
        "longitude": -73.9825039,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-10T16:51:44.185Z",
        "type": "Periodical"
    },
    {
        "id": 458967028,
        "latitude": 40.7662494,
        "longitude": -73.982406,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-10T16:15:00.822Z",
        "type": "Periodical"
    },
    {
        "id": 458958224,
        "latitude": 40.7452117,
        "longitude": -73.9427787,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-09-10T15:36:32.460Z",
        "type": "Periodical"
    },
    {
        "id": 458951257,
        "latitude": 40.7429422,
        "longitude": -73.9185146,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-09-10T15:14:58.600Z",
        "type": "Periodical"
    },
    {
        "id": 458945422,
        "latitude": 40.7447827,
        "longitude": -73.9180719,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-10T14:52:53.099Z",
        "type": "Periodical"
    },
    {
        "id": 458935046,
        "latitude": 40.7447827,
        "longitude": -73.9180446,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-10T14:15:00.650Z",
        "type": "Periodical"
    },
    {
        "id": 458927970,
        "latitude": 40.7447587,
        "longitude": -73.9181178,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-10T13:50:00.315Z",
        "type": "Periodical"
    },
    {
        "id": 458919027,
        "latitude": 40.7447974,
        "longitude": -73.9180216,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-10T13:18:59.475Z",
        "type": "Periodical"
    },
    {
        "id": 458912804,
        "latitude": 40.7447498,
        "longitude": -73.9183825,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-10T12:57:59.290Z",
        "type": "Periodical"
    },
    {
        "id": 458899846,
        "latitude": 40.7447779,
        "longitude": -73.9182861,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-10T12:17:01.185Z",
        "type": "Periodical"
    },
    {
        "id": 458889839,
        "latitude": 40.7646362,
        "longitude": -73.9472635,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-09-10T11:47:36.699Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 458540709,
        "latitude": 40.7624339,
        "longitude": -73.949703,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-09T20:57:25.132Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 458518748,
        "latitude": 40.7632893,
        "longitude": -73.9154391,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-09T20:20:12.374Z",
        "type": "Periodical"
    },
    {
        "id": 458490279,
        "latitude": 40.7629804,
        "longitude": -73.9156581,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-09-09T19:43:10.439Z",
        "type": "Periodical"
    },
    {
        "id": 458464467,
        "latitude": 40.743347,
        "longitude": -73.9197817,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-09-09T19:09:12.333Z",
        "type": "Periodical"
    },
    {
        "id": 458435597,
        "latitude": 40.7447514,
        "longitude": -73.9183065,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-09T18:31:12.298Z",
        "type": "Periodical"
    },
    {
        "id": 458410794,
        "latitude": 40.7447723,
        "longitude": -73.9182696,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-09T18:05:11.931Z",
        "type": "Periodical"
    },
    {
        "id": 458338876,
        "latitude": 40.7447575,
        "longitude": -73.9182868,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-09T17:27:11.766Z",
        "type": "Periodical"
    },
    {
        "id": 458284955,
        "latitude": 40.7447553,
        "longitude": -73.9183057,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-09T17:05:36.240Z",
        "type": "Periodical"
    },
    {
        "id": 458274045,
        "latitude": 40.7447721,
        "longitude": -73.9182931,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-09T16:32:11.509Z",
        "type": "Periodical"
    },
    {
        "id": 458267559,
        "latitude": 40.7447687,
        "longitude": -73.9182844,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-09T16:08:11.509Z",
        "type": "Periodical"
    },
    {
        "id": 458262029,
        "latitude": 40.7447881,
        "longitude": -73.9182457,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-09T15:46:07.356Z",
        "type": "Periodical"
    },
    {
        "id": 458254062,
        "latitude": 40.7447757,
        "longitude": -73.9182844,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-09T15:15:01.340Z",
        "type": "Periodical"
    },
    {
        "id": 458243451,
        "latitude": 40.7447304,
        "longitude": -73.9182637,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-09T14:36:29.055Z",
        "type": "Periodical"
    },
    {
        "id": 458236463,
        "latitude": 40.7447673,
        "longitude": -73.918269,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-09T14:14:28.963Z",
        "type": "Periodical"
    },
    {
        "id": 458228107,
        "latitude": 40.7447792,
        "longitude": -73.9182391,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-09T13:45:28.823Z",
        "type": "Periodical"
    },
    {
        "id": 458220529,
        "latitude": 40.7600013,
        "longitude": -73.9416555,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-09T13:20:12.153Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 458195989,
        "latitude": 40.7623418,
        "longitude": -73.9497127,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-09T12:05:26.363Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 458190950,
        "latitude": 40.7639953,
        "longitude": -73.9479259,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-09T11:49:46.604Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 457939903,
        "latitude": 40.7626386,
        "longitude": -73.9497355,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-08T23:20:09.843Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 457939904,
        "latitude": 40.7626386,
        "longitude": -73.9497355,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-08T23:20:09.843Z",
        "type": "Periodical"
    },
    {
        "id": 457926456,
        "latitude": 40.7667628,
        "longitude": -73.9469804,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-09-08T22:45:44.080Z",
        "type": "Periodical"
    },
    {
        "id": 457914351,
        "latitude": 40.7662158,
        "longitude": -73.9468001,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-08T22:13:09.544Z",
        "type": "Periodical"
    },
    {
        "id": 457898315,
        "latitude": 40.7662362,
        "longitude": -73.946318,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-09-08T21:35:09.395Z",
        "type": "Periodical"
    },
    {
        "id": 457885634,
        "latitude": 40.7662204,
        "longitude": -73.9463932,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-09-08T21:05:09.291Z",
        "type": "Periodical"
    },
    {
        "id": 457884548,
        "latitude": 40.7653572,
        "longitude": -73.9473936,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-09-08T21:02:57.835Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 457849545,
        "latitude": 40.7620599,
        "longitude": -73.9503242,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-09-08T19:44:06.603Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 457848616,
        "latitude": 40.7619008,
        "longitude": -73.9512948,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-08T19:42:53.063Z",
        "type": "Periodical"
    },
    {
        "id": 457839874,
        "latitude": 40.7587321,
        "longitude": -73.9531462,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-08T19:22:52.961Z",
        "type": "Periodical"
    },
    {
        "id": 457835893,
        "latitude": 40.7589867,
        "longitude": -73.9534508,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-08T19:14:01.281Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 457826629,
        "latitude": 40.7624383,
        "longitude": -73.9497322,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-08T18:49:51.465Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 457820000,
        "latitude": 40.7587328,
        "longitude": -73.953158,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-08T18:33:17.487Z",
        "type": "Periodical"
    },
    {
        "id": 457819382,
        "latitude": 40.7587326,
        "longitude": -73.9531531,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-08T18:31:52.604Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 457451594,
        "latitude": 40.7620792,
        "longitude": -73.9502822,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-08T00:11:57.774Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 457442051,
        "latitude": 40.7663202,
        "longitude": -73.9819279,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-07T23:47:36.799Z",
        "type": "Periodical"
    },
    {
        "id": 457428052,
        "latitude": 40.766273,
        "longitude": -73.9825604,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-07T23:10:36.700Z",
        "type": "Periodical"
    },
    {
        "id": 457416675,
        "latitude": 40.7662948,
        "longitude": -73.9825716,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-07T22:38:36.709Z",
        "type": "Periodical"
    },
    {
        "id": 457407619,
        "latitude": 40.7662354,
        "longitude": -73.9823703,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-07T22:14:36.661Z",
        "type": "Periodical"
    },
    {
        "id": 457399464,
        "latitude": 40.7662845,
        "longitude": -73.9825581,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-07T21:53:20.935Z",
        "type": "Periodical"
    },
    {
        "id": 457385189,
        "latitude": 40.7663289,
        "longitude": -73.9825703,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-09-07T21:20:00.070Z",
        "type": "Periodical"
    },
    {
        "id": 457374773,
        "latitude": 40.7662435,
        "longitude": -73.9824098,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-07T20:53:18.514Z",
        "type": "Periodical"
    },
    {
        "id": 457359894,
        "latitude": 40.7662263,
        "longitude": -73.9822603,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-07T20:17:03.890Z",
        "type": "Periodical"
    },
    {
        "id": 457344525,
        "latitude": 40.7665197,
        "longitude": -73.9827423,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-09-07T19:41:18.439Z",
        "type": "Periodical"
    },
    {
        "id": 457327787,
        "latitude": 40.7665125,
        "longitude": -73.982585,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-07T19:02:18.199Z",
        "type": "Periodical"
    },
    {
        "id": 457315018,
        "latitude": 40.766156,
        "longitude": -73.9820283,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-09-07T18:30:18.121Z",
        "type": "Periodical"
    },
    {
        "id": 457307609,
        "latitude": 40.7662685,
        "longitude": -73.9821754,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-09-07T18:11:18.074Z",
        "type": "Periodical"
    },
    {
        "id": 457301967,
        "latitude": 40.7590104,
        "longitude": -73.9529708,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-07T17:50:47.885Z",
        "type": "Periodical"
    },
    {
        "id": 457290557,
        "latitude": 40.7611231,
        "longitude": -73.9506127,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-07T17:32:29.241Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 456994973,
        "latitude": 40.762354,
        "longitude": -73.9497297,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-07T03:05:18.098Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 456990555,
        "latitude": 40.7598041,
        "longitude": -73.9525706,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-09-07T02:55:14.525Z",
        "type": "Periodical"
    },
    {
        "id": 456978057,
        "latitude": 40.760072,
        "longitude": -73.9805022,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-07T02:25:58.620Z",
        "type": "Periodical"
    },
    {
        "id": 456964799,
        "latitude": 40.7599775,
        "longitude": -73.9795335,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-07T01:56:33.535Z",
        "type": "Periodical"
    },
    {
        "id": 456953526,
        "latitude": 40.7600331,
        "longitude": -73.9794367,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-07T01:32:33.488Z",
        "type": "Periodical"
    },
    {
        "id": 456937071,
        "latitude": 40.7600361,
        "longitude": -73.979397,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-07T00:56:04.326Z",
        "type": "Periodical"
    },
    {
        "id": 456920104,
        "latitude": 40.7600102,
        "longitude": -73.9794525,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-07T00:18:04.210Z",
        "type": "Periodical"
    },
    {
        "id": 456904274,
        "latitude": 40.7600698,
        "longitude": -73.9797433,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-06T23:44:04.139Z",
        "type": "Periodical"
    },
    {
        "id": 456894747,
        "latitude": 40.7584453,
        "longitude": -73.9521971,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-06T23:22:23.128Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 456744209,
        "latitude": 40.7625062,
        "longitude": -73.9497163,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-06T17:40:23.826Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 456738904,
        "latitude": 40.7587562,
        "longitude": -73.9533091,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-06T17:22:08.908Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 456709237,
        "latitude": 40.7626304,
        "longitude": -73.9494214,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-06T15:40:19.445Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 456703541,
        "latitude": 40.7586377,
        "longitude": -73.9193873,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-06T15:20:40.632Z",
        "type": "Periodical"
    },
    {
        "id": 456691867,
        "latitude": 40.7586298,
        "longitude": -73.9193468,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-06T14:43:29.108Z",
        "type": "Periodical"
    },
    {
        "id": 456680058,
        "latitude": 40.7636457,
        "longitude": -73.9150669,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-06T14:03:28.951Z",
        "type": "Periodical"
    },
    {
        "id": 456672913,
        "latitude": 40.7636478,
        "longitude": -73.9150757,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-06T13:42:28.821Z",
        "type": "Periodical"
    },
    {
        "id": 456664238,
        "latitude": 40.7629013,
        "longitude": -73.9157397,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-06T13:14:28.715Z",
        "type": "Periodical"
    },
    {
        "id": 456651890,
        "latitude": 40.7653455,
        "longitude": -73.931927,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-06T12:38:08.452Z",
        "type": "Periodical"
    },
    {
        "id": 456647633,
        "latitude": 40.7645647,
        "longitude": -73.9468763,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-09-06T12:25:31.600Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 456272928,
        "latitude": 40.761456,
        "longitude": -73.9503416,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-05T20:45:09.762Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 456272885,
        "latitude": 40.7612245,
        "longitude": -73.9504718,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-05T20:44:49.675Z",
        "type": "Periodical"
    },
    {
        "id": 456262075,
        "latitude": 40.7660372,
        "longitude": -73.9812435,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-05T20:22:46.235Z",
        "type": "Periodical"
    },
    {
        "id": 456248085,
        "latitude": 40.7662507,
        "longitude": -73.9824028,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-09-05T19:57:58.681Z",
        "type": "Periodical"
    },
    {
        "id": 456233134,
        "latitude": 40.7661695,
        "longitude": -73.9820188,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-09-05T19:23:05.591Z",
        "type": "Periodical"
    },
    {
        "id": 456220903,
        "latitude": 40.7664063,
        "longitude": -73.9824253,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-05T18:51:05.490Z",
        "type": "Periodical"
    },
    {
        "id": 456207652,
        "latitude": 40.7663829,
        "longitude": -73.9828585,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-05T18:11:05.451Z",
        "type": "Periodical"
    },
    {
        "id": 456197551,
        "latitude": 40.7662676,
        "longitude": -73.982538,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-05T17:37:43.674Z",
        "type": "Periodical"
    },
    {
        "id": 456189848,
        "latitude": 40.7663442,
        "longitude": -73.9827314,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-05T17:11:43.507Z",
        "type": "Periodical"
    },
    {
        "id": 456183679,
        "latitude": 40.7662676,
        "longitude": -73.9824493,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-09-05T16:51:43.660Z",
        "type": "Periodical"
    },
    {
        "id": 456172202,
        "latitude": 40.76625,
        "longitude": -73.9824256,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-05T16:14:43.530Z",
        "type": "Periodical"
    },
    {
        "id": 456166755,
        "latitude": 40.7662498,
        "longitude": -73.9823459,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-09-05T15:55:43.477Z",
        "type": "Periodical"
    },
    {
        "id": 456157423,
        "latitude": 40.7662486,
        "longitude": -73.9826374,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-05T15:25:27.111Z",
        "type": "Periodical"
    },
    {
        "id": 456147769,
        "latitude": 40.7662791,
        "longitude": -73.982578,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-05T14:54:43.129Z",
        "type": "Periodical"
    },
    {
        "id": 456136707,
        "latitude": 40.7667087,
        "longitude": -73.9828615,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-09-05T14:20:02.272Z",
        "type": "Periodical"
    },
    {
        "id": 456128111,
        "latitude": 40.7609738,
        "longitude": -73.9514665,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-05T13:52:46.337Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 455870462,
        "latitude": 40.7620299,
        "longitude": -73.9510137,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-05T02:09:31.189Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 455866660,
        "latitude": 40.7594584,
        "longitude": -73.9530153,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-05T02:04:25.764Z",
        "type": "Periodical"
    },
    {
        "id": 455848857,
        "latitude": 40.7662152,
        "longitude": -73.9826055,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-05T01:29:30.478Z",
        "type": "Periodical"
    },
    {
        "id": 455827993,
        "latitude": 40.7662296,
        "longitude": -73.9825109,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-05T00:51:30.594Z",
        "type": "Periodical"
    },
    {
        "id": 455816280,
        "latitude": 40.7663667,
        "longitude": -73.9828016,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-05T00:28:30.452Z",
        "type": "Periodical"
    },
    {
        "id": 455798821,
        "latitude": 40.7662328,
        "longitude": -73.9823701,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-09-04T23:54:30.313Z",
        "type": "Periodical"
    },
    {
        "id": 455777102,
        "latitude": 40.7661652,
        "longitude": -73.9820268,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-09-04T23:14:13.002Z",
        "type": "Periodical"
    },
    {
        "id": 455762166,
        "latitude": 40.7664516,
        "longitude": -73.9825183,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-09-04T22:46:29.826Z",
        "type": "Periodical"
    },
    {
        "id": 455747545,
        "latitude": 40.7662342,
        "longitude": -73.9823749,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-04T22:21:29.911Z",
        "type": "Periodical"
    },
    {
        "id": 455732126,
        "latitude": 40.7662377,
        "longitude": -73.982376,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-04T21:51:29.575Z",
        "type": "Periodical"
    },
    {
        "id": 455722246,
        "latitude": 40.766245,
        "longitude": -73.9823891,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-04T21:32:29.652Z",
        "type": "Periodical"
    },
    {
        "id": 455704601,
        "latitude": 40.7662387,
        "longitude": -73.9823985,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-04T21:00:29.583Z",
        "type": "Periodical"
    },
    {
        "id": 455691033,
        "latitude": 40.7666343,
        "longitude": -73.9829361,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-09-04T20:33:29.347Z",
        "type": "Periodical"
    },
    {
        "id": 455679470,
        "latitude": 40.7660429,
        "longitude": -73.9816884,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-09-04T20:08:22.957Z",
        "type": "Periodical"
    },
    {
        "id": 455661986,
        "latitude": 40.7326291,
        "longitude": -74.0001533,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-04T19:29:28.054Z",
        "type": "Periodical"
    },
    {
        "id": 455649837,
        "latitude": 40.7082902,
        "longitude": -74.0105725,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-04T18:59:28.610Z",
        "type": "Periodical"
    },
    {
        "id": 455638008,
        "latitude": 40.7083469,
        "longitude": -74.010793,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-04T18:27:28.882Z",
        "type": "Periodical"
    },
    {
        "id": 455627740,
        "latitude": 40.7083469,
        "longitude": -74.010793,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T17:57:28.880Z",
        "type": "Periodical"
    },
    {
        "id": 455620297,
        "latitude": 40.7091685,
        "longitude": -74.0110684,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-04T17:33:28.826Z",
        "type": "Periodical"
    },
    {
        "id": 455609742,
        "latitude": 40.7124563,
        "longitude": -74.0047802,
        "accuracy": 941,
        "source": "Fused",
        "ts": "2024-09-04T16:57:57.278Z",
        "type": "Periodical"
    },
    {
        "id": 455597989,
        "latitude": 40.7602635,
        "longitude": -73.9518131,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-09-04T16:19:20.063Z",
        "type": "Periodical"
    },
    {
        "id": 455597134,
        "latitude": 40.7608394,
        "longitude": -73.9511369,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-04T16:16:35.766Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 455581857,
        "latitude": 40.7613787,
        "longitude": -73.9502107,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-04T15:25:29.871Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 455579827,
        "latitude": 40.7591557,
        "longitude": -73.9533137,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T15:19:47.861Z",
        "type": "Periodical"
    },
    {
        "id": 455578835,
        "latitude": 40.7607815,
        "longitude": -73.9512473,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-04T15:15:59.565Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 455559441,
        "latitude": 40.7623603,
        "longitude": -73.9497561,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-04T14:16:42.686Z",
        "type": "Periodical"
    },
    {
        "id": 455556491,
        "latitude": 40.7623911,
        "longitude": -73.9497411,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-04T14:08:00.935Z",
        "type": "Periodical"
    },
    {
        "id": 455543972,
        "latitude": 40.762353,
        "longitude": -73.9497312,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-04T13:28:39.912Z",
        "type": "Periodical"
    },
    {
        "id": 455533646,
        "latitude": 40.7623402,
        "longitude": -73.9497333,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-04T12:58:00.676Z",
        "type": "Periodical"
    },
    {
        "id": 455524513,
        "latitude": 40.7623437,
        "longitude": -73.949741,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-04T12:31:00.484Z",
        "type": "Periodical"
    },
    {
        "id": 455513277,
        "latitude": 40.7623568,
        "longitude": -73.9497301,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-04T12:00:00.356Z",
        "type": "Periodical"
    },
    {
        "id": 455505644,
        "latitude": 40.7623701,
        "longitude": -73.9496994,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-04T11:40:00.339Z",
        "type": "Periodical"
    },
    {
        "id": 455489986,
        "latitude": 40.7623949,
        "longitude": -73.9497775,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-04T10:58:10.809Z",
        "type": "Periodical"
    },
    {
        "id": 455479014,
        "latitude": 40.7623369,
        "longitude": -73.9496621,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-04T10:26:10.881Z",
        "type": "Periodical"
    },
    {
        "id": 455472654,
        "latitude": 40.7623406,
        "longitude": -73.9496426,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-04T10:06:10.707Z",
        "type": "Periodical"
    },
    {
        "id": 455460375,
        "latitude": 40.7623466,
        "longitude": -73.9496761,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-04T09:27:10.599Z",
        "type": "Periodical"
    },
    {
        "id": 455448321,
        "latitude": 40.7623291,
        "longitude": -73.9496967,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-04T08:48:10.458Z",
        "type": "Periodical"
    },
    {
        "id": 455438002,
        "latitude": 40.7623497,
        "longitude": -73.9496938,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-04T08:13:10.113Z",
        "type": "Periodical"
    },
    {
        "id": 455428685,
        "latitude": 40.7623497,
        "longitude": -73.9496938,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-04T07:43:10.110Z",
        "type": "Periodical"
    },
    {
        "id": 455416711,
        "latitude": 40.7623356,
        "longitude": -73.9496505,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-04T07:04:09.911Z",
        "type": "Periodical"
    },
    {
        "id": 455408064,
        "latitude": 40.762336,
        "longitude": -73.9496582,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-04T06:37:09.762Z",
        "type": "Periodical"
    },
    {
        "id": 455401845,
        "latitude": 40.7623319,
        "longitude": -73.949687,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T06:17:09.760Z",
        "type": "Periodical"
    },
    {
        "id": 455390106,
        "latitude": 40.7624558,
        "longitude": -73.9498397,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T05:42:09.501Z",
        "type": "Periodical"
    },
    {
        "id": 455378574,
        "latitude": 40.762325,
        "longitude": -73.9496208,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-04T05:08:09.367Z",
        "type": "Periodical"
    },
    {
        "id": 455366101,
        "latitude": 40.7623225,
        "longitude": -73.9496245,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-04T04:33:09.077Z",
        "type": "Periodical"
    },
    {
        "id": 455356905,
        "latitude": 40.7623402,
        "longitude": -73.9496681,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T04:09:09.112Z",
        "type": "Periodical"
    },
    {
        "id": 455347484,
        "latitude": 40.7623322,
        "longitude": -73.9496493,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T03:45:09.086Z",
        "type": "Periodical"
    },
    {
        "id": 455336073,
        "latitude": 40.7623152,
        "longitude": -73.9496101,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-04T03:17:08.795Z",
        "type": "Periodical"
    },
    {
        "id": 455318447,
        "latitude": 40.762338,
        "longitude": -73.9496442,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-04T02:38:08.644Z",
        "type": "Periodical"
    },
    {
        "id": 455305784,
        "latitude": 40.7623352,
        "longitude": -73.9496259,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-04T02:12:08.484Z",
        "type": "Periodical"
    },
    {
        "id": 455292159,
        "latitude": 40.7623118,
        "longitude": -73.949649,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-04T01:44:08.329Z",
        "type": "Periodical"
    },
    {
        "id": 455275086,
        "latitude": 40.762354,
        "longitude": -73.9497258,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-04T01:09:08.278Z",
        "type": "Periodical"
    },
    {
        "id": 455264734,
        "latitude": 40.762363,
        "longitude": -73.9497175,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-04T00:48:08.087Z",
        "type": "Periodical"
    },
    {
        "id": 455247799,
        "latitude": 40.7646139,
        "longitude": -73.9465646,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-09-04T00:13:02.016Z",
        "type": "Periodical"
    },
    {
        "id": 455230006,
        "latitude": 40.7623878,
        "longitude": -73.9496947,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T23:36:07.829Z",
        "type": "Periodical"
    },
    {
        "id": 455213065,
        "latitude": 40.7612465,
        "longitude": -73.9508136,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-03T23:02:59.633Z",
        "type": "Periodical"
    },
    {
        "id": 455199633,
        "latitude": 40.7623878,
        "longitude": -73.9497786,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T22:35:06.077Z",
        "type": "Periodical"
    },
    {
        "id": 455188832,
        "latitude": 40.7623601,
        "longitude": -73.949742,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T22:11:06.007Z",
        "type": "Periodical"
    },
    {
        "id": 455177469,
        "latitude": 40.7623622,
        "longitude": -73.949789,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-03T21:49:05.950Z",
        "type": "Periodical"
    },
    {
        "id": 455159847,
        "latitude": 40.7624016,
        "longitude": -73.9498619,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T21:17:05.841Z",
        "type": "Periodical"
    },
    {
        "id": 455149472,
        "latitude": 40.7623362,
        "longitude": -73.9497702,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T20:57:05.732Z",
        "type": "Periodical"
    },
    {
        "id": 455135135,
        "latitude": 40.7672455,
        "longitude": -73.9448805,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-03T20:28:53.741Z",
        "type": "Periodical"
    },
    {
        "id": 455119519,
        "latitude": 40.7623678,
        "longitude": -73.9497446,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-03T19:56:05.447Z",
        "type": "Periodical"
    },
    {
        "id": 455109214,
        "latitude": 40.7623638,
        "longitude": -73.9497587,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-03T19:33:05.287Z",
        "type": "Periodical"
    },
    {
        "id": 455092268,
        "latitude": 40.7623699,
        "longitude": -73.9497384,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T18:55:05.224Z",
        "type": "Periodical"
    },
    {
        "id": 455080987,
        "latitude": 40.7623231,
        "longitude": -73.9497372,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T18:25:05.115Z",
        "type": "Periodical"
    },
    {
        "id": 455074268,
        "latitude": 40.7623472,
        "longitude": -73.9496418,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T18:05:05.145Z",
        "type": "Periodical"
    },
    {
        "id": 455063870,
        "latitude": 40.7623659,
        "longitude": -73.9497074,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T17:32:04.875Z",
        "type": "Periodical"
    },
    {
        "id": 455052188,
        "latitude": 40.7665522,
        "longitude": -73.9448867,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-09-03T16:58:05.082Z",
        "type": "Periodical"
    },
    {
        "id": 455045220,
        "latitude": 40.7626192,
        "longitude": -73.9497408,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-03T16:38:01.718Z",
        "type": "Periodical"
    },
    {
        "id": 455038321,
        "latitude": 40.7642405,
        "longitude": -73.9475758,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T16:16:04.704Z",
        "type": "Periodical"
    },
    {
        "id": 455026290,
        "latitude": 40.7629769,
        "longitude": -73.9498498,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-09-03T15:37:37.730Z",
        "type": "Periodical"
    },
    {
        "id": 455013900,
        "latitude": 40.7623822,
        "longitude": -73.9498848,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-03T15:01:04.546Z",
        "type": "Periodical"
    },
    {
        "id": 455002650,
        "latitude": 40.7623828,
        "longitude": -73.9497711,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-03T14:27:04.377Z",
        "type": "Periodical"
    },
    {
        "id": 454993315,
        "latitude": 40.7623806,
        "longitude": -73.9496377,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T13:59:04.830Z",
        "type": "Periodical"
    },
    {
        "id": 454979921,
        "latitude": 40.7623802,
        "longitude": -73.9496376,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T13:19:34.476Z",
        "type": "Periodical"
    },
    {
        "id": 454968673,
        "latitude": 40.7624802,
        "longitude": -73.9498913,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-03T12:50:04.169Z",
        "type": "Periodical"
    },
    {
        "id": 454960603,
        "latitude": 40.7624338,
        "longitude": -73.949784,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T12:27:03.989Z",
        "type": "Periodical"
    },
    {
        "id": 454951420,
        "latitude": 40.7624297,
        "longitude": -73.9498255,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T12:02:04.001Z",
        "type": "Periodical"
    },
    {
        "id": 454939128,
        "latitude": 40.7623875,
        "longitude": -73.9497995,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-03T11:30:03.672Z",
        "type": "Periodical"
    },
    {
        "id": 454925040,
        "latitude": 40.7623834,
        "longitude": -73.9497872,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-03T10:52:03.666Z",
        "type": "Periodical"
    },
    {
        "id": 454913142,
        "latitude": 40.7624083,
        "longitude": -73.949779,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-03T10:18:03.625Z",
        "type": "Periodical"
    },
    {
        "id": 454903323,
        "latitude": 40.7624088,
        "longitude": -73.9498158,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T09:49:03.482Z",
        "type": "Periodical"
    },
    {
        "id": 454896318,
        "latitude": 40.7623701,
        "longitude": -73.9498868,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T09:27:03.497Z",
        "type": "Periodical"
    },
    {
        "id": 454888133,
        "latitude": 40.7624252,
        "longitude": -73.949845,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-03T09:01:03.445Z",
        "type": "Periodical"
    },
    {
        "id": 454881442,
        "latitude": 40.7623767,
        "longitude": -73.9499593,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T08:40:03.230Z",
        "type": "Periodical"
    },
    {
        "id": 454870987,
        "latitude": 40.7624107,
        "longitude": -73.9497685,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T08:05:03.080Z",
        "type": "Periodical"
    },
    {
        "id": 454862475,
        "latitude": 40.7623557,
        "longitude": -73.9496826,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T07:39:03.113Z",
        "type": "Periodical"
    },
    {
        "id": 454850249,
        "latitude": 40.7624238,
        "longitude": -73.9497706,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-03T07:01:02.941Z",
        "type": "Periodical"
    },
    {
        "id": 454843033,
        "latitude": 40.7624156,
        "longitude": -73.9497759,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T06:39:02.765Z",
        "type": "Periodical"
    },
    {
        "id": 454835312,
        "latitude": 40.7624129,
        "longitude": -73.9497448,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-03T06:16:02.643Z",
        "type": "Periodical"
    },
    {
        "id": 454823944,
        "latitude": 40.7623919,
        "longitude": -73.9497708,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T05:44:02.525Z",
        "type": "Periodical"
    },
    {
        "id": 454816177,
        "latitude": 40.762394,
        "longitude": -73.9497473,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T05:22:02.326Z",
        "type": "Periodical"
    },
    {
        "id": 454805265,
        "latitude": 40.762421,
        "longitude": -73.9497451,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T04:52:02.329Z",
        "type": "Periodical"
    },
    {
        "id": 454793998,
        "latitude": 40.7623912,
        "longitude": -73.9497065,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T04:22:02.312Z",
        "type": "Periodical"
    },
    {
        "id": 454783820,
        "latitude": 40.7624099,
        "longitude": -73.9497701,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T03:55:02.072Z",
        "type": "Periodical"
    },
    {
        "id": 454768057,
        "latitude": 40.7624034,
        "longitude": -73.9497777,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T03:18:02.079Z",
        "type": "Periodical"
    },
    {
        "id": 454752369,
        "latitude": 40.7624286,
        "longitude": -73.949797,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T02:42:01.967Z",
        "type": "Periodical"
    },
    {
        "id": 454737467,
        "latitude": 40.7624035,
        "longitude": -73.9497549,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-03T02:10:01.901Z",
        "type": "Periodical"
    },
    {
        "id": 454720059,
        "latitude": 40.7623615,
        "longitude": -73.949668,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-03T01:33:01.794Z",
        "type": "Periodical"
    },
    {
        "id": 454716561,
        "latitude": 40.7623819,
        "longitude": -73.9497781,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-03T01:25:16.691Z",
        "type": "Periodical"
    },
    {
        "id": 454692902,
        "latitude": 40.7623721,
        "longitude": -73.9497922,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-03T00:29:01.477Z",
        "type": "Periodical"
    },
    {
        "id": 454680278,
        "latitude": 40.7623824,
        "longitude": -73.9497563,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-03T00:09:01.415Z",
        "type": "Periodical"
    },
    {
        "id": 454670541,
        "latitude": 40.7623743,
        "longitude": -73.949779,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-02T23:49:01.329Z",
        "type": "Periodical"
    },
    {
        "id": 454651375,
        "latitude": 40.7623893,
        "longitude": -73.9497889,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-02T23:10:17.086Z",
        "type": "Periodical"
    },
    {
        "id": 454639384,
        "latitude": 40.7662832,
        "longitude": -73.9469417,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-09-02T22:44:16.896Z",
        "type": "Periodical"
    },
    {
        "id": 454621805,
        "latitude": 40.7668908,
        "longitude": -73.9466443,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-09-02T22:07:16.637Z",
        "type": "Periodical"
    },
    {
        "id": 454611022,
        "latitude": 40.7663239,
        "longitude": -73.9461126,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-09-02T21:44:16.698Z",
        "type": "Periodical"
    },
    {
        "id": 454596768,
        "latitude": 40.7663876,
        "longitude": -73.9463705,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-09-02T21:13:16.622Z",
        "type": "Periodical"
    },
    {
        "id": 454584764,
        "latitude": 40.7663583,
        "longitude": -73.9463121,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-09-02T20:48:04.021Z",
        "type": "Periodical"
    },
    {
        "id": 454572182,
        "latitude": 40.7662495,
        "longitude": -73.9467116,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-09-02T20:21:16.478Z",
        "type": "Periodical"
    },
    {
        "id": 454559823,
        "latitude": 40.7663878,
        "longitude": -73.9463809,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-02T19:55:16.385Z",
        "type": "Periodical"
    },
    {
        "id": 454541325,
        "latitude": 40.7624056,
        "longitude": -73.9497248,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-02T19:17:16.198Z",
        "type": "Periodical"
    },
    {
        "id": 454523654,
        "latitude": 40.7623454,
        "longitude": -73.9497889,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-02T18:41:16.025Z",
        "type": "Periodical"
    },
    {
        "id": 454507314,
        "latitude": 40.7623902,
        "longitude": -73.9497628,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T18:07:16.051Z",
        "type": "Periodical"
    },
    {
        "id": 454493133,
        "latitude": 40.7623671,
        "longitude": -73.9497694,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-02T17:38:15.756Z",
        "type": "Periodical"
    },
    {
        "id": 454477449,
        "latitude": 40.7623813,
        "longitude": -73.9496485,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-02T17:08:15.755Z",
        "type": "Periodical"
    },
    {
        "id": 454465934,
        "latitude": 40.762438,
        "longitude": -73.9499114,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T16:43:15.651Z",
        "type": "Periodical"
    },
    {
        "id": 454448308,
        "latitude": 40.7624021,
        "longitude": -73.9496699,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-09-02T16:10:15.502Z",
        "type": "Periodical"
    },
    {
        "id": 454431479,
        "latitude": 40.7623649,
        "longitude": -73.9497018,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-02T15:34:15.347Z",
        "type": "Periodical"
    },
    {
        "id": 454419792,
        "latitude": 40.7613968,
        "longitude": -73.9501449,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-09-02T15:07:58.940Z",
        "type": "Periodical"
    },
    {
        "id": 454405431,
        "latitude": 40.7623764,
        "longitude": -73.9497096,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-02T14:35:15.191Z",
        "type": "Periodical"
    },
    {
        "id": 454391577,
        "latitude": 40.7624302,
        "longitude": -73.9497846,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-02T14:02:15.026Z",
        "type": "Periodical"
    },
    {
        "id": 454383160,
        "latitude": 40.7623699,
        "longitude": -73.9499006,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T13:41:14.888Z",
        "type": "Periodical"
    },
    {
        "id": 454368529,
        "latitude": 40.7624245,
        "longitude": -73.9498352,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T13:02:11.826Z",
        "type": "Periodical"
    },
    {
        "id": 454360570,
        "latitude": 40.7624251,
        "longitude": -73.9497518,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-02T12:40:57.425Z",
        "type": "Periodical"
    },
    {
        "id": 454347115,
        "latitude": 40.7623737,
        "longitude": -73.9496541,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-02T12:01:55.146Z",
        "type": "Periodical"
    },
    {
        "id": 454335091,
        "latitude": 40.7623865,
        "longitude": -73.9496948,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T11:24:54.758Z",
        "type": "Periodical"
    },
    {
        "id": 454324347,
        "latitude": 40.7624557,
        "longitude": -73.9500439,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-02T10:50:54.580Z",
        "type": "Periodical"
    },
    {
        "id": 454322929,
        "latitude": 40.7624259,
        "longitude": -73.9498048,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-09-02T10:46:29.334Z",
        "type": "Periodical"
    },
    {
        "id": 454272957,
        "latitude": 40.7623683,
        "longitude": -73.9496935,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-02T08:00:57.641Z",
        "type": "Periodical"
    },
    {
        "id": 454233788,
        "latitude": 40.7623977,
        "longitude": -73.9497722,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-02T06:00:25.094Z",
        "type": "Periodical"
    },
    {
        "id": 454201103,
        "latitude": 40.7623903,
        "longitude": -73.949727,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-02T04:33:52.877Z",
        "type": "Periodical"
    },
    {
        "id": 454187750,
        "latitude": 40.7623515,
        "longitude": -73.9496693,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-02T03:59:53.119Z",
        "type": "Periodical"
    },
    {
        "id": 454172076,
        "latitude": 40.7623827,
        "longitude": -73.9497139,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-02T03:22:42.797Z",
        "type": "Periodical"
    },
    {
        "id": 454161463,
        "latitude": 40.7624036,
        "longitude": -73.9497328,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-02T02:58:52.483Z",
        "type": "Periodical"
    },
    {
        "id": 454149003,
        "latitude": 40.7623696,
        "longitude": -73.9496955,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T02:30:52.352Z",
        "type": "Periodical"
    },
    {
        "id": 454136253,
        "latitude": 40.7623489,
        "longitude": -73.9497924,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-02T02:02:52.278Z",
        "type": "Periodical"
    },
    {
        "id": 454118568,
        "latitude": 40.7624111,
        "longitude": -73.9497984,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-02T01:24:52.073Z",
        "type": "Periodical"
    },
    {
        "id": 454107527,
        "latitude": 40.7623957,
        "longitude": -73.9497647,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-02T01:01:51.968Z",
        "type": "Periodical"
    },
    {
        "id": 454097232,
        "latitude": 40.762375,
        "longitude": -73.9497773,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-02T00:39:51.864Z",
        "type": "Periodical"
    },
    {
        "id": 454092516,
        "latitude": 40.762372,
        "longitude": -73.9497901,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-02T00:29:53.525Z",
        "type": "Periodical"
    },
    {
        "id": 454067889,
        "latitude": 40.7623789,
        "longitude": -73.9498138,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T23:35:22.823Z",
        "type": "Periodical"
    },
    {
        "id": 454039457,
        "latitude": 40.7623937,
        "longitude": -73.9497694,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-01T22:32:51.449Z",
        "type": "Periodical"
    },
    {
        "id": 454027049,
        "latitude": 40.7623718,
        "longitude": -73.9498014,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-01T22:04:51.369Z",
        "type": "Periodical"
    },
    {
        "id": 454009922,
        "latitude": 40.7623397,
        "longitude": -73.9497096,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T21:28:51.243Z",
        "type": "Periodical"
    },
    {
        "id": 453998113,
        "latitude": 40.7623664,
        "longitude": -73.9496618,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T21:02:51.157Z",
        "type": "Periodical"
    },
    {
        "id": 453984791,
        "latitude": 40.7623898,
        "longitude": -73.9497196,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T20:34:51.251Z",
        "type": "Periodical"
    },
    {
        "id": 453968372,
        "latitude": 40.7623712,
        "longitude": -73.949648,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-09-01T19:58:50.876Z",
        "type": "Periodical"
    },
    {
        "id": 453956516,
        "latitude": 40.7624266,
        "longitude": -73.9497973,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-01T19:33:35.870Z",
        "type": "Periodical"
    },
    {
        "id": 453939761,
        "latitude": 40.7589686,
        "longitude": -73.9527032,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-01T18:56:32.663Z",
        "type": "Periodical"
    },
    {
        "id": 453927014,
        "latitude": 40.7590693,
        "longitude": -73.9523691,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-01T18:28:35.820Z",
        "type": "Periodical"
    },
    {
        "id": 453911712,
        "latitude": 40.7590666,
        "longitude": -73.9523767,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-01T17:54:35.684Z",
        "type": "Periodical"
    },
    {
        "id": 453898231,
        "latitude": 40.7590623,
        "longitude": -73.9523773,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-09-01T17:25:35.555Z",
        "type": "Periodical"
    },
    {
        "id": 453883199,
        "latitude": 40.7617833,
        "longitude": -73.9502815,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-09-01T16:52:26.107Z",
        "type": "Periodical"
    },
    {
        "id": 453872068,
        "latitude": 40.7617923,
        "longitude": -73.9502684,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T16:27:35.213Z",
        "type": "Periodical"
    },
    {
        "id": 453862322,
        "latitude": 40.7617864,
        "longitude": -73.9502561,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T16:05:35.020Z",
        "type": "Periodical"
    },
    {
        "id": 453851067,
        "latitude": 40.7617819,
        "longitude": -73.9502735,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-01T15:41:34.907Z",
        "type": "Periodical"
    },
    {
        "id": 453834401,
        "latitude": 40.7617819,
        "longitude": -73.9502492,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-01T15:02:34.727Z",
        "type": "Periodical"
    },
    {
        "id": 453820944,
        "latitude": 40.7623874,
        "longitude": -73.9497457,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-09-01T14:31:12.778Z",
        "type": "Periodical"
    },
    {
        "id": 453808234,
        "latitude": 40.7623646,
        "longitude": -73.9498405,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T14:01:12.774Z",
        "type": "Periodical"
    },
    {
        "id": 453792585,
        "latitude": 40.7624575,
        "longitude": -73.9498871,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-01T13:22:30.675Z",
        "type": "Periodical"
    },
    {
        "id": 453791804,
        "latitude": 40.7624037,
        "longitude": -73.949759,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-09-01T13:20:31.499Z",
        "type": "Periodical"
    },
    {
        "id": 453770494,
        "latitude": 40.7624158,
        "longitude": -73.9497299,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T12:23:30.529Z",
        "type": "Periodical"
    },
    {
        "id": 453757373,
        "latitude": 40.762378,
        "longitude": -73.9496759,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-09-01T11:45:30.364Z",
        "type": "Periodical"
    },
    {
        "id": 453750567,
        "latitude": 40.7624254,
        "longitude": -73.9497764,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T11:24:30.417Z",
        "type": "Periodical"
    },
    {
        "id": 453743779,
        "latitude": 40.7624201,
        "longitude": -73.9499298,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T11:02:30.031Z",
        "type": "Periodical"
    },
    {
        "id": 453733562,
        "latitude": 40.7623445,
        "longitude": -73.9496809,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T10:29:29.988Z",
        "type": "Periodical"
    },
    {
        "id": 453724768,
        "latitude": 40.7624114,
        "longitude": -73.949899,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T10:01:29.897Z",
        "type": "Periodical"
    },
    {
        "id": 453717021,
        "latitude": 40.7623457,
        "longitude": -73.9496609,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T09:35:29.859Z",
        "type": "Periodical"
    },
    {
        "id": 453707721,
        "latitude": 40.7623327,
        "longitude": -73.9496838,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-01T09:03:29.703Z",
        "type": "Periodical"
    },
    {
        "id": 453696981,
        "latitude": 40.7623509,
        "longitude": -73.9496388,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T08:28:29.555Z",
        "type": "Periodical"
    },
    {
        "id": 453688041,
        "latitude": 40.7623324,
        "longitude": -73.9496326,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T07:59:29.449Z",
        "type": "Periodical"
    },
    {
        "id": 453675657,
        "latitude": 40.762351,
        "longitude": -73.9497389,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T07:20:29.467Z",
        "type": "Periodical"
    },
    {
        "id": 453661886,
        "latitude": 40.7624065,
        "longitude": -73.9498006,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-01T06:40:29.220Z",
        "type": "Periodical"
    },
    {
        "id": 453650545,
        "latitude": 40.7623329,
        "longitude": -73.9496629,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T06:07:29.087Z",
        "type": "Periodical"
    },
    {
        "id": 453638304,
        "latitude": 40.7623493,
        "longitude": -73.9496144,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T05:35:28.850Z",
        "type": "Periodical"
    },
    {
        "id": 453624792,
        "latitude": 40.7623698,
        "longitude": -73.949746,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T05:00:28.764Z",
        "type": "Periodical"
    },
    {
        "id": 453611899,
        "latitude": 40.7623465,
        "longitude": -73.9496304,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-09-01T04:29:28.607Z",
        "type": "Periodical"
    },
    {
        "id": 453596376,
        "latitude": 40.7623259,
        "longitude": -73.9496825,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-01T03:52:28.417Z",
        "type": "Periodical"
    },
    {
        "id": 453583290,
        "latitude": 40.7623427,
        "longitude": -73.9496392,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-09-01T03:22:28.411Z",
        "type": "Periodical"
    },
    {
        "id": 453570961,
        "latitude": 40.7623334,
        "longitude": -73.9496886,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T02:54:28.185Z",
        "type": "Periodical"
    },
    {
        "id": 453556380,
        "latitude": 40.7624119,
        "longitude": -73.9497554,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T02:22:27.953Z",
        "type": "Periodical"
    },
    {
        "id": 453546667,
        "latitude": 40.7623592,
        "longitude": -73.9496796,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-09-01T02:00:27.802Z",
        "type": "Periodical"
    },
    {
        "id": 453534985,
        "latitude": 40.7623897,
        "longitude": -73.9497147,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T01:35:27.806Z",
        "type": "Periodical"
    },
    {
        "id": 453521292,
        "latitude": 40.7623516,
        "longitude": -73.9496578,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-09-01T01:06:27.584Z",
        "type": "Periodical"
    },
    {
        "id": 453511896,
        "latitude": 40.7623831,
        "longitude": -73.9497738,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-09-01T00:46:27.615Z",
        "type": "Periodical"
    },
    {
        "id": 453501161,
        "latitude": 40.7623487,
        "longitude": -73.9496617,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-09-01T00:23:27.532Z",
        "type": "Periodical"
    },
    {
        "id": 453485479,
        "latitude": 40.7590408,
        "longitude": -73.9529925,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-31T23:49:57.574Z",
        "type": "Periodical"
    },
    {
        "id": 453467771,
        "latitude": 40.766224,
        "longitude": -73.9823015,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-31T23:11:27.480Z",
        "type": "Periodical"
    },
    {
        "id": 453458072,
        "latitude": 40.7666139,
        "longitude": -73.9829997,
        "accuracy": 300,
        "source": "Fused",
        "ts": "2024-08-31T22:50:27.247Z",
        "type": "Periodical"
    },
    {
        "id": 453449183,
        "latitude": 40.7662473,
        "longitude": -73.9824168,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-31T22:31:27.173Z",
        "type": "Periodical"
    },
    {
        "id": 453433411,
        "latitude": 40.7662235,
        "longitude": -73.9822604,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T21:57:19.045Z",
        "type": "Periodical"
    },
    {
        "id": 453423469,
        "latitude": 40.766362,
        "longitude": -73.9827984,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-31T21:35:27.205Z",
        "type": "Periodical"
    },
    {
        "id": 453413913,
        "latitude": 40.7666631,
        "longitude": -73.9831566,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-31T21:15:26.781Z",
        "type": "Periodical"
    },
    {
        "id": 453397647,
        "latitude": 40.7663809,
        "longitude": -73.9828226,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-31T20:43:27.006Z",
        "type": "Periodical"
    },
    {
        "id": 453384388,
        "latitude": 40.7661205,
        "longitude": -73.9815172,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-08-31T20:15:26.536Z",
        "type": "Periodical"
    },
    {
        "id": 453372905,
        "latitude": 40.7668075,
        "longitude": -73.9833092,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-08-31T19:54:26.313Z",
        "type": "Periodical"
    },
    {
        "id": 453360653,
        "latitude": 40.7664675,
        "longitude": -73.9829349,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-31T19:31:25.407Z",
        "type": "Periodical"
    },
    {
        "id": 453339600,
        "latitude": 40.7667018,
        "longitude": -73.9830798,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-08-31T18:48:13.260Z",
        "type": "Periodical"
    },
    {
        "id": 453321605,
        "latitude": 40.7656926,
        "longitude": -73.9805211,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T18:10:13.774Z",
        "type": "Periodical"
    },
    {
        "id": 453311837,
        "latitude": 40.7626198,
        "longitude": -73.9497316,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-31T17:49:00.440Z",
        "type": "Periodical"
    },
    {
        "id": 453293238,
        "latitude": 40.7624215,
        "longitude": -73.949922,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-31T17:10:09.193Z",
        "type": "Periodical"
    },
    {
        "id": 453275379,
        "latitude": 40.7624425,
        "longitude": -73.949942,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-31T16:33:08.967Z",
        "type": "Periodical"
    },
    {
        "id": 453261743,
        "latitude": 40.7624039,
        "longitude": -73.9497797,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T16:04:08.950Z",
        "type": "Periodical"
    },
    {
        "id": 453250347,
        "latitude": 40.7624154,
        "longitude": -73.9497963,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T15:40:08.974Z",
        "type": "Periodical"
    },
    {
        "id": 453235612,
        "latitude": 40.7624034,
        "longitude": -73.9497678,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-31T15:07:08.791Z",
        "type": "Periodical"
    },
    {
        "id": 453221127,
        "latitude": 40.7624476,
        "longitude": -73.9498528,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-31T14:35:08.453Z",
        "type": "Periodical"
    },
    {
        "id": 453203572,
        "latitude": 40.7623529,
        "longitude": -73.9496782,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-31T13:55:08.442Z",
        "type": "Periodical"
    },
    {
        "id": 453187218,
        "latitude": 40.76236,
        "longitude": -73.9497334,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T13:17:08.554Z",
        "type": "Periodical"
    },
    {
        "id": 453175567,
        "latitude": 40.7623897,
        "longitude": -73.9497834,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-31T12:48:19.206Z",
        "type": "Periodical"
    },
    {
        "id": 453174944,
        "latitude": 40.7623641,
        "longitude": -73.9497669,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-31T12:46:55.196Z",
        "type": "Periodical"
    },
    {
        "id": 453169617,
        "latitude": 40.7623463,
        "longitude": -73.9496611,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-31T12:32:24.669Z",
        "type": "Periodical"
    },
    {
        "id": 453157070,
        "latitude": 40.762368,
        "longitude": -73.949805,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T11:59:45.997Z",
        "type": "Periodical"
    },
    {
        "id": 453147115,
        "latitude": 40.7624504,
        "longitude": -73.9498106,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T11:31:35.623Z",
        "type": "Periodical"
    },
    {
        "id": 453135812,
        "latitude": 40.7624408,
        "longitude": -73.9498948,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T10:58:30.298Z",
        "type": "Periodical"
    },
    {
        "id": 453124706,
        "latitude": 40.7624253,
        "longitude": -73.9498172,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T10:25:19.708Z",
        "type": "Periodical"
    },
    {
        "id": 453111130,
        "latitude": 40.7623817,
        "longitude": -73.9497349,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-31T09:42:49.639Z",
        "type": "Periodical"
    },
    {
        "id": 453103889,
        "latitude": 40.7624824,
        "longitude": -73.9498382,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-31T09:18:39.048Z",
        "type": "Periodical"
    },
    {
        "id": 453095785,
        "latitude": 40.7623566,
        "longitude": -73.949687,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-31T08:53:48.774Z",
        "type": "Periodical"
    },
    {
        "id": 453084177,
        "latitude": 40.7624783,
        "longitude": -73.9499443,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-31T08:17:04.471Z",
        "type": "Periodical"
    },
    {
        "id": 453070392,
        "latitude": 40.7623541,
        "longitude": -73.949679,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-31T07:36:55.026Z",
        "type": "Periodical"
    },
    {
        "id": 453059208,
        "latitude": 40.7623396,
        "longitude": -73.9496844,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T07:03:34.427Z",
        "type": "Periodical"
    },
    {
        "id": 453045257,
        "latitude": 40.7623643,
        "longitude": -73.9499191,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-31T06:26:05.877Z",
        "type": "Periodical"
    },
    {
        "id": 453028717,
        "latitude": 40.7624343,
        "longitude": -73.9498914,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-31T05:42:41.460Z",
        "type": "Periodical"
    },
    {
        "id": 453014221,
        "latitude": 40.7623078,
        "longitude": -73.9499059,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-31T05:05:43.445Z",
        "type": "Periodical"
    },
    {
        "id": 452998730,
        "latitude": 40.762349,
        "longitude": -73.9496843,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T04:30:19.101Z",
        "type": "Periodical"
    },
    {
        "id": 452982340,
        "latitude": 40.762309,
        "longitude": -73.9498993,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-31T03:55:01.276Z",
        "type": "Periodical"
    },
    {
        "id": 452968981,
        "latitude": 40.7623726,
        "longitude": -73.9496972,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-31T03:27:37.915Z",
        "type": "Periodical"
    },
    {
        "id": 452952656,
        "latitude": 40.7624129,
        "longitude": -73.9499793,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T02:55:20.377Z",
        "type": "Periodical"
    },
    {
        "id": 452937760,
        "latitude": 40.762345,
        "longitude": -73.9496453,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T02:25:11.166Z",
        "type": "Periodical"
    },
    {
        "id": 452925333,
        "latitude": 40.762484,
        "longitude": -73.9498439,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T02:00:20.712Z",
        "type": "Periodical"
    },
    {
        "id": 452905453,
        "latitude": 40.7624619,
        "longitude": -73.949828,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T01:25:02.330Z",
        "type": "Periodical"
    },
    {
        "id": 452905457,
        "latitude": 40.7624619,
        "longitude": -73.949828,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T01:25:02.330Z",
        "type": "Periodical"
    },
    {
        "id": 452905585,
        "latitude": 40.7624619,
        "longitude": -73.949828,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T01:25:02.330Z",
        "type": "Periodical"
    },
    {
        "id": 452905587,
        "latitude": 40.7624619,
        "longitude": -73.949828,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-31T01:25:02.330Z",
        "type": "Periodical"
    },
    {
        "id": 452904552,
        "latitude": 40.7623778,
        "longitude": -73.949732,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-31T01:23:21.622Z",
        "type": "Periodical"
    },
    {
        "id": 452904559,
        "latitude": 40.7623778,
        "longitude": -73.949732,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-31T01:23:21.622Z",
        "type": "Periodical"
    },
    {
        "id": 452904681,
        "latitude": 40.7623778,
        "longitude": -73.949732,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-31T01:23:21.622Z",
        "type": "Periodical"
    },
    {
        "id": 452899810,
        "latitude": 40.7624921,
        "longitude": -73.9499919,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-31T01:13:47.800Z",
        "type": "Periodical"
    },
    {
        "id": 452878395,
        "latitude": 40.7624311,
        "longitude": -73.9498023,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-31T00:32:47.643Z",
        "type": "Periodical"
    },
    {
        "id": 452860028,
        "latitude": 40.7624723,
        "longitude": -73.9498495,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-30T23:58:47.510Z",
        "type": "Periodical"
    },
    {
        "id": 452846240,
        "latitude": 40.7623471,
        "longitude": -73.9496647,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-30T23:32:47.395Z",
        "type": "Periodical"
    },
    {
        "id": 452830836,
        "latitude": 40.7623631,
        "longitude": -73.9496399,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-30T23:05:29.100Z",
        "type": "Periodical"
    },
    {
        "id": 452819067,
        "latitude": 40.7623812,
        "longitude": -73.9497663,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-30T22:45:47.232Z",
        "type": "Periodical"
    },
    {
        "id": 452800117,
        "latitude": 40.7623815,
        "longitude": -73.9497188,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-30T22:12:47.111Z",
        "type": "Periodical"
    },
    {
        "id": 452780819,
        "latitude": 40.7623861,
        "longitude": -73.9497158,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-30T21:38:47.044Z",
        "type": "Periodical"
    },
    {
        "id": 452766435,
        "latitude": 40.7623821,
        "longitude": -73.9497601,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-30T21:13:46.944Z",
        "type": "Periodical"
    },
    {
        "id": 452747468,
        "latitude": 40.7623532,
        "longitude": -73.9496821,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-30T20:39:46.908Z",
        "type": "Periodical"
    },
    {
        "id": 452733617,
        "latitude": 40.7623786,
        "longitude": -73.9496602,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-30T20:13:46.824Z",
        "type": "Periodical"
    },
    {
        "id": 452716563,
        "latitude": 40.7623696,
        "longitude": -73.9497632,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-30T19:41:46.760Z",
        "type": "Periodical"
    },
    {
        "id": 452699815,
        "latitude": 40.762365,
        "longitude": -73.9497528,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-30T19:09:46.589Z",
        "type": "Periodical"
    },
    {
        "id": 452681421,
        "latitude": 40.7624489,
        "longitude": -73.9498459,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-30T18:30:46.826Z",
        "type": "Periodical"
    },
    {
        "id": 452667834,
        "latitude": 40.7619151,
        "longitude": -73.9492891,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-30T17:58:53.491Z",
        "type": "Periodical"
    },
    {
        "id": 452655572,
        "latitude": 40.7623441,
        "longitude": -73.9499358,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-30T17:28:46.365Z",
        "type": "Periodical"
    },
    {
        "id": 452640410,
        "latitude": 40.7623796,
        "longitude": -73.9499115,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-30T16:50:46.245Z",
        "type": "Periodical"
    },
    {
        "id": 452635462,
        "latitude": 40.7623782,
        "longitude": -73.9498206,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-30T16:38:46.924Z",
        "type": "Periodical"
    },
    {
        "id": 452618328,
        "latitude": 40.7624273,
        "longitude": -73.9496229,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-30T15:57:45.962Z",
        "type": "Periodical"
    },
    {
        "id": 452604458,
        "latitude": 40.7623764,
        "longitude": -73.9497467,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-30T15:23:45.962Z",
        "type": "Periodical"
    },
    {
        "id": 452589445,
        "latitude": 40.7623926,
        "longitude": -73.9497218,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-30T14:45:45.724Z",
        "type": "Periodical"
    },
    {
        "id": 452575464,
        "latitude": 40.762439,
        "longitude": -73.9497867,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-30T14:11:45.562Z",
        "type": "Periodical"
    },
    {
        "id": 452560749,
        "latitude": 40.7624189,
        "longitude": -73.9498076,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T13:35:44.838Z",
        "type": "Periodical"
    },
    {
        "id": 452552026,
        "latitude": 40.7624404,
        "longitude": -73.9497055,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-30T13:14:45.363Z",
        "type": "Periodical"
    },
    {
        "id": 452538221,
        "latitude": 40.7623724,
        "longitude": -73.9496873,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-30T12:40:45.238Z",
        "type": "Periodical"
    },
    {
        "id": 452531724,
        "latitude": 40.762329,
        "longitude": -73.9497171,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-30T12:25:15.866Z",
        "type": "Periodical"
    },
    {
        "id": 452508126,
        "latitude": 40.7624062,
        "longitude": -73.9497671,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-30T11:27:44.835Z",
        "type": "Periodical"
    },
    {
        "id": 452497499,
        "latitude": 40.7623194,
        "longitude": -73.9498806,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-30T11:01:44.739Z",
        "type": "Periodical"
    },
    {
        "id": 452492392,
        "latitude": 40.7623185,
        "longitude": -73.9497482,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-30T10:49:16.290Z",
        "type": "Periodical"
    },
    {
        "id": 452467764,
        "latitude": 40.7623462,
        "longitude": -73.9496989,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-30T09:40:16.598Z",
        "type": "Periodical"
    },
    {
        "id": 452442305,
        "latitude": 40.7623854,
        "longitude": -73.9497866,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-30T08:25:44.001Z",
        "type": "Periodical"
    },
    {
        "id": 452432646,
        "latitude": 40.7623633,
        "longitude": -73.9496478,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-30T07:57:43.936Z",
        "type": "Periodical"
    },
    {
        "id": 452423998,
        "latitude": 40.7623236,
        "longitude": -73.9496969,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-30T07:32:43.814Z",
        "type": "Periodical"
    },
    {
        "id": 452411785,
        "latitude": 40.7623714,
        "longitude": -73.9496796,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T06:58:43.650Z",
        "type": "Periodical"
    },
    {
        "id": 452403027,
        "latitude": 40.762345,
        "longitude": -73.9496338,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-30T06:34:43.615Z",
        "type": "Periodical"
    },
    {
        "id": 452395113,
        "latitude": 40.7623325,
        "longitude": -73.949659,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T06:12:43.466Z",
        "type": "Periodical"
    },
    {
        "id": 452384972,
        "latitude": 40.7623495,
        "longitude": -73.9496459,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-30T05:47:43.299Z",
        "type": "Periodical"
    },
    {
        "id": 452372108,
        "latitude": 40.7623507,
        "longitude": -73.9496558,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-30T05:15:43.154Z",
        "type": "Periodical"
    },
    {
        "id": 452362139,
        "latitude": 40.7623376,
        "longitude": -73.9496554,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-30T04:52:43.036Z",
        "type": "Periodical"
    },
    {
        "id": 452346168,
        "latitude": 40.7623537,
        "longitude": -73.949658,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T04:15:42.852Z",
        "type": "Periodical"
    },
    {
        "id": 452328389,
        "latitude": 40.7623526,
        "longitude": -73.9496455,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T03:38:42.654Z",
        "type": "Periodical"
    },
    {
        "id": 452318303,
        "latitude": 40.7623498,
        "longitude": -73.9496479,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T03:17:42.606Z",
        "type": "Periodical"
    },
    {
        "id": 452305631,
        "latitude": 40.7623423,
        "longitude": -73.9496781,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-30T02:52:15.033Z",
        "type": "Periodical"
    },
    {
        "id": 452291246,
        "latitude": 40.7624775,
        "longitude": -73.9496838,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-30T02:25:00.379Z",
        "type": "Periodical"
    },
    {
        "id": 452269663,
        "latitude": 40.7624376,
        "longitude": -73.9498104,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-30T01:47:07.686Z",
        "type": "Periodical"
    },
    {
        "id": 452237918,
        "latitude": 40.7624356,
        "longitude": -73.9496791,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-30T00:50:00.155Z",
        "type": "Periodical"
    },
    {
        "id": 452214771,
        "latitude": 40.7624268,
        "longitude": -73.9498069,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-30T00:09:03.804Z",
        "type": "Periodical"
    },
    {
        "id": 452202447,
        "latitude": 40.7650012,
        "longitude": -73.9474595,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-29T23:47:55.975Z",
        "type": "Periodical"
    },
    {
        "id": 452185699,
        "latitude": 40.7624652,
        "longitude": -73.9497214,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-08-29T23:17:52.194Z",
        "type": "Periodical"
    },
    {
        "id": 452169275,
        "latitude": 40.7623761,
        "longitude": -73.9497073,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-08-29T22:48:55.667Z",
        "type": "Periodical"
    },
    {
        "id": 452150171,
        "latitude": 40.7623503,
        "longitude": -73.9496568,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-29T22:15:55.845Z",
        "type": "Periodical"
    },
    {
        "id": 452138464,
        "latitude": 40.7623758,
        "longitude": -73.9497725,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-29T21:55:55.560Z",
        "type": "Periodical"
    },
    {
        "id": 452125168,
        "latitude": 40.7623159,
        "longitude": -73.9496588,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-29T21:33:38.704Z",
        "type": "Periodical"
    },
    {
        "id": 452112029,
        "latitude": 40.7624124,
        "longitude": -73.9497657,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-29T21:10:58.243Z",
        "type": "Periodical"
    },
    {
        "id": 452099568,
        "latitude": 40.759422,
        "longitude": -73.9524172,
        "accuracy": 55,
        "source": "Fused",
        "ts": "2024-08-29T20:49:58.128Z",
        "type": "Periodical"
    },
    {
        "id": 452085470,
        "latitude": 40.7667484,
        "longitude": -73.98135,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-08-29T20:23:57.945Z",
        "type": "Periodical"
    },
    {
        "id": 452068812,
        "latitude": 40.7662452,
        "longitude": -73.9824875,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-29T19:51:53.866Z",
        "type": "Periodical"
    },
    {
        "id": 452055572,
        "latitude": 40.7664326,
        "longitude": -73.9825003,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-08-29T19:25:57.875Z",
        "type": "Periodical"
    },
    {
        "id": 452040759,
        "latitude": 40.7662572,
        "longitude": -73.9822474,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-29T18:55:52.203Z",
        "type": "Periodical"
    },
    {
        "id": 452024532,
        "latitude": 40.7661508,
        "longitude": -73.9822927,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-08-29T18:16:57.751Z",
        "type": "Periodical"
    },
    {
        "id": 452015711,
        "latitude": 40.7662448,
        "longitude": -73.9824072,
        "accuracy": 432,
        "source": "Fused",
        "ts": "2024-08-29T17:54:57.375Z",
        "type": "Periodical"
    },
    {
        "id": 452000554,
        "latitude": 40.7662731,
        "longitude": -73.9825791,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-29T17:14:57.417Z",
        "type": "Periodical"
    },
    {
        "id": 451986861,
        "latitude": 40.7662346,
        "longitude": -73.9823793,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T16:40:57.327Z",
        "type": "Periodical"
    },
    {
        "id": 451973051,
        "latitude": 40.7662407,
        "longitude": -73.9823923,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T16:04:57.399Z",
        "type": "Periodical"
    },
    {
        "id": 451958812,
        "latitude": 40.7662967,
        "longitude": -73.9826358,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-29T15:26:57.249Z",
        "type": "Periodical"
    },
    {
        "id": 451945335,
        "latitude": 40.7663333,
        "longitude": -73.9826866,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-29T14:52:51.224Z",
        "type": "Periodical"
    },
    {
        "id": 451932024,
        "latitude": 40.7661896,
        "longitude": -73.9816375,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-29T14:18:54.089Z",
        "type": "Periodical"
    },
    {
        "id": 451923970,
        "latitude": 40.7637093,
        "longitude": -73.9777044,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-29T13:58:26.782Z",
        "type": "Periodical"
    },
    {
        "id": 451910290,
        "latitude": 40.7624036,
        "longitude": -73.9497374,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-29T13:24:54.258Z",
        "type": "Periodical"
    },
    {
        "id": 451901844,
        "latitude": 40.7623943,
        "longitude": -73.949754,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-29T13:04:54.255Z",
        "type": "Periodical"
    },
    {
        "id": 451892238,
        "latitude": 40.7623535,
        "longitude": -73.9496346,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-29T12:43:56.414Z",
        "type": "Periodical"
    },
    {
        "id": 451879807,
        "latitude": 40.754789,
        "longitude": -73.9842435,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-29T12:14:40.761Z",
        "type": "Periodical"
    },
    {
        "id": 451869756,
        "latitude": 40.7611864,
        "longitude": -74.0074271,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-08-29T11:51:26.372Z",
        "type": "Periodical"
    },
    {
        "id": 451859607,
        "latitude": 40.8888426,
        "longitude": -74.2172091,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-08-29T11:27:55.993Z",
        "type": "Periodical"
    },
    {
        "id": 451846872,
        "latitude": 40.9087635,
        "longitude": -74.723282,
        "accuracy": 1899,
        "source": "Fused",
        "ts": "2024-08-29T10:58:56.252Z",
        "type": "Periodical"
    },
    {
        "id": 451832506,
        "latitude": 41.0035827,
        "longitude": -75.1551821,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-29T10:23:55.840Z",
        "type": "Periodical"
    },
    {
        "id": 451822702,
        "latitude": 41.1184442,
        "longitude": -75.3543808,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-08-29T09:58:26.139Z",
        "type": "Periodical"
    },
    {
        "id": 451809281,
        "latitude": 41.0912153,
        "longitude": -75.4230947,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-29T09:21:55.638Z",
        "type": "Periodical"
    },
    {
        "id": 451795039,
        "latitude": 41.0912103,
        "longitude": -75.4231189,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-29T08:42:33.609Z",
        "type": "Periodical"
    },
    {
        "id": 451787635,
        "latitude": 41.0912145,
        "longitude": -75.4231157,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:21:26.410Z",
        "type": "Periodical"
    },
    {
        "id": 451786701,
        "latitude": 41.0912237,
        "longitude": -75.4231121,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:19:03.876Z",
        "type": "Periodical"
    },
    {
        "id": 451786714,
        "latitude": 41.0912237,
        "longitude": -75.4231121,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:19:03.876Z",
        "type": "Periodical"
    },
    {
        "id": 451786287,
        "latitude": 41.0912175,
        "longitude": -75.4231151,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:18:14.595Z",
        "type": "Periodical"
    },
    {
        "id": 451786308,
        "latitude": 41.0912175,
        "longitude": -75.4231151,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:18:14.595Z",
        "type": "Periodical"
    },
    {
        "id": 451786323,
        "latitude": 41.0912175,
        "longitude": -75.4231151,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:18:14.595Z",
        "type": "Periodical"
    },
    {
        "id": 451786353,
        "latitude": 41.0912175,
        "longitude": -75.4231151,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:18:14.595Z",
        "type": "Periodical"
    },
    {
        "id": 451786061,
        "latitude": 41.0912156,
        "longitude": -75.4231158,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:17:42.272Z",
        "type": "Periodical"
    },
    {
        "id": 451786068,
        "latitude": 41.0912156,
        "longitude": -75.4231158,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:17:42.272Z",
        "type": "Periodical"
    },
    {
        "id": 451786141,
        "latitude": 41.0912156,
        "longitude": -75.4231158,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:17:42.272Z",
        "type": "Periodical"
    },
    {
        "id": 451786113,
        "latitude": 41.0912156,
        "longitude": -75.4231158,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:17:42.272Z",
        "type": "Periodical"
    },
    {
        "id": 451786144,
        "latitude": 41.0912156,
        "longitude": -75.4231158,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:17:42.272Z",
        "type": "Periodical"
    },
    {
        "id": 451786236,
        "latitude": 41.0912156,
        "longitude": -75.4231158,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:17:42.272Z",
        "type": "Periodical"
    },
    {
        "id": 451785918,
        "latitude": 41.0912151,
        "longitude": -75.4231157,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:17:10.472Z",
        "type": "Periodical"
    },
    {
        "id": 451785878,
        "latitude": 41.0912151,
        "longitude": -75.4231157,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:17:10.472Z",
        "type": "Periodical"
    },
    {
        "id": 451785904,
        "latitude": 41.0912151,
        "longitude": -75.4231157,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:17:10.472Z",
        "type": "Periodical"
    },
    {
        "id": 451785947,
        "latitude": 41.0912151,
        "longitude": -75.4231157,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:17:10.472Z",
        "type": "Periodical"
    },
    {
        "id": 451785990,
        "latitude": 41.0912151,
        "longitude": -75.4231157,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:17:10.472Z",
        "type": "Periodical"
    },
    {
        "id": 451785725,
        "latitude": 41.0912866,
        "longitude": -75.4231186,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:16:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 451785729,
        "latitude": 41.0912866,
        "longitude": -75.4231186,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:16:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 451785768,
        "latitude": 41.0912866,
        "longitude": -75.4231186,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:16:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 451785779,
        "latitude": 41.0912866,
        "longitude": -75.4231186,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:16:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 451785835,
        "latitude": 41.0912866,
        "longitude": -75.4231186,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:16:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 451785405,
        "latitude": 41.0912214,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:16:05.206Z",
        "type": "Periodical"
    },
    {
        "id": 451785436,
        "latitude": 41.0912214,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:16:05.206Z",
        "type": "Periodical"
    },
    {
        "id": 451785464,
        "latitude": 41.0912214,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:16:05.206Z",
        "type": "Periodical"
    },
    {
        "id": 451785476,
        "latitude": 41.0912214,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:16:05.206Z",
        "type": "Periodical"
    },
    {
        "id": 451785498,
        "latitude": 41.0912214,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:16:05.206Z",
        "type": "Periodical"
    },
    {
        "id": 451785528,
        "latitude": 41.0912214,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:16:05.206Z",
        "type": "Periodical"
    },
    {
        "id": 451785343,
        "latitude": 41.0911937,
        "longitude": -75.4231158,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:15:30.793Z",
        "type": "Periodical"
    },
    {
        "id": 451785385,
        "latitude": 41.0911937,
        "longitude": -75.4231158,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:15:30.793Z",
        "type": "Periodical"
    },
    {
        "id": 451785333,
        "latitude": 41.0911942,
        "longitude": -75.4231153,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-29T08:15:28.895Z",
        "type": "Periodical"
    },
    {
        "id": 451785298,
        "latitude": 41.0912096,
        "longitude": -75.4231198,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-29T08:15:00.650Z",
        "type": "Periodical"
    },
    {
        "id": 451785211,
        "latitude": 41.091213,
        "longitude": -75.4231167,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:14:28.703Z",
        "type": "Periodical"
    },
    {
        "id": 451784809,
        "latitude": 41.0912113,
        "longitude": -75.4231154,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T08:13:40.973Z",
        "type": "Periodical"
    },
    {
        "id": 451784752,
        "latitude": 41.0912134,
        "longitude": -75.423116,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-29T08:13:26.174Z",
        "type": "Periodical"
    },
    {
        "id": 451620374,
        "latitude": 41.091225,
        "longitude": -75.4231112,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T02:08:26.240Z",
        "type": "Periodical"
    },
    {
        "id": 451598130,
        "latitude": 41.0912177,
        "longitude": -75.4231144,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T01:30:42.503Z",
        "type": "Periodical"
    },
    {
        "id": 451579471,
        "latitude": 41.0912126,
        "longitude": -75.4231169,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-29T00:59:31.227Z",
        "type": "Periodical"
    },
    {
        "id": 451565785,
        "latitude": 41.091297,
        "longitude": -75.4231109,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-29T00:36:41.900Z",
        "type": "Periodical"
    },
    {
        "id": 451546224,
        "latitude": 41.091297,
        "longitude": -75.4231109,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-29T00:03:31.470Z",
        "type": "Periodical"
    },
    {
        "id": 451529383,
        "latitude": 41.0912039,
        "longitude": -75.423121,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T23:34:42.064Z",
        "type": "Periodical"
    },
    {
        "id": 451506849,
        "latitude": 41.0912041,
        "longitude": -75.4231207,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T22:56:41.907Z",
        "type": "Periodical"
    },
    {
        "id": 451484299,
        "latitude": 41.091204,
        "longitude": -75.4231213,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-28T22:18:41.905Z",
        "type": "Periodical"
    },
    {
        "id": 451466460,
        "latitude": 41.0912231,
        "longitude": -75.4231111,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T21:49:41.767Z",
        "type": "Periodical"
    },
    {
        "id": 451447260,
        "latitude": 41.0912044,
        "longitude": -75.4231157,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T21:19:41.367Z",
        "type": "Periodical"
    },
    {
        "id": 451425562,
        "latitude": 41.0912043,
        "longitude": -75.4231155,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T20:42:41.259Z",
        "type": "Periodical"
    },
    {
        "id": 451404312,
        "latitude": 41.0911727,
        "longitude": -75.4235556,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T20:07:40.985Z",
        "type": "Periodical"
    },
    {
        "id": 451387500,
        "latitude": 41.0911727,
        "longitude": -75.4235556,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T19:38:40.934Z",
        "type": "Periodical"
    },
    {
        "id": 451365562,
        "latitude": 41.0911923,
        "longitude": -75.4230985,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-28T18:58:40.884Z",
        "type": "Periodical"
    },
    {
        "id": 451352731,
        "latitude": 41.0912126,
        "longitude": -75.4230832,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-28T18:30:40.794Z",
        "type": "Periodical"
    },
    {
        "id": 451344627,
        "latitude": 41.0911628,
        "longitude": -75.4230365,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T18:10:40.630Z",
        "type": "Periodical"
    },
    {
        "id": 451336465,
        "latitude": 41.0911628,
        "longitude": -75.4230365,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T17:51:13.447Z",
        "type": "Periodical"
    },
    {
        "id": 451322285,
        "latitude": 41.0911936,
        "longitude": -75.4230989,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-28T17:18:40.526Z",
        "type": "Periodical"
    },
    {
        "id": 451308795,
        "latitude": 41.0912077,
        "longitude": -75.4231204,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T16:47:40.740Z",
        "type": "Periodical"
    },
    {
        "id": 451296252,
        "latitude": 41.122641,
        "longitude": -75.3712193,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-28T16:20:26.722Z",
        "type": "Periodical"
    },
    {
        "id": 451283845,
        "latitude": 41.1226446,
        "longitude": -75.3712749,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-28T15:52:52.651Z",
        "type": "Periodical"
    },
    {
        "id": 451272762,
        "latitude": 41.1252083,
        "longitude": -75.3589352,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-28T15:23:55.860Z",
        "type": "Periodical"
    },
    {
        "id": 451251601,
        "latitude": 41.1179291,
        "longitude": -75.3549775,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-28T14:48:43.428Z",
        "type": "Periodical"
    },
    {
        "id": 451241022,
        "latitude": 41.0915672,
        "longitude": -75.4220922,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-28T14:20:57.623Z",
        "type": "Periodical"
    },
    {
        "id": 451222255,
        "latitude": 41.0912028,
        "longitude": -75.4231256,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T13:41:04.121Z",
        "type": "Periodical"
    },
    {
        "id": 451210483,
        "latitude": 41.0913778,
        "longitude": -75.4231204,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T13:13:03.710Z",
        "type": "Periodical"
    },
    {
        "id": 451200811,
        "latitude": 41.0913778,
        "longitude": -75.4231204,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T12:51:13.065Z",
        "type": "Periodical"
    },
    {
        "id": 451188833,
        "latitude": 41.0912186,
        "longitude": -75.4231054,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-28T12:24:03.658Z",
        "type": "Periodical"
    },
    {
        "id": 451174048,
        "latitude": 41.0912044,
        "longitude": -75.4230796,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-28T11:50:03.450Z",
        "type": "Periodical"
    },
    {
        "id": 451158080,
        "latitude": 41.0912035,
        "longitude": -75.4230767,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-28T11:15:03.265Z",
        "type": "Periodical"
    },
    {
        "id": 451146509,
        "latitude": 41.0912058,
        "longitude": -75.4230831,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-28T10:50:03.150Z",
        "type": "Periodical"
    },
    {
        "id": 451133606,
        "latitude": 41.0914136,
        "longitude": -75.4231713,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T10:18:02.878Z",
        "type": "Periodical"
    },
    {
        "id": 451123677,
        "latitude": 41.0914136,
        "longitude": -75.4231713,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T09:52:12.217Z",
        "type": "Periodical"
    },
    {
        "id": 451109487,
        "latitude": 41.0913494,
        "longitude": -75.4231852,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-28T09:13:02.522Z",
        "type": "Periodical"
    },
    {
        "id": 451098429,
        "latitude": 41.0913494,
        "longitude": -75.4231852,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T08:43:02.517Z",
        "type": "Periodical"
    },
    {
        "id": 451088320,
        "latitude": 41.0913494,
        "longitude": -75.4231852,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T08:15:02.369Z",
        "type": "Periodical"
    },
    {
        "id": 451079667,
        "latitude": 41.0913494,
        "longitude": -75.4231852,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T07:52:13.779Z",
        "type": "Periodical"
    },
    {
        "id": 451066443,
        "latitude": 41.0912478,
        "longitude": -75.4231721,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-28T07:16:02.210Z",
        "type": "Periodical"
    },
    {
        "id": 451053335,
        "latitude": 41.0912478,
        "longitude": -75.4231721,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-28T06:41:30.687Z",
        "type": "Periodical"
    },
    {
        "id": 451039304,
        "latitude": 41.091187,
        "longitude": -75.4230869,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-28T06:04:02.151Z",
        "type": "Periodical"
    },
    {
        "id": 451029379,
        "latitude": 41.0911682,
        "longitude": -75.4232117,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T05:40:01.992Z",
        "type": "Periodical"
    },
    {
        "id": 451017838,
        "latitude": 41.0911682,
        "longitude": -75.4232117,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-28T05:12:01.802Z",
        "type": "Periodical"
    },
    {
        "id": 451006021,
        "latitude": 41.0912035,
        "longitude": -75.4230752,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-28T04:45:01.884Z",
        "type": "Periodical"
    },
    {
        "id": 450994472,
        "latitude": 41.0912055,
        "longitude": -75.4230803,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-28T04:19:01.716Z",
        "type": "Periodical"
    },
    {
        "id": 450981187,
        "latitude": 41.0912723,
        "longitude": -75.4231986,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-28T03:52:01.450Z",
        "type": "Periodical"
    },
    {
        "id": 450962293,
        "latitude": 41.0912723,
        "longitude": -75.4231986,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-28T03:14:01.351Z",
        "type": "Periodical"
    },
    {
        "id": 450946347,
        "latitude": 41.0912033,
        "longitude": -75.4230712,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-28T02:44:01.371Z",
        "type": "Periodical"
    },
    {
        "id": 450931015,
        "latitude": 41.0912035,
        "longitude": -75.4230685,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-28T02:19:01.228Z",
        "type": "Periodical"
    },
    {
        "id": 450908208,
        "latitude": 41.0910016,
        "longitude": -75.4238991,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-08-28T01:40:00.993Z",
        "type": "Periodical"
    },
    {
        "id": 450893418,
        "latitude": 41.0912035,
        "longitude": -75.4230745,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-28T01:15:00.996Z",
        "type": "Periodical"
    },
    {
        "id": 450879906,
        "latitude": 41.0912035,
        "longitude": -75.4230739,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-28T00:53:00.847Z",
        "type": "Periodical"
    },
    {
        "id": 450860507,
        "latitude": 41.0911872,
        "longitude": -75.4230948,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-28T00:21:00.692Z",
        "type": "Periodical"
    },
    {
        "id": 450839534,
        "latitude": 41.0912912,
        "longitude": -75.4231252,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-27T23:45:00.485Z",
        "type": "Periodical"
    },
    {
        "id": 450823284,
        "latitude": 41.091218,
        "longitude": -75.4231143,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T23:17:00.799Z",
        "type": "Periodical"
    },
    {
        "id": 450806629,
        "latitude": 41.0912174,
        "longitude": -75.4231099,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T22:50:00.268Z",
        "type": "Periodical"
    },
    {
        "id": 450793415,
        "latitude": 41.12486,
        "longitude": -75.355154,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-08-27T22:23:31.316Z",
        "type": "Periodical"
    },
    {
        "id": 450766098,
        "latitude": 41.0912147,
        "longitude": -75.4230936,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-27T21:43:59.257Z",
        "type": "Periodical"
    },
    {
        "id": 450742077,
        "latitude": 41.0788024,
        "longitude": -75.4119023,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-27T21:04:59.228Z",
        "type": "Periodical"
    },
    {
        "id": 450720252,
        "latitude": 41.0788075,
        "longitude": -75.4118968,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-08-27T20:29:59.173Z",
        "type": "Periodical"
    },
    {
        "id": 450700778,
        "latitude": 41.0912228,
        "longitude": -75.4231127,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T19:56:37.460Z",
        "type": "Periodical"
    },
    {
        "id": 450689345,
        "latitude": 41.0912108,
        "longitude": -75.4231232,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T19:35:59.078Z",
        "type": "Periodical"
    },
    {
        "id": 450669410,
        "latitude": 41.0912022,
        "longitude": -75.4231044,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-08-27T18:56:58.787Z",
        "type": "Periodical"
    },
    {
        "id": 450655623,
        "latitude": 41.0912041,
        "longitude": -75.4231066,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-08-27T18:27:58.587Z",
        "type": "Periodical"
    },
    {
        "id": 450646238,
        "latitude": 41.091224,
        "longitude": -75.4231133,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T18:06:58.726Z",
        "type": "Periodical"
    },
    {
        "id": 450634322,
        "latitude": 41.0912228,
        "longitude": -75.4231116,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T17:39:48.593Z",
        "type": "Periodical"
    },
    {
        "id": 450622800,
        "latitude": 41.0912079,
        "longitude": -75.4231219,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T17:12:58.656Z",
        "type": "Periodical"
    },
    {
        "id": 450610600,
        "latitude": 41.0912047,
        "longitude": -75.423076,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-08-27T16:44:58.421Z",
        "type": "Periodical"
    },
    {
        "id": 450597450,
        "latitude": 41.0913428,
        "longitude": -75.4228451,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-08-27T16:14:58.334Z",
        "type": "Periodical"
    },
    {
        "id": 450581409,
        "latitude": 41.0912216,
        "longitude": -75.4230896,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-27T15:39:58.184Z",
        "type": "Periodical"
    },
    {
        "id": 450572843,
        "latitude": 41.0911982,
        "longitude": -75.4230844,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-27T15:19:58.074Z",
        "type": "Periodical"
    },
    {
        "id": 450558528,
        "latitude": 41.0912005,
        "longitude": -75.4230804,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-27T14:44:57.951Z",
        "type": "Periodical"
    },
    {
        "id": 450541812,
        "latitude": 41.0912061,
        "longitude": -75.4230887,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-27T14:04:57.777Z",
        "type": "Periodical"
    },
    {
        "id": 450528929,
        "latitude": 41.0912013,
        "longitude": -75.4230889,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-27T13:35:29.504Z",
        "type": "Periodical"
    },
    {
        "id": 450515179,
        "latitude": 41.0912192,
        "longitude": -75.4231142,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T13:02:57.585Z",
        "type": "Periodical"
    },
    {
        "id": 450502376,
        "latitude": 41.0912036,
        "longitude": -75.423082,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-27T12:33:57.491Z",
        "type": "Periodical"
    },
    {
        "id": 450489135,
        "latitude": 41.0912861,
        "longitude": -75.4230971,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-27T12:04:29.782Z",
        "type": "Periodical"
    },
    {
        "id": 450478698,
        "latitude": 41.091203,
        "longitude": -75.4230824,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-27T11:41:56.946Z",
        "type": "Periodical"
    },
    {
        "id": 450470233,
        "latitude": 41.091203,
        "longitude": -75.4230824,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-27T11:21:56.945Z",
        "type": "Periodical"
    },
    {
        "id": 450453993,
        "latitude": 41.0911893,
        "longitude": -75.4230911,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-27T10:44:56.970Z",
        "type": "Periodical"
    },
    {
        "id": 450441898,
        "latitude": 41.0911895,
        "longitude": -75.4230961,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-27T10:14:56.852Z",
        "type": "Periodical"
    },
    {
        "id": 450432566,
        "latitude": 41.091219,
        "longitude": -75.4231111,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T09:50:56.712Z",
        "type": "Periodical"
    },
    {
        "id": 450424889,
        "latitude": 41.0911918,
        "longitude": -75.4231004,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-27T09:29:56.472Z",
        "type": "Periodical"
    },
    {
        "id": 450418203,
        "latitude": 41.0911897,
        "longitude": -75.4230876,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-27T09:10:56.447Z",
        "type": "Periodical"
    },
    {
        "id": 450409318,
        "latitude": 41.0911892,
        "longitude": -75.4230941,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-27T08:46:56.280Z",
        "type": "Periodical"
    },
    {
        "id": 450395351,
        "latitude": 41.0912239,
        "longitude": -75.4231093,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T08:07:56.232Z",
        "type": "Periodical"
    },
    {
        "id": 450383287,
        "latitude": 41.0911897,
        "longitude": -75.4230896,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-27T07:36:56.153Z",
        "type": "Periodical"
    },
    {
        "id": 450368829,
        "latitude": 41.09121,
        "longitude": -75.4231133,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T06:58:55.905Z",
        "type": "Periodical"
    },
    {
        "id": 450357847,
        "latitude": 41.0912197,
        "longitude": -75.4231085,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T06:30:55.877Z",
        "type": "Periodical"
    },
    {
        "id": 450350107,
        "latitude": 41.0912144,
        "longitude": -75.423116,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T06:10:55.625Z",
        "type": "Periodical"
    },
    {
        "id": 450341168,
        "latitude": 41.0912122,
        "longitude": -75.4231155,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T05:50:55.709Z",
        "type": "Periodical"
    },
    {
        "id": 450331976,
        "latitude": 41.0912123,
        "longitude": -75.4231152,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T05:28:55.483Z",
        "type": "Periodical"
    },
    {
        "id": 450320240,
        "latitude": 41.0911794,
        "longitude": -75.4230851,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-27T05:01:43.104Z",
        "type": "Periodical"
    },
    {
        "id": 450301199,
        "latitude": 41.0911794,
        "longitude": -75.4230844,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-27T04:21:43.101Z",
        "type": "Periodical"
    },
    {
        "id": 450285001,
        "latitude": 41.0912178,
        "longitude": -75.4231107,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T03:48:54.208Z",
        "type": "Periodical"
    },
    {
        "id": 450276930,
        "latitude": 41.09121,
        "longitude": -75.423116,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T03:32:41.611Z",
        "type": "Periodical"
    },
    {
        "id": 450264338,
        "latitude": 41.0911859,
        "longitude": -75.4230831,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-08-27T03:06:41.714Z",
        "type": "Periodical"
    },
    {
        "id": 450245206,
        "latitude": 41.0912035,
        "longitude": -75.4230839,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-27T02:30:36.663Z",
        "type": "Periodical"
    },
    {
        "id": 450222649,
        "latitude": 41.0912206,
        "longitude": -75.4231135,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-27T01:50:41.179Z",
        "type": "Periodical"
    },
    {
        "id": 450201634,
        "latitude": 41.0912035,
        "longitude": -75.4230822,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-27T01:12:40.966Z",
        "type": "Periodical"
    },
    {
        "id": 450181275,
        "latitude": 41.0912082,
        "longitude": -75.4230895,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-27T00:38:40.827Z",
        "type": "Periodical"
    },
    {
        "id": 450167629,
        "latitude": 41.0911975,
        "longitude": -75.4230889,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-27T00:15:40.661Z",
        "type": "Periodical"
    },
    {
        "id": 450138982,
        "latitude": 41.0912033,
        "longitude": -75.4230843,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-26T23:40:40.495Z",
        "type": "Periodical"
    },
    {
        "id": 450138988,
        "latitude": 41.0913928,
        "longitude": -75.4229559,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-26T23:11:18.467Z",
        "type": "Periodical"
    },
    {
        "id": 450121419,
        "latitude": 41.0913928,
        "longitude": -75.4229559,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T22:50:40.262Z",
        "type": "Periodical"
    },
    {
        "id": 450096638,
        "latitude": 41.0913928,
        "longitude": -75.4229559,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T22:11:22.549Z",
        "type": "Periodical"
    },
    {
        "id": 450079282,
        "latitude": 41.091246,
        "longitude": -75.4230655,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-08-26T21:43:47.349Z",
        "type": "Periodical"
    },
    {
        "id": 450062771,
        "latitude": 41.0912086,
        "longitude": -75.4230967,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-08-26T21:16:36.310Z",
        "type": "Periodical"
    },
    {
        "id": 450040215,
        "latitude": 41.0912031,
        "longitude": -75.4230811,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-26T20:39:31.572Z",
        "type": "Periodical"
    },
    {
        "id": 450025302,
        "latitude": 41.0857726,
        "longitude": -75.4274816,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-08-26T20:13:00.046Z",
        "type": "Periodical"
    },
    {
        "id": 450013941,
        "latitude": 41.0837588,
        "longitude": -75.4292567,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-08-26T19:51:59.903Z",
        "type": "Periodical"
    },
    {
        "id": 450002005,
        "latitude": 41.0841189,
        "longitude": -75.4265654,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T19:29:08.036Z",
        "type": "Periodical"
    },
    {
        "id": 449985016,
        "latitude": 41.0912074,
        "longitude": -75.4230945,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-26T18:55:43.309Z",
        "type": "Periodical"
    },
    {
        "id": 449974074,
        "latitude": 41.0912217,
        "longitude": -75.4231123,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T18:33:00.006Z",
        "type": "Periodical"
    },
    {
        "id": 449963513,
        "latitude": 41.0915451,
        "longitude": -75.4221559,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-26T18:08:59.693Z",
        "type": "Periodical"
    },
    {
        "id": 449947373,
        "latitude": 41.0912038,
        "longitude": -75.4231019,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-08-26T17:30:30.434Z",
        "type": "Periodical"
    },
    {
        "id": 449933496,
        "latitude": 41.0912116,
        "longitude": -75.4231223,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T16:58:59.579Z",
        "type": "Periodical"
    },
    {
        "id": 449923601,
        "latitude": 41.0912093,
        "longitude": -75.4231214,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T16:34:59.416Z",
        "type": "Periodical"
    },
    {
        "id": 449911529,
        "latitude": 41.0912145,
        "longitude": -75.4231209,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T16:06:59.329Z",
        "type": "Periodical"
    },
    {
        "id": 449900025,
        "latitude": 41.0912089,
        "longitude": -75.4231216,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T15:39:59.295Z",
        "type": "Periodical"
    },
    {
        "id": 449887021,
        "latitude": 41.0912102,
        "longitude": -75.4231216,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T15:09:59.209Z",
        "type": "Periodical"
    },
    {
        "id": 449876560,
        "latitude": 41.0912067,
        "longitude": -75.4231234,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-26T14:46:59.143Z",
        "type": "Periodical"
    },
    {
        "id": 449862115,
        "latitude": 41.0912043,
        "longitude": -75.423082,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-26T14:15:58.755Z",
        "type": "Periodical"
    },
    {
        "id": 449840356,
        "latitude": 41.0912014,
        "longitude": -75.4230891,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-26T13:35:58.614Z",
        "type": "Periodical"
    },
    {
        "id": 449824188,
        "latitude": 41.0912041,
        "longitude": -75.4230811,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-26T12:57:58.587Z",
        "type": "Periodical"
    },
    {
        "id": 449813535,
        "latitude": 41.0912042,
        "longitude": -75.4230822,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-26T12:32:58.360Z",
        "type": "Periodical"
    },
    {
        "id": 449801033,
        "latitude": 41.0911968,
        "longitude": -75.4230896,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-26T12:04:58.318Z",
        "type": "Periodical"
    },
    {
        "id": 449785475,
        "latitude": 41.091203,
        "longitude": -75.4230818,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-26T11:28:58.193Z",
        "type": "Periodical"
    },
    {
        "id": 449776941,
        "latitude": 41.0912028,
        "longitude": -75.4230795,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-26T11:08:58.104Z",
        "type": "Periodical"
    },
    {
        "id": 449764534,
        "latitude": 41.0911875,
        "longitude": -75.4231673,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T10:40:57.849Z",
        "type": "Periodical"
    },
    {
        "id": 449753653,
        "latitude": 41.0911875,
        "longitude": -75.4231673,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T10:12:57.790Z",
        "type": "Periodical"
    },
    {
        "id": 449741769,
        "latitude": 41.0911875,
        "longitude": -75.4231673,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-26T09:40:57.600Z",
        "type": "Periodical"
    },
    {
        "id": 449731348,
        "latitude": 41.0911875,
        "longitude": -75.4231673,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T09:10:57.598Z",
        "type": "Periodical"
    },
    {
        "id": 449723448,
        "latitude": 41.0911875,
        "longitude": -75.4231673,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T08:49:07.006Z",
        "type": "Periodical"
    },
    {
        "id": 449711149,
        "latitude": 41.0910987,
        "longitude": -75.4230324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-26T08:13:57.264Z",
        "type": "Periodical"
    },
    {
        "id": 449700135,
        "latitude": 41.0910987,
        "longitude": -75.4230324,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T07:43:57.261Z",
        "type": "Periodical"
    },
    {
        "id": 449691476,
        "latitude": 41.0910987,
        "longitude": -75.4230324,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T07:19:57.210Z",
        "type": "Periodical"
    },
    {
        "id": 449678062,
        "latitude": 41.0910987,
        "longitude": -75.4230324,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T06:44:57.098Z",
        "type": "Periodical"
    },
    {
        "id": 449663374,
        "latitude": 41.0910987,
        "longitude": -75.4230324,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T06:06:29.548Z",
        "type": "Periodical"
    },
    {
        "id": 449650752,
        "latitude": 41.0908308,
        "longitude": -75.4227866,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-26T05:36:56.837Z",
        "type": "Periodical"
    },
    {
        "id": 449638368,
        "latitude": 41.0908308,
        "longitude": -75.4227866,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-26T05:07:56.657Z",
        "type": "Periodical"
    },
    {
        "id": 449622858,
        "latitude": 41.0912027,
        "longitude": -75.4230781,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-26T04:32:56.648Z",
        "type": "Periodical"
    },
    {
        "id": 449608559,
        "latitude": 41.0912768,
        "longitude": -75.4229898,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-26T04:01:56.279Z",
        "type": "Periodical"
    },
    {
        "id": 449593481,
        "latitude": 41.0912768,
        "longitude": -75.4229898,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T03:31:56.277Z",
        "type": "Periodical"
    },
    {
        "id": 449579467,
        "latitude": 41.0912768,
        "longitude": -75.4229898,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-26T03:03:56.187Z",
        "type": "Periodical"
    },
    {
        "id": 449562616,
        "latitude": 41.0912054,
        "longitude": -75.4230791,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-26T02:33:56.200Z",
        "type": "Periodical"
    },
    {
        "id": 449542005,
        "latitude": 41.0912036,
        "longitude": -75.4230849,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-26T01:56:56.045Z",
        "type": "Periodical"
    },
    {
        "id": 449522260,
        "latitude": 41.0912039,
        "longitude": -75.4230833,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-26T01:20:55.881Z",
        "type": "Periodical"
    },
    {
        "id": 449507116,
        "latitude": 41.0912032,
        "longitude": -75.4230803,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-26T00:53:55.848Z",
        "type": "Periodical"
    },
    {
        "id": 449493293,
        "latitude": 41.0912033,
        "longitude": -75.4230798,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-26T00:28:55.640Z",
        "type": "Periodical"
    },
    {
        "id": 449473283,
        "latitude": 41.0912724,
        "longitude": -75.4230727,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-25T23:52:55.466Z",
        "type": "Periodical"
    },
    {
        "id": 449458047,
        "latitude": 41.0912036,
        "longitude": -75.4230816,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-25T23:23:55.598Z",
        "type": "Periodical"
    },
    {
        "id": 449438876,
        "latitude": 41.0912032,
        "longitude": -75.4230844,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-25T22:47:55.462Z",
        "type": "Periodical"
    },
    {
        "id": 449432146,
        "latitude": 41.0529715,
        "longitude": -75.3303068,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-25T22:24:36.323Z",
        "type": "Periodical"
    },
    {
        "id": 449407755,
        "latitude": 41.0529713,
        "longitude": -75.3303137,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-25T21:48:55.241Z",
        "type": "Periodical"
    },
    {
        "id": 449392306,
        "latitude": 41.0529246,
        "longitude": -75.3304081,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-08-25T21:20:09.935Z",
        "type": "Periodical"
    },
    {
        "id": 449373557,
        "latitude": 41.0912559,
        "longitude": -75.4230401,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-08-25T20:45:09.865Z",
        "type": "Periodical"
    },
    {
        "id": 449354620,
        "latitude": 41.0912293,
        "longitude": -75.4231063,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-25T20:08:09.723Z",
        "type": "Periodical"
    },
    {
        "id": 449336078,
        "latitude": 41.0019558,
        "longitude": -75.2719137,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-25T19:33:40.856Z",
        "type": "Periodical"
    },
    {
        "id": 449324428,
        "latitude": 40.925289,
        "longitude": -74.9614426,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-25T19:12:20.307Z",
        "type": "Periodical"
    },
    {
        "id": 449308025,
        "latitude": 40.8906714,
        "longitude": -74.312582,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-08-25T18:42:05.249Z",
        "type": "Periodical"
    },
    {
        "id": 449292560,
        "latitude": 40.7565291,
        "longitude": -73.995045,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-25T18:16:06.261Z",
        "type": "Periodical"
    },
    {
        "id": 449280104,
        "latitude": 40.7514416,
        "longitude": -73.9398453,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-25T17:52:06.740Z",
        "type": "Periodical"
    },
    {
        "id": 449278093,
        "latitude": 40.7560088,
        "longitude": -73.9464315,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-25T17:48:04.279Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 448812846,
        "latitude": 40.762388,
        "longitude": -73.9497389,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-25T00:20:44.326Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 448805076,
        "latitude": 40.7588118,
        "longitude": -73.9525695,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-25T00:06:41.928Z",
        "type": "Periodical"
    },
    {
        "id": 448783174,
        "latitude": 40.7662879,
        "longitude": -73.9826201,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-24T23:25:13.760Z",
        "type": "Periodical"
    },
    {
        "id": 448773061,
        "latitude": 40.76625,
        "longitude": -73.9824221,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-24T23:05:41.808Z",
        "type": "Periodical"
    },
    {
        "id": 448762651,
        "latitude": 40.7662613,
        "longitude": -73.9824876,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-24T22:46:32.566Z",
        "type": "Periodical"
    },
    {
        "id": 448745234,
        "latitude": 40.7661048,
        "longitude": -73.9819912,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-24T22:13:34.225Z",
        "type": "Periodical"
    },
    {
        "id": 448730222,
        "latitude": 40.7663976,
        "longitude": -73.9827803,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-24T21:45:41.232Z",
        "type": "Periodical"
    },
    {
        "id": 448711854,
        "latitude": 40.7662493,
        "longitude": -73.9823008,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-24T21:10:41.173Z",
        "type": "Periodical"
    },
    {
        "id": 448699752,
        "latitude": 40.7663968,
        "longitude": -73.982762,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-24T20:48:40.982Z",
        "type": "Periodical"
    },
    {
        "id": 448683244,
        "latitude": 40.7662382,
        "longitude": -73.9823549,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-24T20:17:41.102Z",
        "type": "Periodical"
    },
    {
        "id": 448665051,
        "latitude": 40.766255,
        "longitude": -73.9825353,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-24T19:42:40.841Z",
        "type": "Periodical"
    },
    {
        "id": 448653706,
        "latitude": 40.7662728,
        "longitude": -73.9825491,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T19:20:40.945Z",
        "type": "Periodical"
    },
    {
        "id": 448634070,
        "latitude": 40.7662692,
        "longitude": -73.9825149,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-24T18:43:40.498Z",
        "type": "Periodical"
    },
    {
        "id": 448615640,
        "latitude": 40.7666289,
        "longitude": -73.9827088,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-24T18:08:40.343Z",
        "type": "Periodical"
    },
    {
        "id": 448600365,
        "latitude": 40.7590389,
        "longitude": -73.9530659,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-08-24T17:39:31.825Z",
        "type": "Periodical"
    },
    {
        "id": 448597267,
        "latitude": 40.7598378,
        "longitude": -73.9520594,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-24T17:33:47.963Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 448545185,
        "latitude": 40.7623922,
        "longitude": -73.9497578,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-24T15:53:39.549Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 448530513,
        "latitude": 40.7485664,
        "longitude": -73.9809162,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-24T15:25:39.668Z",
        "type": "Periodical"
    },
    {
        "id": 448509788,
        "latitude": 40.8942709,
        "longitude": -74.2449896,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-24T14:43:52.286Z",
        "type": "Periodical"
    },
    {
        "id": 448490073,
        "latitude": 40.9907575,
        "longitude": -75.1421892,
        "accuracy": 719,
        "source": "Fused",
        "ts": "2024-08-24T13:59:49.484Z",
        "type": "Periodical"
    },
    {
        "id": 448471057,
        "latitude": 41.0915627,
        "longitude": -75.4221977,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-24T13:24:19.522Z",
        "type": "Periodical"
    },
    {
        "id": 448455383,
        "latitude": 41.0912168,
        "longitude": -75.4231197,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T12:50:19.751Z",
        "type": "Periodical"
    },
    {
        "id": 448436571,
        "latitude": 41.0911971,
        "longitude": -75.423081,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-24T12:12:19.016Z",
        "type": "Periodical"
    },
    {
        "id": 448428098,
        "latitude": 41.0911802,
        "longitude": -75.4230936,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T11:51:19.010Z",
        "type": "Periodical"
    },
    {
        "id": 448413330,
        "latitude": 41.091211,
        "longitude": -75.4231193,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T11:11:19.532Z",
        "type": "Periodical"
    },
    {
        "id": 448401248,
        "latitude": 41.091179,
        "longitude": -75.4230959,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-24T10:38:18.704Z",
        "type": "Periodical"
    },
    {
        "id": 448390621,
        "latitude": 41.0911906,
        "longitude": -75.4231018,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T10:07:18.503Z",
        "type": "Periodical"
    },
    {
        "id": 448382575,
        "latitude": 41.0911938,
        "longitude": -75.423105,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T09:44:18.374Z",
        "type": "Periodical"
    },
    {
        "id": 448370522,
        "latitude": 41.0912064,
        "longitude": -75.4230881,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T09:07:18.325Z",
        "type": "Periodical"
    },
    {
        "id": 448360372,
        "latitude": 41.0911903,
        "longitude": -75.4230957,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T08:38:18.238Z",
        "type": "Periodical"
    },
    {
        "id": 448351233,
        "latitude": 41.0911909,
        "longitude": -75.4231022,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T08:10:18.029Z",
        "type": "Periodical"
    },
    {
        "id": 448337577,
        "latitude": 41.0911939,
        "longitude": -75.4231007,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-24T07:32:17.852Z",
        "type": "Periodical"
    },
    {
        "id": 448328410,
        "latitude": 41.09119,
        "longitude": -75.4230983,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T07:06:17.705Z",
        "type": "Periodical"
    },
    {
        "id": 448320982,
        "latitude": 41.0912116,
        "longitude": -75.4231177,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T06:47:17.699Z",
        "type": "Periodical"
    },
    {
        "id": 448308671,
        "latitude": 41.0912048,
        "longitude": -75.4230835,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T06:15:17.525Z",
        "type": "Periodical"
    },
    {
        "id": 448293605,
        "latitude": 41.0912201,
        "longitude": -75.4231095,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T05:38:17.350Z",
        "type": "Periodical"
    },
    {
        "id": 448281061,
        "latitude": 41.0911908,
        "longitude": -75.4230991,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T05:08:17.316Z",
        "type": "Periodical"
    },
    {
        "id": 448269164,
        "latitude": 41.0912201,
        "longitude": -75.4231095,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T04:43:16.923Z",
        "type": "Periodical"
    },
    {
        "id": 448257756,
        "latitude": 41.0911908,
        "longitude": -75.4230984,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T04:19:16.867Z",
        "type": "Periodical"
    },
    {
        "id": 448244649,
        "latitude": 41.0912217,
        "longitude": -75.4231093,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-24T03:53:16.900Z",
        "type": "Periodical"
    },
    {
        "id": 448232779,
        "latitude": 41.0911909,
        "longitude": -75.4231017,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T03:29:16.580Z",
        "type": "Periodical"
    },
    {
        "id": 448218194,
        "latitude": 41.0911965,
        "longitude": -75.4230857,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-24T03:00:16.731Z",
        "type": "Periodical"
    },
    {
        "id": 448205803,
        "latitude": 41.0912031,
        "longitude": -75.4230782,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-24T02:37:16.095Z",
        "type": "Periodical"
    },
    {
        "id": 448187847,
        "latitude": 41.0911985,
        "longitude": -75.423087,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-24T02:05:16.025Z",
        "type": "Periodical"
    },
    {
        "id": 448167008,
        "latitude": 41.0912055,
        "longitude": -75.4230831,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-24T01:28:15.892Z",
        "type": "Periodical"
    },
    {
        "id": 448145146,
        "latitude": 41.0913127,
        "longitude": -75.4229425,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-08-24T00:50:15.586Z",
        "type": "Periodical"
    },
    {
        "id": 448124076,
        "latitude": 41.0912393,
        "longitude": -75.423188,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-08-24T00:11:15.482Z",
        "type": "Periodical"
    },
    {
        "id": 448103137,
        "latitude": 41.0912393,
        "longitude": -75.423188,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-08-23T23:33:53.083Z",
        "type": "Periodical"
    },
    {
        "id": 448082676,
        "latitude": 41.0911711,
        "longitude": -75.4230512,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-23T22:59:15.193Z",
        "type": "Periodical"
    },
    {
        "id": 448065389,
        "latitude": 41.0911711,
        "longitude": -75.4230512,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-23T22:30:15.110Z",
        "type": "Periodical"
    },
    {
        "id": 448043856,
        "latitude": 41.0912166,
        "longitude": -75.4231149,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-23T21:54:15.062Z",
        "type": "Periodical"
    },
    {
        "id": 448029576,
        "latitude": 41.0912197,
        "longitude": -75.4231131,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-23T21:31:14.968Z",
        "type": "Periodical"
    },
    {
        "id": 448008020,
        "latitude": 41.09128,
        "longitude": -75.4231199,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-23T20:56:08.115Z",
        "type": "Periodical"
    },
    {
        "id": 448007983,
        "latitude": 41.09128,
        "longitude": -75.4231199,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-23T20:56:08.115Z",
        "type": "Periodical"
    },
    {
        "id": 448008003,
        "latitude": 41.09128,
        "longitude": -75.4231199,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-23T20:56:08.115Z",
        "type": "Periodical"
    },
    {
        "id": 448008023,
        "latitude": 41.09128,
        "longitude": -75.4231199,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-23T20:56:08.115Z",
        "type": "Periodical"
    },
    {
        "id": 448007954,
        "latitude": 41.0912029,
        "longitude": -75.4230811,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-23T20:56:06.569Z",
        "type": "Periodical"
    },
    {
        "id": 448007731,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007734,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007805,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007793,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007885,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007871,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007861,
        "latitude": 41.0912728,
        "longitude": -75.4231326,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-23T20:55:30.628Z",
        "type": "Periodical"
    },
    {
        "id": 448007710,
        "latitude": 41.0912817,
        "longitude": -75.4231308,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T20:55:20.021Z",
        "type": "Periodical"
    },
    {
        "id": 448007648,
        "latitude": 41.0912817,
        "longitude": -75.4231308,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T20:55:20.021Z",
        "type": "Periodical"
    },
    {
        "id": 448007361,
        "latitude": 41.0912506,
        "longitude": -75.4231359,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T20:54:50.242Z",
        "type": "Periodical"
    },
    {
        "id": 448007573,
        "latitude": 41.0912506,
        "longitude": -75.4231359,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T20:54:50.242Z",
        "type": "Periodical"
    },
    {
        "id": 448007627,
        "latitude": 41.0912506,
        "longitude": -75.4231359,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T20:54:50.242Z",
        "type": "Periodical"
    },
    {
        "id": 448007354,
        "latitude": 41.0912506,
        "longitude": -75.4231359,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T20:54:50.242Z",
        "type": "Periodical"
    },
    {
        "id": 448007238,
        "latitude": 41.0912032,
        "longitude": -75.4230792,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T20:54:22.746Z",
        "type": "Periodical"
    },
    {
        "id": 447991934,
        "latitude": 41.0912064,
        "longitude": -75.4230923,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T20:27:10.582Z",
        "type": "Periodical"
    },
    {
        "id": 447978913,
        "latitude": 41.0912056,
        "longitude": -75.4230879,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T20:04:10.539Z",
        "type": "Periodical"
    },
    {
        "id": 447960642,
        "latitude": 41.0840182,
        "longitude": -75.4263668,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T19:31:10.171Z",
        "type": "Periodical"
    },
    {
        "id": 447949534,
        "latitude": 41.0857797,
        "longitude": -75.4274986,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-08-23T19:08:10.208Z",
        "type": "Periodical"
    },
    {
        "id": 447938233,
        "latitude": 41.0857733,
        "longitude": -75.427477,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-08-23T18:44:10.102Z",
        "type": "Periodical"
    },
    {
        "id": 447928100,
        "latitude": 41.0844225,
        "longitude": -75.4291588,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-23T18:23:10.088Z",
        "type": "Periodical"
    },
    {
        "id": 447916552,
        "latitude": 41.0840801,
        "longitude": -75.4264329,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T17:57:09.773Z",
        "type": "Periodical"
    },
    {
        "id": 447907758,
        "latitude": 41.0840801,
        "longitude": -75.4264329,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-23T17:36:09.625Z",
        "type": "Periodical"
    },
    {
        "id": 447897698,
        "latitude": 41.083843,
        "longitude": -75.4292375,
        "accuracy": 94,
        "source": "Fused",
        "ts": "2024-08-23T17:12:09.978Z",
        "type": "Periodical"
    },
    {
        "id": 447884369,
        "latitude": 41.0857736,
        "longitude": -75.4274744,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-08-23T16:40:09.709Z",
        "type": "Periodical"
    },
    {
        "id": 447868996,
        "latitude": 41.0912187,
        "longitude": -75.4231171,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-23T16:04:09.741Z",
        "type": "Periodical"
    },
    {
        "id": 447854907,
        "latitude": 41.0912109,
        "longitude": -75.4231273,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-23T15:28:45.959Z",
        "type": "Periodical"
    },
    {
        "id": 447846055,
        "latitude": 41.0912109,
        "longitude": -75.4231243,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-23T15:08:09.578Z",
        "type": "Periodical"
    },
    {
        "id": 447832849,
        "latitude": 41.091204,
        "longitude": -75.4230831,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T14:38:09.020Z",
        "type": "Periodical"
    },
    {
        "id": 447818888,
        "latitude": 41.0912033,
        "longitude": -75.4230857,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-23T14:04:08.867Z",
        "type": "Periodical"
    },
    {
        "id": 447808600,
        "latitude": 41.0911996,
        "longitude": -75.4231022,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-08-23T13:40:08.468Z",
        "type": "Periodical"
    },
    {
        "id": 447793130,
        "latitude": 41.0912137,
        "longitude": -75.4231148,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-23T13:03:08.189Z",
        "type": "Periodical"
    },
    {
        "id": 447779794,
        "latitude": 41.0912069,
        "longitude": -75.4230874,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T12:33:08.186Z",
        "type": "Periodical"
    },
    {
        "id": 447770547,
        "latitude": 41.0911898,
        "longitude": -75.4230938,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T12:11:08.219Z",
        "type": "Periodical"
    },
    {
        "id": 447757618,
        "latitude": 41.0912044,
        "longitude": -75.4230864,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T11:41:08.102Z",
        "type": "Periodical"
    },
    {
        "id": 447743487,
        "latitude": 41.0911993,
        "longitude": -75.423091,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-08-23T11:06:07.987Z",
        "type": "Periodical"
    },
    {
        "id": 447732224,
        "latitude": 41.0912065,
        "longitude": -75.4230931,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T10:38:07.886Z",
        "type": "Periodical"
    },
    {
        "id": 447718588,
        "latitude": 41.0911784,
        "longitude": -75.4230942,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T10:03:07.613Z",
        "type": "Periodical"
    },
    {
        "id": 447706423,
        "latitude": 41.091179,
        "longitude": -75.4230971,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-23T09:30:07.568Z",
        "type": "Periodical"
    },
    {
        "id": 447694637,
        "latitude": 41.0911798,
        "longitude": -75.4230927,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T08:57:07.353Z",
        "type": "Periodical"
    },
    {
        "id": 447687093,
        "latitude": 41.0912015,
        "longitude": -75.4230879,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-23T08:36:07.421Z",
        "type": "Periodical"
    },
    {
        "id": 447680119,
        "latitude": 41.0911992,
        "longitude": -75.4230874,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T08:16:07.188Z",
        "type": "Periodical"
    },
    {
        "id": 447669349,
        "latitude": 41.0911728,
        "longitude": -75.4231009,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-08-23T07:42:07.125Z",
        "type": "Periodical"
    },
    {
        "id": 447658857,
        "latitude": 41.0911907,
        "longitude": -75.4230964,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T07:18:07.008Z",
        "type": "Periodical"
    },
    {
        "id": 447644733,
        "latitude": 41.0911806,
        "longitude": -75.4230974,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-23T06:42:07.059Z",
        "type": "Periodical"
    },
    {
        "id": 447634514,
        "latitude": 41.0911791,
        "longitude": -75.423093,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T06:15:06.792Z",
        "type": "Periodical"
    },
    {
        "id": 447623961,
        "latitude": 41.0912048,
        "longitude": -75.4230855,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T05:49:06.720Z",
        "type": "Periodical"
    },
    {
        "id": 447610218,
        "latitude": 41.0912052,
        "longitude": -75.423087,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T05:16:06.501Z",
        "type": "Periodical"
    },
    {
        "id": 447596555,
        "latitude": 41.091208,
        "longitude": -75.4230889,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T04:45:06.499Z",
        "type": "Periodical"
    },
    {
        "id": 447586836,
        "latitude": 41.091197,
        "longitude": -75.4230877,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T04:23:06.395Z",
        "type": "Periodical"
    },
    {
        "id": 447573217,
        "latitude": 41.0912052,
        "longitude": -75.4230851,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T03:55:06.327Z",
        "type": "Periodical"
    },
    {
        "id": 447562357,
        "latitude": 41.0912068,
        "longitude": -75.4230882,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T03:33:06.296Z",
        "type": "Periodical"
    },
    {
        "id": 447548705,
        "latitude": 41.0912142,
        "longitude": -75.423115,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-23T03:05:06.038Z",
        "type": "Periodical"
    },
    {
        "id": 447536279,
        "latitude": 41.0912051,
        "longitude": -75.4230842,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T02:42:05.886Z",
        "type": "Periodical"
    },
    {
        "id": 447524217,
        "latitude": 41.0912055,
        "longitude": -75.4230877,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-23T02:19:05.735Z",
        "type": "Periodical"
    },
    {
        "id": 447501738,
        "latitude": 41.0911976,
        "longitude": -75.4230907,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-23T01:40:05.555Z",
        "type": "Periodical"
    },
    {
        "id": 447490128,
        "latitude": 41.0912037,
        "longitude": -75.423085,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T01:19:15.286Z",
        "type": "Periodical"
    },
    {
        "id": 447488081,
        "latitude": 41.0912035,
        "longitude": -75.423086,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-23T01:15:51.965Z",
        "type": "Periodical"
    },
    {
        "id": 447465239,
        "latitude": 41.0911964,
        "longitude": -75.4230944,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-23T00:35:45.807Z",
        "type": "Periodical"
    },
    {
        "id": 447465209,
        "latitude": 41.0911964,
        "longitude": -75.4230944,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-23T00:35:45.807Z",
        "type": "Periodical"
    },
    {
        "id": 447465210,
        "latitude": 41.0911964,
        "longitude": -75.4230944,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-23T00:35:45.807Z",
        "type": "Periodical"
    },
    {
        "id": 447464897,
        "latitude": 41.0911969,
        "longitude": -75.4230812,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-23T00:34:50.400Z",
        "type": "Periodical"
    },
    {
        "id": 447464899,
        "latitude": 41.0911969,
        "longitude": -75.4230812,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-23T00:34:50.400Z",
        "type": "Periodical"
    },
    {
        "id": 447464817,
        "latitude": 41.0911962,
        "longitude": -75.4230917,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-08-23T00:34:28.687Z",
        "type": "Periodical"
    },
    {
        "id": 447462865,
        "latitude": 41.0912006,
        "longitude": -75.4230805,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-08-23T00:31:46.684Z",
        "type": "Periodical"
    },
    {
        "id": 447441887,
        "latitude": 41.0912325,
        "longitude": -75.422892,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T23:54:46.568Z",
        "type": "Periodical"
    },
    {
        "id": 447426474,
        "latitude": 41.0912325,
        "longitude": -75.422892,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T23:26:46.453Z",
        "type": "Periodical"
    },
    {
        "id": 447404854,
        "latitude": 41.0912325,
        "longitude": -75.422892,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T22:47:46.331Z",
        "type": "Periodical"
    },
    {
        "id": 447387262,
        "latitude": 41.0912325,
        "longitude": -75.422892,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T22:18:53.807Z",
        "type": "Periodical"
    },
    {
        "id": 447372219,
        "latitude": 41.0911468,
        "longitude": -75.4230305,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-08-22T21:52:46.087Z",
        "type": "Periodical"
    },
    {
        "id": 447358537,
        "latitude": 41.0911468,
        "longitude": -75.4230305,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-08-22T21:28:45.967Z",
        "type": "Periodical"
    },
    {
        "id": 447345477,
        "latitude": 41.0911468,
        "longitude": -75.4230305,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-08-22T21:06:45.850Z",
        "type": "Periodical"
    },
    {
        "id": 447324953,
        "latitude": 41.0911468,
        "longitude": -75.4230305,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-08-22T20:30:58.054Z",
        "type": "Periodical"
    },
    {
        "id": 447310827,
        "latitude": 41.0911806,
        "longitude": -75.4232969,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-22T20:04:45.567Z",
        "type": "Periodical"
    },
    {
        "id": 447300570,
        "latitude": 41.0911806,
        "longitude": -75.4232969,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T19:44:45.565Z",
        "type": "Periodical"
    },
    {
        "id": 447289730,
        "latitude": 41.0911806,
        "longitude": -75.4232969,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T19:22:44.778Z",
        "type": "Periodical"
    },
    {
        "id": 447273574,
        "latitude": 41.0912184,
        "longitude": -75.4231177,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T18:46:45.781Z",
        "type": "Periodical"
    },
    {
        "id": 447264271,
        "latitude": 41.0912081,
        "longitude": -75.4231293,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T18:24:45.854Z",
        "type": "Periodical"
    },
    {
        "id": 447254023,
        "latitude": 41.091209,
        "longitude": -75.4231276,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T17:59:45.706Z",
        "type": "Periodical"
    },
    {
        "id": 447239595,
        "latitude": 41.0912079,
        "longitude": -75.4231229,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T17:24:45.509Z",
        "type": "Periodical"
    },
    {
        "id": 447226425,
        "latitude": 41.0912211,
        "longitude": -75.4230698,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T16:52:44.953Z",
        "type": "Periodical"
    },
    {
        "id": 447212320,
        "latitude": 41.0912211,
        "longitude": -75.4230698,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T16:16:59.236Z",
        "type": "Periodical"
    },
    {
        "id": 447194848,
        "latitude": 41.0912096,
        "longitude": -75.4231159,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T15:33:12.231Z",
        "type": "Periodical"
    },
    {
        "id": 447183798,
        "latitude": 41.091223,
        "longitude": -75.4231102,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T15:05:03.156Z",
        "type": "Periodical"
    },
    {
        "id": 447167277,
        "latitude": 41.0911962,
        "longitude": -75.4230866,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-22T14:27:02.965Z",
        "type": "Periodical"
    },
    {
        "id": 447154371,
        "latitude": 41.0912226,
        "longitude": -75.4231098,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T13:56:02.765Z",
        "type": "Periodical"
    },
    {
        "id": 447142525,
        "latitude": 41.0912126,
        "longitude": -75.423114,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T13:28:02.577Z",
        "type": "Periodical"
    },
    {
        "id": 447132257,
        "latitude": 41.0911829,
        "longitude": -75.4230264,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-08-22T13:03:37.924Z",
        "type": "Periodical"
    },
    {
        "id": 447119069,
        "latitude": 41.0912088,
        "longitude": -75.423114,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-22T12:34:02.391Z",
        "type": "Periodical"
    },
    {
        "id": 447104951,
        "latitude": 41.0910092,
        "longitude": -75.4228314,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-22T12:02:02.094Z",
        "type": "Periodical"
    },
    {
        "id": 447094789,
        "latitude": 41.0910092,
        "longitude": -75.4228314,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-22T11:40:01.937Z",
        "type": "Periodical"
    },
    {
        "id": 447079490,
        "latitude": 41.0911896,
        "longitude": -75.4230908,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-22T11:05:01.996Z",
        "type": "Periodical"
    },
    {
        "id": 447067430,
        "latitude": 41.0911806,
        "longitude": -75.4230862,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-22T10:36:01.701Z",
        "type": "Periodical"
    },
    {
        "id": 447058873,
        "latitude": 41.0913373,
        "longitude": -75.4229656,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T10:14:01.427Z",
        "type": "Periodical"
    },
    {
        "id": 447044796,
        "latitude": 41.0913373,
        "longitude": -75.4229656,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T09:37:01.344Z",
        "type": "Periodical"
    },
    {
        "id": 447033490,
        "latitude": 41.0913373,
        "longitude": -75.4229656,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T09:06:01.168Z",
        "type": "Periodical"
    },
    {
        "id": 447022656,
        "latitude": 41.0913373,
        "longitude": -75.4229656,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T08:37:10.500Z",
        "type": "Periodical"
    },
    {
        "id": 447013653,
        "latitude": 41.0912083,
        "longitude": -75.4230576,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T08:12:00.801Z",
        "type": "Periodical"
    },
    {
        "id": 447004566,
        "latitude": 41.0912083,
        "longitude": -75.4230576,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-22T07:48:00.505Z",
        "type": "Periodical"
    },
    {
        "id": 446997196,
        "latitude": 41.0912083,
        "longitude": -75.4230576,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T07:28:00.501Z",
        "type": "Periodical"
    },
    {
        "id": 446990038,
        "latitude": 41.091203,
        "longitude": -75.4230801,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-22T07:09:00.315Z",
        "type": "Periodical"
    },
    {
        "id": 446978068,
        "latitude": 41.091203,
        "longitude": -75.4230801,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-22T06:39:00.305Z",
        "type": "Periodical"
    },
    {
        "id": 446962610,
        "latitude": 41.1203586,
        "longitude": -75.3556359,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T05:59:46.292Z",
        "type": "Periodical"
    },
    {
        "id": 446946707,
        "latitude": 40.9881202,
        "longitude": -75.1444099,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-22T05:20:51.888Z",
        "type": "Periodical"
    },
    {
        "id": 446946708,
        "latitude": 40.9881202,
        "longitude": -75.1444099,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-22T05:20:51.888Z",
        "type": "Periodical"
    },
    {
        "id": 446946467,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946573,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946498,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946543,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946577,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946510,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946533,
        "latitude": 40.9897028,
        "longitude": -75.1469461,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.524Z",
        "type": "Periodical"
    },
    {
        "id": 446946408,
        "latitude": 40.9897052,
        "longitude": -75.1469588,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.283Z",
        "type": "Periodical"
    },
    {
        "id": 446946409,
        "latitude": 40.9897052,
        "longitude": -75.1469588,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.283Z",
        "type": "Periodical"
    },
    {
        "id": 446946410,
        "latitude": 40.9897052,
        "longitude": -75.1469588,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:20:23.283Z",
        "type": "Periodical"
    },
    {
        "id": 446946505,
        "latitude": 40.9865908,
        "longitude": -75.152513,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:19:16.483Z",
        "type": "Periodical"
    },
    {
        "id": 446946506,
        "latitude": 40.9865908,
        "longitude": -75.152513,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:19:16.483Z",
        "type": "Periodical"
    },
    {
        "id": 446946503,
        "latitude": 40.986423,
        "longitude": -75.1521103,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:18:06.619Z",
        "type": "Periodical"
    },
    {
        "id": 446946504,
        "latitude": 40.986423,
        "longitude": -75.1521103,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T05:18:06.619Z",
        "type": "Periodical"
    },
    {
        "id": 446946502,
        "latitude": 40.7567775,
        "longitude": -73.991365,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-08-22T05:17:34.506Z",
        "type": "Periodical"
    },
    {
        "id": 446940456,
        "latitude": 40.9291656,
        "longitude": -75.071004,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-22T05:06:10.520Z",
        "type": "Periodical"
    },
    {
        "id": 446927512,
        "latitude": 40.9110083,
        "longitude": -74.5279325,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-22T04:37:20.540Z",
        "type": "Periodical"
    },
    {
        "id": 446917292,
        "latitude": 40.7517407,
        "longitude": -74.2124269,
        "accuracy": 61,
        "source": "Fused",
        "ts": "2024-08-22T04:14:04.689Z",
        "type": "Periodical"
    },
    {
        "id": 446906748,
        "latitude": 40.7765834,
        "longitude": -74.058288,
        "accuracy": 82,
        "source": "Fused",
        "ts": "2024-08-22T03:52:04.979Z",
        "type": "Periodical"
    },
    {
        "id": 446890675,
        "latitude": 40.7571177,
        "longitude": -73.9908733,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-22T03:18:58.086Z",
        "type": "Periodical"
    },
    {
        "id": 446874501,
        "latitude": 40.757073,
        "longitude": -73.9907205,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-22T02:48:04.896Z",
        "type": "Periodical"
    },
    {
        "id": 446853669,
        "latitude": 40.7583927,
        "longitude": -73.9891257,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:09:57.535Z",
        "type": "Periodical"
    },
    {
        "id": 446853534,
        "latitude": 40.7583548,
        "longitude": -73.9891592,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:09:30.975Z",
        "type": "Periodical"
    },
    {
        "id": 446853602,
        "latitude": 40.7583548,
        "longitude": -73.9891592,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:09:30.975Z",
        "type": "Periodical"
    },
    {
        "id": 446853253,
        "latitude": 40.7584679,
        "longitude": -73.9890717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-22T02:09:19.037Z",
        "type": "Periodical"
    },
    {
        "id": 446853232,
        "latitude": 40.7584679,
        "longitude": -73.9890717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-22T02:09:19.037Z",
        "type": "Periodical"
    },
    {
        "id": 446853398,
        "latitude": 40.7584679,
        "longitude": -73.9890717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-22T02:09:19.037Z",
        "type": "Periodical"
    },
    {
        "id": 446853489,
        "latitude": 40.7584679,
        "longitude": -73.9890717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-22T02:09:19.037Z",
        "type": "Periodical"
    },
    {
        "id": 446853335,
        "latitude": 40.7584679,
        "longitude": -73.9890717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-22T02:09:19.037Z",
        "type": "Periodical"
    },
    {
        "id": 446853492,
        "latitude": 40.7584679,
        "longitude": -73.9890717,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-22T02:09:19.037Z",
        "type": "Periodical"
    },
    {
        "id": 446853056,
        "latitude": 40.7584736,
        "longitude": -73.9890646,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:08:47.962Z",
        "type": "Periodical"
    },
    {
        "id": 446853176,
        "latitude": 40.7584736,
        "longitude": -73.9890646,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:08:47.962Z",
        "type": "Periodical"
    },
    {
        "id": 446852748,
        "latitude": 40.7584735,
        "longitude": -73.9890631,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:08:12.617Z",
        "type": "Periodical"
    },
    {
        "id": 446848824,
        "latitude": 40.7593978,
        "longitude": -73.9884305,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-22T02:01:57.734Z",
        "type": "Periodical"
    },
    {
        "id": 446828733,
        "latitude": 40.7662452,
        "longitude": -73.9823855,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-22T01:25:57.852Z",
        "type": "Periodical"
    },
    {
        "id": 446811679,
        "latitude": 40.7662414,
        "longitude": -73.9823471,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-22T00:55:57.822Z",
        "type": "Periodical"
    },
    {
        "id": 446794515,
        "latitude": 40.7663896,
        "longitude": -73.9828489,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-08-22T00:23:47.522Z",
        "type": "Periodical"
    },
    {
        "id": 446775766,
        "latitude": 40.7662448,
        "longitude": -73.9822763,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-21T23:49:26.853Z",
        "type": "Periodical"
    },
    {
        "id": 446775676,
        "latitude": 40.7662448,
        "longitude": -73.9822763,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-21T23:49:26.853Z",
        "type": "Periodical"
    },
    {
        "id": 446775691,
        "latitude": 40.7662448,
        "longitude": -73.9822763,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-21T23:49:26.853Z",
        "type": "Periodical"
    },
    {
        "id": 446775602,
        "latitude": 40.7660707,
        "longitude": -73.9818453,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-21T23:48:54.847Z",
        "type": "Periodical"
    },
    {
        "id": 446775620,
        "latitude": 40.7660707,
        "longitude": -73.9818453,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-21T23:48:54.847Z",
        "type": "Periodical"
    },
    {
        "id": 446775407,
        "latitude": 40.7661157,
        "longitude": -73.9817871,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-21T23:48:23.205Z",
        "type": "Periodical"
    },
    {
        "id": 446761970,
        "latitude": 40.7662436,
        "longitude": -73.9824083,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-21T23:26:31.682Z",
        "type": "Periodical"
    },
    {
        "id": 446743814,
        "latitude": 40.7662384,
        "longitude": -73.9823868,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-21T22:53:31.373Z",
        "type": "Periodical"
    },
    {
        "id": 446730119,
        "latitude": 40.7662395,
        "longitude": -73.9823896,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-21T22:29:31.335Z",
        "type": "Periodical"
    },
    {
        "id": 446709648,
        "latitude": 40.7662354,
        "longitude": -73.9823775,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-21T21:55:31.285Z",
        "type": "Periodical"
    },
    {
        "id": 446687560,
        "latitude": 40.7662356,
        "longitude": -73.9824124,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-21T21:17:24.770Z",
        "type": "Periodical"
    },
    {
        "id": 446667621,
        "latitude": 40.7664224,
        "longitude": -73.9827962,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-21T20:42:14.770Z",
        "type": "Periodical"
    },
    {
        "id": 446649316,
        "latitude": 40.7596653,
        "longitude": -73.9528194,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-21T20:07:35.542Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 446541670,
        "latitude": 40.7628062,
        "longitude": -73.9496028,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-21T16:02:29.943Z",
        "type": "Periodical"
    },
    {
        "id": 446541668,
        "latitude": 40.7628062,
        "longitude": -73.9496028,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-21T16:02:29.943Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 446533291,
        "latitude": 40.7672285,
        "longitude": -73.944854,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-21T15:42:29.876Z",
        "type": "Periodical"
    },
    {
        "id": 446522123,
        "latitude": 40.7667675,
        "longitude": -73.9513612,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-08-21T15:14:28.651Z",
        "type": "Periodical"
    },
    {
        "id": 446507750,
        "latitude": 40.7665144,
        "longitude": -73.951317,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-21T14:39:17.359Z",
        "type": "Periodical"
    },
    {
        "id": 446504577,
        "latitude": 40.7654366,
        "longitude": -73.9476555,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-08-21T14:31:28.324Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 446494209,
        "latitude": 40.763551,
        "longitude": -73.9487012,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-21T14:05:22.501Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 446493761,
        "latitude": 40.7641363,
        "longitude": -73.9479351,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-21T14:03:37.320Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 446137785,
        "latitude": 40.7620714,
        "longitude": -73.9501206,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-21T00:31:40.463Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 446130282,
        "latitude": 40.7639368,
        "longitude": -73.9774842,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-21T00:16:59.019Z",
        "type": "Periodical"
    },
    {
        "id": 446112172,
        "latitude": 40.7662031,
        "longitude": -73.9818346,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-20T23:43:43.680Z",
        "type": "Periodical"
    },
    {
        "id": 446101506,
        "latitude": 40.766113,
        "longitude": -73.9819315,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-08-20T23:24:13.406Z",
        "type": "Periodical"
    },
    {
        "id": 446088113,
        "latitude": 40.766233,
        "longitude": -73.9823673,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-20T22:59:13.640Z",
        "type": "Periodical"
    },
    {
        "id": 446066067,
        "latitude": 40.7662707,
        "longitude": -73.9823058,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-20T22:21:13.542Z",
        "type": "Periodical"
    },
    {
        "id": 446044436,
        "latitude": 40.7662046,
        "longitude": -73.9823465,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-20T21:43:13.150Z",
        "type": "Periodical"
    },
    {
        "id": 446025792,
        "latitude": 40.7662413,
        "longitude": -73.9822824,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-20T21:11:13.275Z",
        "type": "Periodical"
    },
    {
        "id": 446011612,
        "latitude": 40.7661192,
        "longitude": -73.9813837,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-08-20T20:45:12.993Z",
        "type": "Periodical"
    },
    {
        "id": 445999612,
        "latitude": 40.7662015,
        "longitude": -73.9821711,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-20T20:22:44.376Z",
        "type": "Periodical"
    },
    {
        "id": 445965978,
        "latitude": 40.7661007,
        "longitude": -73.9813488,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-20T19:15:12.677Z",
        "type": "Periodical"
    },
    {
        "id": 445956618,
        "latitude": 40.7662489,
        "longitude": -73.9822241,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-08-20T18:54:12.622Z",
        "type": "Periodical"
    },
    {
        "id": 445947607,
        "latitude": 40.7660921,
        "longitude": -73.9817735,
        "accuracy": 55,
        "source": "Fused",
        "ts": "2024-08-20T18:33:12.430Z",
        "type": "Periodical"
    },
    {
        "id": 445939748,
        "latitude": 40.7661749,
        "longitude": -73.98181,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-08-20T18:14:12.289Z",
        "type": "Periodical"
    },
    {
        "id": 445929627,
        "latitude": 40.766244,
        "longitude": -73.9823784,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-20T17:50:08.820Z",
        "type": "Periodical"
    },
    {
        "id": 445916755,
        "latitude": 40.7633926,
        "longitude": -73.9816888,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-20T17:18:11.435Z",
        "type": "Periodical"
    },
    {
        "id": 445903452,
        "latitude": 40.7573749,
        "longitude": -73.8941572,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-20T16:45:14.493Z",
        "type": "Periodical"
    },
    {
        "id": 445903339,
        "latitude": 40.7582154,
        "longitude": -73.8943194,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-20T16:44:38.936Z",
        "type": "Periodical"
    },
    {
        "id": 445897914,
        "latitude": 40.7645161,
        "longitude": -73.893472,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-20T16:25:00.093Z",
        "type": "Periodical"
    },
    {
        "id": 445897913,
        "latitude": 40.7645215,
        "longitude": -73.8934834,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-20T15:46:36.847Z",
        "type": "Periodical"
    },
    {
        "id": 445869884,
        "latitude": 40.7472328,
        "longitude": -73.8914366,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-08-20T15:23:35.908Z",
        "type": "Periodical"
    },
    {
        "id": 445853942,
        "latitude": 40.7577714,
        "longitude": -73.969733,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-20T14:45:33.777Z",
        "type": "Periodical"
    },
    {
        "id": 445842812,
        "latitude": 40.7567658,
        "longitude": -73.9914036,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-20T14:18:07.767Z",
        "type": "Periodical"
    },
    {
        "id": 445826134,
        "latitude": 40.8620343,
        "longitude": -74.3477308,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-20T13:39:43.912Z",
        "type": "Periodical"
    },
    {
        "id": 445814581,
        "latitude": 40.9000453,
        "longitude": -74.6259878,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-20T13:12:41.654Z",
        "type": "Periodical"
    },
    {
        "id": 445804475,
        "latitude": 40.9253268,
        "longitude": -75.0678554,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-20T12:48:42.352Z",
        "type": "Periodical"
    },
    {
        "id": 445790457,
        "latitude": 41.1016048,
        "longitude": -75.3351085,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-20T12:15:18.636Z",
        "type": "Periodical"
    },
    {
        "id": 445775282,
        "latitude": 41.1225595,
        "longitude": -75.3576414,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-20T11:39:36.322Z",
        "type": "Periodical"
    },
    {
        "id": 445765126,
        "latitude": 41.0912224,
        "longitude": -75.4231134,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T11:13:36.657Z",
        "type": "Periodical"
    },
    {
        "id": 445749355,
        "latitude": 41.0912065,
        "longitude": -75.4231233,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T10:33:37.109Z",
        "type": "Periodical"
    },
    {
        "id": 445738891,
        "latitude": 41.0912065,
        "longitude": -75.4231233,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T10:04:36.705Z",
        "type": "Periodical"
    },
    {
        "id": 445730934,
        "latitude": 41.0912067,
        "longitude": -75.4231223,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T09:42:36.553Z",
        "type": "Periodical"
    },
    {
        "id": 445717905,
        "latitude": 41.091206,
        "longitude": -75.4231229,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T09:03:36.409Z",
        "type": "Periodical"
    },
    {
        "id": 445704496,
        "latitude": 41.0912065,
        "longitude": -75.4231222,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T08:24:36.322Z",
        "type": "Periodical"
    },
    {
        "id": 445696217,
        "latitude": 41.091205,
        "longitude": -75.4231234,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T08:00:36.195Z",
        "type": "Periodical"
    },
    {
        "id": 445688548,
        "latitude": 41.091206,
        "longitude": -75.4231229,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T07:38:36.210Z",
        "type": "Periodical"
    },
    {
        "id": 445676488,
        "latitude": 41.0912055,
        "longitude": -75.4231233,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T07:03:36.071Z",
        "type": "Periodical"
    },
    {
        "id": 445668596,
        "latitude": 41.091206,
        "longitude": -75.4231228,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T06:42:35.928Z",
        "type": "Periodical"
    },
    {
        "id": 445659626,
        "latitude": 41.0912068,
        "longitude": -75.4231226,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T06:17:35.960Z",
        "type": "Periodical"
    },
    {
        "id": 445649243,
        "latitude": 41.0912067,
        "longitude": -75.4231231,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T05:50:35.633Z",
        "type": "Periodical"
    },
    {
        "id": 445634191,
        "latitude": 41.0912052,
        "longitude": -75.4231234,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T05:11:35.938Z",
        "type": "Periodical"
    },
    {
        "id": 445618517,
        "latitude": 41.091205,
        "longitude": -75.4231236,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T04:34:35.488Z",
        "type": "Periodical"
    },
    {
        "id": 445607444,
        "latitude": 41.091205,
        "longitude": -75.4231236,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T04:08:35.439Z",
        "type": "Periodical"
    },
    {
        "id": 445595134,
        "latitude": 41.0912053,
        "longitude": -75.4231235,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T03:41:46.499Z",
        "type": "Periodical"
    },
    {
        "id": 445585607,
        "latitude": 41.0912053,
        "longitude": -75.4231235,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T03:20:54.578Z",
        "type": "Periodical"
    },
    {
        "id": 445567281,
        "latitude": 41.0912057,
        "longitude": -75.423123,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T02:42:54.039Z",
        "type": "Periodical"
    },
    {
        "id": 445552333,
        "latitude": 41.0912058,
        "longitude": -75.4231213,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T02:13:54.174Z",
        "type": "Periodical"
    },
    {
        "id": 445538922,
        "latitude": 41.0912067,
        "longitude": -75.423123,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T01:48:53.805Z",
        "type": "Periodical"
    },
    {
        "id": 445522693,
        "latitude": 41.0912066,
        "longitude": -75.4231237,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-20T01:18:53.700Z",
        "type": "Periodical"
    },
    {
        "id": 445504796,
        "latitude": 41.0910979,
        "longitude": -75.4230062,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-20T00:45:57.096Z",
        "type": "Periodical"
    },
    {
        "id": 445486956,
        "latitude": 41.0912922,
        "longitude": -75.4228267,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-08-20T00:13:05.682Z",
        "type": "Periodical"
    },
    {
        "id": 445469378,
        "latitude": 41.0802345,
        "longitude": -75.3942047,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-19T23:41:19.399Z",
        "type": "Periodical"
    },
    {
        "id": 445449905,
        "latitude": 40.9875488,
        "longitude": -75.2248651,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-19T23:07:41.404Z",
        "type": "Periodical"
    },
    {
        "id": 445434173,
        "latitude": 40.9881204,
        "longitude": -75.2248294,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-19T22:39:09.668Z",
        "type": "Periodical"
    },
    {
        "id": 445414891,
        "latitude": 40.9965802,
        "longitude": -75.2446856,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-19T22:06:13.865Z",
        "type": "Periodical"
    },
    {
        "id": 445398620,
        "latitude": 40.995949,
        "longitude": -75.2446665,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-19T21:38:15.404Z",
        "type": "Periodical"
    },
    {
        "id": 445376822,
        "latitude": 41.0096398,
        "longitude": -75.2952465,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-19T20:59:48.324Z",
        "type": "Periodical"
    },
    {
        "id": 445365633,
        "latitude": 41.04647,
        "longitude": -75.3115616,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-19T20:39:55.639Z",
        "type": "Periodical"
    },
    {
        "id": 445349229,
        "latitude": 41.0502265,
        "longitude": -75.3151403,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-19T20:09:49.406Z",
        "type": "Periodical"
    },
    {
        "id": 445331803,
        "latitude": 41.0467583,
        "longitude": -75.3123802,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-19T19:35:51.434Z",
        "type": "Periodical"
    },
    {
        "id": 445312433,
        "latitude": 41.0870028,
        "longitude": -75.3928218,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-19T18:56:08.992Z",
        "type": "Periodical"
    },
    {
        "id": 445294079,
        "latitude": 41.0912128,
        "longitude": -75.4231197,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T18:15:41.590Z",
        "type": "Periodical"
    },
    {
        "id": 445283046,
        "latitude": 41.0909793,
        "longitude": -75.4231795,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-19T17:50:42.983Z",
        "type": "Periodical"
    },
    {
        "id": 445272746,
        "latitude": 41.0909793,
        "longitude": -75.4231795,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-08-19T17:14:23.743Z",
        "type": "Periodical"
    },
    {
        "id": 445272745,
        "latitude": 41.0912609,
        "longitude": -75.423142,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-19T16:41:25.521Z",
        "type": "Periodical"
    },
    {
        "id": 445242580,
        "latitude": 41.0912609,
        "longitude": -75.423142,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-19T16:14:49.748Z",
        "type": "Periodical"
    },
    {
        "id": 445233372,
        "latitude": 41.0911965,
        "longitude": -75.422917,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-19T15:53:01.762Z",
        "type": "Periodical"
    },
    {
        "id": 445219737,
        "latitude": 41.0909707,
        "longitude": -75.4230455,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-19T15:21:23.746Z",
        "type": "Periodical"
    },
    {
        "id": 445208016,
        "latitude": 41.0915011,
        "longitude": -75.4241589,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-19T14:55:50.752Z",
        "type": "Periodical"
    },
    {
        "id": 445197287,
        "latitude": 41.0912247,
        "longitude": -75.4230313,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-19T14:31:00.774Z",
        "type": "Periodical"
    },
    {
        "id": 445185735,
        "latitude": 41.091208,
        "longitude": -75.4231254,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T14:02:55.658Z",
        "type": "Periodical"
    },
    {
        "id": 445170765,
        "latitude": 41.0912255,
        "longitude": -75.4230927,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-19T13:27:10.147Z",
        "type": "Periodical"
    },
    {
        "id": 445163276,
        "latitude": 41.091227,
        "longitude": -75.4231111,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T13:04:24.932Z",
        "type": "Periodical"
    },
    {
        "id": 445150265,
        "latitude": 41.0912045,
        "longitude": -75.423111,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T12:39:24.836Z",
        "type": "Periodical"
    },
    {
        "id": 445138381,
        "latitude": 41.0912042,
        "longitude": -75.4231162,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T12:11:24.703Z",
        "type": "Periodical"
    },
    {
        "id": 445123650,
        "latitude": 41.0912066,
        "longitude": -75.4231239,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T11:36:24.858Z",
        "type": "Periodical"
    },
    {
        "id": 445109625,
        "latitude": 41.0912063,
        "longitude": -75.4231241,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T11:01:24.618Z",
        "type": "Periodical"
    },
    {
        "id": 445097885,
        "latitude": 41.0912068,
        "longitude": -75.4231224,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T10:30:24.616Z",
        "type": "Periodical"
    },
    {
        "id": 445089590,
        "latitude": 41.0912067,
        "longitude": -75.4231222,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T10:07:39.000Z",
        "type": "Periodical"
    },
    {
        "id": 445078823,
        "latitude": 41.0912066,
        "longitude": -75.423123,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T09:37:37.590Z",
        "type": "Periodical"
    },
    {
        "id": 445065798,
        "latitude": 41.0912068,
        "longitude": -75.423122,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T09:00:00.745Z",
        "type": "Periodical"
    },
    {
        "id": 445052373,
        "latitude": 41.0912052,
        "longitude": -75.4231234,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T08:20:11.030Z",
        "type": "Periodical"
    },
    {
        "id": 445038652,
        "latitude": 41.0912068,
        "longitude": -75.423122,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T07:41:06.033Z",
        "type": "Periodical"
    },
    {
        "id": 445028183,
        "latitude": 41.0912068,
        "longitude": -75.4231224,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T07:11:01.011Z",
        "type": "Periodical"
    },
    {
        "id": 445014494,
        "latitude": 41.0912068,
        "longitude": -75.4231226,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T06:33:52.369Z",
        "type": "Periodical"
    },
    {
        "id": 445000144,
        "latitude": 41.0912068,
        "longitude": -75.4231226,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T05:55:52.044Z",
        "type": "Periodical"
    },
    {
        "id": 444986486,
        "latitude": 41.0912069,
        "longitude": -75.4231219,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T05:20:52.001Z",
        "type": "Periodical"
    },
    {
        "id": 444972013,
        "latitude": 41.091205,
        "longitude": -75.4231236,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T04:45:51.984Z",
        "type": "Periodical"
    },
    {
        "id": 444964061,
        "latitude": 41.0912067,
        "longitude": -75.4231231,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T04:26:51.768Z",
        "type": "Periodical"
    },
    {
        "id": 444953597,
        "latitude": 41.0912057,
        "longitude": -75.4231231,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T04:02:51.534Z",
        "type": "Periodical"
    },
    {
        "id": 444939833,
        "latitude": 41.0910774,
        "longitude": -75.4230204,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-19T03:32:51.163Z",
        "type": "Periodical"
    },
    {
        "id": 444929403,
        "latitude": 41.0910774,
        "longitude": -75.4230204,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-19T03:10:24.156Z",
        "type": "Periodical"
    },
    {
        "id": 444917583,
        "latitude": 41.0912041,
        "longitude": -75.4231224,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T02:46:51.322Z",
        "type": "Periodical"
    },
    {
        "id": 444905853,
        "latitude": 41.0912067,
        "longitude": -75.4231212,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T02:22:51.182Z",
        "type": "Periodical"
    },
    {
        "id": 444885815,
        "latitude": 41.0913259,
        "longitude": -75.4231001,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-19T01:44:50.326Z",
        "type": "Periodical"
    },
    {
        "id": 444865079,
        "latitude": 41.0913259,
        "longitude": -75.4231001,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-19T01:04:50.322Z",
        "type": "Periodical"
    },
    {
        "id": 444854743,
        "latitude": 41.0913259,
        "longitude": -75.4231001,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-19T00:45:29.689Z",
        "type": "Periodical"
    },
    {
        "id": 444833589,
        "latitude": 41.0912041,
        "longitude": -75.4231224,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-19T00:05:50.552Z",
        "type": "Periodical"
    },
    {
        "id": 444822476,
        "latitude": 41.091205,
        "longitude": -75.4231151,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-18T23:42:50.059Z",
        "type": "Periodical"
    },
    {
        "id": 444807114,
        "latitude": 41.091207,
        "longitude": -75.4231198,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-18T23:11:50.320Z",
        "type": "Periodical"
    },
    {
        "id": 444791108,
        "latitude": 41.091205,
        "longitude": -75.4231208,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-18T22:40:51.173Z",
        "type": "Periodical"
    },
    {
        "id": 444774032,
        "latitude": 41.0912043,
        "longitude": -75.4231209,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-18T22:07:01.734Z",
        "type": "Periodical"
    },
    {
        "id": 444754454,
        "latitude": 41.0912206,
        "longitude": -75.4231128,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-18T21:28:01.572Z",
        "type": "Periodical"
    },
    {
        "id": 444741152,
        "latitude": 41.0586587,
        "longitude": -75.3245339,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-18T21:01:23.691Z",
        "type": "Periodical"
    },
    {
        "id": 444723514,
        "latitude": 40.9230997,
        "longitude": -74.8722535,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-18T20:26:11.097Z",
        "type": "Periodical"
    },
    {
        "id": 444708375,
        "latitude": 40.8952696,
        "longitude": -74.2618296,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-18T19:57:27.695Z",
        "type": "Periodical"
    },
    {
        "id": 444690006,
        "latitude": 40.8104443,
        "longitude": -73.9306994,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-18T19:23:49.546Z",
        "type": "Periodical"
    },
    {
        "id": 444672046,
        "latitude": 40.7710585,
        "longitude": -73.9297027,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-18T18:49:00.552Z",
        "type": "Periodical"
    },
    {
        "id": 444662761,
        "latitude": 40.7710588,
        "longitude": -73.9296948,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-18T18:30:00.396Z",
        "type": "Periodical"
    },
    {
        "id": 444656375,
        "latitude": 40.7619564,
        "longitude": -73.9422913,
        "accuracy": 88,
        "source": "Fused",
        "ts": "2024-08-18T18:16:36.047Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 444617838,
        "latitude": 40.7621118,
        "longitude": -73.9501344,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-18T17:00:09.244Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 444616891,
        "latitude": 40.7613567,
        "longitude": -73.9510305,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-18T16:58:06.697Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 444076628,
        "latitude": 40.7625333,
        "longitude": -73.9494723,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-17T20:01:10.508Z",
        "type": "Periodical"
    },
    {
        "id": 444074436,
        "latitude": 40.7625425,
        "longitude": -73.9494954,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-17T20:00:06.006Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 444060957,
        "latitude": 40.7662397,
        "longitude": -73.9468692,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-17T19:34:10.400Z",
        "type": "Periodical"
    },
    {
        "id": 444043525,
        "latitude": 40.7666216,
        "longitude": -73.951201,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-17T19:00:10.237Z",
        "type": "Periodical"
    },
    {
        "id": 444033643,
        "latitude": 40.7662026,
        "longitude": -73.9468414,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-17T18:40:10.056Z",
        "type": "Periodical"
    },
    {
        "id": 444013815,
        "latitude": 40.7662555,
        "longitude": -73.9461792,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-08-17T18:00:09.977Z",
        "type": "Periodical"
    },
    {
        "id": 444007814,
        "latitude": 40.766211,
        "longitude": -73.9469067,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-17T17:47:40.463Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 443589314,
        "latitude": 40.7619583,
        "longitude": -73.9512838,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-08-17T01:05:26.808Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 443585154,
        "latitude": 40.763776,
        "longitude": -73.9638626,
        "accuracy": 1231,
        "source": "Fused",
        "ts": "2024-08-17T00:57:15.579Z",
        "type": "Periodical"
    },
    {
        "id": 443566767,
        "latitude": 40.7483493,
        "longitude": -73.9886107,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-17T00:22:58.812Z",
        "type": "Periodical"
    },
    {
        "id": 443557379,
        "latitude": 40.7476259,
        "longitude": -73.986828,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-17T00:05:58.454Z",
        "type": "Periodical"
    },
    {
        "id": 443523360,
        "latitude": 40.7476548,
        "longitude": -73.9867684,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-16T23:01:53.971Z",
        "type": "Periodical"
    },
    {
        "id": 443503114,
        "latitude": 40.747628,
        "longitude": -73.9868037,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T22:24:55.847Z",
        "type": "Periodical"
    },
    {
        "id": 443488796,
        "latitude": 40.7484822,
        "longitude": -73.9879914,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-16T21:59:57.812Z",
        "type": "Periodical"
    },
    {
        "id": 443488784,
        "latitude": 40.7484663,
        "longitude": -73.9879836,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:56.799Z",
        "type": "Periodical"
    },
    {
        "id": 443488764,
        "latitude": 40.7484574,
        "longitude": -73.9879596,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:53.807Z",
        "type": "Periodical"
    },
    {
        "id": 443488727,
        "latitude": 40.7484284,
        "longitude": -73.9878821,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:49.815Z",
        "type": "Periodical"
    },
    {
        "id": 443488731,
        "latitude": 40.7483098,
        "longitude": -73.987747,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:45.817Z",
        "type": "Periodical"
    },
    {
        "id": 443488689,
        "latitude": 40.7482753,
        "longitude": -73.9876896,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:44.834Z",
        "type": "Periodical"
    },
    {
        "id": 443488672,
        "latitude": 40.7482196,
        "longitude": -73.9875965,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:42.797Z",
        "type": "Periodical"
    },
    {
        "id": 443488627,
        "latitude": 40.7481369,
        "longitude": -73.9874537,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:38.803Z",
        "type": "Periodical"
    },
    {
        "id": 443488610,
        "latitude": 40.7481213,
        "longitude": -73.9873989,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:35.813Z",
        "type": "Periodical"
    },
    {
        "id": 443488607,
        "latitude": 40.7481282,
        "longitude": -73.9874507,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:27.810Z",
        "type": "Periodical"
    },
    {
        "id": 443488611,
        "latitude": 40.7481728,
        "longitude": -73.9875264,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-16T21:59:24.804Z",
        "type": "Periodical"
    },
    {
        "id": 443488533,
        "latitude": 40.7482874,
        "longitude": -73.9876488,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-16T21:59:10.841Z",
        "type": "Periodical"
    },
    {
        "id": 443488529,
        "latitude": 40.748992,
        "longitude": -73.9880778,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-16T21:58:56.802Z",
        "type": "Periodical"
    },
    {
        "id": 443488526,
        "latitude": 40.7489818,
        "longitude": -73.9880863,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-16T21:58:55.859Z",
        "type": "Periodical"
    },
    {
        "id": 443488518,
        "latitude": 40.7491333,
        "longitude": -73.9880804,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-16T21:58:32.855Z",
        "type": "Periodical"
    },
    {
        "id": 443488276,
        "latitude": 40.7488379,
        "longitude": -73.9881754,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-16T21:58:17.834Z",
        "type": "Periodical"
    },
    {
        "id": 443487924,
        "latitude": 40.7494252,
        "longitude": -73.9886213,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-16T21:58:05.796Z",
        "type": "Periodical"
    },
    {
        "id": 443487899,
        "latitude": 40.7490445,
        "longitude": -73.9881414,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-08-16T21:57:51.279Z",
        "type": "Periodical"
    },
    {
        "id": 443487882,
        "latitude": 40.7492488,
        "longitude": -73.9884076,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-16T21:57:24.603Z",
        "type": "Periodical"
    },
    {
        "id": 443482429,
        "latitude": 40.7590502,
        "longitude": -73.9530295,
        "accuracy": 73,
        "source": "Fused",
        "ts": "2024-08-16T21:42:52.931Z",
        "type": "Periodical"
    },
    {
        "id": 443477410,
        "latitude": 40.7589845,
        "longitude": -73.9529441,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-16T21:40:11.742Z",
        "type": "Periodical"
    },
    {
        "id": 443476500,
        "latitude": 40.7591554,
        "longitude": -73.9533351,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-16T21:38:29.304Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 442982396,
        "latitude": 40.7620528,
        "longitude": -73.9498026,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-16T02:20:58.930Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 442974239,
        "latitude": 40.7638783,
        "longitude": -73.9775393,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-16T02:05:30.180Z",
        "type": "Periodical"
    },
    {
        "id": 442964131,
        "latitude": 40.7662745,
        "longitude": -73.9823715,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-16T01:46:26.806Z",
        "type": "Periodical"
    },
    {
        "id": 442944335,
        "latitude": 40.7667674,
        "longitude": -73.9832596,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-08-16T01:09:35.782Z",
        "type": "Periodical"
    },
    {
        "id": 442922417,
        "latitude": 40.7662971,
        "longitude": -73.9826254,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-16T00:29:35.557Z",
        "type": "Periodical"
    },
    {
        "id": 442904340,
        "latitude": 40.7662405,
        "longitude": -73.9822443,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-15T23:56:33.349Z",
        "type": "Periodical"
    },
    {
        "id": 442891172,
        "latitude": 40.7663155,
        "longitude": -73.9826224,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-15T23:32:30.135Z",
        "type": "Periodical"
    },
    {
        "id": 442870805,
        "latitude": 40.7662386,
        "longitude": -73.9822416,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-15T22:54:07.609Z",
        "type": "Periodical"
    },
    {
        "id": 442870698,
        "latitude": 40.7662296,
        "longitude": -73.9822117,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-15T22:53:27.478Z",
        "type": "Periodical"
    },
    {
        "id": 442814061,
        "latitude": 40.7663865,
        "longitude": -73.9827163,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-15T21:12:59.629Z",
        "type": "Periodical"
    },
    {
        "id": 442802744,
        "latitude": 40.7664294,
        "longitude": -73.9828216,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-15T20:53:18.266Z",
        "type": "Periodical"
    },
    {
        "id": 442791789,
        "latitude": 40.7590166,
        "longitude": -73.9530467,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-15T20:33:35.748Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 442778740,
        "latitude": 40.7624964,
        "longitude": -73.9498196,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-15T20:09:38.522Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 442769931,
        "latitude": 40.7599238,
        "longitude": -73.9519012,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-15T19:51:44.264Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 442715482,
        "latitude": 40.7623797,
        "longitude": -73.9497605,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-15T17:49:37.345Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 442705046,
        "latitude": 40.7608025,
        "longitude": -73.9505889,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-15T17:23:18.427Z",
        "type": "Periodical"
    },
    {
        "id": 442694872,
        "latitude": 40.7608078,
        "longitude": -73.950625,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-15T16:58:32.256Z",
        "type": "Periodical"
    },
    {
        "id": 442671204,
        "latitude": 40.7607377,
        "longitude": -73.9504068,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-15T15:59:57.760Z",
        "type": "Periodical"
    },
    {
        "id": 442664379,
        "latitude": 40.7608124,
        "longitude": -73.9505861,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-15T15:43:21.802Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 442636753,
        "latitude": 40.7623843,
        "longitude": -73.9497525,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-15T14:36:01.508Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 442628396,
        "latitude": 40.7602305,
        "longitude": -73.9516883,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-15T14:17:56.061Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 442263765,
        "latitude": 40.762364,
        "longitude": -73.9496765,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-15T00:18:05.493Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 442257177,
        "latitude": 40.7590407,
        "longitude": -73.9530845,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-15T00:06:03.426Z",
        "type": "Periodical"
    },
    {
        "id": 442242736,
        "latitude": 40.7661604,
        "longitude": -73.9817411,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-14T23:39:10.449Z",
        "type": "Periodical"
    },
    {
        "id": 442242493,
        "latitude": 40.7661193,
        "longitude": -73.9817689,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-14T23:38:39.188Z",
        "type": "Periodical"
    },
    {
        "id": 442242506,
        "latitude": 40.7661193,
        "longitude": -73.9817689,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-14T23:38:39.188Z",
        "type": "Periodical"
    },
    {
        "id": 442242509,
        "latitude": 40.7661193,
        "longitude": -73.9817689,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-14T23:38:39.188Z",
        "type": "Periodical"
    },
    {
        "id": 442242535,
        "latitude": 40.7661193,
        "longitude": -73.9817689,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-14T23:38:39.188Z",
        "type": "Periodical"
    },
    {
        "id": 442242544,
        "latitude": 40.7661193,
        "longitude": -73.9817689,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-14T23:38:39.188Z",
        "type": "Periodical"
    },
    {
        "id": 442242604,
        "latitude": 40.7661193,
        "longitude": -73.9817689,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-08-14T23:38:39.188Z",
        "type": "Periodical"
    },
    {
        "id": 442242230,
        "latitude": 40.766268,
        "longitude": -73.9822921,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-14T23:38:08.001Z",
        "type": "Periodical"
    },
    {
        "id": 442242192,
        "latitude": 40.766268,
        "longitude": -73.9822921,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-14T23:38:08.001Z",
        "type": "Periodical"
    },
    {
        "id": 442242043,
        "latitude": 40.7662405,
        "longitude": -73.9822189,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-14T23:37:35.378Z",
        "type": "Periodical"
    },
    {
        "id": 442242072,
        "latitude": 40.7662405,
        "longitude": -73.9822189,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-14T23:37:35.378Z",
        "type": "Periodical"
    },
    {
        "id": 442226196,
        "latitude": 40.7662449,
        "longitude": -73.982353,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-14T23:09:35.652Z",
        "type": "Periodical"
    },
    {
        "id": 442210203,
        "latitude": 40.7664487,
        "longitude": -73.9822873,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-14T22:45:35.329Z",
        "type": "Periodical"
    },
    {
        "id": 442188414,
        "latitude": 40.7662972,
        "longitude": -73.9822976,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-14T22:13:34.778Z",
        "type": "Periodical"
    },
    {
        "id": 442164087,
        "latitude": 40.766331,
        "longitude": -73.9822356,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-08-14T21:34:27.242Z",
        "type": "Periodical"
    },
    {
        "id": 442163306,
        "latitude": 40.766059,
        "longitude": -73.9814079,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-14T21:33:12.599Z",
        "type": "Periodical"
    },
    {
        "id": 442153770,
        "latitude": 40.7662033,
        "longitude": -73.9821279,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-08-14T21:19:17.278Z",
        "type": "Periodical"
    },
    {
        "id": 442133208,
        "latitude": 40.7662124,
        "longitude": -73.9821889,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-14T20:44:17.460Z",
        "type": "Periodical"
    },
    {
        "id": 442118419,
        "latitude": 40.7662028,
        "longitude": -73.9821062,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-08-14T20:19:17.075Z",
        "type": "Periodical"
    },
    {
        "id": 442105669,
        "latitude": 40.7662164,
        "longitude": -73.982205,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-14T19:56:05.200Z",
        "type": "Periodical"
    },
    {
        "id": 442089741,
        "latitude": 40.7663877,
        "longitude": -73.9822461,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-14T19:25:16.765Z",
        "type": "Periodical"
    },
    {
        "id": 442077212,
        "latitude": 40.7662663,
        "longitude": -73.982207,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-14T19:01:16.728Z",
        "type": "Periodical"
    },
    {
        "id": 442061882,
        "latitude": 40.7662305,
        "longitude": -73.982218,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-14T18:28:16.585Z",
        "type": "Periodical"
    },
    {
        "id": 442045721,
        "latitude": 40.7649457,
        "longitude": -73.9788255,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-14T17:51:44.363Z",
        "type": "Periodical"
    },
    {
        "id": 442039237,
        "latitude": 40.7608219,
        "longitude": -73.951147,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-14T17:36:28.348Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 441662587,
        "latitude": 40.7624189,
        "longitude": -73.9496842,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-14T02:29:07.983Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 441650009,
        "latitude": 40.7634356,
        "longitude": -73.861086,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-08-14T02:03:31.646Z",
        "type": "Periodical"
    },
    {
        "id": 441638150,
        "latitude": 40.7576221,
        "longitude": -73.8462133,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-14T01:41:05.875Z",
        "type": "Periodical"
    },
    {
        "id": 441624884,
        "latitude": 40.7576281,
        "longitude": -73.8462003,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-14T01:16:20.772Z",
        "type": "Periodical"
    },
    {
        "id": 441603947,
        "latitude": 40.756639,
        "longitude": -73.8454221,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-14T00:37:20.528Z",
        "type": "Periodical"
    },
    {
        "id": 441590059,
        "latitude": 40.756639,
        "longitude": -73.8454221,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-14T00:11:31.743Z",
        "type": "Periodical"
    },
    {
        "id": 441576647,
        "latitude": 40.7576044,
        "longitude": -73.846231,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-13T23:46:20.470Z",
        "type": "Periodical"
    },
    {
        "id": 441563460,
        "latitude": 40.757636,
        "longitude": -73.8462127,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-13T23:21:20.178Z",
        "type": "Periodical"
    },
    {
        "id": 441543330,
        "latitude": 40.7430311,
        "longitude": -73.9174011,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-13T22:43:40.126Z",
        "type": "Periodical"
    },
    {
        "id": 441531103,
        "latitude": 40.7668517,
        "longitude": -73.982991,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-13T22:21:40.125Z",
        "type": "Periodical"
    },
    {
        "id": 441531067,
        "latitude": 40.7668831,
        "longitude": -73.9829916,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-13T22:21:36.121Z",
        "type": "Periodical"
    },
    {
        "id": 441531062,
        "latitude": 40.7668797,
        "longitude": -73.9830619,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-13T22:21:35.135Z",
        "type": "Periodical"
    },
    {
        "id": 441530965,
        "latitude": 40.766818,
        "longitude": -73.98312,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-13T22:21:21.073Z",
        "type": "Periodical"
    },
    {
        "id": 441530959,
        "latitude": 40.766818,
        "longitude": -73.98312,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-13T22:21:21.073Z",
        "type": "Periodical"
    },
    {
        "id": 441530799,
        "latitude": 40.7667763,
        "longitude": -73.9835456,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:20:49.132Z",
        "type": "Periodical"
    },
    {
        "id": 441530671,
        "latitude": 40.7667763,
        "longitude": -73.9835456,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:20:49.132Z",
        "type": "Periodical"
    },
    {
        "id": 441530774,
        "latitude": 40.7667763,
        "longitude": -73.9835456,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:20:49.132Z",
        "type": "Periodical"
    },
    {
        "id": 441530858,
        "latitude": 40.7667763,
        "longitude": -73.9835456,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:20:49.132Z",
        "type": "Periodical"
    },
    {
        "id": 441530603,
        "latitude": 40.7667915,
        "longitude": -73.9835383,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-13T22:20:47.146Z",
        "type": "Periodical"
    },
    {
        "id": 441530481,
        "latitude": 40.7668048,
        "longitude": -73.9832883,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-13T22:20:35.146Z",
        "type": "Periodical"
    },
    {
        "id": 441530474,
        "latitude": 40.7668276,
        "longitude": -73.9833113,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-13T22:20:33.178Z",
        "type": "Periodical"
    },
    {
        "id": 441530437,
        "latitude": 40.7668513,
        "longitude": -73.9835172,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-13T22:20:30.117Z",
        "type": "Periodical"
    },
    {
        "id": 441530241,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530084,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530103,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530206,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530112,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530144,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530174,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530205,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530220,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530256,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530284,
        "latitude": 40.7670196,
        "longitude": -73.9838127,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-13T22:19:46.125Z",
        "type": "Periodical"
    },
    {
        "id": 441530057,
        "latitude": 40.766937,
        "longitude": -73.9833911,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-13T22:19:40.138Z",
        "type": "Periodical"
    },
    {
        "id": 441530028,
        "latitude": 40.7669307,
        "longitude": -73.983389,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-13T22:19:36.198Z",
        "type": "Periodical"
    },
    {
        "id": 441530027,
        "latitude": 40.7668281,
        "longitude": -73.9831357,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-13T22:19:21.979Z",
        "type": "Periodical"
    },
    {
        "id": 441529921,
        "latitude": 40.7671293,
        "longitude": -73.9838506,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-13T22:18:50.206Z",
        "type": "Periodical"
    },
    {
        "id": 441529422,
        "latitude": 40.7664593,
        "longitude": -73.9832412,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-13T22:18:11.606Z",
        "type": "Periodical"
    },
    {
        "id": 441529444,
        "latitude": 40.7664593,
        "longitude": -73.9832412,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-13T22:18:11.606Z",
        "type": "Periodical"
    },
    {
        "id": 441529310,
        "latitude": 40.7668316,
        "longitude": -73.9829708,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-13T22:17:45.485Z",
        "type": "Periodical"
    },
    {
        "id": 441529131,
        "latitude": 40.7666565,
        "longitude": -73.9829124,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-13T22:17:28.590Z",
        "type": "Periodical"
    },
    {
        "id": 441527616,
        "latitude": 40.7667716,
        "longitude": -73.983348,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-13T22:15:34.284Z",
        "type": "Periodical"
    },
    {
        "id": 441511628,
        "latitude": 40.7662704,
        "longitude": -73.9824341,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-13T21:46:34.529Z",
        "type": "Periodical"
    },
    {
        "id": 441498469,
        "latitude": 40.76629,
        "longitude": -73.982576,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-08-13T21:24:34.428Z",
        "type": "Periodical"
    },
    {
        "id": 441485097,
        "latitude": 40.7662716,
        "longitude": -73.982431,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-13T20:59:34.585Z",
        "type": "Periodical"
    },
    {
        "id": 441464636,
        "latitude": 40.7661706,
        "longitude": -73.9816826,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-13T20:21:34.269Z",
        "type": "Periodical"
    },
    {
        "id": 441452058,
        "latitude": 40.76624,
        "longitude": -73.9822507,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-13T19:58:33.797Z",
        "type": "Periodical"
    },
    {
        "id": 441430020,
        "latitude": 40.7662436,
        "longitude": -73.9820654,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-13T19:14:33.964Z",
        "type": "Periodical"
    },
    {
        "id": 441413337,
        "latitude": 40.7662141,
        "longitude": -73.9820722,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-13T18:40:33.862Z",
        "type": "Periodical"
    },
    {
        "id": 441397627,
        "latitude": 40.7662029,
        "longitude": -73.9820638,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-08-13T18:06:14.147Z",
        "type": "Periodical"
    },
    {
        "id": 441383222,
        "latitude": 40.766239,
        "longitude": -73.9822743,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-13T17:34:33.525Z",
        "type": "Periodical"
    },
    {
        "id": 441372973,
        "latitude": 40.7661478,
        "longitude": -73.981968,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-13T17:12:03.490Z",
        "type": "Periodical"
    },
    {
        "id": 441362990,
        "latitude": 40.7663161,
        "longitude": -73.9825429,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-13T16:50:07.667Z",
        "type": "Periodical"
    },
    {
        "id": 441348126,
        "latitude": 40.7639735,
        "longitude": -73.9774617,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-13T16:17:03.595Z",
        "type": "Periodical"
    },
    {
        "id": 441336743,
        "latitude": 40.759389,
        "longitude": -73.952681,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-13T15:52:33.573Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 440885904,
        "latitude": 40.7623736,
        "longitude": -73.9497495,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-12T22:49:00.374Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 440875730,
        "latitude": 40.7608371,
        "longitude": -73.9511198,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-12T22:29:24.252Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 440849960,
        "latitude": 40.7628417,
        "longitude": -73.9493363,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-12T21:41:57.460Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 440839237,
        "latitude": 40.7374469,
        "longitude": -73.9334928,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-12T21:22:30.026Z",
        "type": "Periodical"
    },
    {
        "id": 440822795,
        "latitude": 40.6719435,
        "longitude": -73.8430475,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-12T20:51:03.961Z",
        "type": "Periodical"
    },
    {
        "id": 440809192,
        "latitude": 40.5809428,
        "longitude": -73.8266951,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-12T20:27:03.710Z",
        "type": "Periodical"
    },
    {
        "id": 440789160,
        "latitude": 40.5809908,
        "longitude": -73.8265703,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-08-12T19:49:14.324Z",
        "type": "Periodical"
    },
    {
        "id": 440776318,
        "latitude": 40.5811249,
        "longitude": -73.8261143,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-08-12T19:25:14.200Z",
        "type": "Periodical"
    },
    {
        "id": 440766080,
        "latitude": 40.5811568,
        "longitude": -73.8260796,
        "accuracy": 58,
        "source": "Fused",
        "ts": "2024-08-12T19:06:00.895Z",
        "type": "Periodical"
    },
    {
        "id": 440749027,
        "latitude": 40.5808786,
        "longitude": -73.8277159,
        "accuracy": 75,
        "source": "Fused",
        "ts": "2024-08-12T18:32:14.110Z",
        "type": "Periodical"
    },
    {
        "id": 440734640,
        "latitude": 40.580505,
        "longitude": -73.8270514,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-12T18:02:07.042Z",
        "type": "Periodical"
    },
    {
        "id": 440719341,
        "latitude": 40.5813393,
        "longitude": -73.8255876,
        "accuracy": 76,
        "source": "Fused",
        "ts": "2024-08-12T17:30:13.338Z",
        "type": "Periodical"
    },
    {
        "id": 440703845,
        "latitude": 40.5812484,
        "longitude": -73.8259833,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-08-12T16:58:13.261Z",
        "type": "Periodical"
    },
    {
        "id": 440689773,
        "latitude": 40.5810038,
        "longitude": -73.826306,
        "accuracy": 85,
        "source": "Fused",
        "ts": "2024-08-12T16:27:13.206Z",
        "type": "Periodical"
    },
    {
        "id": 440673157,
        "latitude": 40.5814139,
        "longitude": -73.825612,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-08-12T15:52:13.138Z",
        "type": "Periodical"
    },
    {
        "id": 440660683,
        "latitude": 40.5805337,
        "longitude": -73.8270454,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-08-12T15:20:13.025Z",
        "type": "Periodical"
    },
    {
        "id": 440645211,
        "latitude": 40.5811929,
        "longitude": -73.8262471,
        "accuracy": 70,
        "source": "Fused",
        "ts": "2024-08-12T14:51:13.086Z",
        "type": "Periodical"
    },
    {
        "id": 440633271,
        "latitude": 40.5805621,
        "longitude": -73.826285,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-08-12T14:24:55.790Z",
        "type": "Periodical"
    },
    {
        "id": 440619311,
        "latitude": 40.696803,
        "longitude": -73.8531285,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-08-12T13:56:06.420Z",
        "type": "Periodical"
    },
    {
        "id": 440607211,
        "latitude": 40.7520058,
        "longitude": -73.947891,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-12T13:28:12.732Z",
        "type": "Periodical"
    },
    {
        "id": 440603261,
        "latitude": 40.7565408,
        "longitude": -73.9470221,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-12T13:19:03.980Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 440231299,
        "latitude": 40.7624971,
        "longitude": -73.9497051,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-11T22:41:27.591Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 440216362,
        "latitude": 40.7564547,
        "longitude": -73.955822,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-11T22:11:28.576Z",
        "type": "Periodical"
    },
    {
        "id": 440206834,
        "latitude": 40.7553344,
        "longitude": -73.9564101,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-11T21:52:24.956Z",
        "type": "Periodical"
    },
    {
        "id": 440192045,
        "latitude": 40.7553325,
        "longitude": -73.9565863,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-11T21:21:28.524Z",
        "type": "Periodical"
    },
    {
        "id": 440181173,
        "latitude": 40.755161,
        "longitude": -73.9562212,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-08-11T20:59:28.368Z",
        "type": "Periodical"
    },
    {
        "id": 440165100,
        "latitude": 40.7550877,
        "longitude": -73.9562482,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-08-11T20:26:28.275Z",
        "type": "Periodical"
    },
    {
        "id": 440152749,
        "latitude": 40.7555846,
        "longitude": -73.9563151,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-11T20:01:28.080Z",
        "type": "Periodical"
    },
    {
        "id": 440133984,
        "latitude": 40.7554231,
        "longitude": -73.9562804,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-08-11T19:24:28.139Z",
        "type": "Periodical"
    },
    {
        "id": 440115563,
        "latitude": 40.7593648,
        "longitude": -73.9531163,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-11T18:48:53.815Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 439581681,
        "latitude": 40.7623261,
        "longitude": -73.9498214,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-10T22:39:58.555Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 439570975,
        "latitude": 40.7644344,
        "longitude": -73.9774724,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-10T22:17:14.822Z",
        "type": "Periodical"
    },
    {
        "id": 439563773,
        "latitude": 40.7662523,
        "longitude": -73.9822405,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-10T22:02:25.589Z",
        "type": "Periodical"
    },
    {
        "id": 439540847,
        "latitude": 40.7662503,
        "longitude": -73.9822648,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-08-10T21:17:29.667Z",
        "type": "Periodical"
    },
    {
        "id": 439528854,
        "latitude": 40.7662485,
        "longitude": -73.9822514,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-10T20:53:07.867Z",
        "type": "Periodical"
    },
    {
        "id": 439514044,
        "latitude": 40.7662511,
        "longitude": -73.9822767,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-08-10T20:24:29.416Z",
        "type": "Periodical"
    },
    {
        "id": 439496106,
        "latitude": 40.7661349,
        "longitude": -73.9818412,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-10T19:48:29.310Z",
        "type": "Periodical"
    },
    {
        "id": 439479569,
        "latitude": 40.7663142,
        "longitude": -73.9826417,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-10T19:14:29.223Z",
        "type": "Periodical"
    },
    {
        "id": 439468792,
        "latitude": 40.7662993,
        "longitude": -73.9825726,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-10T18:51:23.972Z",
        "type": "Periodical"
    },
    {
        "id": 439454932,
        "latitude": 40.7663585,
        "longitude": -73.9828041,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-10T18:22:16.076Z",
        "type": "Periodical"
    },
    {
        "id": 439443467,
        "latitude": 40.76626,
        "longitude": -73.9825234,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-10T17:58:29.001Z",
        "type": "Periodical"
    },
    {
        "id": 439424843,
        "latitude": 40.7663665,
        "longitude": -73.9825046,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-10T17:19:28.848Z",
        "type": "Periodical"
    },
    {
        "id": 439415420,
        "latitude": 40.7664206,
        "longitude": -73.9828162,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-10T16:59:28.718Z",
        "type": "Periodical"
    },
    {
        "id": 439404675,
        "latitude": 40.7588723,
        "longitude": -73.9532614,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-10T16:36:47.775Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 439366718,
        "latitude": 40.7627805,
        "longitude": -73.9494989,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-10T15:13:27.277Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 439354312,
        "latitude": 40.7436314,
        "longitude": -73.9209123,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-10T14:46:28.118Z",
        "type": "Periodical"
    },
    {
        "id": 439338805,
        "latitude": 40.7437053,
        "longitude": -73.9209202,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-10T14:11:06.305Z",
        "type": "Periodical"
    },
    {
        "id": 439326363,
        "latitude": 40.7436502,
        "longitude": -73.9209185,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-10T13:43:19.061Z",
        "type": "Periodical"
    },
    {
        "id": 439314644,
        "latitude": 40.7437793,
        "longitude": -73.9208768,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-08-10T13:15:35.235Z",
        "type": "Periodical"
    },
    {
        "id": 439300988,
        "latitude": 40.7436496,
        "longitude": -73.9208858,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-10T12:42:07.373Z",
        "type": "Periodical"
    },
    {
        "id": 439290452,
        "latitude": 40.743612,
        "longitude": -73.9209035,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-10T12:15:00.287Z",
        "type": "Periodical"
    },
    {
        "id": 439280117,
        "latitude": 40.7464178,
        "longitude": -73.9172459,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-10T11:49:07.203Z",
        "type": "Periodical"
    },
    {
        "id": 439270130,
        "latitude": 40.7616252,
        "longitude": -73.9349698,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-10T11:21:02.574Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 439003426,
        "latitude": 40.763158,
        "longitude": -73.9489804,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-08-10T00:29:05.425Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 438994832,
        "latitude": 40.7721859,
        "longitude": -73.9257914,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-10T00:11:47.269Z",
        "type": "Periodical"
    },
    {
        "id": 438976879,
        "latitude": 40.953868,
        "longitude": -73.9500286,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-09T23:35:27.309Z",
        "type": "Periodical"
    },
    {
        "id": 438963843,
        "latitude": 40.9537234,
        "longitude": -73.9498658,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-08-09T23:09:10.309Z",
        "type": "Periodical"
    },
    {
        "id": 438943503,
        "latitude": 40.9542661,
        "longitude": -73.9502579,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-09T22:29:46.812Z",
        "type": "Periodical"
    },
    {
        "id": 438932629,
        "latitude": 40.9542661,
        "longitude": -73.9502579,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-09T22:07:58.883Z",
        "type": "Periodical"
    },
    {
        "id": 438922042,
        "latitude": 40.9538791,
        "longitude": -73.9500088,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-09T21:47:46.520Z",
        "type": "Periodical"
    },
    {
        "id": 438906121,
        "latitude": 40.9538791,
        "longitude": -73.9500088,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-09T21:17:46.518Z",
        "type": "Periodical"
    },
    {
        "id": 438895450,
        "latitude": 40.9538814,
        "longitude": -73.9500102,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-09T20:56:35.841Z",
        "type": "Periodical"
    },
    {
        "id": 438877048,
        "latitude": 40.8171241,
        "longitude": -73.9602089,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-09T20:21:46.327Z",
        "type": "Periodical"
    },
    {
        "id": 438856482,
        "latitude": 40.7733953,
        "longitude": -73.9168357,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-08-09T19:42:46.175Z",
        "type": "Periodical"
    },
    {
        "id": 438849115,
        "latitude": 40.7692953,
        "longitude": -73.9353621,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-09T19:28:20.162Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 438424825,
        "latitude": 40.7629286,
        "longitude": -73.9494784,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-09T02:10:11.834Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 438415007,
        "latitude": 40.7653461,
        "longitude": -73.9796536,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-09T01:49:45.540Z",
        "type": "Periodical"
    },
    {
        "id": 438414327,
        "latitude": 40.76598,
        "longitude": -73.9811383,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-09T01:47:33.336Z",
        "type": "Periodical"
    },
    {
        "id": 438402255,
        "latitude": 40.7663014,
        "longitude": -73.9826542,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-09T01:24:04.203Z",
        "type": "Periodical"
    },
    {
        "id": 438383127,
        "latitude": 40.7663655,
        "longitude": -73.982483,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-09T00:45:04.033Z",
        "type": "Periodical"
    },
    {
        "id": 438370305,
        "latitude": 40.766271,
        "longitude": -73.9824609,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-09T00:19:03.948Z",
        "type": "Periodical"
    },
    {
        "id": 438358704,
        "latitude": 40.7663255,
        "longitude": -73.9826336,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-08T23:55:03.886Z",
        "type": "Periodical"
    },
    {
        "id": 438345908,
        "latitude": 40.7663431,
        "longitude": -73.9827611,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-08T23:28:03.936Z",
        "type": "Periodical"
    },
    {
        "id": 438335819,
        "latitude": 40.7661046,
        "longitude": -73.9813667,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-08T23:08:03.767Z",
        "type": "Periodical"
    },
    {
        "id": 438325290,
        "latitude": 40.7662916,
        "longitude": -73.9824895,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-08T22:46:49.309Z",
        "type": "Periodical"
    },
    {
        "id": 438307331,
        "latitude": 40.7664063,
        "longitude": -73.9829006,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-08T22:12:03.543Z",
        "type": "Periodical"
    },
    {
        "id": 438292062,
        "latitude": 40.7663022,
        "longitude": -73.9825558,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-08T21:42:03.594Z",
        "type": "Periodical"
    },
    {
        "id": 438274294,
        "latitude": 40.7663311,
        "longitude": -73.9826345,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-08T21:06:03.263Z",
        "type": "Periodical"
    },
    {
        "id": 438263790,
        "latitude": 40.7662877,
        "longitude": -73.9825441,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-08T20:45:55.709Z",
        "type": "Periodical"
    },
    {
        "id": 438249621,
        "latitude": 40.7660245,
        "longitude": -73.9812601,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-08T20:19:02.717Z",
        "type": "Periodical"
    },
    {
        "id": 438231723,
        "latitude": 40.7443142,
        "longitude": -73.9184739,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-08T19:42:45.078Z",
        "type": "Periodical"
    },
    {
        "id": 438219954,
        "latitude": 40.7435018,
        "longitude": -73.9194677,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-08T19:18:03.031Z",
        "type": "Periodical"
    },
    {
        "id": 438205247,
        "latitude": 40.7447356,
        "longitude": -73.9183088,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-08T18:49:02.865Z",
        "type": "Periodical"
    },
    {
        "id": 438193203,
        "latitude": 40.7624303,
        "longitude": -73.9422038,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-08T18:23:02.686Z",
        "type": "Periodical"
    },
    {
        "id": 438181166,
        "latitude": 40.7674094,
        "longitude": -73.9387209,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-08T17:57:02.505Z",
        "type": "Periodical"
    },
    {
        "id": 438167471,
        "latitude": 40.7677633,
        "longitude": -73.9378332,
        "accuracy": 99,
        "source": "Fused",
        "ts": "2024-08-08T17:27:02.388Z",
        "type": "Periodical"
    },
    {
        "id": 438153195,
        "latitude": 40.7447251,
        "longitude": -73.918324,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-08T16:56:02.346Z",
        "type": "Periodical"
    },
    {
        "id": 438139200,
        "latitude": 40.758809,
        "longitude": -73.9525968,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-08T16:25:55.052Z",
        "type": "Periodical"
    },
    {
        "id": 438132450,
        "latitude": 40.7608276,
        "longitude": -73.951103,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-08T16:10:08.535Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 437748379,
        "latitude": 40.7623287,
        "longitude": -73.9498342,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-08T00:17:19.382Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 437735267,
        "latitude": 40.7638355,
        "longitude": -73.9775835,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-07T23:49:48.519Z",
        "type": "Periodical"
    },
    {
        "id": 437715181,
        "latitude": 40.7661826,
        "longitude": -73.9817744,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-08-07T23:10:48.394Z",
        "type": "Periodical"
    },
    {
        "id": 437703797,
        "latitude": 40.7663042,
        "longitude": -73.9824548,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-07T22:46:25.575Z",
        "type": "Periodical"
    },
    {
        "id": 437692474,
        "latitude": 40.7662969,
        "longitude": -73.9826269,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-07T22:23:48.031Z",
        "type": "Periodical"
    },
    {
        "id": 437679272,
        "latitude": 40.7662932,
        "longitude": -73.9825551,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-07T21:56:47.895Z",
        "type": "Periodical"
    },
    {
        "id": 437662447,
        "latitude": 40.7662677,
        "longitude": -73.9824172,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-07T21:23:47.768Z",
        "type": "Periodical"
    },
    {
        "id": 437645096,
        "latitude": 40.7662805,
        "longitude": -73.9823967,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-07T20:51:47.606Z",
        "type": "Periodical"
    },
    {
        "id": 437628953,
        "latitude": 40.7662421,
        "longitude": -73.9822153,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-08-07T20:20:47.487Z",
        "type": "Periodical"
    },
    {
        "id": 437619355,
        "latitude": 40.7662738,
        "longitude": -73.9823417,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-07T20:01:47.289Z",
        "type": "Periodical"
    },
    {
        "id": 437603533,
        "latitude": 40.7663088,
        "longitude": -73.9823732,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-08-07T19:29:47.193Z",
        "type": "Periodical"
    },
    {
        "id": 437587242,
        "latitude": 40.7662678,
        "longitude": -73.9823866,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-07T18:54:47.277Z",
        "type": "Periodical"
    },
    {
        "id": 437568998,
        "latitude": 40.766257,
        "longitude": -73.9823671,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-07T18:14:47.372Z",
        "type": "Periodical"
    },
    {
        "id": 437557635,
        "latitude": 40.7640132,
        "longitude": -73.9774417,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-07T17:50:14.307Z",
        "type": "Periodical"
    },
    {
        "id": 437552820,
        "latitude": 40.7590777,
        "longitude": -73.9531467,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-08-07T17:39:54.924Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 437206547,
        "latitude": 40.7621866,
        "longitude": -73.9500413,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-07T03:01:44.363Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 437196992,
        "latitude": 40.7545848,
        "longitude": -73.9843669,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-07T02:41:31.175Z",
        "type": "Periodical"
    },
    {
        "id": 437177459,
        "latitude": 40.7431267,
        "longitude": -73.9184331,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-07T02:01:11.385Z",
        "type": "Periodical"
    },
    {
        "id": 437160178,
        "latitude": 40.7445656,
        "longitude": -73.9193046,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-07T01:28:31.025Z",
        "type": "Periodical"
    },
    {
        "id": 437139879,
        "latitude": 40.7445841,
        "longitude": -73.9193051,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-07T00:48:30.781Z",
        "type": "Periodical"
    },
    {
        "id": 437125276,
        "latitude": 40.7445841,
        "longitude": -73.9193051,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-07T00:18:30.778Z",
        "type": "Periodical"
    },
    {
        "id": 437106127,
        "latitude": 40.7445684,
        "longitude": -73.9193019,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-06T23:45:30.739Z",
        "type": "Periodical"
    },
    {
        "id": 437087645,
        "latitude": 40.744557,
        "longitude": -73.9193099,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-06T23:08:30.646Z",
        "type": "Periodical"
    },
    {
        "id": 437069162,
        "latitude": 40.7648815,
        "longitude": -73.9805207,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-06T22:32:10.590Z",
        "type": "Periodical"
    },
    {
        "id": 437056533,
        "latitude": 40.7664223,
        "longitude": -73.9828052,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-06T22:07:15.105Z",
        "type": "Periodical"
    },
    {
        "id": 437043121,
        "latitude": 40.7662914,
        "longitude": -73.9826025,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-06T21:38:37.273Z",
        "type": "Periodical"
    },
    {
        "id": 437031133,
        "latitude": 40.7663367,
        "longitude": -73.9827386,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-06T21:14:03.147Z",
        "type": "Periodical"
    },
    {
        "id": 437015567,
        "latitude": 40.7662909,
        "longitude": -73.9821265,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:33.459Z",
        "type": "Periodical"
    },
    {
        "id": 437015601,
        "latitude": 40.7662909,
        "longitude": -73.9821265,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:33.459Z",
        "type": "Periodical"
    },
    {
        "id": 437015534,
        "latitude": 40.7663023,
        "longitude": -73.9821806,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-06T20:42:31.197Z",
        "type": "Periodical"
    },
    {
        "id": 437015556,
        "latitude": 40.7663023,
        "longitude": -73.9821806,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-06T20:42:31.197Z",
        "type": "Periodical"
    },
    {
        "id": 437015544,
        "latitude": 40.7663023,
        "longitude": -73.9821806,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-06T20:42:31.197Z",
        "type": "Periodical"
    },
    {
        "id": 437015512,
        "latitude": 40.7663501,
        "longitude": -73.9822721,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:21.170Z",
        "type": "Periodical"
    },
    {
        "id": 437015471,
        "latitude": 40.7663501,
        "longitude": -73.9822721,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:21.170Z",
        "type": "Periodical"
    },
    {
        "id": 437015526,
        "latitude": 40.7663501,
        "longitude": -73.9822721,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:21.170Z",
        "type": "Periodical"
    },
    {
        "id": 437015465,
        "latitude": 40.7663609,
        "longitude": -73.9822702,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-06T20:42:21.126Z",
        "type": "Periodical"
    },
    {
        "id": 437015443,
        "latitude": 40.7663609,
        "longitude": -73.9822702,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-06T20:42:21.126Z",
        "type": "Periodical"
    },
    {
        "id": 437015408,
        "latitude": 40.7662792,
        "longitude": -73.9822206,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-06T20:42:11.325Z",
        "type": "Periodical"
    },
    {
        "id": 437015438,
        "latitude": 40.7662792,
        "longitude": -73.9822206,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-06T20:42:11.325Z",
        "type": "Periodical"
    },
    {
        "id": 437015395,
        "latitude": 40.7662792,
        "longitude": -73.9822206,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-06T20:42:11.325Z",
        "type": "Periodical"
    },
    {
        "id": 437015372,
        "latitude": 40.7662812,
        "longitude": -73.9822189,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-06T20:42:10.894Z",
        "type": "Periodical"
    },
    {
        "id": 437015356,
        "latitude": 40.7663391,
        "longitude": -73.9822645,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-06T20:42:00.965Z",
        "type": "Periodical"
    },
    {
        "id": 437015312,
        "latitude": 40.7663391,
        "longitude": -73.9822645,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-06T20:42:00.965Z",
        "type": "Periodical"
    },
    {
        "id": 437015319,
        "latitude": 40.7663391,
        "longitude": -73.9822645,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-06T20:42:00.965Z",
        "type": "Periodical"
    },
    {
        "id": 437015280,
        "latitude": 40.766355,
        "longitude": -73.9822132,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:00.937Z",
        "type": "Periodical"
    },
    {
        "id": 437015270,
        "latitude": 40.766355,
        "longitude": -73.9822132,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T20:42:00.937Z",
        "type": "Periodical"
    },
    {
        "id": 437015185,
        "latitude": 40.7663482,
        "longitude": -73.9820964,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-08-06T20:41:41.198Z",
        "type": "Periodical"
    },
    {
        "id": 437015212,
        "latitude": 40.7663482,
        "longitude": -73.9820964,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-08-06T20:41:41.198Z",
        "type": "Periodical"
    },
    {
        "id": 437015248,
        "latitude": 40.7663482,
        "longitude": -73.9820964,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-08-06T20:41:41.198Z",
        "type": "Periodical"
    },
    {
        "id": 437014411,
        "latitude": 40.7661123,
        "longitude": -73.9816857,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-08-06T20:39:52.865Z",
        "type": "Periodical"
    },
    {
        "id": 437012078,
        "latitude": 40.7661546,
        "longitude": -73.9818491,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-06T20:35:43.148Z",
        "type": "Periodical"
    },
    {
        "id": 436999105,
        "latitude": 40.7662576,
        "longitude": -73.9822497,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-06T20:09:42.950Z",
        "type": "Periodical"
    },
    {
        "id": 436982318,
        "latitude": 40.7662722,
        "longitude": -73.9822509,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-06T19:36:22.525Z",
        "type": "Periodical"
    },
    {
        "id": 436968701,
        "latitude": 40.7661426,
        "longitude": -73.9820606,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-06T19:08:43.085Z",
        "type": "Periodical"
    },
    {
        "id": 436951770,
        "latitude": 40.7663154,
        "longitude": -73.9826709,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-06T18:33:23.596Z",
        "type": "Periodical"
    },
    {
        "id": 436934070,
        "latitude": 40.7662741,
        "longitude": -73.9825181,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-06T17:55:42.678Z",
        "type": "Periodical"
    },
    {
        "id": 436918298,
        "latitude": 40.7662789,
        "longitude": -73.9824659,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-06T17:21:42.582Z",
        "type": "Periodical"
    },
    {
        "id": 436905432,
        "latitude": 40.7663076,
        "longitude": -73.9826162,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-06T16:53:42.739Z",
        "type": "Periodical"
    },
    {
        "id": 436895059,
        "latitude": 40.7662651,
        "longitude": -73.9823473,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-06T16:30:42.354Z",
        "type": "Periodical"
    },
    {
        "id": 436880192,
        "latitude": 40.7587209,
        "longitude": -73.9531495,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-06T15:58:41.060Z",
        "type": "Periodical"
    },
    {
        "id": 436877957,
        "latitude": 40.7607234,
        "longitude": -73.9512183,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-06T15:53:51.171Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 436464807,
        "latitude": 40.7620471,
        "longitude": -73.9503343,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-08-05T23:21:37.048Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 436460785,
        "latitude": 40.7590274,
        "longitude": -73.9534016,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-05T23:13:35.783Z",
        "type": "Periodical"
    },
    {
        "id": 436441440,
        "latitude": 40.7423562,
        "longitude": -73.9914281,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-08-05T22:35:36.348Z",
        "type": "Periodical"
    },
    {
        "id": 436425325,
        "latitude": 40.7418588,
        "longitude": -73.9862561,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-05T22:03:13.992Z",
        "type": "Periodical"
    },
    {
        "id": 436406667,
        "latitude": 40.7446339,
        "longitude": -73.9928528,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-05T21:27:13.192Z",
        "type": "Periodical"
    },
    {
        "id": 436406282,
        "latitude": 40.7445268,
        "longitude": -73.991885,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-05T21:26:22.193Z",
        "type": "Periodical"
    },
    {
        "id": 436406269,
        "latitude": 40.7445112,
        "longitude": -73.991724,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-05T21:26:08.265Z",
        "type": "Periodical"
    },
    {
        "id": 436406254,
        "latitude": 40.7445928,
        "longitude": -73.9913308,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-05T21:25:54.257Z",
        "type": "Periodical"
    },
    {
        "id": 436405727,
        "latitude": 40.7436703,
        "longitude": -73.9926328,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-05T21:24:43.246Z",
        "type": "Periodical"
    },
    {
        "id": 436405680,
        "latitude": 40.7440326,
        "longitude": -73.9920989,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-08-05T21:24:12.172Z",
        "type": "Periodical"
    },
    {
        "id": 436389755,
        "latitude": 40.761144,
        "longitude": -73.9503189,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-08-05T20:53:07.109Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 436372035,
        "latitude": 40.763105,
        "longitude": -73.9489847,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-05T20:16:47.660Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 436372034,
        "latitude": 40.763105,
        "longitude": -73.9489847,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-05T20:16:47.660Z",
        "type": "Periodical"
    },
    {
        "id": 436360712,
        "latitude": 40.7665364,
        "longitude": -73.9513178,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-08-05T19:55:47.556Z",
        "type": "Periodical"
    },
    {
        "id": 436340414,
        "latitude": 40.7662931,
        "longitude": -73.9461889,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-05T19:15:43.349Z",
        "type": "Periodical"
    },
    {
        "id": 436321659,
        "latitude": 40.7666448,
        "longitude": -73.9467331,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-05T18:38:47.568Z",
        "type": "Periodical"
    },
    {
        "id": 436311272,
        "latitude": 40.7648246,
        "longitude": -73.9473261,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-05T18:17:11.341Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 436262444,
        "latitude": 40.7622601,
        "longitude": -73.9509113,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-05T16:34:12.522Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 436253834,
        "latitude": 40.7608396,
        "longitude": -73.9511315,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-05T16:16:15.559Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 435844127,
        "latitude": 40.7624139,
        "longitude": -73.9497832,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-04T23:58:09.796Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 435836751,
        "latitude": 40.765296,
        "longitude": -73.9475844,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-04T23:41:47.857Z",
        "type": "Periodical"
    },
    {
        "id": 435826815,
        "latitude": 40.7652862,
        "longitude": -73.9475892,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-04T23:22:16.480Z",
        "type": "Periodical"
    },
    {
        "id": 435813519,
        "latitude": 40.7651363,
        "longitude": -73.9473241,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-04T22:54:16.247Z",
        "type": "Periodical"
    },
    {
        "id": 435803541,
        "latitude": 40.7651363,
        "longitude": -73.9473241,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-08-04T22:33:27.451Z",
        "type": "Periodical"
    },
    {
        "id": 435790218,
        "latitude": 40.7652765,
        "longitude": -73.9475867,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-04T22:05:16.133Z",
        "type": "Periodical"
    },
    {
        "id": 435779884,
        "latitude": 40.7652746,
        "longitude": -73.9475873,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-04T21:44:15.975Z",
        "type": "Periodical"
    },
    {
        "id": 435761524,
        "latitude": 40.7652862,
        "longitude": -73.9475911,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-04T21:06:00.418Z",
        "type": "Periodical"
    },
    {
        "id": 435749993,
        "latitude": 40.7652827,
        "longitude": -73.9475843,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-04T20:42:15.709Z",
        "type": "Periodical"
    },
    {
        "id": 435731069,
        "latitude": 40.7662687,
        "longitude": -73.9462313,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-04T20:04:15.583Z",
        "type": "Periodical"
    },
    {
        "id": 435721311,
        "latitude": 40.766659,
        "longitude": -73.9466832,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-04T19:40:03.094Z",
        "type": "Periodical"
    },
    {
        "id": 435703392,
        "latitude": 40.7666185,
        "longitude": -73.9466749,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-08-04T19:08:45.801Z",
        "type": "Periodical"
    },
    {
        "id": 435701821,
        "latitude": 40.7662753,
        "longitude": -73.9467343,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-04T19:05:31.091Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 435288803,
        "latitude": 40.7625058,
        "longitude": -73.9498679,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-04T02:19:04.501Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 435288802,
        "latitude": 40.7599533,
        "longitude": -73.9520415,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-04T02:13:36.591Z",
        "type": "Periodical"
    },
    {
        "id": 435277071,
        "latitude": 40.766244,
        "longitude": -73.9822199,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-08-04T01:40:31.693Z",
        "type": "Periodical"
    },
    {
        "id": 435277070,
        "latitude": 40.7662851,
        "longitude": -73.9822917,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-04T01:27:00.860Z",
        "type": "Periodical"
    },
    {
        "id": 435277069,
        "latitude": 40.7662851,
        "longitude": -73.9822917,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-04T00:05:18.289Z",
        "type": "Periodical"
    },
    {
        "id": 435277068,
        "latitude": 40.7662494,
        "longitude": -73.9822457,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-03T23:49:05.280Z",
        "type": "Periodical"
    },
    {
        "id": 435277067,
        "latitude": 40.7662502,
        "longitude": -73.9822626,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-08-03T22:56:15.219Z",
        "type": "Periodical"
    },
    {
        "id": 435277066,
        "latitude": 40.7661416,
        "longitude": -73.9819566,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-03T22:49:35.978Z",
        "type": "Periodical"
    },
    {
        "id": 435277065,
        "latitude": 40.7663768,
        "longitude": -73.9828562,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-03T22:32:16.097Z",
        "type": "Periodical"
    },
    {
        "id": 435277064,
        "latitude": 40.7665318,
        "longitude": -73.9830173,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-08-03T22:03:04.609Z",
        "type": "Periodical"
    },
    {
        "id": 435277063,
        "latitude": 40.7664031,
        "longitude": -73.9829011,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-08-03T21:58:23.878Z",
        "type": "Periodical"
    },
    {
        "id": 435153874,
        "latitude": 40.7666165,
        "longitude": -73.9830873,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-03T21:40:37.491Z",
        "type": "Periodical"
    },
    {
        "id": 435141892,
        "latitude": 40.7667103,
        "longitude": -73.9830795,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-03T21:17:20.238Z",
        "type": "Periodical"
    },
    {
        "id": 435121894,
        "latitude": 40.7597882,
        "longitude": -73.9526467,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-03T20:36:15.108Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 435122097,
        "latitude": 40.7597882,
        "longitude": -73.9526467,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-08-03T20:36:15.108Z",
        "type": "Periodical"
    },
    {
        "id": 435071439,
        "latitude": 40.7633211,
        "longitude": -73.9487299,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-03T18:57:59.288Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 435057773,
        "latitude": 40.766275,
        "longitude": -73.9463588,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-08-03T18:29:09.790Z",
        "type": "Periodical"
    },
    {
        "id": 435039211,
        "latitude": 40.7662516,
        "longitude": -73.946972,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-08-03T17:50:10.384Z",
        "type": "Periodical"
    },
    {
        "id": 435028100,
        "latitude": 40.7661103,
        "longitude": -73.9468621,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-03T17:28:06.420Z",
        "type": "Periodical"
    },
    {
        "id": 435018324,
        "latitude": 40.7665442,
        "longitude": -73.9466945,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-08-03T17:07:46.153Z",
        "type": "Periodical"
    },
    {
        "id": 435003028,
        "latitude": 40.7664471,
        "longitude": -73.9465679,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-08-03T16:35:31.054Z",
        "type": "Periodical"
    },
    {
        "id": 435001004,
        "latitude": 40.7649576,
        "longitude": -73.9474452,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-08-03T16:31:30.973Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 434993956,
        "latitude": 40.7628852,
        "longitude": -73.9494576,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-03T16:18:48.137Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 434985423,
        "latitude": 40.7646015,
        "longitude": -73.9466335,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-03T16:00:51.002Z",
        "type": "Periodical"
    },
    {
        "id": 434984831,
        "latitude": 40.764616,
        "longitude": -73.9466368,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-08-03T15:59:34.104Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 434535149,
        "latitude": 40.7633579,
        "longitude": -73.9487185,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-02T22:05:14.029Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 434533367,
        "latitude": 40.764567,
        "longitude": -73.9467844,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-02T22:01:47.434Z",
        "type": "Periodical"
    },
    {
        "id": 434514539,
        "latitude": 40.7207362,
        "longitude": -73.8660429,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-02T21:25:12.112Z",
        "type": "Periodical"
    },
    {
        "id": 434498630,
        "latitude": 40.6616011,
        "longitude": -73.8401622,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-08-02T20:55:11.818Z",
        "type": "Periodical"
    },
    {
        "id": 434477961,
        "latitude": 40.5807468,
        "longitude": -73.8267134,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-08-02T20:13:33.916Z",
        "type": "Periodical"
    },
    {
        "id": 434469652,
        "latitude": 40.5804866,
        "longitude": -73.8278313,
        "accuracy": 93,
        "source": "Fused",
        "ts": "2024-08-02T19:55:53.195Z",
        "type": "Periodical"
    },
    {
        "id": 434455825,
        "latitude": 40.5799139,
        "longitude": -73.825227,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T19:28:00.346Z",
        "type": "Periodical"
    },
    {
        "id": 434438607,
        "latitude": 40.5799139,
        "longitude": -73.825227,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T18:53:53.069Z",
        "type": "Periodical"
    },
    {
        "id": 434423143,
        "latitude": 40.5824025,
        "longitude": -73.8252043,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T18:21:57.628Z",
        "type": "Periodical"
    },
    {
        "id": 434403284,
        "latitude": 40.5798939,
        "longitude": -73.8252301,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T17:41:18.351Z",
        "type": "Periodical"
    },
    {
        "id": 434386248,
        "latitude": 40.5809203,
        "longitude": -73.8267269,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T17:04:28.884Z",
        "type": "Periodical"
    },
    {
        "id": 434372370,
        "latitude": 40.5809203,
        "longitude": -73.8267269,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T16:34:28.883Z",
        "type": "Periodical"
    },
    {
        "id": 434356439,
        "latitude": 40.5813858,
        "longitude": -73.8256106,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-08-02T16:00:29.834Z",
        "type": "Periodical"
    },
    {
        "id": 434340681,
        "latitude": 40.5813279,
        "longitude": -73.8261267,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T15:25:10.260Z",
        "type": "Periodical"
    },
    {
        "id": 434322701,
        "latitude": 40.5809374,
        "longitude": -73.8266691,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-02T14:44:08.564Z",
        "type": "Periodical"
    },
    {
        "id": 434306111,
        "latitude": 40.5811965,
        "longitude": -73.8258511,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-08-02T14:05:22.433Z",
        "type": "Periodical"
    },
    {
        "id": 434289275,
        "latitude": 40.6645572,
        "longitude": -73.8183697,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-08-02T13:24:56.266Z",
        "type": "Periodical"
    },
    {
        "id": 434273026,
        "latitude": 40.7692669,
        "longitude": -73.9172392,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-02T12:55:06.112Z",
        "type": "Periodical"
    },
    {
        "id": 434262184,
        "latitude": 40.7625408,
        "longitude": -73.9441267,
        "accuracy": 81,
        "source": "Fused",
        "ts": "2024-08-02T12:26:45.763Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 434262094,
        "latitude": 40.7626492,
        "longitude": -73.9486374,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-08-02T12:26:28.886Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 434261945,
        "latitude": 40.7644845,
        "longitude": -73.9473871,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-02T12:26:08.894Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 434262006,
        "latitude": 40.7644845,
        "longitude": -73.9473871,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-02T12:26:08.894Z",
        "type": "Periodical"
    },
    {
        "id": 434030317,
        "latitude": 40.761855,
        "longitude": -73.9498614,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-02T02:19:51.816Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 434023645,
        "latitude": 40.7634984,
        "longitude": -73.9778016,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-02T02:06:24.004Z",
        "type": "Periodical"
    },
    {
        "id": 434007879,
        "latitude": 40.7662909,
        "longitude": -73.9826485,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-02T01:34:43.635Z",
        "type": "Periodical"
    },
    {
        "id": 433996454,
        "latitude": 40.7662729,
        "longitude": -73.9824289,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-08-02T01:11:07.579Z",
        "type": "Periodical"
    },
    {
        "id": 433979402,
        "latitude": 40.7662902,
        "longitude": -73.9825027,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-02T00:40:51.060Z",
        "type": "Periodical"
    },
    {
        "id": 433961984,
        "latitude": 40.7659726,
        "longitude": -73.9816276,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-08-02T00:05:07.819Z",
        "type": "Periodical"
    },
    {
        "id": 433947058,
        "latitude": 40.7662818,
        "longitude": -73.9824761,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-08-01T23:36:42.785Z",
        "type": "Periodical"
    },
    {
        "id": 433931685,
        "latitude": 40.7662702,
        "longitude": -73.9825069,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-08-01T23:05:58.458Z",
        "type": "Periodical"
    },
    {
        "id": 433931653,
        "latitude": 40.7663148,
        "longitude": -73.9825086,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-01T23:05:41.799Z",
        "type": "Periodical"
    },
    {
        "id": 433916240,
        "latitude": 40.7662848,
        "longitude": -73.9825082,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-01T22:37:00.748Z",
        "type": "Periodical"
    },
    {
        "id": 433898548,
        "latitude": 40.7662893,
        "longitude": -73.9825912,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-08-01T22:04:15.880Z",
        "type": "Periodical"
    },
    {
        "id": 433883792,
        "latitude": 40.7662845,
        "longitude": -73.9825579,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-08-01T21:35:03.667Z",
        "type": "Periodical"
    },
    {
        "id": 433867136,
        "latitude": 40.7662841,
        "longitude": -73.9825282,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-08-01T21:01:45.028Z",
        "type": "Periodical"
    },
    {
        "id": 433854021,
        "latitude": 40.7661359,
        "longitude": -73.9815733,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-08-01T20:36:20.053Z",
        "type": "Periodical"
    },
    {
        "id": 433838222,
        "latitude": 40.7595396,
        "longitude": -73.9525117,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-08-01T20:05:29.352Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 433824638,
        "latitude": 40.7626549,
        "longitude": -73.9496177,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-08-01T19:39:38.975Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 433823123,
        "latitude": 40.7641576,
        "longitude": -73.9479086,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-01T19:36:45.924Z",
        "type": "Periodical"
    },
    {
        "id": 433812145,
        "latitude": 40.766406,
        "longitude": -73.9463832,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-08-01T19:15:12.410Z",
        "type": "Periodical"
    },
    {
        "id": 433810586,
        "latitude": 40.7662687,
        "longitude": -73.9468027,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-08-01T19:12:18.603Z",
        "type": "Periodical"
    },
    {
        "id": 433785766,
        "latitude": 40.7662665,
        "longitude": -73.9463339,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-08-01T18:20:20.408Z",
        "type": "Periodical"
    },
    {
        "id": 433775513,
        "latitude": 40.7662665,
        "longitude": -73.9463339,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-08-01T17:58:20.405Z",
        "type": "Periodical"
    },
    {
        "id": 433773674,
        "latitude": 40.76666,
        "longitude": -73.9467457,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-08-01T17:54:18.648Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 433332772,
        "latitude": 40.7624184,
        "longitude": -73.9497709,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-08-01T00:19:08.866Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 433326594,
        "latitude": 40.7587111,
        "longitude": -73.9531237,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-08-01T00:06:26.653Z",
        "type": "Periodical"
    },
    {
        "id": 433307382,
        "latitude": 40.7662868,
        "longitude": -73.9825168,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-31T23:29:53.379Z",
        "type": "Periodical"
    },
    {
        "id": 433291248,
        "latitude": 40.7663915,
        "longitude": -73.9828696,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-31T22:58:54.416Z",
        "type": "Periodical"
    },
    {
        "id": 433280659,
        "latitude": 40.7662713,
        "longitude": -73.9822334,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-31T22:37:39.088Z",
        "type": "Periodical"
    },
    {
        "id": 433260908,
        "latitude": 40.7664422,
        "longitude": -73.9829669,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-31T21:57:17.862Z",
        "type": "Periodical"
    },
    {
        "id": 433241675,
        "latitude": 40.7662787,
        "longitude": -73.9824454,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-31T21:18:45.065Z",
        "type": "Periodical"
    },
    {
        "id": 433230856,
        "latitude": 40.7662916,
        "longitude": -73.9825769,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-31T20:57:00.503Z",
        "type": "Periodical"
    },
    {
        "id": 433214404,
        "latitude": 40.7662758,
        "longitude": -73.9824361,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-31T20:22:43.188Z",
        "type": "Periodical"
    },
    {
        "id": 433195223,
        "latitude": 40.7662759,
        "longitude": -73.9824262,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-31T19:47:45.106Z",
        "type": "Periodical"
    },
    {
        "id": 433184545,
        "latitude": 40.7663262,
        "longitude": -73.9825888,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-31T19:25:49.397Z",
        "type": "Periodical"
    },
    {
        "id": 433167809,
        "latitude": 40.7662686,
        "longitude": -73.9825414,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-31T18:52:20.146Z",
        "type": "Periodical"
    },
    {
        "id": 433157206,
        "latitude": 40.7662595,
        "longitude": -73.9824554,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-31T18:31:00.608Z",
        "type": "Periodical"
    },
    {
        "id": 433138780,
        "latitude": 40.765303,
        "longitude": -73.9798402,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-07-31T17:52:43.285Z",
        "type": "Periodical"
    },
    {
        "id": 433127289,
        "latitude": 40.7589459,
        "longitude": -73.9533714,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-31T17:28:21.031Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 432658021,
        "latitude": 40.7625996,
        "longitude": -73.949671,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-30T22:51:14.354Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 432655800,
        "latitude": 40.7602524,
        "longitude": -73.9519223,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-30T22:46:34.110Z",
        "type": "Periodical"
    },
    {
        "id": 432646737,
        "latitude": 40.7647374,
        "longitude": -73.9805723,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-30T22:28:45.067Z",
        "type": "Periodical"
    },
    {
        "id": 432646706,
        "latitude": 40.7647683,
        "longitude": -73.9805742,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-30T22:27:56.939Z",
        "type": "Periodical"
    },
    {
        "id": 432641420,
        "latitude": 40.766219,
        "longitude": -73.9811574,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-30T22:18:16.342Z",
        "type": "Periodical"
    },
    {
        "id": 432624271,
        "latitude": 40.7662973,
        "longitude": -73.9826052,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-30T21:43:00.692Z",
        "type": "Periodical"
    },
    {
        "id": 432610675,
        "latitude": 40.7662873,
        "longitude": -73.9825873,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-30T21:15:45.512Z",
        "type": "Periodical"
    },
    {
        "id": 432589131,
        "latitude": 40.7662589,
        "longitude": -73.9824212,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-30T20:32:19.460Z",
        "type": "Periodical"
    },
    {
        "id": 432579303,
        "latitude": 40.7662893,
        "longitude": -73.9825851,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-30T20:13:00.504Z",
        "type": "Periodical"
    },
    {
        "id": 432571050,
        "latitude": 40.7663059,
        "longitude": -73.9825922,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-30T19:56:38.940Z",
        "type": "Periodical"
    },
    {
        "id": 432554321,
        "latitude": 40.7662235,
        "longitude": -73.9820399,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-30T19:24:21.824Z",
        "type": "Periodical"
    },
    {
        "id": 432538903,
        "latitude": 40.7662882,
        "longitude": -73.9824524,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-30T18:53:51.243Z",
        "type": "Periodical"
    },
    {
        "id": 432525629,
        "latitude": 40.76629,
        "longitude": -73.9824276,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-30T18:27:00.799Z",
        "type": "Periodical"
    },
    {
        "id": 432511768,
        "latitude": 40.7662678,
        "longitude": -73.9824459,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-30T17:58:01.735Z",
        "type": "Periodical"
    },
    {
        "id": 432502646,
        "latitude": 40.7662868,
        "longitude": -73.9823833,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-07-30T17:38:01.096Z",
        "type": "Periodical"
    },
    {
        "id": 432492871,
        "latitude": 40.7662695,
        "longitude": -73.9824322,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-30T17:17:43.112Z",
        "type": "Periodical"
    },
    {
        "id": 432473228,
        "latitude": 40.7662372,
        "longitude": -73.982247,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-07-30T16:38:01.883Z",
        "type": "Periodical"
    },
    {
        "id": 432464474,
        "latitude": 40.766397,
        "longitude": -73.9824057,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-30T16:19:22.614Z",
        "type": "Periodical"
    },
    {
        "id": 432449467,
        "latitude": 40.7607973,
        "longitude": -73.9505658,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-30T15:47:16.409Z",
        "type": "Periodical"
    },
    {
        "id": 432441439,
        "latitude": 40.7608023,
        "longitude": -73.9505686,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-30T15:30:28.589Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 432100970,
        "latitude": 40.762497,
        "longitude": -73.9497436,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-30T01:15:42.126Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 432101113,
        "latitude": 40.762497,
        "longitude": -73.9497436,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-30T01:15:42.126Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 432084773,
        "latitude": 40.7661638,
        "longitude": -73.981687,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-30T00:43:37.381Z",
        "type": "Periodical"
    },
    {
        "id": 432068011,
        "latitude": 40.7663066,
        "longitude": -73.9826072,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-30T00:09:57.735Z",
        "type": "Periodical"
    },
    {
        "id": 432053292,
        "latitude": 40.7663028,
        "longitude": -73.9826628,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-29T23:40:32.347Z",
        "type": "Periodical"
    },
    {
        "id": 432041056,
        "latitude": 40.766402,
        "longitude": -73.982859,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-29T23:14:57.180Z",
        "type": "Periodical"
    },
    {
        "id": 432030496,
        "latitude": 40.7661298,
        "longitude": -73.9814645,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-07-29T22:54:28.944Z",
        "type": "Periodical"
    },
    {
        "id": 432030494,
        "latitude": 40.7661966,
        "longitude": -73.9822406,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-07-29T22:53:29.050Z",
        "type": "Periodical"
    },
    {
        "id": 432012016,
        "latitude": 40.7662691,
        "longitude": -73.9824142,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-29T22:16:58.896Z",
        "type": "Periodical"
    },
    {
        "id": 431995458,
        "latitude": 40.7661854,
        "longitude": -73.9822059,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-07-29T21:41:40.252Z",
        "type": "Periodical"
    },
    {
        "id": 431972777,
        "latitude": 40.7662676,
        "longitude": -73.9825512,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-29T20:57:52.937Z",
        "type": "Periodical"
    },
    {
        "id": 431960589,
        "latitude": 40.7663086,
        "longitude": -73.9825841,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-29T20:34:28.182Z",
        "type": "Periodical"
    },
    {
        "id": 431949361,
        "latitude": 40.7662102,
        "longitude": -73.9822888,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-29T20:12:02.243Z",
        "type": "Periodical"
    },
    {
        "id": 431938924,
        "latitude": 40.7662184,
        "longitude": -73.9822146,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-29T19:51:55.582Z",
        "type": "Periodical"
    },
    {
        "id": 431929157,
        "latitude": 40.7662184,
        "longitude": -73.9822146,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-29T19:31:55.580Z",
        "type": "Periodical"
    },
    {
        "id": 431913022,
        "latitude": 40.766215,
        "longitude": -73.9822753,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-29T18:59:43.795Z",
        "type": "Periodical"
    },
    {
        "id": 431900865,
        "latitude": 40.7594469,
        "longitude": -73.9529996,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-29T18:34:47.780Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 431866289,
        "latitude": 40.7615372,
        "longitude": -73.9501434,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-29T17:24:16.500Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 431857539,
        "latitude": 40.7529909,
        "longitude": -73.9649493,
        "accuracy": 97,
        "source": "Fused",
        "ts": "2024-07-29T17:07:01.840Z",
        "type": "Periodical"
    },
    {
        "id": 431843659,
        "latitude": 40.7586974,
        "longitude": -73.9528352,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-29T16:36:57.995Z",
        "type": "Periodical"
    },
    {
        "id": 431824453,
        "latitude": 40.7586884,
        "longitude": -73.9528349,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-29T15:57:36.361Z",
        "type": "Periodical"
    },
    {
        "id": 431815747,
        "latitude": 40.7608861,
        "longitude": -73.9510209,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-07-29T15:40:56.505Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 431544582,
        "latitude": 40.7625532,
        "longitude": -73.9493787,
        "accuracy": 161,
        "source": "Fused",
        "ts": "2024-07-29T03:54:02.896Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 431540529,
        "latitude": 40.7642937,
        "longitude": -73.9651349,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-29T03:44:54.686Z",
        "type": "Periodical"
    },
    {
        "id": 431526287,
        "latitude": 40.7647711,
        "longitude": -73.9664287,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-29T03:13:43.101Z",
        "type": "Periodical"
    },
    {
        "id": 431512734,
        "latitude": 40.8283089,
        "longitude": -73.9263814,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-29T02:42:38.114Z",
        "type": "Periodical"
    },
    {
        "id": 431496441,
        "latitude": 40.8293248,
        "longitude": -73.9255595,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-29T02:08:53.622Z",
        "type": "Periodical"
    },
    {
        "id": 431483549,
        "latitude": 40.8292925,
        "longitude": -73.9255642,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-29T01:40:58.045Z",
        "type": "Periodical"
    },
    {
        "id": 431483551,
        "latitude": 40.8292743,
        "longitude": -73.9255135,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-29T01:39:24.359Z",
        "type": "Periodical"
    },
    {
        "id": 431467504,
        "latitude": 40.8292855,
        "longitude": -73.9255047,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-29T01:07:59.698Z",
        "type": "Periodical"
    },
    {
        "id": 431450245,
        "latitude": 40.829296,
        "longitude": -73.9255606,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-07-29T00:31:28.991Z",
        "type": "Periodical"
    },
    {
        "id": 431439273,
        "latitude": 40.8292986,
        "longitude": -73.9255196,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-29T00:09:14.109Z",
        "type": "Periodical"
    },
    {
        "id": 431423839,
        "latitude": 40.8292764,
        "longitude": -73.925502,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-28T23:37:10.574Z",
        "type": "Periodical"
    },
    {
        "id": 431410756,
        "latitude": 40.8299447,
        "longitude": -73.9248978,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-28T23:10:20.835Z",
        "type": "Periodical"
    },
    {
        "id": 431399111,
        "latitude": 40.7798394,
        "longitude": -73.9553378,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-28T22:46:44.361Z",
        "type": "Periodical"
    },
    {
        "id": 431388582,
        "latitude": 40.7611379,
        "longitude": -73.954902,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-07-28T22:24:41.392Z",
        "type": "Periodical"
    },
    {
        "id": 431384725,
        "latitude": 40.7590111,
        "longitude": -73.9534232,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-28T22:16:32.796Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 431363519,
        "latitude": 40.7624642,
        "longitude": -73.949804,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-28T21:34:14.207Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 431354066,
        "latitude": 40.7664114,
        "longitude": -73.9465021,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-28T21:15:15.818Z",
        "type": "Periodical"
    },
    {
        "id": 431348747,
        "latitude": 40.7653535,
        "longitude": -73.947433,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-28T21:04:27.635Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 431337982,
        "latitude": 40.7626266,
        "longitude": -73.9497786,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-28T20:41:38.915Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 431337983,
        "latitude": 40.7626266,
        "longitude": -73.9497786,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-28T20:41:38.915Z",
        "type": "Periodical"
    },
    {
        "id": 431324719,
        "latitude": 40.7661387,
        "longitude": -73.9466732,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-07-28T20:13:49.541Z",
        "type": "Periodical"
    },
    {
        "id": 431315631,
        "latitude": 40.7662416,
        "longitude": -73.9468938,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-28T19:54:31.513Z",
        "type": "Periodical"
    },
    {
        "id": 431299395,
        "latitude": 40.7666097,
        "longitude": -73.9467668,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-28T19:22:57.694Z",
        "type": "Periodical"
    },
    {
        "id": 431295905,
        "latitude": 40.7641833,
        "longitude": -73.9478854,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-28T19:15:35.605Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430804434,
        "latitude": 40.7627583,
        "longitude": -73.9496616,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-27T23:12:38.734Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430790589,
        "latitude": 40.7665392,
        "longitude": -73.94667,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-27T22:42:24.355Z",
        "type": "Periodical"
    },
    {
        "id": 430776416,
        "latitude": 40.7661966,
        "longitude": -73.9468005,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-27T22:13:49.063Z",
        "type": "Periodical"
    },
    {
        "id": 430763201,
        "latitude": 40.7662709,
        "longitude": -73.9462338,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-07-27T21:47:41.313Z",
        "type": "Periodical"
    },
    {
        "id": 430749929,
        "latitude": 40.7667904,
        "longitude": -73.9466988,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-07-27T21:18:39.686Z",
        "type": "Periodical"
    },
    {
        "id": 430746435,
        "latitude": 40.7666025,
        "longitude": -73.9467508,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-27T21:11:21.126Z",
        "type": "Periodical"
    },
    {
        "id": 430734848,
        "latitude": 40.7649231,
        "longitude": -73.9466796,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-27T20:47:07.020Z",
        "type": "Periodical"
    },
    {
        "id": 430722016,
        "latitude": 40.7666274,
        "longitude": -73.9467048,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-27T20:20:36.895Z",
        "type": "Periodical"
    },
    {
        "id": 430714366,
        "latitude": 40.7662714,
        "longitude": -73.9463033,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-27T20:04:04.088Z",
        "type": "Periodical"
    },
    {
        "id": 430695652,
        "latitude": 40.7665238,
        "longitude": -73.9465633,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-27T19:24:12.133Z",
        "type": "Periodical"
    },
    {
        "id": 430680894,
        "latitude": 40.7665597,
        "longitude": -73.9466491,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-27T18:52:04.983Z",
        "type": "Periodical"
    },
    {
        "id": 430660291,
        "latitude": 40.7666098,
        "longitude": -73.9467543,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-27T18:08:33.471Z",
        "type": "Periodical"
    },
    {
        "id": 430657530,
        "latitude": 40.7643777,
        "longitude": -73.9476619,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T18:03:09.681Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430646191,
        "latitude": 40.7624182,
        "longitude": -73.9497386,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-27T17:38:36.921Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430645909,
        "latitude": 40.7634009,
        "longitude": -73.9410725,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-27T17:38:06.829Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430645799,
        "latitude": 40.7625994,
        "longitude": -73.948217,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:37:52.361Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430645758,
        "latitude": 40.7634192,
        "longitude": -73.9409549,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:37:47.374Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430644992,
        "latitude": 40.7625739,
        "longitude": -73.9479522,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:36:01.360Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430644833,
        "latitude": 40.763754,
        "longitude": -73.9391381,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:35:36.371Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430644780,
        "latitude": 40.7626023,
        "longitude": -73.9481185,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:35:31.362Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430644467,
        "latitude": 40.763841,
        "longitude": -73.9390007,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:34:56.371Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430644431,
        "latitude": 40.762637,
        "longitude": -73.9480304,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:34:51.367Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430644324,
        "latitude": 40.7638688,
        "longitude": -73.9389633,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:34:36.367Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430644280,
        "latitude": 40.7625939,
        "longitude": -73.9482328,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:34:31.366Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430644183,
        "latitude": 40.76392,
        "longitude": -73.9388917,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:34:16.370Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430644148,
        "latitude": 40.7626434,
        "longitude": -73.9478988,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:34:11.368Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430644046,
        "latitude": 40.763917,
        "longitude": -73.9389644,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:33:56.372Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430644018,
        "latitude": 40.7626009,
        "longitude": -73.9478963,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:33:51.352Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643984,
        "latitude": 40.7639081,
        "longitude": -73.9390227,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:33:46.363Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643954,
        "latitude": 40.7626352,
        "longitude": -73.9480806,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:33:41.394Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643923,
        "latitude": 40.7639237,
        "longitude": -73.938989,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:33:36.365Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643877,
        "latitude": 40.762636,
        "longitude": -73.9479189,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:33:31.369Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643831,
        "latitude": 40.7639599,
        "longitude": -73.9389176,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:33:26.363Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643796,
        "latitude": 40.7626561,
        "longitude": -73.9479999,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:33:21.366Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643760,
        "latitude": 40.7639736,
        "longitude": -73.9388872,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:33:16.366Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643734,
        "latitude": 40.7626109,
        "longitude": -73.94809,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:33:11.366Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643564,
        "latitude": 40.7639787,
        "longitude": -73.9389036,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:32:46.371Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643509,
        "latitude": 40.7627037,
        "longitude": -73.948015,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:32:41.375Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643460,
        "latitude": 40.7640121,
        "longitude": -73.9389106,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:32:36.375Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643430,
        "latitude": 40.7626677,
        "longitude": -73.9479003,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-27T17:32:31.368Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643325,
        "latitude": 40.7640332,
        "longitude": -73.9389103,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:32:16.371Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643306,
        "latitude": 40.7626657,
        "longitude": -73.9480821,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:32:11.389Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643203,
        "latitude": 40.7638856,
        "longitude": -73.938952,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:31:56.375Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643159,
        "latitude": 40.7625851,
        "longitude": -73.9481484,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:31:51.365Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430643061,
        "latitude": 40.7634931,
        "longitude": -73.9391242,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:31:36.366Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430643022,
        "latitude": 40.7625715,
        "longitude": -73.948129,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:31:31.362Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430642996,
        "latitude": 40.7634989,
        "longitude": -73.9392042,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:31:26.362Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430642953,
        "latitude": 40.7625715,
        "longitude": -73.9480821,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-27T17:31:21.369Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430641801,
        "latitude": 40.7635121,
        "longitude": -73.9395059,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-27T17:29:14.867Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430641723,
        "latitude": 40.7626005,
        "longitude": -73.9479452,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-27T17:29:04.793Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430641261,
        "latitude": 40.7639915,
        "longitude": -73.9396886,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-07-27T17:28:05.399Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430598772,
        "latitude": 40.7622707,
        "longitude": -73.9497477,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-27T15:53:25.481Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430592006,
        "latitude": 40.7607827,
        "longitude": -73.9505183,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-27T15:40:00.940Z",
        "type": "Periodical"
    },
    {
        "id": 430588000,
        "latitude": 40.7606767,
        "longitude": -73.9504763,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-07-27T15:31:12.167Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 430041293,
        "latitude": 40.7628076,
        "longitude": -73.9494038,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-26T18:05:15.382Z",
        "type": "Periodical"
    },
    {
        "id": 430041294,
        "latitude": 40.7628076,
        "longitude": -73.9494038,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-26T18:05:15.382Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 430020342,
        "latitude": 40.9296414,
        "longitude": -73.7641847,
        "accuracy": 3558,
        "source": "Fused",
        "ts": "2024-07-26T17:24:01.928Z",
        "type": "Periodical"
    },
    {
        "id": 430004119,
        "latitude": 41.1199518,
        "longitude": -73.3802045,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-07-26T16:50:52.004Z",
        "type": "Periodical"
    },
    {
        "id": 429995150,
        "latitude": 41.1667156,
        "longitude": -73.228799,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-07-26T16:31:47.230Z",
        "type": "Periodical"
    },
    {
        "id": 429974787,
        "latitude": 41.6251799,
        "longitude": -72.7401946,
        "accuracy": 1399,
        "source": "Fused",
        "ts": "2024-07-26T15:47:47.991Z",
        "type": "Periodical"
    },
    {
        "id": 429960911,
        "latitude": 41.6577933,
        "longitude": -72.726443,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-26T15:16:23.942Z",
        "type": "Periodical"
    },
    {
        "id": 429950011,
        "latitude": 41.9059003,
        "longitude": -72.6338091,
        "accuracy": 1122,
        "source": "Fused",
        "ts": "2024-07-26T14:52:25.540Z",
        "type": "Periodical"
    },
    {
        "id": 429949921,
        "latitude": 41.9067258,
        "longitude": -72.6334159,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-07-26T14:51:49.985Z",
        "type": "Periodical"
    },
    {
        "id": 429477700,
        "latitude": 42.8508322,
        "longitude": -72.3056035,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T20:25:00.538Z",
        "type": "Periodical"
    },
    {
        "id": 429448930,
        "latitude": 42.8508322,
        "longitude": -72.3056035,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-25T19:30:10.791Z",
        "type": "Periodical"
    },
    {
        "id": 429433211,
        "latitude": 42.8514601,
        "longitude": -72.3047578,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T19:02:47.300Z",
        "type": "Periodical"
    },
    {
        "id": 429412917,
        "latitude": 42.8514601,
        "longitude": -72.3047578,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T18:22:47.298Z",
        "type": "Periodical"
    },
    {
        "id": 429397686,
        "latitude": 42.8514601,
        "longitude": -72.3047578,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T17:52:47.296Z",
        "type": "Periodical"
    },
    {
        "id": 429381168,
        "latitude": 42.8514599,
        "longitude": -72.3047255,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-25T17:18:55.961Z",
        "type": "Periodical"
    },
    {
        "id": 429366379,
        "latitude": 42.8513471,
        "longitude": -72.3051784,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-25T16:48:25.267Z",
        "type": "Periodical"
    },
    {
        "id": 429366385,
        "latitude": 42.8513471,
        "longitude": -72.3051784,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-25T16:48:25.267Z",
        "type": "Periodical"
    },
    {
        "id": 429366401,
        "latitude": 42.8513471,
        "longitude": -72.3051784,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-25T16:48:25.267Z",
        "type": "Periodical"
    },
    {
        "id": 429366261,
        "latitude": 42.851371,
        "longitude": -72.3051701,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:47:54.240Z",
        "type": "Periodical"
    },
    {
        "id": 429366199,
        "latitude": 42.851371,
        "longitude": -72.3051701,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:47:54.240Z",
        "type": "Periodical"
    },
    {
        "id": 429366306,
        "latitude": 42.851371,
        "longitude": -72.3051701,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:47:54.240Z",
        "type": "Periodical"
    },
    {
        "id": 429366344,
        "latitude": 42.851371,
        "longitude": -72.3051701,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:47:54.240Z",
        "type": "Periodical"
    },
    {
        "id": 429366255,
        "latitude": 42.8513869,
        "longitude": -72.3050026,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-25T16:47:34.219Z",
        "type": "Periodical"
    },
    {
        "id": 429366256,
        "latitude": 42.8513869,
        "longitude": -72.3050026,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-25T16:47:34.219Z",
        "type": "Periodical"
    },
    {
        "id": 429366257,
        "latitude": 42.8513869,
        "longitude": -72.3050026,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-25T16:47:34.219Z",
        "type": "Periodical"
    },
    {
        "id": 429366258,
        "latitude": 42.8513869,
        "longitude": -72.3050026,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-25T16:47:34.219Z",
        "type": "Periodical"
    },
    {
        "id": 429366247,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366248,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366249,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366250,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366251,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366252,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366253,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366254,
        "latitude": 42.8513613,
        "longitude": -72.305183,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-25T16:47:13.541Z",
        "type": "Periodical"
    },
    {
        "id": 429366241,
        "latitude": 42.8513722,
        "longitude": -72.305174,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:46:53.065Z",
        "type": "Periodical"
    },
    {
        "id": 429366242,
        "latitude": 42.8513722,
        "longitude": -72.305174,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:46:53.065Z",
        "type": "Periodical"
    },
    {
        "id": 429366243,
        "latitude": 42.8513722,
        "longitude": -72.305174,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:46:53.065Z",
        "type": "Periodical"
    },
    {
        "id": 429366244,
        "latitude": 42.8513722,
        "longitude": -72.305174,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:46:53.065Z",
        "type": "Periodical"
    },
    {
        "id": 429366245,
        "latitude": 42.8513722,
        "longitude": -72.305174,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:46:53.065Z",
        "type": "Periodical"
    },
    {
        "id": 429366246,
        "latitude": 42.8513722,
        "longitude": -72.305174,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-25T16:46:53.065Z",
        "type": "Periodical"
    },
    {
        "id": 429366240,
        "latitude": 42.8513585,
        "longitude": -72.3051943,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-25T16:46:32.216Z",
        "type": "Periodical"
    },
    {
        "id": 429366235,
        "latitude": 42.851392,
        "longitude": -72.3051341,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-25T16:46:11.326Z",
        "type": "Periodical"
    },
    {
        "id": 429366236,
        "latitude": 42.851392,
        "longitude": -72.3051341,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-25T16:46:11.326Z",
        "type": "Periodical"
    },
    {
        "id": 429366237,
        "latitude": 42.851392,
        "longitude": -72.3051341,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-25T16:46:11.326Z",
        "type": "Periodical"
    },
    {
        "id": 429366238,
        "latitude": 42.851392,
        "longitude": -72.3051341,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-25T16:46:11.326Z",
        "type": "Periodical"
    },
    {
        "id": 429366239,
        "latitude": 42.851392,
        "longitude": -72.3051341,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-25T16:46:11.326Z",
        "type": "Periodical"
    },
    {
        "id": 429366234,
        "latitude": 42.8511151,
        "longitude": -72.3050273,
        "accuracy": 1600,
        "source": "Fused",
        "ts": "2024-07-25T16:45:51.561Z",
        "type": "Periodical"
    },
    {
        "id": 429239896,
        "latitude": 42.8508065,
        "longitude": -72.305645,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T12:07:47.996Z",
        "type": "Periodical"
    },
    {
        "id": 429227738,
        "latitude": 42.8508046,
        "longitude": -72.3056403,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-25T11:27:47.993Z",
        "type": "Periodical"
    },
    {
        "id": 429227737,
        "latitude": 42.8508097,
        "longitude": -72.3056351,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T10:55:00.211Z",
        "type": "Periodical"
    },
    {
        "id": 429227736,
        "latitude": 42.8508097,
        "longitude": -72.3056351,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T08:03:17.750Z",
        "type": "Periodical"
    },
    {
        "id": 429105959,
        "latitude": 42.8508295,
        "longitude": -72.3056208,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T05:02:40.142Z",
        "type": "Periodical"
    },
    {
        "id": 429068911,
        "latitude": 42.8508295,
        "longitude": -72.3056208,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T03:55:00.223Z",
        "type": "Periodical"
    },
    {
        "id": 429053390,
        "latitude": 42.850806,
        "longitude": -72.3056499,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-25T03:00:40.125Z",
        "type": "Periodical"
    },
    {
        "id": 429004153,
        "latitude": 42.8508103,
        "longitude": -72.3056483,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-25T01:50:23.319Z",
        "type": "Periodical"
    },
    {
        "id": 428903157,
        "latitude": 42.8507862,
        "longitude": -72.3056727,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-07-24T22:32:33.894Z",
        "type": "Periodical"
    },
    {
        "id": 428886578,
        "latitude": 42.8508151,
        "longitude": -72.3056297,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T22:00:10.235Z",
        "type": "Periodical"
    },
    {
        "id": 428865919,
        "latitude": 42.8508194,
        "longitude": -72.3056216,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T21:20:10.233Z",
        "type": "Periodical"
    },
    {
        "id": 428845371,
        "latitude": 42.8508194,
        "longitude": -72.3056216,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T20:40:10.231Z",
        "type": "Periodical"
    },
    {
        "id": 428823987,
        "latitude": 42.8508194,
        "longitude": -72.3056216,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T20:00:10.227Z",
        "type": "Periodical"
    },
    {
        "id": 428808888,
        "latitude": 42.8508194,
        "longitude": -72.3056216,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-24T19:30:10.225Z",
        "type": "Periodical"
    },
    {
        "id": 428798605,
        "latitude": 42.850809,
        "longitude": -72.3056382,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T19:10:10.237Z",
        "type": "Periodical"
    },
    {
        "id": 428783588,
        "latitude": 42.850809,
        "longitude": -72.3056382,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T18:40:10.235Z",
        "type": "Periodical"
    },
    {
        "id": 428768947,
        "latitude": 42.850809,
        "longitude": -72.3056382,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T18:10:10.233Z",
        "type": "Periodical"
    },
    {
        "id": 428754322,
        "latitude": 42.850809,
        "longitude": -72.3056382,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T17:40:10.231Z",
        "type": "Periodical"
    },
    {
        "id": 428734037,
        "latitude": 42.8508085,
        "longitude": -72.3056372,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T17:00:18.677Z",
        "type": "Periodical"
    },
    {
        "id": 428718916,
        "latitude": 42.8508085,
        "longitude": -72.3056372,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T16:30:18.675Z",
        "type": "Periodical"
    },
    {
        "id": 428704673,
        "latitude": 42.8508157,
        "longitude": -72.3056253,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-24T16:00:18.674Z",
        "type": "Periodical"
    },
    {
        "id": 428695604,
        "latitude": 42.850819,
        "longitude": -72.30562,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-24T15:40:58.973Z",
        "type": "Periodical"
    },
    {
        "id": 428676979,
        "latitude": 42.8508195,
        "longitude": -72.3056,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-24T15:01:11.452Z",
        "type": "Periodical"
    },
    {
        "id": 428647316,
        "latitude": 42.8508213,
        "longitude": -72.305618,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T13:55:00.321Z",
        "type": "Periodical"
    },
    {
        "id": 428635641,
        "latitude": 42.8508213,
        "longitude": -72.305618,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T13:27:20.850Z",
        "type": "Periodical"
    },
    {
        "id": 428606654,
        "latitude": 42.850808,
        "longitude": -72.3056303,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T12:16:02.490Z",
        "type": "Periodical"
    },
    {
        "id": 428592717,
        "latitude": 42.850808,
        "longitude": -72.3056303,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-24T11:39:01.264Z",
        "type": "Periodical"
    },
    {
        "id": 428584124,
        "latitude": 42.8508138,
        "longitude": -72.3056361,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-24T11:15:01.412Z",
        "type": "Periodical"
    },
    {
        "id": 428572902,
        "latitude": 42.8508054,
        "longitude": -72.3056398,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T10:43:02.774Z",
        "type": "Periodical"
    },
    {
        "id": 428466606,
        "latitude": 42.8508054,
        "longitude": -72.3056398,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T05:27:06.023Z",
        "type": "Periodical"
    },
    {
        "id": 428456340,
        "latitude": 42.8508054,
        "longitude": -72.3056398,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T05:00:40.171Z",
        "type": "Periodical"
    },
    {
        "id": 428429595,
        "latitude": 42.8508054,
        "longitude": -72.3056398,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T03:57:20.236Z",
        "type": "Periodical"
    },
    {
        "id": 428404735,
        "latitude": 42.8508054,
        "longitude": -72.3056398,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-24T03:02:55.219Z",
        "type": "Periodical"
    },
    {
        "id": 428379106,
        "latitude": 42.8508118,
        "longitude": -72.3056343,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-24T02:10:37.686Z",
        "type": "Periodical"
    },
    {
        "id": 428367128,
        "latitude": 42.850816,
        "longitude": -72.3056331,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-24T01:46:56.216Z",
        "type": "Periodical"
    },
    {
        "id": 428348765,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T01:10:02.170Z",
        "type": "Periodical"
    },
    {
        "id": 428338329,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T00:50:02.169Z",
        "type": "Periodical"
    },
    {
        "id": 428323796,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T00:20:02.167Z",
        "type": "Periodical"
    },
    {
        "id": 428313946,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-24T00:00:02.167Z",
        "type": "Periodical"
    },
    {
        "id": 428293997,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T23:20:02.165Z",
        "type": "Periodical"
    },
    {
        "id": 428278818,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T22:50:02.163Z",
        "type": "Periodical"
    },
    {
        "id": 428258202,
        "latitude": 42.8508151,
        "longitude": -72.3056292,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T22:10:02.151Z",
        "type": "Periodical"
    },
    {
        "id": 428239487,
        "latitude": 42.8508074,
        "longitude": -72.3056429,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T21:34:16.491Z",
        "type": "Periodical"
    },
    {
        "id": 428221066,
        "latitude": 42.8508074,
        "longitude": -72.3056429,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-23T20:56:40.429Z",
        "type": "Periodical"
    },
    {
        "id": 428205330,
        "latitude": 42.8508222,
        "longitude": -72.3056338,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T20:25:01.278Z",
        "type": "Periodical"
    },
    {
        "id": 428178037,
        "latitude": 42.8508399,
        "longitude": -72.3056232,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T19:30:41.226Z",
        "type": "Periodical"
    },
    {
        "id": 428151115,
        "latitude": 42.8508399,
        "longitude": -72.3056232,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T18:23:12.789Z",
        "type": "Periodical"
    },
    {
        "id": 428117211,
        "latitude": 42.8508399,
        "longitude": -72.3056232,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-23T17:27:19.842Z",
        "type": "Periodical"
    },
    {
        "id": 428092916,
        "latitude": 42.850816,
        "longitude": -72.3056261,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T16:49:52.256Z",
        "type": "Periodical"
    },
    {
        "id": 428072189,
        "latitude": 42.8508231,
        "longitude": -72.3056225,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-23T16:09:52.253Z",
        "type": "Periodical"
    },
    {
        "id": 428062163,
        "latitude": 42.8508128,
        "longitude": -72.3056248,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-23T15:45:48.297Z",
        "type": "Periodical"
    },
    {
        "id": 428062164,
        "latitude": 42.8508128,
        "longitude": -72.3056248,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-23T15:45:48.297Z",
        "type": "Periodical"
    },
    {
        "id": 428062165,
        "latitude": 42.8508128,
        "longitude": -72.3056248,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-23T15:45:48.297Z",
        "type": "Periodical"
    },
    {
        "id": 428062162,
        "latitude": 42.850798,
        "longitude": -72.3056451,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-23T15:44:01.525Z",
        "type": "Periodical"
    },
    {
        "id": 427787171,
        "latitude": 42.8508112,
        "longitude": -72.3056405,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T03:55:01.223Z",
        "type": "Periodical"
    },
    {
        "id": 427761743,
        "latitude": 42.850804,
        "longitude": -72.3056353,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T03:00:40.151Z",
        "type": "Periodical"
    },
    {
        "id": 427743015,
        "latitude": 42.850804,
        "longitude": -72.3056353,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-23T02:22:15.131Z",
        "type": "Periodical"
    },
    {
        "id": 427726801,
        "latitude": 42.8508317,
        "longitude": -72.3056252,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T01:49:33.105Z",
        "type": "Periodical"
    },
    {
        "id": 427709029,
        "latitude": 42.8508154,
        "longitude": -72.3056226,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T01:15:42.845Z",
        "type": "Periodical"
    },
    {
        "id": 427688157,
        "latitude": 42.8508154,
        "longitude": -72.3056226,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T00:35:10.263Z",
        "type": "Periodical"
    },
    {
        "id": 427672695,
        "latitude": 42.8508154,
        "longitude": -72.3056226,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-23T00:05:10.256Z",
        "type": "Periodical"
    },
    {
        "id": 427662100,
        "latitude": 42.8508154,
        "longitude": -72.3056226,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T23:45:10.244Z",
        "type": "Periodical"
    },
    {
        "id": 427651888,
        "latitude": 42.8506979,
        "longitude": -72.3052133,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T23:25:10.240Z",
        "type": "Periodical"
    },
    {
        "id": 427641881,
        "latitude": 42.8506979,
        "longitude": -72.3052133,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T23:05:10.230Z",
        "type": "Periodical"
    },
    {
        "id": 427620301,
        "latitude": 42.8508154,
        "longitude": -72.305631,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T22:23:49.804Z",
        "type": "Periodical"
    },
    {
        "id": 427609539,
        "latitude": 42.8508154,
        "longitude": -72.305631,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T22:03:49.799Z",
        "type": "Periodical"
    },
    {
        "id": 427593673,
        "latitude": 42.8508154,
        "longitude": -72.305631,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T21:33:49.797Z",
        "type": "Periodical"
    },
    {
        "id": 427576288,
        "latitude": 42.8508171,
        "longitude": -72.3056296,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-22T21:00:45.936Z",
        "type": "Periodical"
    },
    {
        "id": 427571003,
        "latitude": 42.8508119,
        "longitude": -72.3056258,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-22T20:50:59.942Z",
        "type": "Periodical"
    },
    {
        "id": 427557277,
        "latitude": 42.8508107,
        "longitude": -72.305631,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T20:25:01.286Z",
        "type": "Periodical"
    },
    {
        "id": 427520012,
        "latitude": 42.8508343,
        "longitude": -72.3056263,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T19:14:43.859Z",
        "type": "Periodical"
    },
    {
        "id": 427494501,
        "latitude": 42.8508337,
        "longitude": -72.3056373,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T18:25:00.592Z",
        "type": "Periodical"
    },
    {
        "id": 427483643,
        "latitude": 42.8508337,
        "longitude": -72.3056373,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T18:04:13.548Z",
        "type": "Periodical"
    },
    {
        "id": 427450774,
        "latitude": 42.8508337,
        "longitude": -72.3056373,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T16:58:28.219Z",
        "type": "Periodical"
    },
    {
        "id": 427438075,
        "latitude": 42.8508337,
        "longitude": -72.3056373,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T16:33:44.005Z",
        "type": "Periodical"
    },
    {
        "id": 427391598,
        "latitude": 42.8508337,
        "longitude": -72.3056373,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-22T15:00:32.661Z",
        "type": "Periodical"
    },
    {
        "id": 427368143,
        "latitude": 42.8508441,
        "longitude": -72.3056265,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T14:10:45.962Z",
        "type": "Periodical"
    },
    {
        "id": 427352589,
        "latitude": 42.8508441,
        "longitude": -72.3056265,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T13:36:01.154Z",
        "type": "Periodical"
    },
    {
        "id": 427336652,
        "latitude": 42.8508441,
        "longitude": -72.3056265,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T13:00:10.231Z",
        "type": "Periodical"
    },
    {
        "id": 427323637,
        "latitude": 42.8508441,
        "longitude": -72.3056265,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T12:30:10.229Z",
        "type": "Periodical"
    },
    {
        "id": 427311171,
        "latitude": 42.850838,
        "longitude": -72.3056354,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T11:59:36.331Z",
        "type": "Periodical"
    },
    {
        "id": 427296067,
        "latitude": 42.8508116,
        "longitude": -72.3056381,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-22T11:19:36.328Z",
        "type": "Periodical"
    },
    {
        "id": 427281808,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T10:40:10.257Z",
        "type": "Periodical"
    },
    {
        "id": 427271541,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T10:10:10.254Z",
        "type": "Periodical"
    },
    {
        "id": 427258090,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T09:30:10.250Z",
        "type": "Periodical"
    },
    {
        "id": 427257788,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T09:00:10.248Z",
        "type": "Periodical"
    },
    {
        "id": 427234276,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T08:20:10.246Z",
        "type": "Periodical"
    },
    {
        "id": 427223814,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T07:50:10.245Z",
        "type": "Periodical"
    },
    {
        "id": 427209947,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T07:10:10.243Z",
        "type": "Periodical"
    },
    {
        "id": 427199041,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T06:40:10.241Z",
        "type": "Periodical"
    },
    {
        "id": 427188435,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T06:10:10.240Z",
        "type": "Periodical"
    },
    {
        "id": 427180677,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T05:50:10.239Z",
        "type": "Periodical"
    },
    {
        "id": 427169172,
        "latitude": 42.8508332,
        "longitude": -72.3056214,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T05:20:10.238Z",
        "type": "Periodical"
    },
    {
        "id": 427153359,
        "latitude": 42.8508344,
        "longitude": -72.3056269,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T04:42:29.571Z",
        "type": "Periodical"
    },
    {
        "id": 427144736,
        "latitude": 42.8508344,
        "longitude": -72.3056269,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T04:22:29.571Z",
        "type": "Periodical"
    },
    {
        "id": 427135929,
        "latitude": 42.8508344,
        "longitude": -72.3056269,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T04:02:29.569Z",
        "type": "Periodical"
    },
    {
        "id": 427122380,
        "latitude": 42.8508344,
        "longitude": -72.3056269,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T03:32:29.564Z",
        "type": "Periodical"
    },
    {
        "id": 427106569,
        "latitude": 42.8508204,
        "longitude": -72.3056252,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T02:58:16.158Z",
        "type": "Periodical"
    },
    {
        "id": 427096927,
        "latitude": 42.8508204,
        "longitude": -72.3056252,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T02:38:16.157Z",
        "type": "Periodical"
    },
    {
        "id": 427077540,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T01:58:16.153Z",
        "type": "Periodical"
    },
    {
        "id": 427067562,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T01:38:16.152Z",
        "type": "Periodical"
    },
    {
        "id": 427052210,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T01:08:16.147Z",
        "type": "Periodical"
    },
    {
        "id": 427032348,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T00:28:16.138Z",
        "type": "Periodical"
    },
    {
        "id": 427022512,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-22T00:08:16.136Z",
        "type": "Periodical"
    },
    {
        "id": 427002213,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T23:28:16.134Z",
        "type": "Periodical"
    },
    {
        "id": 426981248,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T22:48:16.132Z",
        "type": "Periodical"
    },
    {
        "id": 426965639,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T22:18:16.129Z",
        "type": "Periodical"
    },
    {
        "id": 426954868,
        "latitude": 42.8508072,
        "longitude": -72.3056381,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T21:58:16.128Z",
        "type": "Periodical"
    },
    {
        "id": 426938078,
        "latitude": 42.8508075,
        "longitude": -72.3056362,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T21:25:32.128Z",
        "type": "Periodical"
    },
    {
        "id": 426927781,
        "latitude": 42.8508075,
        "longitude": -72.3056362,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T21:05:32.126Z",
        "type": "Periodical"
    },
    {
        "id": 426912335,
        "latitude": 42.8508214,
        "longitude": -72.3056359,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T20:35:32.124Z",
        "type": "Periodical"
    },
    {
        "id": 426897444,
        "latitude": 42.8508214,
        "longitude": -72.3056359,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T20:05:32.123Z",
        "type": "Periodical"
    },
    {
        "id": 426877394,
        "latitude": 42.8508214,
        "longitude": -72.3056359,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T19:25:32.121Z",
        "type": "Periodical"
    },
    {
        "id": 426871032,
        "latitude": 42.8508214,
        "longitude": -72.3056359,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-21T19:12:34.251Z",
        "type": "Periodical"
    },
    {
        "id": 426846768,
        "latitude": 42.850806,
        "longitude": -72.3056338,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T18:25:11.294Z",
        "type": "Periodical"
    },
    {
        "id": 426828467,
        "latitude": 42.8508047,
        "longitude": -72.3056333,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T17:48:49.459Z",
        "type": "Periodical"
    },
    {
        "id": 426788981,
        "latitude": 42.8508047,
        "longitude": -72.3056333,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-21T16:30:24.221Z",
        "type": "Periodical"
    },
    {
        "id": 426784970,
        "latitude": 42.8508058,
        "longitude": -72.3056403,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T16:00:10.463Z",
        "type": "Periodical"
    },
    {
        "id": 426761033,
        "latitude": 42.8508058,
        "longitude": -72.3056403,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T15:30:10.460Z",
        "type": "Periodical"
    },
    {
        "id": 426747652,
        "latitude": 42.8508144,
        "longitude": -72.3056171,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T15:00:10.458Z",
        "type": "Periodical"
    },
    {
        "id": 426729083,
        "latitude": 42.8508344,
        "longitude": -72.3056107,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T14:17:30.704Z",
        "type": "Periodical"
    },
    {
        "id": 426715710,
        "latitude": 42.8508344,
        "longitude": -72.3056107,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T13:47:30.702Z",
        "type": "Periodical"
    },
    {
        "id": 426700338,
        "latitude": 42.8514195,
        "longitude": -72.3047469,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-21T13:10:04.854Z",
        "type": "Periodical"
    },
    {
        "id": 426683112,
        "latitude": 42.8508033,
        "longitude": -72.3056354,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T12:30:10.264Z",
        "type": "Periodical"
    },
    {
        "id": 426675255,
        "latitude": 42.8508033,
        "longitude": -72.3056354,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T12:10:10.263Z",
        "type": "Periodical"
    },
    {
        "id": 426660838,
        "latitude": 42.8508029,
        "longitude": -72.3056439,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-21T11:33:08.756Z",
        "type": "Periodical"
    },
    {
        "id": 426653366,
        "latitude": 42.8508215,
        "longitude": -72.3056273,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-21T11:12:21.261Z",
        "type": "Periodical"
    },
    {
        "id": 426645285,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T10:50:10.260Z",
        "type": "Periodical"
    },
    {
        "id": 426631751,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T10:10:10.257Z",
        "type": "Periodical"
    },
    {
        "id": 426618211,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T09:30:10.256Z",
        "type": "Periodical"
    },
    {
        "id": 426608245,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T09:00:10.255Z",
        "type": "Periodical"
    },
    {
        "id": 426598149,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T08:30:10.253Z",
        "type": "Periodical"
    },
    {
        "id": 426587801,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T08:00:10.252Z",
        "type": "Periodical"
    },
    {
        "id": 426576543,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T07:30:10.251Z",
        "type": "Periodical"
    },
    {
        "id": 426565788,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T07:00:10.247Z",
        "type": "Periodical"
    },
    {
        "id": 426550825,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T06:20:10.241Z",
        "type": "Periodical"
    },
    {
        "id": 426543156,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T06:00:10.241Z",
        "type": "Periodical"
    },
    {
        "id": 426531131,
        "latitude": 42.8508901,
        "longitude": -72.3055406,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T05:30:10.240Z",
        "type": "Periodical"
    },
    {
        "id": 426516695,
        "latitude": 42.8508015,
        "longitude": -72.3056529,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T04:55:00.389Z",
        "type": "Periodical"
    },
    {
        "id": 426503528,
        "latitude": 42.8508015,
        "longitude": -72.3056529,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T04:25:00.388Z",
        "type": "Periodical"
    },
    {
        "id": 426489961,
        "latitude": 42.8508015,
        "longitude": -72.3056529,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T03:55:00.387Z",
        "type": "Periodical"
    },
    {
        "id": 426471088,
        "latitude": 42.850816,
        "longitude": -72.3056494,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T03:14:38.303Z",
        "type": "Periodical"
    },
    {
        "id": 426456903,
        "latitude": 42.850816,
        "longitude": -72.3056494,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T02:44:38.301Z",
        "type": "Periodical"
    },
    {
        "id": 426440743,
        "latitude": 42.8508072,
        "longitude": -72.3056359,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-21T02:03:34.612Z",
        "type": "Periodical"
    },
    {
        "id": 426423403,
        "latitude": 42.8509312,
        "longitude": -72.3055269,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-07-21T01:34:46.318Z",
        "type": "Periodical"
    },
    {
        "id": 426423406,
        "latitude": 42.8503317,
        "longitude": -72.3063564,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-21T01:05:17.153Z",
        "type": "Periodical"
    },
    {
        "id": 426423405,
        "latitude": 42.8514451,
        "longitude": -72.3048322,
        "accuracy": 2413,
        "source": "Fused",
        "ts": "2024-07-21T00:35:16.968Z",
        "type": "Periodical"
    },
    {
        "id": 426423404,
        "latitude": 42.8054559,
        "longitude": -72.3646149,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-07-21T00:15:16.906Z",
        "type": "Periodical"
    },
    {
        "id": 426376223,
        "latitude": 42.6318423,
        "longitude": -72.582775,
        "accuracy": 2900,
        "source": "Fused",
        "ts": "2024-07-20T23:52:06.598Z",
        "type": "Periodical"
    },
    {
        "id": 426364309,
        "latitude": 42.1299575,
        "longitude": -72.6251749,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T23:17:35.459Z",
        "type": "Periodical"
    },
    {
        "id": 426346331,
        "latitude": 42.1299575,
        "longitude": -72.6251749,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T22:47:35.458Z",
        "type": "Periodical"
    },
    {
        "id": 426346330,
        "latitude": 42.1299573,
        "longitude": -72.6252548,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T22:25:25.432Z",
        "type": "Periodical"
    },
    {
        "id": 426346329,
        "latitude": 42.1299573,
        "longitude": -72.6252548,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T21:55:25.430Z",
        "type": "Periodical"
    },
    {
        "id": 426303196,
        "latitude": 42.0807205,
        "longitude": -72.5892304,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-20T21:25:00.943Z",
        "type": "Periodical"
    },
    {
        "id": 426303195,
        "latitude": 41.7493933,
        "longitude": -72.730988,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-07-20T20:59:39.976Z",
        "type": "Periodical"
    },
    {
        "id": 426275508,
        "latitude": 41.5089665,
        "longitude": -73.142152,
        "accuracy": 2000,
        "source": "Fused",
        "ts": "2024-07-20T20:22:32.808Z",
        "type": "Periodical"
    },
    {
        "id": 426275507,
        "latitude": 41.4070124,
        "longitude": -73.4609272,
        "accuracy": 2099,
        "source": "Fused",
        "ts": "2024-07-20T19:52:32.332Z",
        "type": "Periodical"
    },
    {
        "id": 426236691,
        "latitude": 41.2197851,
        "longitude": -73.7262714,
        "accuracy": 1399,
        "source": "Fused",
        "ts": "2024-07-20T19:23:28.558Z",
        "type": "Periodical"
    },
    {
        "id": 426236646,
        "latitude": 41.1253257,
        "longitude": -74.0974519,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-20T18:45:03.957Z",
        "type": "Periodical"
    },
    {
        "id": 426199636,
        "latitude": 41.1327081,
        "longitude": -74.0870624,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-20T18:10:00.301Z",
        "type": "Periodical"
    },
    {
        "id": 426182913,
        "latitude": 41.1294169,
        "longitude": -74.0906587,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-07-20T17:36:28.663Z",
        "type": "Periodical"
    },
    {
        "id": 426166495,
        "latitude": 41.1326956,
        "longitude": -74.0870404,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-20T17:03:26.577Z",
        "type": "Periodical"
    },
    {
        "id": 426154428,
        "latitude": 41.1293155,
        "longitude": -74.090979,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-07-20T16:40:44.779Z",
        "type": "Periodical"
    },
    {
        "id": 426127401,
        "latitude": 41.1243354,
        "longitude": -74.0862991,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-20T15:43:00.258Z",
        "type": "Periodical"
    },
    {
        "id": 426127400,
        "latitude": 41.0355056,
        "longitude": -73.9482894,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-07-20T15:25:36.542Z",
        "type": "Periodical"
    },
    {
        "id": 426127399,
        "latitude": 40.8299838,
        "longitude": -73.933594,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-07-20T15:01:36.324Z",
        "type": "Periodical"
    },
    {
        "id": 426098967,
        "latitude": 40.7612156,
        "longitude": -73.9351068,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-07-20T14:37:09.320Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 425805725,
        "latitude": 40.7621656,
        "longitude": -73.9500587,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-07-20T02:16:37.006Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 425792232,
        "latitude": 40.7667944,
        "longitude": -73.9832204,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-20T01:50:03.274Z",
        "type": "Periodical"
    },
    {
        "id": 425790464,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T01:24:37.031Z",
        "type": "Periodical"
    },
    {
        "id": 425790463,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T00:54:37.029Z",
        "type": "Periodical"
    },
    {
        "id": 425790462,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-20T00:29:37.027Z",
        "type": "Periodical"
    },
    {
        "id": 425790461,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T23:54:37.023Z",
        "type": "Periodical"
    },
    {
        "id": 425790460,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T23:24:37.019Z",
        "type": "Periodical"
    },
    {
        "id": 425790459,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T22:54:37.017Z",
        "type": "Periodical"
    },
    {
        "id": 425790458,
        "latitude": 40.7661976,
        "longitude": -73.9822324,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-19T22:19:37.012Z",
        "type": "Periodical"
    },
    {
        "id": 425790457,
        "latitude": 40.7661668,
        "longitude": -73.9820924,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T21:41:27.109Z",
        "type": "Periodical"
    },
    {
        "id": 425790456,
        "latitude": 40.7661668,
        "longitude": -73.9820924,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-07-19T21:21:27.108Z",
        "type": "Periodical"
    },
    {
        "id": 425632044,
        "latitude": 40.7593793,
        "longitude": -73.9530664,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-19T20:39:46.464Z",
        "type": "Periodical"
    },
    {
        "id": 425631715,
        "latitude": 40.7598439,
        "longitude": -73.952499,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-19T20:39:17.836Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 425569297,
        "latitude": 40.7624191,
        "longitude": -73.9497811,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-19T18:37:00.336Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 425560288,
        "latitude": 40.7654225,
        "longitude": -73.9474907,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-19T18:14:13.693Z",
        "type": "Periodical"
    },
    {
        "id": 425551570,
        "latitude": 40.7654133,
        "longitude": -73.947513,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-19T17:43:56.399Z",
        "type": "Periodical"
    },
    {
        "id": 425519935,
        "latitude": 40.7654196,
        "longitude": -73.9474917,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-19T16:57:42.931Z",
        "type": "Periodical"
    },
    {
        "id": 425500785,
        "latitude": 40.7654215,
        "longitude": -73.9475171,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T16:18:29.247Z",
        "type": "Periodical"
    },
    {
        "id": 425489734,
        "latitude": 40.7654205,
        "longitude": -73.9475112,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-19T15:54:59.512Z",
        "type": "Periodical"
    },
    {
        "id": 425471799,
        "latitude": 40.7654082,
        "longitude": -73.947502,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-19T15:17:10.327Z",
        "type": "Periodical"
    },
    {
        "id": 425460134,
        "latitude": 40.7663244,
        "longitude": -73.9463447,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T14:40:01.258Z",
        "type": "Periodical"
    },
    {
        "id": 425460133,
        "latitude": 40.7663244,
        "longitude": -73.9463447,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T14:20:01.257Z",
        "type": "Periodical"
    },
    {
        "id": 425460132,
        "latitude": 40.7663244,
        "longitude": -73.9463447,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-19T13:40:01.253Z",
        "type": "Periodical"
    },
    {
        "id": 425460131,
        "latitude": 40.7652954,
        "longitude": -73.9475375,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-19T13:14:00.467Z",
        "type": "Periodical"
    },
    {
        "id": 425407547,
        "latitude": 40.7645802,
        "longitude": -73.9480687,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-19T12:49:21.877Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 425035140,
        "latitude": 40.76238,
        "longitude": -73.9497929,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-18T22:00:18.828Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 425027798,
        "latitude": 40.7587311,
        "longitude": -73.9531547,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-18T21:45:43.695Z",
        "type": "Periodical"
    },
    {
        "id": 425017719,
        "latitude": 40.7589325,
        "longitude": -73.9528588,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-18T21:26:02.099Z",
        "type": "Periodical"
    },
    {
        "id": 425005153,
        "latitude": 40.7639935,
        "longitude": -73.9774368,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-18T21:01:42.257Z",
        "type": "Periodical"
    },
    {
        "id": 424991484,
        "latitude": 40.7662645,
        "longitude": -73.9824306,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-18T20:29:53.223Z",
        "type": "Periodical"
    },
    {
        "id": 424985599,
        "latitude": 40.7662778,
        "longitude": -73.9824162,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-18T20:00:26.529Z",
        "type": "Periodical"
    },
    {
        "id": 424958783,
        "latitude": 40.7662481,
        "longitude": -73.9823906,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-18T19:30:36.494Z",
        "type": "Periodical"
    },
    {
        "id": 424939553,
        "latitude": 40.7662646,
        "longitude": -73.9824057,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-18T18:53:01.094Z",
        "type": "Periodical"
    },
    {
        "id": 424928461,
        "latitude": 40.7661256,
        "longitude": -73.9814679,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-18T18:22:55.906Z",
        "type": "Periodical"
    },
    {
        "id": 424908878,
        "latitude": 40.7662458,
        "longitude": -73.9823091,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-18T17:49:45.276Z",
        "type": "Periodical"
    },
    {
        "id": 424896356,
        "latitude": 40.7663969,
        "longitude": -73.9828642,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-07-18T17:16:54.028Z",
        "type": "Periodical"
    },
    {
        "id": 424888756,
        "latitude": 40.7663748,
        "longitude": -73.9828586,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-18T16:56:04.442Z",
        "type": "Periodical"
    },
    {
        "id": 424876649,
        "latitude": 40.7665319,
        "longitude": -73.982977,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-18T16:22:06.586Z",
        "type": "Periodical"
    },
    {
        "id": 424856425,
        "latitude": 40.7664047,
        "longitude": -73.982877,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-18T15:57:50.583Z",
        "type": "Periodical"
    },
    {
        "id": 424852459,
        "latitude": 40.7663781,
        "longitude": -73.9828462,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-18T15:22:48.163Z",
        "type": "Periodical"
    },
    {
        "id": 424852458,
        "latitude": 40.766249,
        "longitude": -73.9821923,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-18T15:02:46.397Z",
        "type": "Periodical"
    },
    {
        "id": 424823827,
        "latitude": 40.766172,
        "longitude": -73.9819896,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-07-18T14:32:57.915Z",
        "type": "Periodical"
    },
    {
        "id": 424801396,
        "latitude": 40.7160974,
        "longitude": -74.0017931,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-07-18T13:56:41.634Z",
        "type": "Periodical"
    },
    {
        "id": 424789272,
        "latitude": 40.7167673,
        "longitude": -74.0026426,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-18T13:28:26.926Z",
        "type": "Periodical"
    },
    {
        "id": 424770696,
        "latitude": 40.7173316,
        "longitude": -74.0015836,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-18T12:44:24.059Z",
        "type": "Periodical"
    },
    {
        "id": 424762893,
        "latitude": 40.7647335,
        "longitude": -73.9663585,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-18T12:24:38.297Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 424499341,
        "latitude": 40.7633791,
        "longitude": -73.9486144,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-18T00:58:58.853Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 424489845,
        "latitude": 40.744831,
        "longitude": -73.9180566,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-18T00:41:52.087Z",
        "type": "Periodical"
    },
    {
        "id": 424475183,
        "latitude": 40.7447418,
        "longitude": -73.9183044,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-18T00:12:04.221Z",
        "type": "Periodical"
    },
    {
        "id": 424475182,
        "latitude": 40.7447237,
        "longitude": -73.9183456,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-17T23:39:05.923Z",
        "type": "Periodical"
    },
    {
        "id": 424442642,
        "latitude": 40.7646077,
        "longitude": -73.976836,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-07-17T23:00:11.816Z",
        "type": "Periodical"
    },
    {
        "id": 424427885,
        "latitude": 40.7661761,
        "longitude": -73.9822487,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-17T22:31:45.219Z",
        "type": "Periodical"
    },
    {
        "id": 424402639,
        "latitude": 40.7662619,
        "longitude": -73.9825191,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-17T21:54:28.827Z",
        "type": "Periodical"
    },
    {
        "id": 424385105,
        "latitude": 40.7662767,
        "longitude": -73.9824196,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-17T21:19:24.295Z",
        "type": "Periodical"
    },
    {
        "id": 424383790,
        "latitude": 40.7668073,
        "longitude": -73.9828987,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-17T20:52:17.051Z",
        "type": "Periodical"
    },
    {
        "id": 424355753,
        "latitude": 40.7663973,
        "longitude": -73.9827783,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-17T20:15:58.662Z",
        "type": "Periodical"
    },
    {
        "id": 424341248,
        "latitude": 40.7661687,
        "longitude": -73.9817628,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-07-17T19:47:03.314Z",
        "type": "Periodical"
    },
    {
        "id": 424329618,
        "latitude": 40.7662765,
        "longitude": -73.9822309,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-17T19:30:39.072Z",
        "type": "Periodical"
    },
    {
        "id": 424318549,
        "latitude": 40.7662854,
        "longitude": -73.9825752,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-17T19:09:08.185Z",
        "type": "Periodical"
    },
    {
        "id": 424314867,
        "latitude": 40.7662969,
        "longitude": -73.9826503,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-17T19:02:08.901Z",
        "type": "Periodical"
    },
    {
        "id": 424314873,
        "latitude": 40.7662969,
        "longitude": -73.9826503,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-17T19:02:08.901Z",
        "type": "Periodical"
    },
    {
        "id": 424314952,
        "latitude": 40.7662969,
        "longitude": -73.9826503,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-17T19:02:08.901Z",
        "type": "Periodical"
    },
    {
        "id": 424297837,
        "latitude": 40.7662597,
        "longitude": -73.9824279,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-17T18:24:05.298Z",
        "type": "Periodical"
    },
    {
        "id": 424290200,
        "latitude": 40.7664065,
        "longitude": -73.9827945,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-17T18:01:39.046Z",
        "type": "Periodical"
    },
    {
        "id": 424273033,
        "latitude": 40.7662777,
        "longitude": -73.982433,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-17T17:30:26.071Z",
        "type": "Periodical"
    },
    {
        "id": 424256314,
        "latitude": 40.7656514,
        "longitude": -73.9796969,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-07-17T16:51:44.858Z",
        "type": "Periodical"
    },
    {
        "id": 424243493,
        "latitude": 40.7611256,
        "longitude": -73.9508461,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-17T16:35:59.860Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 424242410,
        "latitude": 40.7634601,
        "longitude": -73.9485038,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-07-17T16:33:44.861Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 424234785,
        "latitude": 40.7654054,
        "longitude": -73.9474951,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-17T16:17:44.863Z",
        "type": "Periodical"
    },
    {
        "id": 424228192,
        "latitude": 40.7654193,
        "longitude": -73.9475159,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-17T15:58:26.667Z",
        "type": "Periodical"
    },
    {
        "id": 424221770,
        "latitude": 40.7654201,
        "longitude": -73.9475021,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-17T15:38:47.925Z",
        "type": "Periodical"
    },
    {
        "id": 424211734,
        "latitude": 40.764777,
        "longitude": -73.941437,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-07-17T15:03:19.809Z",
        "type": "Periodical"
    },
    {
        "id": 424195080,
        "latitude": 40.7723664,
        "longitude": -73.9392515,
        "accuracy": 124,
        "source": "Fused",
        "ts": "2024-07-17T14:42:00.135Z",
        "type": "Periodical"
    },
    {
        "id": 424183693,
        "latitude": 40.7761623,
        "longitude": -73.9425345,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-07-17T14:14:48.657Z",
        "type": "Periodical"
    },
    {
        "id": 424167166,
        "latitude": 40.7654201,
        "longitude": -73.9475054,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-17T13:37:21.452Z",
        "type": "Periodical"
    },
    {
        "id": 424155722,
        "latitude": 40.7654169,
        "longitude": -73.9475169,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-17T13:12:35.833Z",
        "type": "Periodical"
    },
    {
        "id": 424148757,
        "latitude": 40.7639746,
        "longitude": -73.9479405,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-17T13:04:03.471Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 423796930,
        "latitude": 40.7628289,
        "longitude": -73.949573,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-16T22:42:36.818Z",
        "type": "Periodical"
    },
    {
        "id": 423796929,
        "latitude": 40.7633931,
        "longitude": -73.948686,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-16T22:40:43.939Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 423775437,
        "latitude": 40.7666608,
        "longitude": -73.9467842,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-16T22:02:38.312Z",
        "type": "Periodical"
    },
    {
        "id": 423775441,
        "latitude": 40.7665925,
        "longitude": -73.9467334,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-16T22:01:48.122Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 423593441,
        "latitude": 40.762588,
        "longitude": -73.9496893,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-16T16:02:55.111Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 423590453,
        "latitude": 40.7652878,
        "longitude": -73.9471873,
        "accuracy": 200,
        "source": "Fused",
        "ts": "2024-07-16T15:56:33.480Z",
        "type": "Periodical"
    },
    {
        "id": 423590170,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423590251,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423590118,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423590114,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423590245,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423590186,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423590278,
        "latitude": 40.7653536,
        "longitude": -73.9475795,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:56:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 423589869,
        "latitude": 40.7653353,
        "longitude": -73.9475982,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:55:12.839Z",
        "type": "Periodical"
    },
    {
        "id": 423589859,
        "latitude": 40.7653353,
        "longitude": -73.9475982,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T15:55:12.839Z",
        "type": "Periodical"
    },
    {
        "id": 423584194,
        "latitude": 40.7654062,
        "longitude": -73.9475029,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-16T15:35:53.628Z",
        "type": "Periodical"
    },
    {
        "id": 423573488,
        "latitude": 40.7653995,
        "longitude": -73.9475256,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-16T15:13:35.920Z",
        "type": "Periodical"
    },
    {
        "id": 423568405,
        "latitude": 40.7645986,
        "longitude": -73.9474032,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-07-16T15:07:25.476Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 423566925,
        "latitude": 40.7614951,
        "longitude": -73.9503456,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-16T14:59:34.292Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 423561601,
        "latitude": 40.7598249,
        "longitude": -73.9513527,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-16T14:42:04.163Z",
        "type": "Periodical"
    },
    {
        "id": 423555651,
        "latitude": 40.7597684,
        "longitude": -73.9514953,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-16T14:39:33.268Z",
        "type": "Periodical"
    },
    {
        "id": 423555663,
        "latitude": 40.7597684,
        "longitude": -73.9514953,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-16T14:39:33.268Z",
        "type": "Periodical"
    },
    {
        "id": 423541990,
        "latitude": 40.7598868,
        "longitude": -73.9514617,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-16T14:08:00.696Z",
        "type": "Periodical"
    },
    {
        "id": 423535674,
        "latitude": 40.7608596,
        "longitude": -73.9515334,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-16T13:49:20.486Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 423535673,
        "latitude": 40.7633146,
        "longitude": -73.9500608,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-16T13:41:20.409Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 423520381,
        "latitude": 40.7654218,
        "longitude": -73.9474899,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-16T13:17:21.708Z",
        "type": "Periodical"
    },
    {
        "id": 423514515,
        "latitude": 40.7648429,
        "longitude": -73.9473677,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-16T13:00:59.859Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 423268301,
        "latitude": 40.7620705,
        "longitude": -73.9501564,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-16T02:18:56.977Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 423255793,
        "latitude": 40.7591562,
        "longitude": -73.9532177,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-16T01:54:34.669Z",
        "type": "Periodical"
    },
    {
        "id": 423248104,
        "latitude": 40.766258,
        "longitude": -73.9823955,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-16T01:30:18.925Z",
        "type": "Periodical"
    },
    {
        "id": 423235005,
        "latitude": 40.7662816,
        "longitude": -73.9825391,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-16T01:07:03.973Z",
        "type": "Periodical"
    },
    {
        "id": 423212558,
        "latitude": 40.7661913,
        "longitude": -73.9816957,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-07-16T00:26:57.477Z",
        "type": "Periodical"
    },
    {
        "id": 423192287,
        "latitude": 40.7664435,
        "longitude": -73.9828762,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-15T23:47:50.498Z",
        "type": "Periodical"
    },
    {
        "id": 423173166,
        "latitude": 40.7664099,
        "longitude": -73.9828125,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-15T23:12:03.907Z",
        "type": "Periodical"
    },
    {
        "id": 423158181,
        "latitude": 40.7663827,
        "longitude": -73.9828392,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-15T22:43:01.155Z",
        "type": "Periodical"
    },
    {
        "id": 423142291,
        "latitude": 40.7663602,
        "longitude": -73.9828666,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-15T22:12:54.699Z",
        "type": "Periodical"
    },
    {
        "id": 423133063,
        "latitude": 40.7663669,
        "longitude": -73.9828658,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-07-15T21:49:00.500Z",
        "type": "Periodical"
    },
    {
        "id": 423124492,
        "latitude": 40.7661715,
        "longitude": -73.9822377,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-07-15T21:24:57.113Z",
        "type": "Periodical"
    },
    {
        "id": 423099282,
        "latitude": 40.7625829,
        "longitude": -73.9670705,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-15T20:47:58.426Z",
        "type": "Periodical"
    },
    {
        "id": 423082670,
        "latitude": 40.7560561,
        "longitude": -73.9604123,
        "accuracy": 600,
        "source": "Fused",
        "ts": "2024-07-15T20:16:23.951Z",
        "type": "Periodical"
    },
    {
        "id": 423075134,
        "latitude": 40.7589898,
        "longitude": -73.9535217,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-15T20:01:16.619Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 422653157,
        "latitude": 40.763468,
        "longitude": -73.9486996,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-07-15T03:00:09.233Z",
        "type": "Periodical"
    },
    {
        "id": 422653158,
        "latitude": 40.763468,
        "longitude": -73.9486996,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-07-15T03:00:09.233Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 422653156,
        "latitude": 40.9628781,
        "longitude": -73.8582029,
        "accuracy": 88,
        "source": "Fused",
        "ts": "2024-07-15T02:30:05.498Z",
        "type": "Periodical"
    },
    {
        "id": 422619644,
        "latitude": 41.2088099,
        "longitude": -73.8622377,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-15T01:55:41.312Z",
        "type": "Periodical"
    },
    {
        "id": 422566996,
        "latitude": 41.2088099,
        "longitude": -73.8622377,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-15T00:09:57.823Z",
        "type": "Periodical"
    },
    {
        "id": 422566995,
        "latitude": 41.2088105,
        "longitude": -73.8622462,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-14T23:47:06.270Z",
        "type": "Periodical"
    },
    {
        "id": 422566994,
        "latitude": 41.2086234,
        "longitude": -73.8624434,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-07-14T22:47:43.957Z",
        "type": "Periodical"
    },
    {
        "id": 422525867,
        "latitude": 41.2086234,
        "longitude": -73.8624434,
        "accuracy": 0,
        "source": "Fused",
        "ts": "2024-07-14T22:47:43.957Z",
        "type": "Periodical"
    },
    {
        "id": 422525775,
        "latitude": 41.2087792,
        "longitude": -73.8622496,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-14T21:30:16.281Z",
        "type": "Periodical"
    },
    {
        "id": 422525774,
        "latitude": 41.2087842,
        "longitude": -73.862262,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-14T19:49:53.337Z",
        "type": "Periodical"
    },
    {
        "id": 422525773,
        "latitude": 41.208793,
        "longitude": -73.8622564,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-14T19:01:24.506Z",
        "type": "Periodical"
    },
    {
        "id": 422525772,
        "latitude": 41.011918,
        "longitude": -73.8284895,
        "accuracy": 899,
        "source": "Fused",
        "ts": "2024-07-14T18:23:02.578Z",
        "type": "Periodical"
    },
    {
        "id": 422525771,
        "latitude": 40.8245622,
        "longitude": -73.8780845,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-07-14T18:05:02.424Z",
        "type": "Periodical"
    },
    {
        "id": 422525770,
        "latitude": 40.7892514,
        "longitude": -73.9247762,
        "accuracy": 699,
        "source": "Fused",
        "ts": "2024-07-14T17:59:00.334Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 421939643,
        "latitude": 40.7627593,
        "longitude": -73.9496114,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-13T23:56:03.719Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 421933144,
        "latitude": 40.766199,
        "longitude": -73.9468467,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-13T23:40:01.913Z",
        "type": "Periodical"
    },
    {
        "id": 421933143,
        "latitude": 40.7662059,
        "longitude": -73.9468492,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-13T23:16:16.321Z",
        "type": "Periodical"
    },
    {
        "id": 421916314,
        "latitude": 40.7680495,
        "longitude": -73.9497443,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-13T22:47:10.815Z",
        "type": "Periodical"
    },
    {
        "id": 421893097,
        "latitude": 40.7668026,
        "longitude": -73.9465723,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-07-13T22:17:01.093Z",
        "type": "Periodical"
    },
    {
        "id": 421893095,
        "latitude": 40.7662971,
        "longitude": -73.946191,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-07-13T21:30:12.227Z",
        "type": "Periodical"
    },
    {
        "id": 421842836,
        "latitude": 40.7668683,
        "longitude": -73.9467058,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-07-13T20:31:23.581Z",
        "type": "Periodical"
    },
    {
        "id": 421839585,
        "latitude": 40.7662557,
        "longitude": -73.9468925,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-13T20:14:20.190Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 421805798,
        "latitude": 40.7621914,
        "longitude": -73.9500269,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-13T19:12:36.950Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 421797578,
        "latitude": 40.7638812,
        "longitude": -73.9775122,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-13T18:57:15.453Z",
        "type": "Periodical"
    },
    {
        "id": 421784805,
        "latitude": 40.7661764,
        "longitude": -73.9822564,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-13T18:25:00.713Z",
        "type": "Periodical"
    },
    {
        "id": 421775616,
        "latitude": 40.7661764,
        "longitude": -73.9822564,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-13T17:52:18.294Z",
        "type": "Periodical"
    },
    {
        "id": 421775615,
        "latitude": 40.7663015,
        "longitude": -73.9816477,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-13T17:10:03.735Z",
        "type": "Periodical"
    },
    {
        "id": 421775614,
        "latitude": 40.7663015,
        "longitude": -73.9816477,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-07-13T17:00:03.732Z",
        "type": "Periodical"
    },
    {
        "id": 421732059,
        "latitude": 40.7661324,
        "longitude": -73.9814174,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-13T16:25:03.781Z",
        "type": "Periodical"
    },
    {
        "id": 421732060,
        "latitude": 40.7661324,
        "longitude": -73.9814174,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-13T16:25:03.781Z",
        "type": "Periodical"
    },
    {
        "id": 421706981,
        "latitude": 40.7665129,
        "longitude": -73.982678,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-07-13T15:50:30.731Z",
        "type": "Periodical"
    },
    {
        "id": 421687544,
        "latitude": 40.7664016,
        "longitude": -73.9827959,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-13T15:08:33.162Z",
        "type": "Periodical"
    },
    {
        "id": 421677005,
        "latitude": 40.766371,
        "longitude": -73.9828409,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-13T14:46:43.810Z",
        "type": "Periodical"
    },
    {
        "id": 421665205,
        "latitude": 40.7662926,
        "longitude": -73.9828495,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-13T14:15:54.239Z",
        "type": "Periodical"
    },
    {
        "id": 421665204,
        "latitude": 40.766358,
        "longitude": -73.9828137,
        "accuracy": 55,
        "source": "Fused",
        "ts": "2024-07-13T13:41:03.966Z",
        "type": "Periodical"
    },
    {
        "id": 421640797,
        "latitude": 40.7662341,
        "longitude": -73.9824119,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-13T13:18:18.047Z",
        "type": "Periodical"
    },
    {
        "id": 421623218,
        "latitude": 40.7661885,
        "longitude": -73.9822362,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-13T12:44:17.457Z",
        "type": "Periodical"
    },
    {
        "id": 421613961,
        "latitude": 40.7590893,
        "longitude": -73.9530526,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-13T12:12:32.563Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 421189110,
        "latitude": 40.7624204,
        "longitude": -73.9497852,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-12T19:22:51.502Z",
        "type": "Periodical"
    },
    {
        "id": 421189105,
        "latitude": 40.7624204,
        "longitude": -73.9497852,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-12T19:22:51.502Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 421169696,
        "latitude": 40.7447532,
        "longitude": -73.9183073,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-12T18:42:12.949Z",
        "type": "Periodical"
    },
    {
        "id": 421160022,
        "latitude": 40.7447387,
        "longitude": -73.9183047,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-12T18:22:45.392Z",
        "type": "Periodical"
    },
    {
        "id": 421148338,
        "latitude": 40.744758,
        "longitude": -73.9183009,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-12T17:58:48.331Z",
        "type": "Periodical"
    },
    {
        "id": 421138934,
        "latitude": 40.744758,
        "longitude": -73.9183009,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-12T17:38:48.329Z",
        "type": "Periodical"
    },
    {
        "id": 421124589,
        "latitude": 40.744758,
        "longitude": -73.9183009,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-12T17:08:48.328Z",
        "type": "Periodical"
    },
    {
        "id": 421105247,
        "latitude": 40.7447675,
        "longitude": -73.9182837,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-12T16:28:43.897Z",
        "type": "Periodical"
    },
    {
        "id": 421094963,
        "latitude": 40.744022,
        "longitude": -73.9185012,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-12T16:07:00.726Z",
        "type": "Periodical"
    },
    {
        "id": 421082174,
        "latitude": 40.7447517,
        "longitude": -73.9183083,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-12T15:40:03.936Z",
        "type": "Periodical"
    },
    {
        "id": 421074108,
        "latitude": 40.7455481,
        "longitude": -73.9172771,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-12T15:17:53.518Z",
        "type": "Periodical"
    },
    {
        "id": 421055274,
        "latitude": 40.7678092,
        "longitude": -73.9358297,
        "accuracy": 55,
        "source": "Fused",
        "ts": "2024-07-12T14:40:07.830Z",
        "type": "Periodical"
    },
    {
        "id": 421054870,
        "latitude": 40.7677722,
        "longitude": -73.9377569,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-07-12T14:19:00.255Z",
        "type": "Periodical"
    },
    {
        "id": 421054869,
        "latitude": 40.7639595,
        "longitude": -73.9411965,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-12T14:11:00.225Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 420644051,
        "latitude": 40.762428,
        "longitude": -73.9497955,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-11T21:43:41.870Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 420640663,
        "latitude": 40.763988,
        "longitude": -73.977451,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-11T21:21:31.846Z",
        "type": "Periodical"
    },
    {
        "id": 420614317,
        "latitude": 40.7661762,
        "longitude": -73.9820842,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-07-11T20:45:29.875Z",
        "type": "Periodical"
    },
    {
        "id": 420602524,
        "latitude": 40.7662325,
        "longitude": -73.9824107,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-11T20:22:36.978Z",
        "type": "Periodical"
    },
    {
        "id": 420598745,
        "latitude": 40.766245,
        "longitude": -73.9824598,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-11T19:44:36.257Z",
        "type": "Periodical"
    },
    {
        "id": 420579653,
        "latitude": 40.7663257,
        "longitude": -73.982724,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-11T19:26:55.414Z",
        "type": "Periodical"
    },
    {
        "id": 420555191,
        "latitude": 40.7662528,
        "longitude": -73.9824762,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-11T18:44:25.005Z",
        "type": "Periodical"
    },
    {
        "id": 420535515,
        "latitude": 40.7662471,
        "longitude": -73.9824397,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-11T18:05:47.076Z",
        "type": "Periodical"
    },
    {
        "id": 420520990,
        "latitude": 40.7662872,
        "longitude": -73.9825513,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-11T17:41:17.004Z",
        "type": "Periodical"
    },
    {
        "id": 420520994,
        "latitude": 40.7662813,
        "longitude": -73.9826517,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-11T17:38:26.896Z",
        "type": "Periodical"
    },
    {
        "id": 420518832,
        "latitude": 40.7662444,
        "longitude": -73.9824179,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-11T17:35:56.362Z",
        "type": "Periodical"
    },
    {
        "id": 420518831,
        "latitude": 40.7662957,
        "longitude": -73.9826908,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-11T16:55:55.969Z",
        "type": "Periodical"
    },
    {
        "id": 420491288,
        "latitude": 40.7662177,
        "longitude": -73.9822901,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-11T16:19:53.883Z",
        "type": "Periodical"
    },
    {
        "id": 420466642,
        "latitude": 40.7589839,
        "longitude": -73.9534553,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-11T15:41:01.194Z",
        "type": "Periodical"
    },
    {
        "id": 420460738,
        "latitude": 40.7599723,
        "longitude": -73.9524004,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-11T15:34:42.474Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 420090191,
        "latitude": 40.7627922,
        "longitude": -73.9496081,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-11T00:10:44.844Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 420083730,
        "latitude": 40.7682137,
        "longitude": -73.9358889,
        "accuracy": 110,
        "source": "Fused",
        "ts": "2024-07-11T00:00:36.317Z",
        "type": "Periodical"
    },
    {
        "id": 420083729,
        "latitude": 40.7680347,
        "longitude": -73.9368352,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-07-10T23:40:35.132Z",
        "type": "Periodical"
    },
    {
        "id": 420057066,
        "latitude": 40.7444524,
        "longitude": -73.9184819,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-10T23:05:13.965Z",
        "type": "Periodical"
    },
    {
        "id": 420053831,
        "latitude": 40.7661991,
        "longitude": -73.9460718,
        "accuracy": 104,
        "source": "Fused",
        "ts": "2024-07-10T22:41:04.251Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 420017519,
        "latitude": 40.7623627,
        "longitude": -73.9498102,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-10T21:44:31.939Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 420013215,
        "latitude": 40.7588998,
        "longitude": -73.9527385,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-10T21:35:58.209Z",
        "type": "Periodical"
    },
    {
        "id": 420000793,
        "latitude": 40.7660936,
        "longitude": -73.9813966,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-07-10T21:10:41.205Z",
        "type": "Periodical"
    },
    {
        "id": 419985573,
        "latitude": 40.7662631,
        "longitude": -73.9824899,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-10T20:35:54.390Z",
        "type": "Periodical"
    },
    {
        "id": 419962068,
        "latitude": 40.7662664,
        "longitude": -73.982567,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-10T19:54:04.541Z",
        "type": "Periodical"
    },
    {
        "id": 419942484,
        "latitude": 40.7662639,
        "longitude": -73.9825771,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-10T19:14:54.080Z",
        "type": "Periodical"
    },
    {
        "id": 419932016,
        "latitude": 40.766118,
        "longitude": -73.9815995,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-10T18:53:58.892Z",
        "type": "Periodical"
    },
    {
        "id": 419922996,
        "latitude": 40.766142,
        "longitude": -73.9821309,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-10T18:35:04.847Z",
        "type": "Periodical"
    },
    {
        "id": 419907593,
        "latitude": 40.7661864,
        "longitude": -73.9824895,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-10T18:02:27.632Z",
        "type": "Periodical"
    },
    {
        "id": 419893900,
        "latitude": 40.7662527,
        "longitude": -73.9825317,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-10T17:28:09.717Z",
        "type": "Periodical"
    },
    {
        "id": 419885936,
        "latitude": 40.7662587,
        "longitude": -73.9825355,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-10T17:10:00.593Z",
        "type": "Periodical"
    },
    {
        "id": 419865219,
        "latitude": 40.7663756,
        "longitude": -73.9828109,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-10T16:33:48.163Z",
        "type": "Periodical"
    },
    {
        "id": 419853402,
        "latitude": 40.7664228,
        "longitude": -73.9828279,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-10T16:06:46.946Z",
        "type": "Periodical"
    },
    {
        "id": 419844228,
        "latitude": 40.7590361,
        "longitude": -73.9529614,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-10T15:46:50.154Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 419795700,
        "latitude": 40.7624452,
        "longitude": -73.949731,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-10T13:56:52.194Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 419789148,
        "latitude": 40.7602508,
        "longitude": -73.9520256,
        "accuracy": 161,
        "source": "Fused",
        "ts": "2024-07-10T13:34:07.636Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 419522497,
        "latitude": 40.7624427,
        "longitude": -73.9498227,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-10T02:05:14.608Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 419513785,
        "latitude": 40.7645469,
        "longitude": -73.9657967,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-07-10T01:48:17.246Z",
        "type": "Periodical"
    },
    {
        "id": 419504050,
        "latitude": 40.7662658,
        "longitude": -73.9825083,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-10T01:14:35.343Z",
        "type": "Periodical"
    },
    {
        "id": 419491055,
        "latitude": 40.7661986,
        "longitude": -73.9822915,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-07-10T00:34:26.497Z",
        "type": "Periodical"
    },
    {
        "id": 419458353,
        "latitude": 40.7661949,
        "longitude": -73.9823016,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-09T23:56:55.068Z",
        "type": "Periodical"
    },
    {
        "id": 419457983,
        "latitude": 40.766165,
        "longitude": -73.9820708,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-09T23:27:29.270Z",
        "type": "Periodical"
    },
    {
        "id": 419457982,
        "latitude": 40.766165,
        "longitude": -73.9820708,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-09T23:02:29.267Z",
        "type": "Periodical"
    },
    {
        "id": 419457981,
        "latitude": 40.7661891,
        "longitude": -73.9822709,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-09T22:27:15.325Z",
        "type": "Periodical"
    },
    {
        "id": 419397635,
        "latitude": 40.7662301,
        "longitude": -73.9822893,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-09T21:54:23.324Z",
        "type": "Periodical"
    },
    {
        "id": 419397628,
        "latitude": 40.7661866,
        "longitude": -73.9822856,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-09T21:30:41.129Z",
        "type": "Periodical"
    },
    {
        "id": 419397627,
        "latitude": 40.7661866,
        "longitude": -73.9822856,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-07-09T20:37:20.093Z",
        "type": "Periodical"
    },
    {
        "id": 419317154,
        "latitude": 40.7587485,
        "longitude": -73.9533432,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-09T19:56:22.659Z",
        "type": "Periodical"
    },
    {
        "id": 419315477,
        "latitude": 40.7600871,
        "longitude": -73.9520124,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-07-09T19:51:42.400Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 418795095,
        "latitude": 40.7623268,
        "longitude": -73.9498521,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-08T23:23:38.795Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 418786988,
        "latitude": 40.7557823,
        "longitude": -73.927851,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-08T22:54:33.277Z",
        "type": "Periodical"
    },
    {
        "id": 418786987,
        "latitude": 40.7557823,
        "longitude": -73.927851,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-07-08T22:24:33.276Z",
        "type": "Periodical"
    },
    {
        "id": 418748237,
        "latitude": 40.7374037,
        "longitude": -73.9295755,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-07-08T21:53:35.901Z",
        "type": "Periodical"
    },
    {
        "id": 418748226,
        "latitude": 40.6960103,
        "longitude": -73.8526664,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-08T21:31:25.591Z",
        "type": "Periodical"
    },
    {
        "id": 418723261,
        "latitude": 40.5984844,
        "longitude": -73.8202491,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-07-08T21:04:59.214Z",
        "type": "Periodical"
    },
    {
        "id": 418704828,
        "latitude": 40.5800671,
        "longitude": -73.8247619,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-07-08T20:30:50.796Z",
        "type": "Periodical"
    },
    {
        "id": 418687563,
        "latitude": 40.5809102,
        "longitude": -73.8268921,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-08T19:59:38.061Z",
        "type": "Periodical"
    },
    {
        "id": 418666221,
        "latitude": 40.5816681,
        "longitude": -73.8246485,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-08T19:19:38.059Z",
        "type": "Periodical"
    },
    {
        "id": 418652691,
        "latitude": 40.5818427,
        "longitude": -73.824624,
        "accuracy": 89,
        "source": "Fused",
        "ts": "2024-07-08T18:46:33.121Z",
        "type": "Periodical"
    },
    {
        "id": 418644624,
        "latitude": 40.5808734,
        "longitude": -73.8261661,
        "accuracy": 113,
        "source": "Fused",
        "ts": "2024-07-08T18:31:27.973Z",
        "type": "Periodical"
    },
    {
        "id": 418626590,
        "latitude": 40.5800874,
        "longitude": -73.8247955,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-07-08T18:02:12.779Z",
        "type": "Periodical"
    },
    {
        "id": 418609302,
        "latitude": 40.5812282,
        "longitude": -73.8268902,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-07-08T17:29:11.907Z",
        "type": "Periodical"
    },
    {
        "id": 418593246,
        "latitude": 40.5812111,
        "longitude": -73.8259581,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-07-08T16:54:46.687Z",
        "type": "Periodical"
    },
    {
        "id": 418583867,
        "latitude": 40.5816348,
        "longitude": -73.824865,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-08T16:35:00.416Z",
        "type": "Periodical"
    },
    {
        "id": 418570677,
        "latitude": 40.581209,
        "longitude": -73.8258692,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-07-08T16:07:54.062Z",
        "type": "Periodical"
    },
    {
        "id": 418561314,
        "latitude": 40.5816703,
        "longitude": -73.8249028,
        "accuracy": 138,
        "source": "Fused",
        "ts": "2024-07-08T15:42:19.908Z",
        "type": "Periodical"
    },
    {
        "id": 418545568,
        "latitude": 40.5816703,
        "longitude": -73.8249028,
        "accuracy": 138,
        "source": "Fused",
        "ts": "2024-07-08T15:12:19.907Z",
        "type": "Periodical"
    },
    {
        "id": 418534860,
        "latitude": 40.5805991,
        "longitude": -73.8258237,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-07-08T14:48:40.661Z",
        "type": "Periodical"
    },
    {
        "id": 418522276,
        "latitude": 40.6516409,
        "longitude": -73.8388212,
        "accuracy": 69,
        "source": "Fused",
        "ts": "2024-07-08T14:20:00.425Z",
        "type": "Periodical"
    },
    {
        "id": 418505561,
        "latitude": 40.7689441,
        "longitude": -73.9117356,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-07-08T13:41:00.192Z",
        "type": "Periodical"
    },
    {
        "id": 418495873,
        "latitude": 40.7646205,
        "longitude": -73.9323502,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-08T13:17:40.591Z",
        "type": "Periodical"
    },
    {
        "id": 418494840,
        "latitude": 40.7606219,
        "longitude": -73.9377042,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-08T13:14:49.717Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 418200328,
        "latitude": 40.7618186,
        "longitude": -73.9499597,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-08T00:51:37.226Z",
        "type": "Periodical"
    },
    {
        "id": 418200329,
        "latitude": 40.7618186,
        "longitude": -73.9499597,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-08T00:51:37.226Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 418190298,
        "latitude": 40.7613511,
        "longitude": -73.9640721,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-08T00:30:26.535Z",
        "type": "Periodical"
    },
    {
        "id": 418180855,
        "latitude": 40.7618534,
        "longitude": -73.9649211,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-08T00:10:10.210Z",
        "type": "Periodical"
    },
    {
        "id": 418165943,
        "latitude": 40.7618534,
        "longitude": -73.9649211,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-07T23:40:10.208Z",
        "type": "Periodical"
    },
    {
        "id": 418155669,
        "latitude": 40.7618316,
        "longitude": -73.9649222,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-07T23:17:53.027Z",
        "type": "Periodical"
    },
    {
        "id": 418141183,
        "latitude": 40.7618507,
        "longitude": -73.9649123,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-07T22:47:32.122Z",
        "type": "Periodical"
    },
    {
        "id": 418129113,
        "latitude": 40.7591236,
        "longitude": -73.9531452,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-07T22:21:37.897Z",
        "type": "Periodical"
    },
    {
        "id": 418128192,
        "latitude": 40.7590367,
        "longitude": -73.9530743,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-07-07T22:19:58.855Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 418029037,
        "latitude": 40.7626433,
        "longitude": -73.9496997,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-07-07T18:53:27.982Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 418025891,
        "latitude": 40.7611359,
        "longitude": -73.9508509,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-07T18:48:56.689Z",
        "type": "Periodical"
    },
    {
        "id": 418008782,
        "latitude": 40.7586456,
        "longitude": -73.9528208,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-07T18:14:04.136Z",
        "type": "Periodical"
    },
    {
        "id": 417997201,
        "latitude": 40.7598983,
        "longitude": -73.9519189,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-07T17:51:09.620Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 417613426,
        "latitude": 40.7624503,
        "longitude": -73.9498445,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-07T02:02:46.501Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 417603036,
        "latitude": 40.7638713,
        "longitude": -73.9775147,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-07T01:42:28.517Z",
        "type": "Periodical"
    },
    {
        "id": 417603035,
        "latitude": 40.7643713,
        "longitude": -73.9770557,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-07T01:41:04.573Z",
        "type": "Periodical"
    },
    {
        "id": 417587332,
        "latitude": 40.7663722,
        "longitude": -73.9828424,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-07T01:11:40.033Z",
        "type": "Periodical"
    },
    {
        "id": 417580641,
        "latitude": 40.7663685,
        "longitude": -73.9828321,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-07T00:58:38.448Z",
        "type": "Periodical"
    },
    {
        "id": 417563206,
        "latitude": 40.7666405,
        "longitude": -73.9830737,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-07-07T00:23:52.332Z",
        "type": "Periodical"
    },
    {
        "id": 417547262,
        "latitude": 40.7662203,
        "longitude": -73.9824602,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-06T23:50:39.462Z",
        "type": "Periodical"
    },
    {
        "id": 417531463,
        "latitude": 40.7664259,
        "longitude": -73.9828669,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-06T23:17:54.740Z",
        "type": "Periodical"
    },
    {
        "id": 417520151,
        "latitude": 40.7663724,
        "longitude": -73.9828517,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-07-06T22:53:46.029Z",
        "type": "Periodical"
    },
    {
        "id": 417508667,
        "latitude": 40.7668317,
        "longitude": -73.9829163,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-07-06T22:29:28.453Z",
        "type": "Periodical"
    },
    {
        "id": 417489566,
        "latitude": 40.7667958,
        "longitude": -73.9829381,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-07-06T21:48:51.761Z",
        "type": "Periodical"
    },
    {
        "id": 417475210,
        "latitude": 40.7662086,
        "longitude": -73.9824446,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-06T21:19:56.152Z",
        "type": "Periodical"
    },
    {
        "id": 417460658,
        "latitude": 40.7664106,
        "longitude": -73.9828088,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-07-06T20:50:00.445Z",
        "type": "Periodical"
    },
    {
        "id": 417442684,
        "latitude": 40.7661395,
        "longitude": -73.981087,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-06T20:13:01.465Z",
        "type": "Periodical"
    },
    {
        "id": 417429210,
        "latitude": 40.7611146,
        "longitude": -73.9503406,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-06T19:45:54.461Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 416985798,
        "latitude": 40.7624495,
        "longitude": -73.9498447,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-06T01:23:34.316Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 416977697,
        "latitude": 40.759009,
        "longitude": -73.9530171,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-06T01:06:47.215Z",
        "type": "Periodical"
    },
    {
        "id": 416959582,
        "latitude": 40.7663071,
        "longitude": -73.9826332,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-06T00:31:03.240Z",
        "type": "Periodical"
    },
    {
        "id": 416947011,
        "latitude": 40.7662592,
        "longitude": -73.9825061,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-06T00:05:10.785Z",
        "type": "Periodical"
    },
    {
        "id": 416928807,
        "latitude": 40.7663176,
        "longitude": -73.9825545,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-07-05T23:26:57.796Z",
        "type": "Periodical"
    },
    {
        "id": 416912889,
        "latitude": 40.766296,
        "longitude": -73.9825981,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-05T22:52:00.578Z",
        "type": "Periodical"
    },
    {
        "id": 416897440,
        "latitude": 40.7662224,
        "longitude": -73.9822065,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-05T22:21:00.152Z",
        "type": "Periodical"
    },
    {
        "id": 416883524,
        "latitude": 40.7662698,
        "longitude": -73.9826617,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-05T21:53:35.268Z",
        "type": "Periodical"
    },
    {
        "id": 416866434,
        "latitude": 40.7663869,
        "longitude": -73.9828497,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-05T21:19:06.433Z",
        "type": "Periodical"
    },
    {
        "id": 416852028,
        "latitude": 40.7662369,
        "longitude": -73.982443,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-05T20:50:05.361Z",
        "type": "Periodical"
    },
    {
        "id": 416840920,
        "latitude": 40.7664052,
        "longitude": -73.9827743,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-05T20:27:45.459Z",
        "type": "Periodical"
    },
    {
        "id": 416825455,
        "latitude": 40.7664072,
        "longitude": -73.9827782,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-05T19:56:55.218Z",
        "type": "Periodical"
    },
    {
        "id": 416810390,
        "latitude": 40.7660235,
        "longitude": -73.9819635,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-05T19:26:05.077Z",
        "type": "Periodical"
    },
    {
        "id": 416795414,
        "latitude": 40.7588579,
        "longitude": -73.9526726,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-05T18:55:04.121Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 416790557,
        "latitude": 40.7615549,
        "longitude": -73.9500687,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-07-05T18:44:50.964Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 416789287,
        "latitude": 40.7594643,
        "longitude": -73.9529028,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-05T18:40:52.686Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 416332900,
        "latitude": 40.7617448,
        "longitude": -73.949892,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-05T00:14:32.829Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 416330983,
        "latitude": 40.7596397,
        "longitude": -73.9523474,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-05T00:10:12.307Z",
        "type": "Periodical"
    },
    {
        "id": 416315543,
        "latitude": 40.7662198,
        "longitude": -73.9823229,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-04T23:37:38.019Z",
        "type": "Periodical"
    },
    {
        "id": 416299452,
        "latitude": 40.7662096,
        "longitude": -73.9824211,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-04T23:02:13.314Z",
        "type": "Periodical"
    },
    {
        "id": 416281234,
        "latitude": 40.7663742,
        "longitude": -73.9828348,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-04T22:21:20.502Z",
        "type": "Periodical"
    },
    {
        "id": 416268100,
        "latitude": 40.7663166,
        "longitude": -73.9822135,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-04T21:55:00.091Z",
        "type": "Periodical"
    },
    {
        "id": 416250876,
        "latitude": 40.7662659,
        "longitude": -73.9825369,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-04T21:18:00.373Z",
        "type": "Periodical"
    },
    {
        "id": 416234264,
        "latitude": 40.7661806,
        "longitude": -73.9824342,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-07-04T20:43:05.397Z",
        "type": "Periodical"
    },
    {
        "id": 416220668,
        "latitude": 40.7662353,
        "longitude": -73.9820846,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-07-04T20:14:15.299Z",
        "type": "Periodical"
    },
    {
        "id": 416206867,
        "latitude": 40.7663433,
        "longitude": -73.9826874,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-04T19:46:17.422Z",
        "type": "Periodical"
    },
    {
        "id": 416195467,
        "latitude": 40.7662346,
        "longitude": -73.9822948,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-04T19:20:49.820Z",
        "type": "Periodical"
    },
    {
        "id": 416182892,
        "latitude": 40.7662529,
        "longitude": -73.982588,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-04T18:54:02.919Z",
        "type": "Periodical"
    },
    {
        "id": 416174442,
        "latitude": 40.7662856,
        "longitude": -73.9826858,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-04T18:35:21.702Z",
        "type": "Periodical"
    },
    {
        "id": 416154625,
        "latitude": 40.7638246,
        "longitude": -73.9775528,
        "accuracy": 434,
        "source": "Fused",
        "ts": "2024-07-04T17:52:21.065Z",
        "type": "Periodical"
    },
    {
        "id": 416148519,
        "latitude": 40.7588895,
        "longitude": -73.9527312,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-04T17:41:08.634Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 415777693,
        "latitude": 40.7628358,
        "longitude": -73.9495713,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-04T02:15:58.928Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 415768041,
        "latitude": 40.7542371,
        "longitude": -73.9846754,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-04T01:56:50.359Z",
        "type": "Periodical"
    },
    {
        "id": 415757377,
        "latitude": 40.7492941,
        "longitude": -73.9956428,
        "accuracy": 2,
        "source": "Fused",
        "ts": "2024-07-04T01:35:23.927Z",
        "type": "Periodical"
    },
    {
        "id": 415743041,
        "latitude": 40.7523268,
        "longitude": -73.9945796,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-07-04T01:06:09.491Z",
        "type": "Periodical"
    },
    {
        "id": 415724421,
        "latitude": 40.7522727,
        "longitude": -73.9945195,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-07-04T00:30:08.374Z",
        "type": "Periodical"
    },
    {
        "id": 415712896,
        "latitude": 40.7523678,
        "longitude": -73.994502,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-07-04T00:07:13.940Z",
        "type": "Periodical"
    },
    {
        "id": 415701612,
        "latitude": 40.7524595,
        "longitude": -73.9945888,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-03T23:45:14.082Z",
        "type": "Periodical"
    },
    {
        "id": 415686651,
        "latitude": 40.7590539,
        "longitude": -73.9529961,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-03T23:14:11.149Z",
        "type": "Periodical"
    },
    {
        "id": 415679408,
        "latitude": 40.7594306,
        "longitude": -73.9523702,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-03T23:00:21.343Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 415664010,
        "latitude": 40.7624263,
        "longitude": -73.9497551,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-03T22:29:31.035Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 415650310,
        "latitude": 40.7661394,
        "longitude": -73.9816942,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-07-03T22:01:07.083Z",
        "type": "Periodical"
    },
    {
        "id": 415641268,
        "latitude": 40.7661777,
        "longitude": -73.982225,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-03T21:42:53.939Z",
        "type": "Periodical"
    },
    {
        "id": 415622401,
        "latitude": 40.7661398,
        "longitude": -73.9819612,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-03T21:04:06.074Z",
        "type": "Periodical"
    },
    {
        "id": 415606397,
        "latitude": 40.7661098,
        "longitude": -73.9815524,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-03T20:31:28.299Z",
        "type": "Periodical"
    },
    {
        "id": 415598189,
        "latitude": 40.7661849,
        "longitude": -73.9816478,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-07-03T20:13:19.297Z",
        "type": "Periodical"
    },
    {
        "id": 415579653,
        "latitude": 40.7662912,
        "longitude": -73.9826818,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-03T19:39:29.252Z",
        "type": "Periodical"
    },
    {
        "id": 415561738,
        "latitude": 40.7662828,
        "longitude": -73.9822472,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-07-03T19:06:29.633Z",
        "type": "Periodical"
    },
    {
        "id": 415543972,
        "latitude": 40.7664067,
        "longitude": -73.9827765,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-03T18:32:26.713Z",
        "type": "Periodical"
    },
    {
        "id": 415522591,
        "latitude": 40.7662808,
        "longitude": -73.9824833,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-07-03T17:51:45.673Z",
        "type": "Periodical"
    },
    {
        "id": 415511057,
        "latitude": 40.7663587,
        "longitude": -73.9828055,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-03T17:28:44.313Z",
        "type": "Periodical"
    },
    {
        "id": 415491920,
        "latitude": 40.759027,
        "longitude": -73.9529593,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-03T16:48:26.631Z",
        "type": "Periodical"
    },
    {
        "id": 415487638,
        "latitude": 40.760714,
        "longitude": -73.9513668,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-07-03T16:39:25.071Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 415077135,
        "latitude": 40.7622445,
        "longitude": -73.9510181,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-07-03T00:14:58.057Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 415077037,
        "latitude": 40.7659602,
        "longitude": -73.9515062,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-03T00:14:47.948Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 415076895,
        "latitude": 40.76235,
        "longitude": -73.9508457,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-03T00:14:28.081Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 415076857,
        "latitude": 40.7664564,
        "longitude": -73.9513307,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-07-03T00:14:17.893Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 415075761,
        "latitude": 40.76333,
        "longitude": -73.9500085,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-07-03T00:12:12.154Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 415072911,
        "latitude": 40.7652215,
        "longitude": -73.9478242,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-07-03T00:06:28.648Z",
        "type": "Periodical"
    },
    {
        "id": 415062217,
        "latitude": 40.764798,
        "longitude": -73.9474,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-02T23:44:50.365Z",
        "type": "Periodical"
    },
    {
        "id": 415043418,
        "latitude": 40.7647958,
        "longitude": -73.9473862,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-02T23:08:31.734Z",
        "type": "Periodical"
    },
    {
        "id": 415029897,
        "latitude": 40.7648018,
        "longitude": -73.9474082,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-02T22:42:11.575Z",
        "type": "Periodical"
    },
    {
        "id": 415018586,
        "latitude": 40.7642932,
        "longitude": -73.9477554,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-07-02T22:20:20.632Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 414995614,
        "latitude": 40.7626023,
        "longitude": -73.9496967,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-07-02T21:35:44.413Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 414990098,
        "latitude": 40.758765,
        "longitude": -73.9525316,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-07-02T21:25:15.773Z",
        "type": "Periodical"
    },
    {
        "id": 414969627,
        "latitude": 40.7664162,
        "longitude": -73.982595,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-02T20:45:04.719Z",
        "type": "Periodical"
    },
    {
        "id": 414957222,
        "latitude": 40.7663239,
        "longitude": -73.9825039,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-07-02T20:21:44.666Z",
        "type": "Periodical"
    },
    {
        "id": 414947926,
        "latitude": 40.7664793,
        "longitude": -73.982606,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-07-02T20:04:45.447Z",
        "type": "Periodical"
    },
    {
        "id": 414919244,
        "latitude": 40.7661701,
        "longitude": -73.9817123,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-07-02T19:13:04.085Z",
        "type": "Periodical"
    },
    {
        "id": 414902394,
        "latitude": 40.7662321,
        "longitude": -73.9824478,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-07-02T18:41:48.052Z",
        "type": "Periodical"
    },
    {
        "id": 414884231,
        "latitude": 40.7662265,
        "longitude": -73.9824615,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-02T18:05:13.439Z",
        "type": "Periodical"
    },
    {
        "id": 414874072,
        "latitude": 40.7663736,
        "longitude": -73.982845,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-02T17:45:26.016Z",
        "type": "Periodical"
    },
    {
        "id": 414856173,
        "latitude": 40.7662388,
        "longitude": -73.9824609,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-02T17:17:42.980Z",
        "type": "Periodical"
    },
    {
        "id": 414844440,
        "latitude": 40.7662168,
        "longitude": -73.9823055,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-07-02T16:54:03.538Z",
        "type": "Periodical"
    },
    {
        "id": 414827827,
        "latitude": 40.7663911,
        "longitude": -73.9827625,
        "accuracy": 425,
        "source": "Fused",
        "ts": "2024-07-02T16:21:24.244Z",
        "type": "Periodical"
    },
    {
        "id": 414813231,
        "latitude": 40.7649695,
        "longitude": -73.9668212,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-07-02T15:50:00.750Z",
        "type": "Periodical"
    },
    {
        "id": 414807663,
        "latitude": 40.761117,
        "longitude": -73.9503235,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-07-02T15:37:56.026Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 414805101,
        "latitude": 40.7634706,
        "longitude": -73.948324,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-02T15:32:16.016Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 414795637,
        "latitude": 40.7654402,
        "longitude": -73.9475061,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-02T15:12:09.516Z",
        "type": "Periodical"
    },
    {
        "id": 414782305,
        "latitude": 40.7653574,
        "longitude": -73.9475614,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-02T14:43:19.144Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 414780111,
        "latitude": 40.7631953,
        "longitude": -73.9487983,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-07-02T14:38:35.868Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 414770324,
        "latitude": 40.7550923,
        "longitude": -73.962496,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-07-02T14:17:00.149Z",
        "type": "Periodical"
    },
    {
        "id": 414749347,
        "latitude": 40.7550313,
        "longitude": -73.9562885,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-02T13:31:15.407Z",
        "type": "Periodical"
    },
    {
        "id": 414748077,
        "latitude": 40.7604329,
        "longitude": -73.9584511,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-07-02T13:28:10.500Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 414431620,
        "latitude": 40.7623394,
        "longitude": -73.9498548,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-02T00:21:00.438Z",
        "type": "Periodical"
    },
    {
        "id": 414431613,
        "latitude": 40.7624741,
        "longitude": -73.9498285,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-07-02T00:19:38.431Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 414414914,
        "latitude": 40.7663127,
        "longitude": -73.9466522,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-01T23:46:57.257Z",
        "type": "Periodical"
    },
    {
        "id": 414414012,
        "latitude": 40.7655237,
        "longitude": -73.9475209,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-07-01T23:45:16.040Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 414402524,
        "latitude": 40.7627659,
        "longitude": -73.9493636,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-01T23:21:55.426Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 414396028,
        "latitude": 40.7663587,
        "longitude": -73.9467937,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-07-01T23:08:36.059Z",
        "type": "Periodical"
    },
    {
        "id": 414379488,
        "latitude": 40.7664082,
        "longitude": -73.946382,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-07-01T22:36:34.772Z",
        "type": "Periodical"
    },
    {
        "id": 414362966,
        "latitude": 40.766552,
        "longitude": -73.9468808,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-07-01T22:04:22.089Z",
        "type": "Periodical"
    },
    {
        "id": 414348037,
        "latitude": 40.7667533,
        "longitude": -73.9466441,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-07-01T21:34:51.834Z",
        "type": "Periodical"
    },
    {
        "id": 414329895,
        "latitude": 40.7637202,
        "longitude": -73.948195,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-07-01T20:59:12.317Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 414241694,
        "latitude": 40.7623484,
        "longitude": -73.9498832,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-01T18:06:56.040Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 414241696,
        "latitude": 40.7623484,
        "longitude": -73.9498832,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-01T18:06:56.040Z",
        "type": "Periodical"
    },
    {
        "id": 414226652,
        "latitude": 40.761123,
        "longitude": -73.9643775,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-07-01T17:37:51.048Z",
        "type": "Periodical"
    },
    {
        "id": 414214656,
        "latitude": 40.7598179,
        "longitude": -73.9611493,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-07-01T17:14:20.755Z",
        "type": "Periodical"
    },
    {
        "id": 414205520,
        "latitude": 40.7576201,
        "longitude": -73.9544601,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-07-01T16:56:04.338Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 413688145,
        "latitude": 40.7623368,
        "longitude": -73.9498432,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-30T20:57:53.329Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 413682438,
        "latitude": 40.7600039,
        "longitude": -73.9520659,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-30T20:43:50.351Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 413229999,
        "latitude": 40.7627304,
        "longitude": -73.9496728,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-30T02:38:10.198Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 413221367,
        "latitude": 40.7638583,
        "longitude": -73.9775098,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-30T02:20:32.629Z",
        "type": "Periodical"
    },
    {
        "id": 413209543,
        "latitude": 40.7644506,
        "longitude": -73.9771891,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-30T01:55:37.825Z",
        "type": "Periodical"
    },
    {
        "id": 413198321,
        "latitude": 40.7662807,
        "longitude": -73.9826415,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-30T01:33:01.506Z",
        "type": "Periodical"
    },
    {
        "id": 413179074,
        "latitude": 40.7661856,
        "longitude": -73.9824506,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-06-30T00:55:00.817Z",
        "type": "Periodical"
    },
    {
        "id": 413162351,
        "latitude": 40.7662319,
        "longitude": -73.9824384,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-30T00:20:40.915Z",
        "type": "Periodical"
    },
    {
        "id": 413145214,
        "latitude": 40.766222,
        "longitude": -73.9824323,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-06-29T23:45:29.632Z",
        "type": "Periodical"
    },
    {
        "id": 413128765,
        "latitude": 40.7661481,
        "longitude": -73.9814839,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-06-29T23:11:30.307Z",
        "type": "Periodical"
    },
    {
        "id": 413119360,
        "latitude": 40.7662891,
        "longitude": -73.982664,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-29T22:53:12.374Z",
        "type": "Periodical"
    },
    {
        "id": 413105260,
        "latitude": 40.7662226,
        "longitude": -73.9824339,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-29T22:26:52.351Z",
        "type": "Periodical"
    },
    {
        "id": 413089211,
        "latitude": 40.7662693,
        "longitude": -73.9826073,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-29T21:52:26.454Z",
        "type": "Periodical"
    },
    {
        "id": 413073263,
        "latitude": 40.7662512,
        "longitude": -73.9824427,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-06-29T21:19:46.672Z",
        "type": "Periodical"
    },
    {
        "id": 413063515,
        "latitude": 40.766192,
        "longitude": -73.982351,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-29T20:58:53.210Z",
        "type": "Periodical"
    },
    {
        "id": 413045245,
        "latitude": 40.7662011,
        "longitude": -73.9823932,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-29T20:24:14.967Z",
        "type": "Periodical"
    },
    {
        "id": 413025977,
        "latitude": 40.759019,
        "longitude": -73.952921,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-29T19:46:29.326Z",
        "type": "Periodical"
    },
    {
        "id": 413022146,
        "latitude": 40.7595278,
        "longitude": -73.9529329,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-29T19:39:22.741Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 412967807,
        "latitude": 40.7623121,
        "longitude": -73.9498729,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-06-29T17:50:14.244Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 412963384,
        "latitude": 40.7520916,
        "longitude": -73.9288264,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-29T17:35:49.190Z",
        "type": "Periodical"
    },
    {
        "id": 412944837,
        "latitude": 40.7476528,
        "longitude": -73.8868499,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-29T17:03:04.251Z",
        "type": "Periodical"
    },
    {
        "id": 412927153,
        "latitude": 40.7472793,
        "longitude": -73.886739,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-29T16:25:15.342Z",
        "type": "Periodical"
    },
    {
        "id": 412917359,
        "latitude": 40.758662,
        "longitude": -73.9520934,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-06-29T15:58:05.589Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 412587077,
        "latitude": 40.7616778,
        "longitude": -73.9499988,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-29T02:15:57.154Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 412587078,
        "latitude": 40.7616778,
        "longitude": -73.9499988,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-29T02:15:57.154Z",
        "type": "Periodical"
    },
    {
        "id": 412567414,
        "latitude": 40.7662136,
        "longitude": -73.9823776,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-29T01:37:11.770Z",
        "type": "Periodical"
    },
    {
        "id": 412555103,
        "latitude": 40.7662136,
        "longitude": -73.9823776,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-29T01:12:11.769Z",
        "type": "Periodical"
    },
    {
        "id": 412537183,
        "latitude": 40.7662082,
        "longitude": -73.982387,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-06-29T00:37:27.945Z",
        "type": "Periodical"
    },
    {
        "id": 412519459,
        "latitude": 40.7662519,
        "longitude": -73.9824315,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-29T00:02:48.813Z",
        "type": "Periodical"
    },
    {
        "id": 412506599,
        "latitude": 40.7662498,
        "longitude": -73.9824819,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-28T23:37:33.859Z",
        "type": "Periodical"
    },
    {
        "id": 412484709,
        "latitude": 40.7662202,
        "longitude": -73.9824301,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-28T22:54:54.885Z",
        "type": "Periodical"
    },
    {
        "id": 412469307,
        "latitude": 40.7662857,
        "longitude": -73.9821947,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-28T22:24:19.423Z",
        "type": "Periodical"
    },
    {
        "id": 412456272,
        "latitude": 40.7664489,
        "longitude": -73.9828856,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-28T21:59:00.573Z",
        "type": "Periodical"
    },
    {
        "id": 412440923,
        "latitude": 40.7664197,
        "longitude": -73.9828928,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-06-28T21:30:09.756Z",
        "type": "Periodical"
    },
    {
        "id": 412421489,
        "latitude": 40.7662003,
        "longitude": -73.982392,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-28T20:52:48.440Z",
        "type": "Periodical"
    },
    {
        "id": 412407655,
        "latitude": 40.7662165,
        "longitude": -73.9824031,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-28T20:26:46.572Z",
        "type": "Periodical"
    },
    {
        "id": 412388097,
        "latitude": 40.7590514,
        "longitude": -73.9529775,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-28T19:49:56.825Z",
        "type": "Periodical"
    },
    {
        "id": 412382408,
        "latitude": 40.7597846,
        "longitude": -73.9523843,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-06-28T19:40:21.584Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 411712273,
        "latitude": 40.7624874,
        "longitude": -73.9498194,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-27T18:57:42.651Z",
        "type": "Periodical"
    },
    {
        "id": 411712271,
        "latitude": 40.7625627,
        "longitude": -73.9497436,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-06-27T18:57:12.685Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 411695287,
        "latitude": 40.7664368,
        "longitude": -73.9468461,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-06-27T18:25:44.009Z",
        "type": "Periodical"
    },
    {
        "id": 411684900,
        "latitude": 40.7664991,
        "longitude": -73.946782,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-06-27T18:05:02.629Z",
        "type": "Periodical"
    },
    {
        "id": 411670543,
        "latitude": 40.7682695,
        "longitude": -73.9493793,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-27T17:35:43.652Z",
        "type": "Periodical"
    },
    {
        "id": 411665472,
        "latitude": 40.7663985,
        "longitude": -73.9463799,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-27T17:12:53.202Z",
        "type": "Periodical"
    },
    {
        "id": 411652388,
        "latitude": 40.7665762,
        "longitude": -73.9467426,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-27T16:52:53.201Z",
        "type": "Periodical"
    },
    {
        "id": 411633872,
        "latitude": 40.7666181,
        "longitude": -73.9513722,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-06-27T16:22:03.822Z",
        "type": "Periodical"
    },
    {
        "id": 411614876,
        "latitude": 40.766297,
        "longitude": -73.9463302,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-27T15:44:54.935Z",
        "type": "Periodical"
    },
    {
        "id": 411609143,
        "latitude": 40.7662212,
        "longitude": -73.9465454,
        "accuracy": 118,
        "source": "Fused",
        "ts": "2024-06-27T15:26:00.406Z",
        "type": "Periodical"
    },
    {
        "id": 411609142,
        "latitude": 40.7656143,
        "longitude": -73.947739,
        "accuracy": 68,
        "source": "Fused",
        "ts": "2024-06-27T15:16:07.290Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 411276638,
        "latitude": 40.7623055,
        "longitude": -73.9498609,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-27T02:00:37.688Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 411275922,
        "latitude": 40.761092,
        "longitude": -73.950701,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-27T01:54:22.517Z",
        "type": "Periodical"
    },
    {
        "id": 411254849,
        "latitude": 40.7661885,
        "longitude": -73.9822457,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-27T01:18:14.294Z",
        "type": "Periodical"
    },
    {
        "id": 411244542,
        "latitude": 40.7661885,
        "longitude": -73.9822457,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-27T00:38:14.291Z",
        "type": "Periodical"
    },
    {
        "id": 411244541,
        "latitude": 40.7662218,
        "longitude": -73.9824048,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-27T00:05:31.049Z",
        "type": "Periodical"
    },
    {
        "id": 411244540,
        "latitude": 40.7662218,
        "longitude": -73.9824048,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-26T23:30:31.045Z",
        "type": "Periodical"
    },
    {
        "id": 411177612,
        "latitude": 40.7663,
        "longitude": -73.9822083,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-26T22:56:31.109Z",
        "type": "Periodical"
    },
    {
        "id": 411160487,
        "latitude": 40.7663911,
        "longitude": -73.9828982,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-06-26T22:25:23.826Z",
        "type": "Periodical"
    },
    {
        "id": 411146306,
        "latitude": 40.7663953,
        "longitude": -73.9828859,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-06-26T21:51:31.818Z",
        "type": "Periodical"
    },
    {
        "id": 411131409,
        "latitude": 40.7662193,
        "longitude": -73.9824647,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-06-26T21:17:00.755Z",
        "type": "Periodical"
    },
    {
        "id": 411104574,
        "latitude": 40.7662124,
        "longitude": -73.9824054,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-26T20:42:48.245Z",
        "type": "Periodical"
    },
    {
        "id": 411088058,
        "latitude": 40.7663677,
        "longitude": -73.9821704,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-06-26T19:59:27.606Z",
        "type": "Periodical"
    },
    {
        "id": 411070321,
        "latitude": 40.7588657,
        "longitude": -73.9523372,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-06-26T19:41:09.305Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 411039682,
        "latitude": 40.7623033,
        "longitude": -73.9498822,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-26T18:44:43.524Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 411031290,
        "latitude": 40.7654082,
        "longitude": -73.9475007,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-26T18:28:22.812Z",
        "type": "Periodical"
    },
    {
        "id": 411020255,
        "latitude": 40.7654315,
        "longitude": -73.9474857,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-26T17:50:30.398Z",
        "type": "Periodical"
    },
    {
        "id": 411002945,
        "latitude": 40.7654404,
        "longitude": -73.9474868,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-26T17:30:30.397Z",
        "type": "Periodical"
    },
    {
        "id": 410990591,
        "latitude": 40.7654403,
        "longitude": -73.9474968,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-26T17:05:30.209Z",
        "type": "Periodical"
    },
    {
        "id": 410977249,
        "latitude": 40.7654275,
        "longitude": -73.947489,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-26T16:37:30.217Z",
        "type": "Periodical"
    },
    {
        "id": 410959516,
        "latitude": 40.7654237,
        "longitude": -73.9474921,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-26T16:00:26.278Z",
        "type": "Periodical"
    },
    {
        "id": 410941449,
        "latitude": 40.7654375,
        "longitude": -73.9474785,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-26T15:20:10.294Z",
        "type": "Periodical"
    },
    {
        "id": 410941448,
        "latitude": 40.7654316,
        "longitude": -73.947493,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-26T14:48:21.789Z",
        "type": "Periodical"
    },
    {
        "id": 410941447,
        "latitude": 40.7654316,
        "longitude": -73.947493,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-26T14:18:21.787Z",
        "type": "Periodical"
    },
    {
        "id": 410941446,
        "latitude": 40.7654299,
        "longitude": -73.9474744,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-26T13:43:21.784Z",
        "type": "Periodical"
    },
    {
        "id": 410879349,
        "latitude": 40.7654316,
        "longitude": -73.9474847,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-26T13:05:00.789Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 410885643,
        "latitude": 40.7654316,
        "longitude": -73.9474847,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-26T13:05:00.789Z",
        "type": "Periodical"
    },
    {
        "id": 410487633,
        "latitude": 40.7625033,
        "longitude": -73.9495007,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-25T21:35:39.205Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 410485534,
        "latitude": 40.7600171,
        "longitude": -73.9521745,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-25T21:31:22.275Z",
        "type": "Periodical"
    },
    {
        "id": 410473213,
        "latitude": 40.7662645,
        "longitude": -73.9824744,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-25T21:06:28.679Z",
        "type": "Periodical"
    },
    {
        "id": 410459626,
        "latitude": 40.7662217,
        "longitude": -73.9823947,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-25T20:41:03.231Z",
        "type": "Periodical"
    },
    {
        "id": 410446835,
        "latitude": 40.7662335,
        "longitude": -73.9824613,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-25T20:16:25.316Z",
        "type": "Periodical"
    },
    {
        "id": 410432173,
        "latitude": 40.7666591,
        "longitude": -73.9828719,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-06-25T19:41:45.438Z",
        "type": "Periodical"
    },
    {
        "id": 410414083,
        "latitude": 40.7661827,
        "longitude": -73.9822209,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-06-25T19:11:12.637Z",
        "type": "Periodical"
    },
    {
        "id": 410396876,
        "latitude": 40.7661893,
        "longitude": -73.9822034,
        "accuracy": 53,
        "source": "Fused",
        "ts": "2024-06-25T18:36:11.522Z",
        "type": "Periodical"
    },
    {
        "id": 410396352,
        "latitude": 40.7662227,
        "longitude": -73.9822522,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-25T18:14:00.781Z",
        "type": "Periodical"
    },
    {
        "id": 410369681,
        "latitude": 40.7661664,
        "longitude": -73.9815914,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-06-25T17:38:57.178Z",
        "type": "Periodical"
    },
    {
        "id": 410369659,
        "latitude": 40.7661789,
        "longitude": -73.9821804,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-06-25T17:13:43.086Z",
        "type": "Periodical"
    },
    {
        "id": 410357427,
        "latitude": 40.7662028,
        "longitude": -73.9822808,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-25T16:43:33.796Z",
        "type": "Periodical"
    },
    {
        "id": 410357426,
        "latitude": 40.7662028,
        "longitude": -73.9822808,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-25T16:23:33.796Z",
        "type": "Periodical"
    },
    {
        "id": 410357425,
        "latitude": 40.7664395,
        "longitude": -73.9824404,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-25T15:49:17.111Z",
        "type": "Periodical"
    },
    {
        "id": 410305457,
        "latitude": 40.7589512,
        "longitude": -73.9533423,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-25T15:25:46.895Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 409952536,
        "latitude": 40.7627961,
        "longitude": -73.949594,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-25T01:00:29.270Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 409941807,
        "latitude": 40.7791426,
        "longitude": -73.953761,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-25T00:32:53.740Z",
        "type": "Periodical"
    },
    {
        "id": 409931985,
        "latitude": 40.7791363,
        "longitude": -73.9543293,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-25T00:04:49.930Z",
        "type": "Periodical"
    },
    {
        "id": 409908621,
        "latitude": 40.7845179,
        "longitude": -73.9673918,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-06-24T23:29:28.279Z",
        "type": "Periodical"
    },
    {
        "id": 409908620,
        "latitude": 40.7836704,
        "longitude": -73.9652154,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-24T23:06:03.413Z",
        "type": "Periodical"
    },
    {
        "id": 409879594,
        "latitude": 40.7836704,
        "longitude": -73.9652154,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-06-24T22:37:25.867Z",
        "type": "Periodical"
    },
    {
        "id": 409865725,
        "latitude": 40.7774912,
        "longitude": -73.9634541,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-06-24T22:10:25.865Z",
        "type": "Periodical"
    },
    {
        "id": 409847167,
        "latitude": 40.7645974,
        "longitude": -73.9658401,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-24T21:34:44.968Z",
        "type": "Periodical"
    },
    {
        "id": 409842904,
        "latitude": 40.7593612,
        "longitude": -73.9528765,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-24T21:23:07.233Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 409776096,
        "latitude": 40.7623884,
        "longitude": -73.9497817,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-06-24T19:19:57.275Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 409759070,
        "latitude": 40.7598285,
        "longitude": -73.9612223,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-24T18:46:50.175Z",
        "type": "Periodical"
    },
    {
        "id": 409748308,
        "latitude": 40.7596055,
        "longitude": -73.9621289,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-24T18:25:00.315Z",
        "type": "Periodical"
    },
    {
        "id": 409737794,
        "latitude": 40.7612144,
        "longitude": -73.9505757,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-24T17:58:56.552Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 409688346,
        "latitude": 40.7624184,
        "longitude": -73.9497886,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-24T16:25:17.939Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 409670012,
        "latitude": 40.766165,
        "longitude": -73.9469366,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-24T15:48:09.264Z",
        "type": "Periodical"
    },
    {
        "id": 409655407,
        "latitude": 40.7663706,
        "longitude": -73.9464213,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-24T15:17:20.748Z",
        "type": "Periodical"
    },
    {
        "id": 409651526,
        "latitude": 40.7643205,
        "longitude": -73.9476737,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-24T15:06:23.716Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 409271812,
        "latitude": 40.7624262,
        "longitude": -73.9497825,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-23T23:39:46.051Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 409271266,
        "latitude": 40.7663191,
        "longitude": -73.9465448,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T23:20:00.478Z",
        "type": "Periodical"
    },
    {
        "id": 409271265,
        "latitude": 40.7663191,
        "longitude": -73.9465448,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T23:00:00.477Z",
        "type": "Periodical"
    },
    {
        "id": 409233054,
        "latitude": 40.7661601,
        "longitude": -73.9469135,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-23T22:18:30.207Z",
        "type": "Periodical"
    },
    {
        "id": 409217237,
        "latitude": 40.7663238,
        "longitude": -73.9465461,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-06-23T21:45:57.923Z",
        "type": "Periodical"
    },
    {
        "id": 409212181,
        "latitude": 40.7664261,
        "longitude": -73.9467014,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T21:16:30.197Z",
        "type": "Periodical"
    },
    {
        "id": 409212180,
        "latitude": 40.7661355,
        "longitude": -73.9468104,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T21:03:00.537Z",
        "type": "Periodical"
    },
    {
        "id": 409181508,
        "latitude": 40.7664086,
        "longitude": -73.9462845,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-06-23T20:32:15.518Z",
        "type": "Periodical"
    },
    {
        "id": 409180361,
        "latitude": 40.7651284,
        "longitude": -73.9475483,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-06-23T20:29:41.294Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 408722824,
        "latitude": 40.7623493,
        "longitude": -73.9498682,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-23T02:21:28.206Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 408712989,
        "latitude": 40.7638683,
        "longitude": -73.9775133,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-23T02:05:32.235Z",
        "type": "Periodical"
    },
    {
        "id": 408697690,
        "latitude": 40.7662422,
        "longitude": -73.9823574,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T01:35:00.418Z",
        "type": "Periodical"
    },
    {
        "id": 408682588,
        "latitude": 40.7662422,
        "longitude": -73.9823574,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T01:05:00.416Z",
        "type": "Periodical"
    },
    {
        "id": 408679932,
        "latitude": 40.7662422,
        "longitude": -73.9823574,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T00:35:00.415Z",
        "type": "Periodical"
    },
    {
        "id": 408679931,
        "latitude": 40.7662422,
        "longitude": -73.9823574,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-23T00:05:00.413Z",
        "type": "Periodical"
    },
    {
        "id": 408634944,
        "latitude": 40.7662026,
        "longitude": -73.9821335,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-06-22T23:27:44.992Z",
        "type": "Periodical"
    },
    {
        "id": 408626041,
        "latitude": 40.7662056,
        "longitude": -73.9822718,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T23:10:10.382Z",
        "type": "Periodical"
    },
    {
        "id": 408605933,
        "latitude": 40.7662056,
        "longitude": -73.9822718,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T22:30:10.381Z",
        "type": "Periodical"
    },
    {
        "id": 408595341,
        "latitude": 40.7662056,
        "longitude": -73.9822718,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T22:10:10.379Z",
        "type": "Periodical"
    },
    {
        "id": 408580807,
        "latitude": 40.7662056,
        "longitude": -73.9822718,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T21:40:10.378Z",
        "type": "Periodical"
    },
    {
        "id": 408560933,
        "latitude": 40.7662006,
        "longitude": -73.9818656,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T21:03:15.497Z",
        "type": "Periodical"
    },
    {
        "id": 408546117,
        "latitude": 40.7662006,
        "longitude": -73.9818656,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T20:33:15.481Z",
        "type": "Periodical"
    },
    {
        "id": 408528008,
        "latitude": 40.7666348,
        "longitude": -73.9827156,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-22T19:55:35.712Z",
        "type": "Periodical"
    },
    {
        "id": 408517451,
        "latitude": 40.7591402,
        "longitude": -73.9532493,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-22T19:34:01.368Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 408460343,
        "latitude": 40.7628258,
        "longitude": -73.9494805,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-22T17:38:27.656Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 408448427,
        "latitude": 40.754836,
        "longitude": -73.9618536,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-06-22T17:14:07.457Z",
        "type": "Periodical"
    },
    {
        "id": 408431607,
        "latitude": 40.7581706,
        "longitude": -73.9544952,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-22T16:38:57.370Z",
        "type": "Periodical"
    },
    {
        "id": 408431606,
        "latitude": 40.7608068,
        "longitude": -73.9514202,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-06-22T16:26:55.215Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 408086597,
        "latitude": 40.7621675,
        "longitude": -73.9500606,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-22T02:10:50.181Z",
        "type": "Periodical"
    },
    {
        "id": 408086588,
        "latitude": 40.7615664,
        "longitude": -73.9500696,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-22T02:09:50.235Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 408071833,
        "latitude": 40.7662054,
        "longitude": -73.9823997,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T01:30:39.342Z",
        "type": "Periodical"
    },
    {
        "id": 408063938,
        "latitude": 40.7662162,
        "longitude": -73.9824102,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T01:05:39.339Z",
        "type": "Periodical"
    },
    {
        "id": 408041282,
        "latitude": 40.7662164,
        "longitude": -73.9823908,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-22T00:35:39.336Z",
        "type": "Periodical"
    },
    {
        "id": 408029792,
        "latitude": 40.7661802,
        "longitude": -73.982357,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-06-22T00:09:21.435Z",
        "type": "Periodical"
    },
    {
        "id": 408014816,
        "latitude": 40.7662028,
        "longitude": -73.9824219,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-06-21T23:41:11.424Z",
        "type": "Periodical"
    },
    {
        "id": 407991530,
        "latitude": 40.7663754,
        "longitude": -73.9828486,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-06-21T22:59:57.849Z",
        "type": "Periodical"
    },
    {
        "id": 407989856,
        "latitude": 40.7662435,
        "longitude": -73.9824555,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-21T22:26:44.456Z",
        "type": "Periodical"
    },
    {
        "id": 407989855,
        "latitude": 40.7662435,
        "longitude": -73.9824555,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-21T22:01:44.454Z",
        "type": "Periodical"
    },
    {
        "id": 407943455,
        "latitude": 40.7660995,
        "longitude": -73.9816106,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-21T21:27:23.527Z",
        "type": "Periodical"
    },
    {
        "id": 407933705,
        "latitude": 40.765383,
        "longitude": -73.9820461,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-21T20:54:04.007Z",
        "type": "Periodical"
    },
    {
        "id": 407906810,
        "latitude": 40.7663466,
        "longitude": -73.9822348,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-21T20:18:23.602Z",
        "type": "Periodical"
    },
    {
        "id": 407890024,
        "latitude": 40.766169,
        "longitude": -73.9820684,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-06-21T19:47:16.462Z",
        "type": "Periodical"
    },
    {
        "id": 407869045,
        "latitude": 40.7664679,
        "longitude": -73.9829445,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-06-21T19:08:10.203Z",
        "type": "Periodical"
    },
    {
        "id": 407850010,
        "latitude": 40.7663205,
        "longitude": -73.9828936,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-06-21T18:33:25.383Z",
        "type": "Periodical"
    },
    {
        "id": 407833799,
        "latitude": 40.7662187,
        "longitude": -73.9824078,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-21T18:02:27.552Z",
        "type": "Periodical"
    },
    {
        "id": 407833798,
        "latitude": 40.7662187,
        "longitude": -73.9824078,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-21T17:28:37.548Z",
        "type": "Periodical"
    },
    {
        "id": 407803062,
        "latitude": 40.7662349,
        "longitude": -73.9821689,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-21T16:59:36.240Z",
        "type": "Periodical"
    },
    {
        "id": 407789930,
        "latitude": 40.7638286,
        "longitude": -73.9775426,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-21T16:36:05.641Z",
        "type": "Periodical"
    },
    {
        "id": 407786318,
        "latitude": 40.7587826,
        "longitude": -73.953359,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-21T16:26:50.235Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 407318288,
        "latitude": 40.7623586,
        "longitude": -73.9498073,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-20T23:26:12.385Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 407313399,
        "latitude": 40.7573856,
        "longitude": -73.9542599,
        "accuracy": 118,
        "source": "Fused",
        "ts": "2024-06-20T23:11:37.569Z",
        "type": "Periodical"
    },
    {
        "id": 407292159,
        "latitude": 40.7649928,
        "longitude": -73.9609783,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-20T22:36:52.704Z",
        "type": "Periodical"
    },
    {
        "id": 407292153,
        "latitude": 40.7650124,
        "longitude": -73.9609629,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-20T22:12:53.504Z",
        "type": "Periodical"
    },
    {
        "id": 407267738,
        "latitude": 40.7649893,
        "longitude": -73.9668477,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-06-20T21:49:59.472Z",
        "type": "Periodical"
    },
    {
        "id": 407264479,
        "latitude": 40.7590309,
        "longitude": -73.9534111,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-20T21:43:38.730Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 407157100,
        "latitude": 40.7624528,
        "longitude": -73.9497791,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-20T18:07:05.115Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 407157099,
        "latitude": 40.7624528,
        "longitude": -73.9497791,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-20T18:07:05.115Z",
        "type": "Periodical"
    },
    {
        "id": 407151200,
        "latitude": 40.7608009,
        "longitude": -73.9505396,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-20T17:49:09.673Z",
        "type": "Periodical"
    },
    {
        "id": 407132622,
        "latitude": 40.7608022,
        "longitude": -73.9505202,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-20T17:19:28.339Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 406753350,
        "latitude": 40.7624263,
        "longitude": -73.9497777,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-20T02:29:05.760Z",
        "type": "Periodical"
    },
    {
        "id": 406753349,
        "latitude": 40.7624263,
        "longitude": -73.9497777,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-20T02:29:05.760Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 406743976,
        "latitude": 40.7588306,
        "longitude": -73.9524627,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-20T02:10:12.290Z",
        "type": "Periodical"
    },
    {
        "id": 406728501,
        "latitude": 40.7662459,
        "longitude": -73.9825792,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-20T01:30:00.800Z",
        "type": "Periodical"
    },
    {
        "id": 406718703,
        "latitude": 40.7660801,
        "longitude": -73.9816436,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-20T01:10:01.156Z",
        "type": "Periodical"
    },
    {
        "id": 406695780,
        "latitude": 40.7662416,
        "longitude": -73.9823952,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-06-20T00:38:00.806Z",
        "type": "Periodical"
    },
    {
        "id": 406695779,
        "latitude": 40.7662146,
        "longitude": -73.9824118,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-20T00:14:00.463Z",
        "type": "Periodical"
    },
    {
        "id": 406669560,
        "latitude": 40.7662106,
        "longitude": -73.9823916,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-19T23:52:52.984Z",
        "type": "Periodical"
    },
    {
        "id": 406652544,
        "latitude": 40.76621,
        "longitude": -73.9823879,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-19T23:17:04.680Z",
        "type": "Periodical"
    },
    {
        "id": 406652543,
        "latitude": 40.7662544,
        "longitude": -73.982498,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-19T22:45:00.271Z",
        "type": "Periodical"
    },
    {
        "id": 406652542,
        "latitude": 40.7662171,
        "longitude": -73.9824509,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-19T22:13:03.888Z",
        "type": "Periodical"
    },
    {
        "id": 406652541,
        "latitude": 40.7663276,
        "longitude": -73.982313,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-19T21:55:28.664Z",
        "type": "Periodical"
    },
    {
        "id": 406589891,
        "latitude": 40.7667722,
        "longitude": -73.9829944,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-19T21:17:40.478Z",
        "type": "Periodical"
    },
    {
        "id": 406577095,
        "latitude": 40.7662176,
        "longitude": -73.9824175,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-19T20:54:14.329Z",
        "type": "Periodical"
    },
    {
        "id": 406561815,
        "latitude": 40.7662167,
        "longitude": -73.9823936,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-19T20:26:48.778Z",
        "type": "Periodical"
    },
    {
        "id": 406555106,
        "latitude": 40.7662255,
        "longitude": -73.9824157,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-06-19T20:01:54.075Z",
        "type": "Periodical"
    },
    {
        "id": 406532555,
        "latitude": 40.7660749,
        "longitude": -73.9822907,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-06-19T19:24:19.634Z",
        "type": "Periodical"
    },
    {
        "id": 406513346,
        "latitude": 40.7662076,
        "longitude": -73.9823969,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-06-19T18:53:33.193Z",
        "type": "Periodical"
    },
    {
        "id": 406503897,
        "latitude": 40.766251,
        "longitude": -73.9818814,
        "accuracy": 86,
        "source": "Fused",
        "ts": "2024-06-19T18:35:03.403Z",
        "type": "Periodical"
    },
    {
        "id": 406494372,
        "latitude": 40.7662173,
        "longitude": -73.9824173,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-19T18:07:15.548Z",
        "type": "Periodical"
    },
    {
        "id": 406472816,
        "latitude": 40.766229,
        "longitude": -73.9824553,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-19T17:33:14.848Z",
        "type": "Periodical"
    },
    {
        "id": 406472802,
        "latitude": 40.7662137,
        "longitude": -73.9824083,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-19T17:10:17.852Z",
        "type": "Periodical"
    },
    {
        "id": 406442537,
        "latitude": 40.7591492,
        "longitude": -73.9532102,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-19T16:32:54.961Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 406438869,
        "latitude": 40.762277,
        "longitude": -73.9497533,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-06-19T16:25:31.608Z",
        "type": "Periodical"
    },
    {
        "id": 406438867,
        "latitude": 40.7627853,
        "longitude": -73.9493534,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-19T16:23:38.681Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 406421450,
        "latitude": 40.7665537,
        "longitude": -73.9468778,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-19T15:49:06.045Z",
        "type": "Periodical"
    },
    {
        "id": 406417974,
        "latitude": 40.7665704,
        "longitude": -73.9467948,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-06-19T15:35:00.365Z",
        "type": "Periodical"
    },
    {
        "id": 406407475,
        "latitude": 40.7673828,
        "longitude": -73.9505801,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-19T15:08:56.450Z",
        "type": "Periodical"
    },
    {
        "id": 406393458,
        "latitude": 40.7646274,
        "longitude": -73.9473782,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-19T14:45:24.532Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 405948824,
        "latitude": 40.7625767,
        "longitude": -73.9497364,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-18T21:51:06.852Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 405943815,
        "latitude": 40.759047,
        "longitude": -73.9530348,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-18T21:42:09.680Z",
        "type": "Periodical"
    },
    {
        "id": 405927799,
        "latitude": 40.7663498,
        "longitude": -73.9828408,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-06-18T21:12:37.300Z",
        "type": "Periodical"
    },
    {
        "id": 405911088,
        "latitude": 40.7662141,
        "longitude": -73.9823931,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-18T20:40:42.867Z",
        "type": "Periodical"
    },
    {
        "id": 405900635,
        "latitude": 40.7662294,
        "longitude": -73.9824335,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-18T20:08:43.784Z",
        "type": "Periodical"
    },
    {
        "id": 405875105,
        "latitude": 40.7662146,
        "longitude": -73.9824118,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-18T19:34:06.689Z",
        "type": "Periodical"
    },
    {
        "id": 405864579,
        "latitude": 40.7662162,
        "longitude": -73.9824134,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-06-18T19:13:42.890Z",
        "type": "Periodical"
    },
    {
        "id": 405848619,
        "latitude": 40.7661679,
        "longitude": -73.9820862,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-18T18:38:13.110Z",
        "type": "Periodical"
    },
    {
        "id": 405831663,
        "latitude": 40.7661675,
        "longitude": -73.9822705,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-18T18:11:03.774Z",
        "type": "Periodical"
    },
    {
        "id": 405825251,
        "latitude": 40.7662592,
        "longitude": -73.982456,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-18T17:49:00.168Z",
        "type": "Periodical"
    },
    {
        "id": 405809732,
        "latitude": 40.7662487,
        "longitude": -73.9824883,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-06-18T17:10:23.655Z",
        "type": "Periodical"
    },
    {
        "id": 405809731,
        "latitude": 40.7662196,
        "longitude": -73.9824262,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-18T16:38:33.172Z",
        "type": "Periodical"
    },
    {
        "id": 405763264,
        "latitude": 40.7661297,
        "longitude": -73.9815578,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-18T15:54:08.844Z",
        "type": "Periodical"
    },
    {
        "id": 405747497,
        "latitude": 40.7587342,
        "longitude": -73.9533016,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-18T15:20:50.309Z",
        "type": "Periodical"
    },
    {
        "id": 405745289,
        "latitude": 40.7602162,
        "longitude": -73.95164,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-18T15:16:40.746Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 405390187,
        "latitude": 40.7617292,
        "longitude": -73.9499562,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-18T01:15:19.512Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 405386410,
        "latitude": 40.7659865,
        "longitude": -73.9607796,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-06-18T01:02:12.240Z",
        "type": "Periodical"
    },
    {
        "id": 405370229,
        "latitude": 40.7657303,
        "longitude": -73.9672599,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-18T00:38:03.997Z",
        "type": "Periodical"
    },
    {
        "id": 405370234,
        "latitude": 40.7656775,
        "longitude": -73.9672042,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-18T00:12:36.851Z",
        "type": "Periodical"
    },
    {
        "id": 405370233,
        "latitude": 40.7657377,
        "longitude": -73.9672634,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-18T00:00:24.691Z",
        "type": "Periodical"
    },
    {
        "id": 405370232,
        "latitude": 40.765699,
        "longitude": -73.967233,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-17T23:12:23.094Z",
        "type": "Periodical"
    },
    {
        "id": 405314281,
        "latitude": 40.7610779,
        "longitude": -73.9504836,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-17T22:51:52.198Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 405278412,
        "latitude": 40.7624456,
        "longitude": -73.9497302,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-17T21:45:45.082Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 405278413,
        "latitude": 40.7624456,
        "longitude": -73.9497302,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-17T21:45:45.082Z",
        "type": "Periodical"
    },
    {
        "id": 405265069,
        "latitude": 40.76642,
        "longitude": -73.982756,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-17T21:14:57.056Z",
        "type": "Periodical"
    },
    {
        "id": 405244188,
        "latitude": 40.7661422,
        "longitude": -73.9823489,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-17T20:41:31.533Z",
        "type": "Periodical"
    },
    {
        "id": 405233037,
        "latitude": 40.7662297,
        "longitude": -73.9824642,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-17T20:13:15.376Z",
        "type": "Periodical"
    },
    {
        "id": 405208852,
        "latitude": 40.7662526,
        "longitude": -73.9826037,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-17T19:35:39.787Z",
        "type": "Periodical"
    },
    {
        "id": 405202042,
        "latitude": 40.7661772,
        "longitude": -73.9822766,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-17T19:13:58.298Z",
        "type": "Periodical"
    },
    {
        "id": 405181635,
        "latitude": 40.7662807,
        "longitude": -73.9826579,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-17T18:43:56.774Z",
        "type": "Periodical"
    },
    {
        "id": 405165717,
        "latitude": 40.7662837,
        "longitude": -73.9826368,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-06-17T18:14:30.234Z",
        "type": "Periodical"
    },
    {
        "id": 405150518,
        "latitude": 40.7662306,
        "longitude": -73.9824511,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-17T17:45:18.755Z",
        "type": "Periodical"
    },
    {
        "id": 405150517,
        "latitude": 40.7663595,
        "longitude": -73.9828221,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-17T17:07:18.434Z",
        "type": "Periodical"
    },
    {
        "id": 405112892,
        "latitude": 40.7662234,
        "longitude": -73.9824391,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-17T16:31:52.715Z",
        "type": "Periodical"
    },
    {
        "id": 405102125,
        "latitude": 40.7662104,
        "longitude": -73.9823518,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-06-17T16:00:33.226Z",
        "type": "Periodical"
    },
    {
        "id": 405087008,
        "latitude": 40.7587899,
        "longitude": -73.9533489,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-17T15:41:37.860Z",
        "type": "Periodical"
    },
    {
        "id": 405083570,
        "latitude": 40.7607118,
        "longitude": -73.9514331,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-17T15:34:21.538Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 404608464,
        "latitude": 40.762441,
        "longitude": -73.9497986,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-16T20:58:37.765Z",
        "type": "Periodical"
    },
    {
        "id": 404157490,
        "latitude": 40.76162,
        "longitude": -73.9491976,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-15T23:40:30.540Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 404051828,
        "latitude": 40.7642311,
        "longitude": -73.9774365,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-15T23:10:39.681Z",
        "type": "Periodical"
    },
    {
        "id": 404037457,
        "latitude": 40.7663179,
        "longitude": -73.9821407,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-06-15T22:43:46.007Z",
        "type": "Periodical"
    },
    {
        "id": 404028547,
        "latitude": 40.7662589,
        "longitude": -73.9826531,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T22:15:20.515Z",
        "type": "Periodical"
    },
    {
        "id": 404005575,
        "latitude": 40.7662842,
        "longitude": -73.9826869,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-15T21:40:16.066Z",
        "type": "Periodical"
    },
    {
        "id": 403983438,
        "latitude": 40.7661353,
        "longitude": -73.9816361,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-06-15T20:56:56.995Z",
        "type": "Periodical"
    },
    {
        "id": 403978220,
        "latitude": 40.7661807,
        "longitude": -73.9822723,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-06-15T20:25:00.294Z",
        "type": "Periodical"
    },
    {
        "id": 403948824,
        "latitude": 40.7660896,
        "longitude": -73.981694,
        "accuracy": 50,
        "source": "Fused",
        "ts": "2024-06-15T19:48:47.840Z",
        "type": "Periodical"
    },
    {
        "id": 403931499,
        "latitude": 40.766196,
        "longitude": -73.9821862,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-06-15T19:15:56.584Z",
        "type": "Periodical"
    },
    {
        "id": 403917900,
        "latitude": 40.7661986,
        "longitude": -73.9822948,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T18:50:03.814Z",
        "type": "Periodical"
    },
    {
        "id": 403908316,
        "latitude": 40.7661522,
        "longitude": -73.9816326,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T18:25:00.354Z",
        "type": "Periodical"
    },
    {
        "id": 403908315,
        "latitude": 40.7661522,
        "longitude": -73.9816326,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T17:55:33.938Z",
        "type": "Periodical"
    },
    {
        "id": 403866433,
        "latitude": 40.7661105,
        "longitude": -73.9816901,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-06-15T17:08:32.238Z",
        "type": "Periodical"
    },
    {
        "id": 403850736,
        "latitude": 40.7590121,
        "longitude": -73.9529024,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-15T16:36:55.162Z",
        "type": "Periodical"
    },
    {
        "id": 403846172,
        "latitude": 40.759072,
        "longitude": -73.9530847,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-15T16:26:00.478Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 403819256,
        "latitude": 40.7620483,
        "longitude": -73.949784,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-15T15:14:44.379Z",
        "type": "Periodical"
    },
    {
        "id": 403819257,
        "latitude": 40.7620483,
        "longitude": -73.949784,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-15T15:14:44.379Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 403819255,
        "latitude": 40.7593834,
        "longitude": -73.9530254,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-06-15T15:09:50.516Z",
        "type": "Periodical"
    },
    {
        "id": 403789923,
        "latitude": 40.7431639,
        "longitude": -73.9187303,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-15T14:31:02.097Z",
        "type": "Periodical"
    },
    {
        "id": 403779762,
        "latitude": 40.7447152,
        "longitude": -73.9183197,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-15T14:09:26.563Z",
        "type": "Periodical"
    },
    {
        "id": 403767749,
        "latitude": 40.7447278,
        "longitude": -73.9183131,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T13:42:50.809Z",
        "type": "Periodical"
    },
    {
        "id": 403758796,
        "latitude": 40.7447538,
        "longitude": -73.9182836,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T13:22:50.808Z",
        "type": "Periodical"
    },
    {
        "id": 403743200,
        "latitude": 40.7447185,
        "longitude": -73.9183218,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T12:45:10.305Z",
        "type": "Periodical"
    },
    {
        "id": 403731566,
        "latitude": 40.7447185,
        "longitude": -73.9183218,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-15T12:15:10.301Z",
        "type": "Periodical"
    },
    {
        "id": 403721350,
        "latitude": 40.7447208,
        "longitude": -73.9183083,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-15T11:50:03.994Z",
        "type": "Periodical"
    },
    {
        "id": 403721351,
        "latitude": 40.7455846,
        "longitude": -73.9241311,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-15T11:31:03.776Z",
        "type": "Periodical"
    },
    {
        "id": 403708247,
        "latitude": 40.752138,
        "longitude": -73.9446928,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-06-15T11:08:57.253Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 403502051,
        "latitude": 40.7617943,
        "longitude": -73.9498414,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-15T02:19:56.292Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 403498475,
        "latitude": 40.7564154,
        "longitude": -73.960592,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-06-15T02:16:55.969Z",
        "type": "Periodical"
    },
    {
        "id": 403478164,
        "latitude": 40.7662073,
        "longitude": -73.9821175,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T01:37:00.491Z",
        "type": "Periodical"
    },
    {
        "id": 403464858,
        "latitude": 40.7662073,
        "longitude": -73.9821175,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-15T01:12:00.489Z",
        "type": "Periodical"
    },
    {
        "id": 403458717,
        "latitude": 40.7662073,
        "longitude": -73.9821175,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-06-15T00:57:00.487Z",
        "type": "Periodical"
    },
    {
        "id": 403458716,
        "latitude": 40.766195,
        "longitude": -73.9823054,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T23:55:10.723Z",
        "type": "Periodical"
    },
    {
        "id": 403458715,
        "latitude": 40.766195,
        "longitude": -73.9823054,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T23:30:10.720Z",
        "type": "Periodical"
    },
    {
        "id": 403458714,
        "latitude": 40.7661941,
        "longitude": -73.9822802,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T22:59:00.398Z",
        "type": "Periodical"
    },
    {
        "id": 403458713,
        "latitude": 40.7661941,
        "longitude": -73.9822802,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T22:39:00.396Z",
        "type": "Periodical"
    },
    {
        "id": 403458712,
        "latitude": 40.766306,
        "longitude": -73.9816397,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T22:08:38.116Z",
        "type": "Periodical"
    },
    {
        "id": 403363495,
        "latitude": 40.7662392,
        "longitude": -73.9823003,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T21:40:10.359Z",
        "type": "Periodical"
    },
    {
        "id": 403363494,
        "latitude": 40.7661952,
        "longitude": -73.982291,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T21:24:30.103Z",
        "type": "Periodical"
    },
    {
        "id": 403363493,
        "latitude": 40.7661952,
        "longitude": -73.982291,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-14T20:54:30.102Z",
        "type": "Periodical"
    },
    {
        "id": 403308425,
        "latitude": 40.7653572,
        "longitude": -73.9802481,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-06-14T20:13:20.850Z",
        "type": "Periodical"
    },
    {
        "id": 403298341,
        "latitude": 40.7599878,
        "longitude": -73.9519112,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-14T19:56:29.794Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 403126036,
        "latitude": 40.7622646,
        "longitude": -73.9499339,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-14T14:23:44.868Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 403125770,
        "latitude": 40.7592414,
        "longitude": -73.9524919,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-06-14T14:18:21.515Z",
        "type": "Periodical"
    },
    {
        "id": 403119181,
        "latitude": 40.7587163,
        "longitude": -73.9532869,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:11:18.074Z",
        "type": "Periodical"
    },
    {
        "id": 403119352,
        "latitude": 40.7587163,
        "longitude": -73.9532869,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:11:18.074Z",
        "type": "Periodical"
    },
    {
        "id": 403119280,
        "latitude": 40.7587163,
        "longitude": -73.9532869,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:11:18.074Z",
        "type": "Periodical"
    },
    {
        "id": 403118367,
        "latitude": 40.7587246,
        "longitude": -73.9533096,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:09:48.652Z",
        "type": "Periodical"
    },
    {
        "id": 403119285,
        "latitude": 40.7587246,
        "longitude": -73.9533096,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:09:48.652Z",
        "type": "Periodical"
    },
    {
        "id": 403119286,
        "latitude": 40.7587246,
        "longitude": -73.9533096,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:09:48.652Z",
        "type": "Periodical"
    },
    {
        "id": 403119287,
        "latitude": 40.7587246,
        "longitude": -73.9533096,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:09:48.652Z",
        "type": "Periodical"
    },
    {
        "id": 403119288,
        "latitude": 40.7587246,
        "longitude": -73.9533096,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-14T14:09:48.652Z",
        "type": "Periodical"
    },
    {
        "id": 403114527,
        "latitude": 40.7606897,
        "longitude": -73.9513484,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-14T14:02:31.321Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 402509433,
        "latitude": 40.7627997,
        "longitude": -73.9494793,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-13T15:22:58.444Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 402502542,
        "latitude": 40.764943,
        "longitude": -73.9668808,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-06-13T15:08:26.892Z",
        "type": "Periodical"
    },
    {
        "id": 402493583,
        "latitude": 40.7723358,
        "longitude": -73.958215,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-13T14:35:21.451Z",
        "type": "Periodical"
    },
    {
        "id": 402493582,
        "latitude": 40.7723444,
        "longitude": -73.9582148,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-13T14:03:36.801Z",
        "type": "Periodical"
    },
    {
        "id": 402462880,
        "latitude": 40.7708511,
        "longitude": -73.9569832,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-06-13T13:40:36.203Z",
        "type": "Periodical"
    },
    {
        "id": 402453066,
        "latitude": 40.7595887,
        "longitude": -73.9528696,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-13T13:12:08.612Z",
        "type": "Periodical"
    },
    {
        "id": 402450339,
        "latitude": 40.760115,
        "longitude": -73.9519778,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-13T13:11:07.570Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 402174875,
        "latitude": 40.761748,
        "longitude": -73.9498815,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-13T01:32:15.191Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 402167364,
        "latitude": 40.7587941,
        "longitude": -73.9523957,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-13T01:17:23.746Z",
        "type": "Periodical"
    },
    {
        "id": 402153239,
        "latitude": 40.7662211,
        "longitude": -73.9823648,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-13T00:40:10.319Z",
        "type": "Periodical"
    },
    {
        "id": 402153238,
        "latitude": 40.7662211,
        "longitude": -73.9823648,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-13T00:00:10.316Z",
        "type": "Periodical"
    },
    {
        "id": 402153237,
        "latitude": 40.7662211,
        "longitude": -73.9823648,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T23:25:10.313Z",
        "type": "Periodical"
    },
    {
        "id": 402153236,
        "latitude": 40.7662211,
        "longitude": -73.9823648,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T23:05:10.311Z",
        "type": "Periodical"
    },
    {
        "id": 402096356,
        "latitude": 40.7661516,
        "longitude": -73.9820367,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T22:31:12.579Z",
        "type": "Periodical"
    },
    {
        "id": 402096355,
        "latitude": 40.7661516,
        "longitude": -73.9820367,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T22:06:12.577Z",
        "type": "Periodical"
    },
    {
        "id": 402064226,
        "latitude": 40.7661735,
        "longitude": -73.9820306,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-12T21:44:30.858Z",
        "type": "Periodical"
    },
    {
        "id": 402042001,
        "latitude": 40.7661761,
        "longitude": -73.9821033,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T21:09:19.928Z",
        "type": "Periodical"
    },
    {
        "id": 402042000,
        "latitude": 40.7661761,
        "longitude": -73.9821033,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T20:39:19.927Z",
        "type": "Periodical"
    },
    {
        "id": 402041999,
        "latitude": 40.7661761,
        "longitude": -73.9821033,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T19:59:19.924Z",
        "type": "Periodical"
    },
    {
        "id": 402041998,
        "latitude": 40.7661761,
        "longitude": -73.9821033,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-12T19:29:19.922Z",
        "type": "Periodical"
    },
    {
        "id": 401966335,
        "latitude": 40.7660871,
        "longitude": -73.9817869,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-06-12T19:00:19.833Z",
        "type": "Periodical"
    },
    {
        "id": 401957285,
        "latitude": 40.7589743,
        "longitude": -73.9528038,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-12T18:43:26.733Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 401414759,
        "latitude": 40.7616857,
        "longitude": -73.9500317,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-11T21:51:42.636Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 401414157,
        "latitude": 40.7591493,
        "longitude": -73.9532919,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-11T21:46:13.593Z",
        "type": "Periodical"
    },
    {
        "id": 401399821,
        "latitude": 40.7664163,
        "longitude": -73.9827523,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-11T21:25:15.182Z",
        "type": "Periodical"
    },
    {
        "id": 401393578,
        "latitude": 40.766277,
        "longitude": -73.9825818,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T21:07:10.401Z",
        "type": "Periodical"
    },
    {
        "id": 401371062,
        "latitude": 40.7662775,
        "longitude": -73.9824076,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-11T20:28:48.829Z",
        "type": "Periodical"
    },
    {
        "id": 401358062,
        "latitude": 40.7663383,
        "longitude": -73.9825345,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-06-11T20:04:15.095Z",
        "type": "Periodical"
    },
    {
        "id": 401355795,
        "latitude": 40.7662658,
        "longitude": -73.9824256,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T19:50:00.387Z",
        "type": "Periodical"
    },
    {
        "id": 401355794,
        "latitude": 40.7662658,
        "longitude": -73.9824256,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T19:10:00.383Z",
        "type": "Periodical"
    },
    {
        "id": 401316627,
        "latitude": 40.7662505,
        "longitude": -73.9823902,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T18:39:43.897Z",
        "type": "Periodical"
    },
    {
        "id": 401316626,
        "latitude": 40.7662505,
        "longitude": -73.9823902,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T18:19:43.897Z",
        "type": "Periodical"
    },
    {
        "id": 401316625,
        "latitude": 40.7662505,
        "longitude": -73.9823902,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T17:49:43.896Z",
        "type": "Periodical"
    },
    {
        "id": 401278396,
        "latitude": 40.7663197,
        "longitude": -73.9825259,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-06-11T17:17:58.926Z",
        "type": "Periodical"
    },
    {
        "id": 401267383,
        "latitude": 40.7662551,
        "longitude": -73.9825462,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-11T16:45:05.391Z",
        "type": "Periodical"
    },
    {
        "id": 401240029,
        "latitude": 40.7662308,
        "longitude": -73.9822574,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-11T16:04:32.349Z",
        "type": "Periodical"
    },
    {
        "id": 401228112,
        "latitude": 40.7590771,
        "longitude": -73.9530154,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-11T15:39:22.864Z",
        "type": "Periodical"
    },
    {
        "id": 401220822,
        "latitude": 40.7608088,
        "longitude": -73.950521,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-11T15:23:39.210Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 400880517,
        "latitude": 40.7628458,
        "longitude": -73.9494092,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-11T01:13:28.238Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 400876201,
        "latitude": 40.7590344,
        "longitude": -73.9530241,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-11T01:03:03.946Z",
        "type": "Periodical"
    },
    {
        "id": 400867354,
        "latitude": 40.7656545,
        "longitude": -73.9672056,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-11T00:25:48.235Z",
        "type": "Periodical"
    },
    {
        "id": 400839587,
        "latitude": 40.7656785,
        "longitude": -73.9672278,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-10T23:50:10.270Z",
        "type": "Periodical"
    },
    {
        "id": 400839586,
        "latitude": 40.7657132,
        "longitude": -73.9672409,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-10T23:10:32.604Z",
        "type": "Periodical"
    },
    {
        "id": 400805759,
        "latitude": 40.7652712,
        "longitude": -73.9677452,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-10T22:47:19.796Z",
        "type": "Periodical"
    },
    {
        "id": 400793843,
        "latitude": 40.7638451,
        "longitude": -73.9774863,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-10T22:25:08.926Z",
        "type": "Periodical"
    },
    {
        "id": 400793844,
        "latitude": 40.7638735,
        "longitude": -73.9774391,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-06-10T22:24:23.154Z",
        "type": "Periodical"
    },
    {
        "id": 400781583,
        "latitude": 40.7662268,
        "longitude": -73.9824238,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-10T21:55:03.761Z",
        "type": "Periodical"
    },
    {
        "id": 400768755,
        "latitude": 40.7662505,
        "longitude": -73.9824234,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-06-10T21:21:00.384Z",
        "type": "Periodical"
    },
    {
        "id": 400768754,
        "latitude": 40.7662363,
        "longitude": -73.9824347,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-10T20:59:46.742Z",
        "type": "Periodical"
    },
    {
        "id": 400768753,
        "latitude": 40.7662333,
        "longitude": -73.9824333,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-10T20:29:00.401Z",
        "type": "Periodical"
    },
    {
        "id": 400713206,
        "latitude": 40.7664274,
        "longitude": -73.9827435,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-06-10T20:00:00.497Z",
        "type": "Periodical"
    },
    {
        "id": 400698316,
        "latitude": 40.7662949,
        "longitude": -73.9823718,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-10T19:26:00.463Z",
        "type": "Periodical"
    },
    {
        "id": 400677041,
        "latitude": 40.7666784,
        "longitude": -73.9828234,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-06-10T18:49:14.960Z",
        "type": "Periodical"
    },
    {
        "id": 400671173,
        "latitude": 40.7667689,
        "longitude": -73.9826099,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-10T18:27:00.620Z",
        "type": "Periodical"
    },
    {
        "id": 400671172,
        "latitude": 40.7663756,
        "longitude": -73.9822268,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-06-10T18:08:00.393Z",
        "type": "Periodical"
    },
    {
        "id": 400643387,
        "latitude": 40.7663339,
        "longitude": -73.982603,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-06-10T17:40:08.338Z",
        "type": "Periodical"
    },
    {
        "id": 400629112,
        "latitude": 40.7663529,
        "longitude": -73.9824573,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-10T17:10:47.918Z",
        "type": "Periodical"
    },
    {
        "id": 400614495,
        "latitude": 40.7663377,
        "longitude": -73.9824484,
        "accuracy": 317,
        "source": "Fused",
        "ts": "2024-06-10T16:40:32.930Z",
        "type": "Periodical"
    },
    {
        "id": 400603935,
        "latitude": 40.7662249,
        "longitude": -73.982386,
        "accuracy": 65,
        "source": "Fused",
        "ts": "2024-06-10T16:18:40.400Z",
        "type": "Periodical"
    },
    {
        "id": 400591308,
        "latitude": 40.765044,
        "longitude": -73.9788647,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-10T15:51:46.340Z",
        "type": "Periodical"
    },
    {
        "id": 400584423,
        "latitude": 40.7588887,
        "longitude": -73.9527695,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-06-10T15:37:14.079Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 400200977,
        "latitude": 40.7626006,
        "longitude": -73.9497019,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-06-10T00:12:00.597Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 400190492,
        "latitude": 40.7468922,
        "longitude": -73.9202383,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-09T23:51:44.594Z",
        "type": "Periodical"
    },
    {
        "id": 400174841,
        "latitude": 40.7447217,
        "longitude": -73.9182889,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T23:20:14.383Z",
        "type": "Periodical"
    },
    {
        "id": 400165050,
        "latitude": 40.7447217,
        "longitude": -73.9182889,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T23:00:14.382Z",
        "type": "Periodical"
    },
    {
        "id": 400150031,
        "latitude": 40.7447246,
        "longitude": -73.9182999,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T22:30:10.288Z",
        "type": "Periodical"
    },
    {
        "id": 400139973,
        "latitude": 40.7447246,
        "longitude": -73.9182999,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T22:10:10.288Z",
        "type": "Periodical"
    },
    {
        "id": 400125391,
        "latitude": 40.7447246,
        "longitude": -73.9182999,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T21:40:10.286Z",
        "type": "Periodical"
    },
    {
        "id": 400112190,
        "latitude": 40.7447235,
        "longitude": -73.9183193,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T21:13:09.403Z",
        "type": "Periodical"
    },
    {
        "id": 400097179,
        "latitude": 40.7447235,
        "longitude": -73.9183193,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T20:43:09.402Z",
        "type": "Periodical"
    },
    {
        "id": 400082632,
        "latitude": 40.744744,
        "longitude": -73.9182498,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-09T20:13:09.399Z",
        "type": "Periodical"
    },
    {
        "id": 400062983,
        "latitude": 40.7447086,
        "longitude": -73.9183256,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-09T19:33:44.200Z",
        "type": "Periodical"
    },
    {
        "id": 400043018,
        "latitude": 40.7447086,
        "longitude": -73.9183256,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-09T18:53:44.196Z",
        "type": "Periodical"
    },
    {
        "id": 400037344,
        "latitude": 40.7447187,
        "longitude": -73.9183235,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-09T18:42:10.118Z",
        "type": "Periodical"
    },
    {
        "id": 400009368,
        "latitude": 40.7586566,
        "longitude": -73.9379443,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-06-09T17:46:31.299Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 400007476,
        "latitude": 40.7628322,
        "longitude": -73.9493337,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-09T17:41:37.920Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 400007475,
        "latitude": 40.7609393,
        "longitude": -73.9508345,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-09T17:38:11.556Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 399476293,
        "latitude": 40.7633888,
        "longitude": -73.9487209,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-08T21:28:23.240Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 399470891,
        "latitude": 40.7649438,
        "longitude": -73.9466495,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-06-08T21:11:38.684Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 399384975,
        "latitude": 40.7625017,
        "longitude": -73.9497087,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-08T18:23:44.592Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 399381996,
        "latitude": 40.7647273,
        "longitude": -73.966177,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-08T18:13:51.331Z",
        "type": "Periodical"
    },
    {
        "id": 399366261,
        "latitude": 40.7637418,
        "longitude": -73.9776105,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-06-08T17:46:28.924Z",
        "type": "Periodical"
    },
    {
        "id": 399353666,
        "latitude": 40.7615039,
        "longitude": -73.9519533,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-08T17:20:44.506Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 399303984,
        "latitude": 40.7629909,
        "longitude": -73.948962,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-08T15:24:00.147Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 399303989,
        "latitude": 40.7629909,
        "longitude": -73.948962,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-08T15:24:00.147Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 399293403,
        "latitude": 40.7637257,
        "longitude": -73.9482191,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-08T15:06:49.155Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 398990868,
        "latitude": 40.7624058,
        "longitude": -73.9497323,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-08T02:36:22.113Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 398982437,
        "latitude": 40.7648883,
        "longitude": -73.9666348,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-08T02:19:56.966Z",
        "type": "Periodical"
    },
    {
        "id": 398971820,
        "latitude": 40.766195,
        "longitude": -73.9822609,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-08T01:53:56.678Z",
        "type": "Periodical"
    },
    {
        "id": 398971819,
        "latitude": 40.766195,
        "longitude": -73.9822609,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-08T01:23:56.676Z",
        "type": "Periodical"
    },
    {
        "id": 398971818,
        "latitude": 40.766195,
        "longitude": -73.9822609,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-08T00:53:56.673Z",
        "type": "Periodical"
    },
    {
        "id": 398971817,
        "latitude": 40.766195,
        "longitude": -73.9822609,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-08T00:33:56.670Z",
        "type": "Periodical"
    },
    {
        "id": 398971816,
        "latitude": 40.766195,
        "longitude": -73.9822609,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-08T00:08:56.668Z",
        "type": "Periodical"
    },
    {
        "id": 398910270,
        "latitude": 40.7661806,
        "longitude": -73.9822815,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-07T23:40:00.027Z",
        "type": "Periodical"
    },
    {
        "id": 398910269,
        "latitude": 40.7661806,
        "longitude": -73.9822815,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-07T23:00:00.024Z",
        "type": "Periodical"
    },
    {
        "id": 398862504,
        "latitude": 40.7661947,
        "longitude": -73.9822817,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-07T22:30:10.365Z",
        "type": "Periodical"
    },
    {
        "id": 398841229,
        "latitude": 40.7661947,
        "longitude": -73.9822817,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-07T21:50:10.363Z",
        "type": "Periodical"
    },
    {
        "id": 398826012,
        "latitude": 40.7662391,
        "longitude": -73.9823396,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-07T21:21:37.376Z",
        "type": "Periodical"
    },
    {
        "id": 398803613,
        "latitude": 40.7662391,
        "longitude": -73.9823396,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-07T20:41:37.374Z",
        "type": "Periodical"
    },
    {
        "id": 398785600,
        "latitude": 40.7668006,
        "longitude": -73.9817173,
        "accuracy": 600,
        "source": "Fused",
        "ts": "2024-06-07T20:01:06.117Z",
        "type": "Periodical"
    },
    {
        "id": 398768758,
        "latitude": 40.7588706,
        "longitude": -73.9526644,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-07T19:37:29.003Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 398188115,
        "latitude": 40.7623683,
        "longitude": -73.949838,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-06T21:57:15.670Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 398182654,
        "latitude": 40.7587196,
        "longitude": -73.953144,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-06T21:47:06.187Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 398174413,
        "latitude": 40.7629865,
        "longitude": -73.9503276,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-06T21:29:21.146Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 398168852,
        "latitude": 40.7587266,
        "longitude": -73.9531576,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-06T21:21:06.523Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 398155678,
        "latitude": 40.7626058,
        "longitude": -73.949455,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-06T20:57:17.208Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 398150874,
        "latitude": 40.7611147,
        "longitude": -73.9512544,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-06T20:40:53.073Z",
        "type": "Periodical"
    },
    {
        "id": 398141514,
        "latitude": 40.7610445,
        "longitude": -73.9513715,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-06T20:30:07.995Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 398080126,
        "latitude": 40.7617946,
        "longitude": -73.9499119,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-06T18:31:37.794Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 398077407,
        "latitude": 40.760717,
        "longitude": -73.95118,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-06-06T18:28:37.771Z",
        "type": "Periodical"
    },
    {
        "id": 398065419,
        "latitude": 40.7662134,
        "longitude": -73.9823771,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-06-06T17:57:40.252Z",
        "type": "Periodical"
    },
    {
        "id": 398052870,
        "latitude": 40.7664922,
        "longitude": -73.9825144,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-06-06T17:27:39.610Z",
        "type": "Periodical"
    },
    {
        "id": 398030824,
        "latitude": 40.7662091,
        "longitude": -73.9823626,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-06T16:51:45.966Z",
        "type": "Periodical"
    },
    {
        "id": 398012091,
        "latitude": 40.7662646,
        "longitude": -73.9825112,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-06T16:13:05.724Z",
        "type": "Periodical"
    },
    {
        "id": 398003549,
        "latitude": 40.7661726,
        "longitude": -73.9821989,
        "accuracy": 58,
        "source": "Fused",
        "ts": "2024-06-06T15:49:37.372Z",
        "type": "Periodical"
    },
    {
        "id": 397993795,
        "latitude": 40.7662118,
        "longitude": -73.9823739,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-06T15:23:10.430Z",
        "type": "Periodical"
    },
    {
        "id": 397977045,
        "latitude": 40.7664316,
        "longitude": -73.9828329,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-06T14:49:09.431Z",
        "type": "Periodical"
    },
    {
        "id": 397957462,
        "latitude": 40.7663902,
        "longitude": -73.9826209,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-06T14:12:45.286Z",
        "type": "Periodical"
    },
    {
        "id": 397957461,
        "latitude": 40.766433,
        "longitude": -73.9827672,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-06T14:12:28.047Z",
        "type": "Periodical"
    },
    {
        "id": 397951804,
        "latitude": 40.7663848,
        "longitude": -73.9826384,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-06T14:05:28.122Z",
        "type": "Periodical"
    },
    {
        "id": 397946684,
        "latitude": 40.7662489,
        "longitude": -73.9824417,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-06T13:45:05.694Z",
        "type": "Periodical"
    },
    {
        "id": 397927716,
        "latitude": 40.7661951,
        "longitude": -73.9824189,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-06T13:08:41.468Z",
        "type": "Periodical"
    },
    {
        "id": 397919979,
        "latitude": 40.7662044,
        "longitude": -73.9823661,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-06T12:42:19.408Z",
        "type": "Periodical"
    },
    {
        "id": 397895516,
        "latitude": 40.7644765,
        "longitude": -73.9771401,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-06T11:55:31.512Z",
        "type": "Periodical"
    },
    {
        "id": 397893340,
        "latitude": 40.7606959,
        "longitude": -73.9513767,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-06T11:47:29.962Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 397597179,
        "latitude": 40.7619256,
        "longitude": -73.9498396,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-06T00:00:46.833Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 397594090,
        "latitude": 40.7602367,
        "longitude": -73.9520025,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-05T23:57:31.368Z",
        "type": "Periodical"
    },
    {
        "id": 397579697,
        "latitude": 40.7661352,
        "longitude": -73.981911,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-06-05T23:28:26.007Z",
        "type": "Periodical"
    },
    {
        "id": 397562567,
        "latitude": 40.7663187,
        "longitude": -73.9826977,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-05T22:54:37.616Z",
        "type": "Periodical"
    },
    {
        "id": 397545633,
        "latitude": 40.7662264,
        "longitude": -73.982396,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-05T22:21:11.068Z",
        "type": "Periodical"
    },
    {
        "id": 397543824,
        "latitude": 40.7662169,
        "longitude": -73.9823768,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-05T21:41:11.998Z",
        "type": "Periodical"
    },
    {
        "id": 397513518,
        "latitude": 40.7662103,
        "longitude": -73.9823662,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-06-05T21:15:07.697Z",
        "type": "Periodical"
    },
    {
        "id": 397492195,
        "latitude": 40.7661887,
        "longitude": -73.9823053,
        "accuracy": 47,
        "source": "Fused",
        "ts": "2024-06-05T20:41:39.939Z",
        "type": "Periodical"
    },
    {
        "id": 397486919,
        "latitude": 40.7661723,
        "longitude": -73.9821079,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-05T20:20:10.250Z",
        "type": "Periodical"
    },
    {
        "id": 397486918,
        "latitude": 40.7661723,
        "longitude": -73.9821079,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-05T20:00:10.250Z",
        "type": "Periodical"
    },
    {
        "id": 397460407,
        "latitude": 40.7661723,
        "longitude": -73.9821079,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-06-05T19:30:10.248Z",
        "type": "Periodical"
    },
    {
        "id": 397460406,
        "latitude": 40.7661956,
        "longitude": -73.9822666,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-05T19:03:48.717Z",
        "type": "Periodical"
    },
    {
        "id": 397422485,
        "latitude": 40.7662179,
        "longitude": -73.9822642,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-05T18:33:48.714Z",
        "type": "Periodical"
    },
    {
        "id": 397401103,
        "latitude": 40.7662068,
        "longitude": -73.9824714,
        "accuracy": 92,
        "source": "Fused",
        "ts": "2024-06-05T17:50:16.489Z",
        "type": "Periodical"
    },
    {
        "id": 397387905,
        "latitude": 40.7666965,
        "longitude": -73.9829526,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-06-05T17:21:35.068Z",
        "type": "Periodical"
    },
    {
        "id": 397371332,
        "latitude": 40.8241945,
        "longitude": -73.9454172,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-05T16:46:26.050Z",
        "type": "Periodical"
    },
    {
        "id": 397359077,
        "latitude": 40.8241674,
        "longitude": -73.9454232,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-05T16:20:37.214Z",
        "type": "Periodical"
    },
    {
        "id": 397345261,
        "latitude": 40.8241323,
        "longitude": -73.9454352,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-05T15:51:20.057Z",
        "type": "Periodical"
    },
    {
        "id": 397333690,
        "latitude": 40.7586558,
        "longitude": -73.9814169,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-05T15:25:31.698Z",
        "type": "Periodical"
    },
    {
        "id": 397326021,
        "latitude": 40.7589633,
        "longitude": -73.9533329,
        "accuracy": 8,
        "source": "Fused",
        "ts": "2024-06-05T15:01:36.009Z",
        "type": "Periodical"
    },
    {
        "id": 397320564,
        "latitude": 40.7611661,
        "longitude": -73.9504133,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-05T14:56:50.939Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 396866926,
        "latitude": 40.7619642,
        "longitude": -73.951217,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-04T21:29:30.477Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 396861484,
        "latitude": 40.7647185,
        "longitude": -73.9661672,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-04T21:19:06.299Z",
        "type": "Periodical"
    },
    {
        "id": 396852521,
        "latitude": 40.7662249,
        "longitude": -73.9822627,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-04T20:55:44.298Z",
        "type": "Periodical"
    },
    {
        "id": 396852520,
        "latitude": 40.7662249,
        "longitude": -73.9822627,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-04T20:35:44.297Z",
        "type": "Periodical"
    },
    {
        "id": 396852519,
        "latitude": 40.7662249,
        "longitude": -73.9822627,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-04T19:55:44.294Z",
        "type": "Periodical"
    },
    {
        "id": 396852518,
        "latitude": 40.7662249,
        "longitude": -73.9822627,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-04T19:25:44.292Z",
        "type": "Periodical"
    },
    {
        "id": 396783150,
        "latitude": 40.7662353,
        "longitude": -73.9822503,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-06-04T18:43:35.755Z",
        "type": "Periodical"
    },
    {
        "id": 396775206,
        "latitude": 40.7661778,
        "longitude": -73.9822579,
        "accuracy": 44,
        "source": "Fused",
        "ts": "2024-06-04T18:21:07.004Z",
        "type": "Periodical"
    },
    {
        "id": 396761932,
        "latitude": 40.7661844,
        "longitude": -73.9822323,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-04T17:45:00.628Z",
        "type": "Periodical"
    },
    {
        "id": 396739469,
        "latitude": 40.7662118,
        "longitude": -73.9823699,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-04T16:58:49.058Z",
        "type": "Periodical"
    },
    {
        "id": 396716239,
        "latitude": 40.7662241,
        "longitude": -73.9823912,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-04T16:07:53.832Z",
        "type": "Periodical"
    },
    {
        "id": 396706762,
        "latitude": 40.766133,
        "longitude": -73.9816644,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-04T15:41:17.158Z",
        "type": "Periodical"
    },
    {
        "id": 396684326,
        "latitude": 40.7661914,
        "longitude": -73.9822952,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-04T15:04:52.875Z",
        "type": "Periodical"
    },
    {
        "id": 396677605,
        "latitude": 40.761953,
        "longitude": -73.9758403,
        "accuracy": 1000,
        "source": "Fused",
        "ts": "2024-06-04T14:50:09.042Z",
        "type": "Periodical"
    },
    {
        "id": 396674196,
        "latitude": 40.7589124,
        "longitude": -73.9527448,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-04T14:41:48.632Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 396351659,
        "latitude": 40.7617619,
        "longitude": -73.9498561,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-04T01:15:37.789Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 396348249,
        "latitude": 40.7591038,
        "longitude": -73.9531972,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-04T01:08:05.212Z",
        "type": "Periodical"
    },
    {
        "id": 396340610,
        "latitude": 40.7649074,
        "longitude": -73.9667587,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-04T00:53:38.784Z",
        "type": "Periodical"
    },
    {
        "id": 396339646,
        "latitude": 40.7650393,
        "longitude": -73.967092,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-04T00:51:51.183Z",
        "type": "Periodical"
    },
    {
        "id": 396338209,
        "latitude": 40.7656971,
        "longitude": -73.9671775,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-04T00:27:33.729Z",
        "type": "Periodical"
    },
    {
        "id": 396310296,
        "latitude": 40.7656984,
        "longitude": -73.9671729,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-03T23:47:49.082Z",
        "type": "Periodical"
    },
    {
        "id": 396301472,
        "latitude": 40.7657169,
        "longitude": -73.9672178,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-03T23:35:00.610Z",
        "type": "Periodical"
    },
    {
        "id": 396301471,
        "latitude": 40.7657106,
        "longitude": -73.9672095,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-06-03T23:04:47.406Z",
        "type": "Periodical"
    },
    {
        "id": 396273199,
        "latitude": 40.7591408,
        "longitude": -73.9532711,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-03T22:39:26.984Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 396219568,
        "latitude": 40.7626558,
        "longitude": -73.9496826,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-03T21:01:10.478Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 396219139,
        "latitude": 40.7692262,
        "longitude": -73.9355135,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-06-03T20:48:19.207Z",
        "type": "Periodical"
    },
    {
        "id": 396212202,
        "latitude": 40.7598319,
        "longitude": -73.951584,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-03T20:24:00.373Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 396099152,
        "latitude": 40.76241,
        "longitude": -73.9497689,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-03T17:03:51.133Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 396097148,
        "latitude": 40.761641,
        "longitude": -73.9497589,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-06-03T16:53:51.511Z",
        "type": "Periodical"
    },
    {
        "id": 396082753,
        "latitude": 40.7505097,
        "longitude": -73.989378,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-03T16:29:13.098Z",
        "type": "Periodical"
    },
    {
        "id": 396065982,
        "latitude": 40.7503468,
        "longitude": -73.9900725,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-03T15:53:33.960Z",
        "type": "Periodical"
    },
    {
        "id": 396052415,
        "latitude": 40.7587572,
        "longitude": -73.9528989,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-03T15:24:14.758Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 396052449,
        "latitude": 40.7587572,
        "longitude": -73.9528989,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-06-03T15:24:14.758Z",
        "type": "Periodical"
    },
    {
        "id": 395744108,
        "latitude": 40.7624523,
        "longitude": -73.9498319,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-03T02:39:18.765Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 395735128,
        "latitude": 40.7634414,
        "longitude": -73.9778302,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-06-03T02:21:18.563Z",
        "type": "Periodical"
    },
    {
        "id": 395722972,
        "latitude": 40.7663049,
        "longitude": -73.9825824,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-03T01:57:09.280Z",
        "type": "Periodical"
    },
    {
        "id": 395722971,
        "latitude": 40.7663012,
        "longitude": -73.9826971,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-03T01:56:01.571Z",
        "type": "Periodical"
    },
    {
        "id": 395705371,
        "latitude": 40.7663525,
        "longitude": -73.9829417,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-06-03T01:23:24.537Z",
        "type": "Periodical"
    },
    {
        "id": 395692473,
        "latitude": 40.7662248,
        "longitude": -73.9824511,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-03T00:59:19.557Z",
        "type": "Periodical"
    },
    {
        "id": 395681287,
        "latitude": 40.7661787,
        "longitude": -73.9824027,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-03T00:37:16.402Z",
        "type": "Periodical"
    },
    {
        "id": 395665465,
        "latitude": 40.7661586,
        "longitude": -73.981451,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-03T00:05:59.184Z",
        "type": "Periodical"
    },
    {
        "id": 395648006,
        "latitude": 40.7664833,
        "longitude": -73.9828253,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-02T23:29:35.943Z",
        "type": "Periodical"
    },
    {
        "id": 395648005,
        "latitude": 40.7664702,
        "longitude": -73.9828201,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-02T23:12:59.472Z",
        "type": "Periodical"
    },
    {
        "id": 395636670,
        "latitude": 40.7664739,
        "longitude": -73.9828241,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-02T22:55:16.376Z",
        "type": "Periodical"
    },
    {
        "id": 395615162,
        "latitude": 40.7664759,
        "longitude": -73.9828179,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-02T22:17:00.105Z",
        "type": "Periodical"
    },
    {
        "id": 395593609,
        "latitude": 40.7661374,
        "longitude": -73.9814728,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-02T21:40:46.373Z",
        "type": "Periodical"
    },
    {
        "id": 395587696,
        "latitude": 40.766319,
        "longitude": -73.9826441,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-02T21:23:29.365Z",
        "type": "Periodical"
    },
    {
        "id": 395567257,
        "latitude": 40.7661261,
        "longitude": -73.9823289,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-06-02T20:49:52.343Z",
        "type": "Periodical"
    },
    {
        "id": 395557364,
        "latitude": 40.766401,
        "longitude": -73.9827436,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-02T20:22:26.726Z",
        "type": "Periodical"
    },
    {
        "id": 395542542,
        "latitude": 40.7659117,
        "longitude": -73.9807377,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-02T20:00:29.595Z",
        "type": "Periodical"
    },
    {
        "id": 395532456,
        "latitude": 40.7589641,
        "longitude": -73.9533213,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-02T19:41:43.325Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 394987055,
        "latitude": 40.7629582,
        "longitude": -73.9499975,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-06-01T22:22:35.564Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 394979045,
        "latitude": 40.7612474,
        "longitude": -73.9642704,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-06-01T21:59:57.748Z",
        "type": "Periodical"
    },
    {
        "id": 394965292,
        "latitude": 40.7519187,
        "longitude": -73.9761268,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-01T21:41:00.744Z",
        "type": "Periodical"
    },
    {
        "id": 394954097,
        "latitude": 40.7429363,
        "longitude": -73.9766565,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-06-01T21:16:19.000Z",
        "type": "Periodical"
    },
    {
        "id": 394943186,
        "latitude": 40.7429135,
        "longitude": -73.9766516,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-01T20:51:07.715Z",
        "type": "Periodical"
    },
    {
        "id": 394943185,
        "latitude": 40.7429135,
        "longitude": -73.9766516,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-06-01T20:21:07.711Z",
        "type": "Periodical"
    },
    {
        "id": 394901507,
        "latitude": 40.7430438,
        "longitude": -73.9778056,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-06-01T19:35:21.590Z",
        "type": "Periodical"
    },
    {
        "id": 394881633,
        "latitude": 40.7590528,
        "longitude": -73.952967,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-01T18:54:57.585Z",
        "type": "Periodical"
    },
    {
        "id": 394866157,
        "latitude": 40.7647177,
        "longitude": -73.9782742,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-06-01T18:22:39.512Z",
        "type": "Periodical"
    },
    {
        "id": 394859552,
        "latitude": 40.7662396,
        "longitude": -73.9825879,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-06-01T18:08:35.772Z",
        "type": "Periodical"
    },
    {
        "id": 394855186,
        "latitude": 40.7662351,
        "longitude": -73.9824659,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-06-01T17:33:34.682Z",
        "type": "Periodical"
    },
    {
        "id": 394840007,
        "latitude": 40.7662525,
        "longitude": -73.9824248,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-06-01T17:12:30.173Z",
        "type": "Periodical"
    },
    {
        "id": 394823024,
        "latitude": 40.766229,
        "longitude": -73.9824287,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-01T16:38:00.986Z",
        "type": "Periodical"
    },
    {
        "id": 394810326,
        "latitude": 40.766234,
        "longitude": -73.9824341,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-06-01T16:02:00.781Z",
        "type": "Periodical"
    },
    {
        "id": 394784179,
        "latitude": 40.7662386,
        "longitude": -73.9824559,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-01T15:28:36.345Z",
        "type": "Periodical"
    },
    {
        "id": 394769970,
        "latitude": 40.7663056,
        "longitude": -73.9824928,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-06-01T14:57:46.520Z",
        "type": "Periodical"
    },
    {
        "id": 394757171,
        "latitude": 40.766263,
        "longitude": -73.9824361,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-01T14:14:11.468Z",
        "type": "Periodical"
    },
    {
        "id": 394744371,
        "latitude": 40.7662748,
        "longitude": -73.9824662,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-06-01T13:51:22.980Z",
        "type": "Periodical"
    },
    {
        "id": 394737324,
        "latitude": 40.7662434,
        "longitude": -73.9825117,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-06-01T13:25:00.276Z",
        "type": "Periodical"
    },
    {
        "id": 394719104,
        "latitude": 40.7662566,
        "longitude": -73.9824922,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-06-01T13:00:56.073Z",
        "type": "Periodical"
    },
    {
        "id": 394711845,
        "latitude": 40.7662648,
        "longitude": -73.9824539,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-06-01T12:43:39.060Z",
        "type": "Periodical"
    },
    {
        "id": 394693270,
        "latitude": 40.7662135,
        "longitude": -73.9829072,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-06-01T11:54:51.604Z",
        "type": "Periodical"
    },
    {
        "id": 394687442,
        "latitude": 40.7588405,
        "longitude": -73.9524764,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-06-01T11:38:24.186Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 394305653,
        "latitude": 40.7623909,
        "longitude": -73.9498133,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-31T21:06:45.724Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 394296588,
        "latitude": 40.7644636,
        "longitude": -73.9771312,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-05-31T20:43:23.061Z",
        "type": "Periodical"
    },
    {
        "id": 394289726,
        "latitude": 40.7631243,
        "longitude": -73.9745482,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-05-31T20:36:07.865Z",
        "type": "Periodical"
    },
    {
        "id": 394279778,
        "latitude": 40.7626262,
        "longitude": -73.9744863,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-05-31T20:11:27.976Z",
        "type": "Periodical"
    },
    {
        "id": 394268498,
        "latitude": 40.7600363,
        "longitude": -73.9519599,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-05-31T19:56:00.651Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 393818903,
        "latitude": 40.7620151,
        "longitude": -73.9505332,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-05-31T02:13:56.137Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 393818904,
        "latitude": 40.7620151,
        "longitude": -73.9505332,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-05-31T02:13:56.137Z",
        "type": "Periodical"
    },
    {
        "id": 393807235,
        "latitude": 40.7643565,
        "longitude": -73.9772513,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-05-31T01:53:47.966Z",
        "type": "Periodical"
    },
    {
        "id": 393799244,
        "latitude": 40.7667946,
        "longitude": -73.9833304,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-05-31T01:19:46.611Z",
        "type": "Periodical"
    },
    {
        "id": 393771540,
        "latitude": 40.7662647,
        "longitude": -73.9825708,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-05-31T00:41:00.520Z",
        "type": "Periodical"
    },
    {
        "id": 393758097,
        "latitude": 40.7662353,
        "longitude": -73.9820846,
        "accuracy": 300,
        "source": "Fused",
        "ts": "2024-05-31T00:22:18.040Z",
        "type": "Periodical"
    },
    {
        "id": 393738993,
        "latitude": 40.7662717,
        "longitude": -73.9825347,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-30T23:45:54.180Z",
        "type": "Periodical"
    },
    {
        "id": 393717031,
        "latitude": 40.766184,
        "longitude": -73.9822581,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-30T23:03:37.931Z",
        "type": "Periodical"
    },
    {
        "id": 393707061,
        "latitude": 40.7662277,
        "longitude": -73.9824341,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-30T22:34:35.047Z",
        "type": "Periodical"
    },
    {
        "id": 393688902,
        "latitude": 40.7662456,
        "longitude": -73.9825329,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-30T22:11:25.770Z",
        "type": "Periodical"
    },
    {
        "id": 393662534,
        "latitude": 40.7662353,
        "longitude": -73.9820846,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-05-30T21:21:17.879Z",
        "type": "Periodical"
    },
    {
        "id": 393647669,
        "latitude": 40.7662533,
        "longitude": -73.9825553,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-05-30T20:51:49.568Z",
        "type": "Periodical"
    },
    {
        "id": 393630785,
        "latitude": 40.7661374,
        "longitude": -73.9814844,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-05-30T20:19:44.266Z",
        "type": "Periodical"
    },
    {
        "id": 393613877,
        "latitude": 40.7590773,
        "longitude": -73.9530076,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-30T19:49:00.594Z",
        "type": "Periodical"
    },
    {
        "id": 393610487,
        "latitude": 40.7590547,
        "longitude": -73.9530568,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-30T19:42:27.250Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 393515434,
        "latitude": 40.7627395,
        "longitude": -73.9497303,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-30T16:11:27.723Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 393515435,
        "latitude": 40.7627395,
        "longitude": -73.9497303,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-30T16:11:27.723Z",
        "type": "Periodical"
    },
    {
        "id": 393515433,
        "latitude": 40.7602499,
        "longitude": -73.9520165,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-30T15:52:16.225Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 393061475,
        "latitude": 40.7624672,
        "longitude": -73.9497266,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-29T22:31:40.257Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 393058811,
        "latitude": 40.7638812,
        "longitude": -73.9774477,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-29T22:19:52.182Z",
        "type": "Periodical"
    },
    {
        "id": 393041286,
        "latitude": 40.7662089,
        "longitude": -73.9823039,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-29T21:52:18.981Z",
        "type": "Periodical"
    },
    {
        "id": 393029160,
        "latitude": 40.7662454,
        "longitude": -73.9825728,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-29T21:22:00.312Z",
        "type": "Periodical"
    },
    {
        "id": 393015449,
        "latitude": 40.7662395,
        "longitude": -73.9824387,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-05-29T21:01:26.889Z",
        "type": "Periodical"
    },
    {
        "id": 393015448,
        "latitude": 40.7662755,
        "longitude": -73.9825825,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-29T20:35:35.458Z",
        "type": "Periodical"
    },
    {
        "id": 392985627,
        "latitude": 40.7662822,
        "longitude": -73.9826224,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-29T20:03:02.299Z",
        "type": "Periodical"
    },
    {
        "id": 392981837,
        "latitude": 40.7662198,
        "longitude": -73.9825175,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-29T19:55:36.663Z",
        "type": "Periodical"
    },
    {
        "id": 392981541,
        "latitude": 40.7662234,
        "longitude": -73.9825421,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-29T19:54:53.594Z",
        "type": "Periodical"
    },
    {
        "id": 392971147,
        "latitude": 40.7661764,
        "longitude": -73.9815301,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-05-29T19:33:36.530Z",
        "type": "Periodical"
    },
    {
        "id": 392956069,
        "latitude": 40.7662458,
        "longitude": -73.9822369,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-05-29T19:04:05.913Z",
        "type": "Periodical"
    },
    {
        "id": 392951110,
        "latitude": 40.7663716,
        "longitude": -73.9828337,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-29T18:49:07.299Z",
        "type": "Periodical"
    },
    {
        "id": 392947847,
        "latitude": 40.7662475,
        "longitude": -73.9824504,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-29T18:18:13.050Z",
        "type": "Periodical"
    },
    {
        "id": 392947846,
        "latitude": 40.7662596,
        "longitude": -73.9825479,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-05-29T17:58:13.053Z",
        "type": "Periodical"
    },
    {
        "id": 392915204,
        "latitude": 40.7662094,
        "longitude": -73.9823948,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-05-29T17:30:45.142Z",
        "type": "Periodical"
    },
    {
        "id": 392894784,
        "latitude": 40.7664269,
        "longitude": -73.9827528,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-29T16:50:02.164Z",
        "type": "Periodical"
    },
    {
        "id": 392887306,
        "latitude": 40.7662013,
        "longitude": -73.9823036,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-05-29T16:26:39.965Z",
        "type": "Periodical"
    },
    {
        "id": 392871776,
        "latitude": 40.7660696,
        "longitude": -73.981643,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-05-29T15:55:51.115Z",
        "type": "Periodical"
    },
    {
        "id": 392863651,
        "latitude": 40.7591391,
        "longitude": -73.9532716,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-29T15:35:43.420Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 392437932,
        "latitude": 40.7621761,
        "longitude": -73.9500762,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-28T22:51:34.085Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 392425768,
        "latitude": 40.7616256,
        "longitude": -73.9666384,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-05-28T22:28:59.641Z",
        "type": "Periodical"
    },
    {
        "id": 392408955,
        "latitude": 40.7661979,
        "longitude": -73.9822921,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-05-28T21:58:01.031Z",
        "type": "Periodical"
    },
    {
        "id": 392394689,
        "latitude": 40.7662226,
        "longitude": -73.9822877,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-05-28T21:31:13.778Z",
        "type": "Periodical"
    },
    {
        "id": 392386773,
        "latitude": 40.7662432,
        "longitude": -73.9824474,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-28T21:02:00.416Z",
        "type": "Periodical"
    },
    {
        "id": 392386772,
        "latitude": 40.7662285,
        "longitude": -73.9824418,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-28T20:37:52.597Z",
        "type": "Periodical"
    },
    {
        "id": 392355765,
        "latitude": 40.7662206,
        "longitude": -73.9824224,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-28T20:07:38.340Z",
        "type": "Periodical"
    },
    {
        "id": 392337233,
        "latitude": 40.7660872,
        "longitude": -73.98213,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-05-28T19:41:40.042Z",
        "type": "Periodical"
    },
    {
        "id": 392321140,
        "latitude": 40.7662389,
        "longitude": -73.9824295,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-28T19:11:12.309Z",
        "type": "Periodical"
    },
    {
        "id": 392317539,
        "latitude": 40.7662061,
        "longitude": -73.9824058,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-28T19:04:15.894Z",
        "type": "Periodical"
    },
    {
        "id": 392306414,
        "latitude": 40.7662271,
        "longitude": -73.9823558,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-28T18:41:48.785Z",
        "type": "Periodical"
    },
    {
        "id": 392304370,
        "latitude": 40.7661844,
        "longitude": -73.9824464,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-28T18:31:11.146Z",
        "type": "Periodical"
    },
    {
        "id": 392287530,
        "latitude": 40.7662208,
        "longitude": -73.9824328,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-28T17:59:25.882Z",
        "type": "Periodical"
    },
    {
        "id": 392273496,
        "latitude": 40.7662949,
        "longitude": -73.9826721,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-28T17:22:51.550Z",
        "type": "Periodical"
    },
    {
        "id": 392273495,
        "latitude": 40.7667033,
        "longitude": -73.9826032,
        "accuracy": 45,
        "source": "Fused",
        "ts": "2024-05-28T17:02:43.379Z",
        "type": "Periodical"
    },
    {
        "id": 392246369,
        "latitude": 40.766178,
        "longitude": -73.9819644,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-05-28T16:23:33.927Z",
        "type": "Periodical"
    },
    {
        "id": 392234025,
        "latitude": 40.766264,
        "longitude": -73.9816452,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-05-28T16:00:55.147Z",
        "type": "Periodical"
    },
    {
        "id": 392221323,
        "latitude": 40.7607096,
        "longitude": -73.9512218,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-05-28T15:29:00.242Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 392170017,
        "latitude": 40.7613689,
        "longitude": -73.9501686,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-28T13:31:32.542Z",
        "type": "Periodical"
    },
    {
        "id": 392170018,
        "latitude": 40.7613689,
        "longitude": -73.9501686,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-28T13:31:32.542Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 392170016,
        "latitude": 40.7603324,
        "longitude": -73.9515335,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-05-28T13:29:23.227Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 391874261,
        "latitude": 40.762014,
        "longitude": -73.9510484,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-28T01:07:00.344Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 391857282,
        "latitude": 40.7662231,
        "longitude": -73.9824239,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-05-28T00:28:24.222Z",
        "type": "Periodical"
    },
    {
        "id": 391838531,
        "latitude": 40.7662554,
        "longitude": -73.9824747,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-05-27T23:55:47.049Z",
        "type": "Periodical"
    },
    {
        "id": 391830754,
        "latitude": 40.7662241,
        "longitude": -73.9824156,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-27T23:17:15.784Z",
        "type": "Periodical"
    },
    {
        "id": 391809467,
        "latitude": 40.7662102,
        "longitude": -73.9824052,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-05-27T22:55:55.605Z",
        "type": "Periodical"
    },
    {
        "id": 391795761,
        "latitude": 40.7662163,
        "longitude": -73.9824047,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-27T22:27:58.860Z",
        "type": "Periodical"
    },
    {
        "id": 391780984,
        "latitude": 40.7662053,
        "longitude": -73.9822978,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-05-27T21:52:51.382Z",
        "type": "Periodical"
    },
    {
        "id": 391771089,
        "latitude": 40.7662181,
        "longitude": -73.9824148,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-27T21:27:00.495Z",
        "type": "Periodical"
    },
    {
        "id": 391771088,
        "latitude": 40.766224,
        "longitude": -73.9824471,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-27T20:44:13.539Z",
        "type": "Periodical"
    },
    {
        "id": 391732072,
        "latitude": 40.7662798,
        "longitude": -73.9826646,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-27T20:15:52.896Z",
        "type": "Periodical"
    },
    {
        "id": 391732071,
        "latitude": 40.7662271,
        "longitude": -73.9824346,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-27T19:44:00.759Z",
        "type": "Periodical"
    },
    {
        "id": 391696058,
        "latitude": 40.7662366,
        "longitude": -73.982417,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-27T19:09:36.366Z",
        "type": "Periodical"
    },
    {
        "id": 391682704,
        "latitude": 40.7647839,
        "longitude": -73.9662802,
        "accuracy": 62,
        "source": "Fused",
        "ts": "2024-05-27T18:43:47.437Z",
        "type": "Periodical"
    },
    {
        "id": 391677634,
        "latitude": 40.7590384,
        "longitude": -73.953012,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-27T18:32:01.177Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 391574381,
        "latitude": 40.7620992,
        "longitude": -73.9509603,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-05-27T15:11:59.644Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 391573041,
        "latitude": 40.7591477,
        "longitude": -73.9533059,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-27T15:06:03.783Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 391138920,
        "latitude": 40.762658,
        "longitude": -73.949746,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-26T21:32:44.764Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 391134880,
        "latitude": 40.7649791,
        "longitude": -73.9471873,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-05-26T21:23:54.021Z",
        "type": "Periodical"
    },
    {
        "id": 391127914,
        "latitude": 40.7662266,
        "longitude": -73.9461375,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-26T20:55:00.471Z",
        "type": "Periodical"
    },
    {
        "id": 391099806,
        "latitude": 40.766629,
        "longitude": -73.9512745,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-05-26T20:10:46.060Z",
        "type": "Periodical"
    },
    {
        "id": 391085386,
        "latitude": 40.7661849,
        "longitude": -73.9468572,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-26T19:40:32.286Z",
        "type": "Periodical"
    },
    {
        "id": 391083040,
        "latitude": 40.7662741,
        "longitude": -73.9463714,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-05-26T19:30:16.272Z",
        "type": "Periodical"
    },
    {
        "id": 391041824,
        "latitude": 40.7666787,
        "longitude": -73.951543,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-05-26T18:01:47.238Z",
        "type": "Periodical"
    },
    {
        "id": 391035823,
        "latitude": 40.7637964,
        "longitude": -73.9482048,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-26T17:55:29.182Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 390491345,
        "latitude": 40.7624268,
        "longitude": -73.9497581,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-25T20:18:09.523Z",
        "type": "Periodical"
    },
    {
        "id": 390491346,
        "latitude": 40.7624268,
        "longitude": -73.9497581,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-25T20:18:09.523Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 390477495,
        "latitude": 40.7612924,
        "longitude": -73.9643009,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-25T19:47:51.167Z",
        "type": "Periodical"
    },
    {
        "id": 390460133,
        "latitude": 40.7588822,
        "longitude": -73.9744572,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-25T19:06:58.345Z",
        "type": "Periodical"
    },
    {
        "id": 390447086,
        "latitude": 40.7668358,
        "longitude": -73.9821398,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-05-25T18:41:23.337Z",
        "type": "Periodical"
    },
    {
        "id": 390431769,
        "latitude": 40.7663609,
        "longitude": -73.982801,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-25T18:14:17.017Z",
        "type": "Periodical"
    },
    {
        "id": 390431773,
        "latitude": 40.7662339,
        "longitude": -73.9824549,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-25T18:00:26.475Z",
        "type": "Periodical"
    },
    {
        "id": 390431772,
        "latitude": 40.7662736,
        "longitude": -73.9826629,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-25T17:36:45.387Z",
        "type": "Periodical"
    },
    {
        "id": 390417640,
        "latitude": 40.7662736,
        "longitude": -73.9826629,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-25T17:36:45.387Z",
        "type": "Periodical"
    },
    {
        "id": 390399272,
        "latitude": 40.7661883,
        "longitude": -73.9818205,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-05-25T17:05:23.801Z",
        "type": "Periodical"
    },
    {
        "id": 390384764,
        "latitude": 40.7664201,
        "longitude": -73.9827266,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-25T16:25:09.741Z",
        "type": "Periodical"
    },
    {
        "id": 390384763,
        "latitude": 40.7662084,
        "longitude": -73.9823871,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-25T16:24:07.449Z",
        "type": "Periodical"
    },
    {
        "id": 390365418,
        "latitude": 40.7662333,
        "longitude": -73.9824169,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-25T15:52:12.224Z",
        "type": "Periodical"
    },
    {
        "id": 390364095,
        "latitude": 40.7662117,
        "longitude": -73.9823835,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-25T15:49:21.381Z",
        "type": "Periodical"
    },
    {
        "id": 390364300,
        "latitude": 40.7662117,
        "longitude": -73.9823835,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-25T15:49:21.381Z",
        "type": "Periodical"
    },
    {
        "id": 390356545,
        "latitude": 40.7662207,
        "longitude": -73.9824272,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-25T15:33:08.002Z",
        "type": "Periodical"
    },
    {
        "id": 390339669,
        "latitude": 40.7662547,
        "longitude": -73.9825538,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-25T14:56:52.910Z",
        "type": "Periodical"
    },
    {
        "id": 390327353,
        "latitude": 40.7662184,
        "longitude": -73.9825687,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-25T14:25:58.073Z",
        "type": "Periodical"
    },
    {
        "id": 390318570,
        "latitude": 40.766259,
        "longitude": -73.982541,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-05-25T14:05:04.024Z",
        "type": "Periodical"
    },
    {
        "id": 390318569,
        "latitude": 40.7664088,
        "longitude": -73.9827108,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-25T13:45:03.884Z",
        "type": "Periodical"
    },
    {
        "id": 390318568,
        "latitude": 40.7662829,
        "longitude": -73.9826507,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-05-25T13:04:45.211Z",
        "type": "Periodical"
    },
    {
        "id": 390268809,
        "latitude": 40.7662489,
        "longitude": -73.9825734,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-25T12:20:47.875Z",
        "type": "Periodical"
    },
    {
        "id": 390253697,
        "latitude": 40.7588714,
        "longitude": -73.9526341,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-25T11:40:44.656Z",
        "type": "Periodical"
    },
    {
        "id": 390252168,
        "latitude": 40.7607991,
        "longitude": -73.9511915,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-25T11:36:45.579Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 389856279,
        "latitude": 40.7623559,
        "longitude": -73.9498452,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-24T20:46:22.831Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 389851146,
        "latitude": 40.7620268,
        "longitude": -73.9512183,
        "accuracy": 340,
        "source": "Fused",
        "ts": "2024-05-24T20:36:54.509Z",
        "type": "Periodical"
    },
    {
        "id": 389833400,
        "latitude": 40.7638499,
        "longitude": -73.9774913,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-24T20:02:30.442Z",
        "type": "Periodical"
    },
    {
        "id": 389833293,
        "latitude": 40.7638499,
        "longitude": -73.9774913,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-24T20:02:30.442Z",
        "type": "Periodical"
    },
    {
        "id": 389833392,
        "latitude": 40.7638499,
        "longitude": -73.9774913,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-24T20:02:30.442Z",
        "type": "Periodical"
    },
    {
        "id": 389818696,
        "latitude": 40.7663808,
        "longitude": -73.9826164,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-05-24T19:23:47.032Z",
        "type": "Periodical"
    },
    {
        "id": 389801889,
        "latitude": 40.7663459,
        "longitude": -73.9827406,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-24T19:01:17.263Z",
        "type": "Periodical"
    },
    {
        "id": 389794907,
        "latitude": 40.7661373,
        "longitude": -73.9823502,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-05-24T18:45:00.434Z",
        "type": "Periodical"
    },
    {
        "id": 389794906,
        "latitude": 40.7662366,
        "longitude": -73.9824576,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-05-24T18:05:00.301Z",
        "type": "Periodical"
    },
    {
        "id": 389757848,
        "latitude": 40.7662095,
        "longitude": -73.982388,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-24T17:26:20.076Z",
        "type": "Periodical"
    },
    {
        "id": 389742691,
        "latitude": 40.766221,
        "longitude": -73.9824113,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-05-24T16:57:44.411Z",
        "type": "Periodical"
    },
    {
        "id": 389731383,
        "latitude": 40.7662229,
        "longitude": -73.9824294,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-05-24T16:32:52.230Z",
        "type": "Periodical"
    },
    {
        "id": 389720758,
        "latitude": 40.7662262,
        "longitude": -73.9823855,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-24T16:04:10.779Z",
        "type": "Periodical"
    },
    {
        "id": 389711177,
        "latitude": 40.7662168,
        "longitude": -73.9824186,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-24T15:37:48.583Z",
        "type": "Periodical"
    },
    {
        "id": 389692358,
        "latitude": 40.7663297,
        "longitude": -73.9827485,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-24T15:06:40.091Z",
        "type": "Periodical"
    },
    {
        "id": 389692340,
        "latitude": 40.766223,
        "longitude": -73.9824325,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-24T14:50:48.491Z",
        "type": "Periodical"
    },
    {
        "id": 389667703,
        "latitude": 40.7662399,
        "longitude": -73.9824404,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-24T14:11:04.832Z",
        "type": "Periodical"
    },
    {
        "id": 389654583,
        "latitude": 40.7667563,
        "longitude": -73.9830089,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-05-24T13:42:21.743Z",
        "type": "Periodical"
    },
    {
        "id": 389647369,
        "latitude": 40.7590867,
        "longitude": -73.9530074,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-24T13:25:46.236Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 389224561,
        "latitude": 40.7626182,
        "longitude": -73.949689,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-23T21:08:56.351Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 389208379,
        "latitude": 40.762643,
        "longitude": -73.9659994,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-05-23T20:40:48.869Z",
        "type": "Periodical"
    },
    {
        "id": 389193032,
        "latitude": 40.7594133,
        "longitude": -73.9661006,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-23T20:12:30.991Z",
        "type": "Periodical"
    },
    {
        "id": 389179180,
        "latitude": 40.7607283,
        "longitude": -73.9511946,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-23T19:46:41.477Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 388716422,
        "latitude": 40.7616152,
        "longitude": -73.9499831,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-23T01:15:50.800Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 388705081,
        "latitude": 40.763962,
        "longitude": -73.9774278,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-23T00:55:01.240Z",
        "type": "Periodical"
    },
    {
        "id": 388694498,
        "latitude": 40.7661623,
        "longitude": -73.9819868,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-23T00:24:04.048Z",
        "type": "Periodical"
    },
    {
        "id": 388677405,
        "latitude": 40.7661981,
        "longitude": -73.9822038,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-22T23:49:39.267Z",
        "type": "Periodical"
    },
    {
        "id": 388658438,
        "latitude": 40.7661723,
        "longitude": -73.9819885,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-22T23:10:10.225Z",
        "type": "Periodical"
    },
    {
        "id": 388658437,
        "latitude": 40.7661888,
        "longitude": -73.9822577,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-22T22:33:38.806Z",
        "type": "Periodical"
    },
    {
        "id": 388612672,
        "latitude": 40.766165,
        "longitude": -73.9816509,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-22T21:55:07.531Z",
        "type": "Periodical"
    },
    {
        "id": 388596960,
        "latitude": 40.7661558,
        "longitude": -73.9818248,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-22T21:19:43.534Z",
        "type": "Periodical"
    },
    {
        "id": 388580796,
        "latitude": 40.7662099,
        "longitude": -73.9823741,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-22T20:57:28.324Z",
        "type": "Periodical"
    },
    {
        "id": 388562978,
        "latitude": 40.7662252,
        "longitude": -73.9823702,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-22T20:33:34.834Z",
        "type": "Periodical"
    },
    {
        "id": 388560893,
        "latitude": 40.766218,
        "longitude": -73.9824121,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-22T20:09:38.830Z",
        "type": "Periodical"
    },
    {
        "id": 388544896,
        "latitude": 40.7662143,
        "longitude": -73.9824043,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-22T19:50:21.406Z",
        "type": "Periodical"
    },
    {
        "id": 388544895,
        "latitude": 40.7662074,
        "longitude": -73.982372,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-22T19:14:03.592Z",
        "type": "Periodical"
    },
    {
        "id": 388506156,
        "latitude": 40.7650893,
        "longitude": -73.9790782,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-22T18:45:04.017Z",
        "type": "Periodical"
    },
    {
        "id": 388496982,
        "latitude": 40.7589338,
        "longitude": -73.9524842,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-22T18:24:24.141Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 388384957,
        "latitude": 40.7624064,
        "longitude": -73.9498204,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-22T13:47:47.750Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 388380487,
        "latitude": 40.7602735,
        "longitude": -73.9520204,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-22T13:36:25.602Z",
        "type": "Periodical"
    },
    {
        "id": 388375902,
        "latitude": 40.760271,
        "longitude": -73.952009,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-05-22T13:25:55.319Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 387997688,
        "latitude": 40.7617823,
        "longitude": -73.9498575,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-21T22:42:31.736Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 387985336,
        "latitude": 40.7667545,
        "longitude": -73.973486,
        "accuracy": 148,
        "source": "Fused",
        "ts": "2024-05-21T22:20:35.581Z",
        "type": "Periodical"
    },
    {
        "id": 387980211,
        "latitude": 40.7665506,
        "longitude": -73.9769383,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-05-21T22:01:52.486Z",
        "type": "Periodical"
    },
    {
        "id": 387952655,
        "latitude": 40.7636956,
        "longitude": -73.9756497,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-05-21T21:22:03.225Z",
        "type": "Periodical"
    },
    {
        "id": 387933990,
        "latitude": 40.7662369,
        "longitude": -73.9824577,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-21T20:46:18.309Z",
        "type": "Periodical"
    },
    {
        "id": 387925698,
        "latitude": 40.7662104,
        "longitude": -73.9823755,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-21T20:24:50.463Z",
        "type": "Periodical"
    },
    {
        "id": 387913699,
        "latitude": 40.7661858,
        "longitude": -73.9824211,
        "accuracy": 51,
        "source": "Fused",
        "ts": "2024-05-21T20:06:34.597Z",
        "type": "Periodical"
    },
    {
        "id": 387898745,
        "latitude": 40.7662272,
        "longitude": -73.9824384,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-21T19:36:59.980Z",
        "type": "Periodical"
    },
    {
        "id": 387876226,
        "latitude": 40.7662984,
        "longitude": -73.982226,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-05-21T18:51:53.971Z",
        "type": "Periodical"
    },
    {
        "id": 387867978,
        "latitude": 40.7662183,
        "longitude": -73.9822777,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-21T18:29:39.740Z",
        "type": "Periodical"
    },
    {
        "id": 387867977,
        "latitude": 40.7662183,
        "longitude": -73.9822777,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-21T18:09:39.739Z",
        "type": "Periodical"
    },
    {
        "id": 387867976,
        "latitude": 40.7662183,
        "longitude": -73.9822777,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-05-21T17:59:39.735Z",
        "type": "Periodical"
    },
    {
        "id": 387836419,
        "latitude": 40.7663536,
        "longitude": -73.9827693,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-05-21T17:14:59.027Z",
        "type": "Periodical"
    },
    {
        "id": 387828182,
        "latitude": 40.7662859,
        "longitude": -73.982529,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-21T16:52:51.465Z",
        "type": "Periodical"
    },
    {
        "id": 387813947,
        "latitude": 40.7662137,
        "longitude": -73.9823931,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-21T16:16:50.003Z",
        "type": "Periodical"
    },
    {
        "id": 387798631,
        "latitude": 40.766232,
        "longitude": -73.9824897,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-21T15:37:08.566Z",
        "type": "Periodical"
    },
    {
        "id": 387789011,
        "latitude": 40.7663365,
        "longitude": -73.9826416,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-21T15:13:06.088Z",
        "type": "Periodical"
    },
    {
        "id": 387784547,
        "latitude": 40.763893,
        "longitude": -73.9775047,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-05-21T15:01:08.378Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 387203292,
        "latitude": 40.7619302,
        "longitude": -73.9511739,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-20T20:01:20.268Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 387203300,
        "latitude": 40.7619302,
        "longitude": -73.9511739,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-20T20:01:20.268Z",
        "type": "Periodical"
    },
    {
        "id": 387191823,
        "latitude": 40.7661618,
        "longitude": -73.981602,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-05-20T19:33:30.249Z",
        "type": "Periodical"
    },
    {
        "id": 387177722,
        "latitude": 40.7661559,
        "longitude": -73.9814746,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-20T19:03:49.002Z",
        "type": "Periodical"
    },
    {
        "id": 387170354,
        "latitude": 40.7662334,
        "longitude": -73.9823978,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-20T18:44:30.195Z",
        "type": "Periodical"
    },
    {
        "id": 387151923,
        "latitude": 40.7662464,
        "longitude": -73.9824653,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-20T18:02:39.227Z",
        "type": "Periodical"
    },
    {
        "id": 387136556,
        "latitude": 40.7662542,
        "longitude": -73.9823846,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-20T17:30:27.256Z",
        "type": "Periodical"
    },
    {
        "id": 387124100,
        "latitude": 40.7663475,
        "longitude": -73.9827604,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-20T16:58:31.150Z",
        "type": "Periodical"
    },
    {
        "id": 387122352,
        "latitude": 40.7662422,
        "longitude": -73.9824438,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-20T16:27:38.934Z",
        "type": "Periodical"
    },
    {
        "id": 387101990,
        "latitude": 40.76623,
        "longitude": -73.9823936,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-20T16:03:00.612Z",
        "type": "Periodical"
    },
    {
        "id": 387101983,
        "latitude": 40.7662355,
        "longitude": -73.9823949,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-20T15:39:50.374Z",
        "type": "Periodical"
    },
    {
        "id": 387083144,
        "latitude": 40.7662337,
        "longitude": -73.9823949,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-20T15:00:00.583Z",
        "type": "Periodical"
    },
    {
        "id": 387083143,
        "latitude": 40.7662319,
        "longitude": -73.9824338,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-20T14:39:26.269Z",
        "type": "Periodical"
    },
    {
        "id": 387055573,
        "latitude": 40.7662381,
        "longitude": -73.9821637,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-05-20T14:06:26.680Z",
        "type": "Periodical"
    },
    {
        "id": 387041837,
        "latitude": 40.7590608,
        "longitude": -73.9529588,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-20T13:32:59.308Z",
        "type": "Periodical"
    },
    {
        "id": 387041083,
        "latitude": 40.758902,
        "longitude": -73.9526673,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-20T13:31:01.359Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 386005401,
        "latitude": 40.7618754,
        "longitude": -73.9497584,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-18T21:40:17.928Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 386004894,
        "latitude": 40.7610652,
        "longitude": -73.9508587,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-05-18T21:38:47.588Z",
        "type": "Periodical"
    },
    {
        "id": 385991318,
        "latitude": 40.7663639,
        "longitude": -73.9827614,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-18T21:11:39.831Z",
        "type": "Periodical"
    },
    {
        "id": 385974379,
        "latitude": 40.7661815,
        "longitude": -73.9820415,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-18T20:39:15.761Z",
        "type": "Periodical"
    },
    {
        "id": 385957377,
        "latitude": 40.7661373,
        "longitude": -73.9815059,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-05-18T20:05:01.079Z",
        "type": "Periodical"
    },
    {
        "id": 385954746,
        "latitude": 40.7661891,
        "longitude": -73.9815349,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-05-18T19:37:21.135Z",
        "type": "Periodical"
    },
    {
        "id": 385931252,
        "latitude": 40.7662208,
        "longitude": -73.9822958,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-05-18T19:12:15.470Z",
        "type": "Periodical"
    },
    {
        "id": 385917119,
        "latitude": 40.7663206,
        "longitude": -73.9826432,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-18T18:43:10.124Z",
        "type": "Periodical"
    },
    {
        "id": 385915064,
        "latitude": 40.7662235,
        "longitude": -73.982283,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-18T18:08:57.651Z",
        "type": "Periodical"
    },
    {
        "id": 385915063,
        "latitude": 40.7662235,
        "longitude": -73.982283,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-05-18T17:28:57.646Z",
        "type": "Periodical"
    },
    {
        "id": 385878795,
        "latitude": 40.7662069,
        "longitude": -73.9823363,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-18T17:09:36.734Z",
        "type": "Periodical"
    },
    {
        "id": 385878794,
        "latitude": 40.7661528,
        "longitude": -73.9815564,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-05-18T16:39:36.731Z",
        "type": "Periodical"
    },
    {
        "id": 385838908,
        "latitude": 40.7657489,
        "longitude": -73.9805467,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-05-18T16:01:50.520Z",
        "type": "Periodical"
    },
    {
        "id": 385825881,
        "latitude": 40.7591023,
        "longitude": -73.9533023,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-18T15:34:31.289Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 385507822,
        "latitude": 40.7624962,
        "longitude": -73.9497785,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-05-18T02:22:18.301Z",
        "type": "Periodical"
    },
    {
        "id": 385507821,
        "latitude": 40.7624962,
        "longitude": -73.9497785,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-05-18T02:22:18.301Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 385493340,
        "latitude": 40.7642737,
        "longitude": -73.9773715,
        "accuracy": 42,
        "source": "Fused",
        "ts": "2024-05-18T01:54:12.454Z",
        "type": "Periodical"
    },
    {
        "id": 385482838,
        "latitude": 40.7663432,
        "longitude": -73.9826944,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-18T01:29:34.446Z",
        "type": "Periodical"
    },
    {
        "id": 385482837,
        "latitude": 40.7662661,
        "longitude": -73.9825174,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-18T01:07:34.203Z",
        "type": "Periodical"
    },
    {
        "id": 385456214,
        "latitude": 40.7662353,
        "longitude": -73.9820846,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-05-18T00:42:54.427Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 385272877,
        "latitude": 40.7628436,
        "longitude": -73.9494113,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-17T19:13:46.223Z",
        "type": "Periodical"
    },
    {
        "id": 385272876,
        "latitude": 40.7626167,
        "longitude": -73.9497283,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-17T19:13:14.971Z",
        "type": "Periodical"
    },
    {
        "id": 385213280,
        "latitude": 40.7623031,
        "longitude": -73.9498762,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-17T16:57:48.143Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 385210435,
        "latitude": 40.7590704,
        "longitude": -73.9530952,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-17T16:51:21.711Z",
        "type": "Periodical"
    },
    {
        "id": 385206034,
        "latitude": 40.7544846,
        "longitude": -73.9839219,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-17T16:40:18.958Z",
        "type": "Periodical"
    },
    {
        "id": 385195831,
        "latitude": 40.7533953,
        "longitude": -73.9821519,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-17T16:14:22.523Z",
        "type": "Periodical"
    },
    {
        "id": 385195730,
        "latitude": 40.7532366,
        "longitude": -73.9822481,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-17T15:49:02.180Z",
        "type": "Periodical"
    },
    {
        "id": 385169250,
        "latitude": 40.7531102,
        "longitude": -73.9819659,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-17T15:05:34.266Z",
        "type": "Periodical"
    },
    {
        "id": 385161060,
        "latitude": 40.7546496,
        "longitude": -73.9835268,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-17T14:46:38.186Z",
        "type": "Periodical"
    },
    {
        "id": 385152896,
        "latitude": 40.7583712,
        "longitude": -73.981622,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-17T14:26:12.229Z",
        "type": "Periodical"
    },
    {
        "id": 385144332,
        "latitude": 40.761207,
        "longitude": -73.9505185,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-17T14:05:44.235Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 384558867,
        "latitude": 40.762176,
        "longitude": -73.9510201,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-05-16T15:33:31.741Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 384558050,
        "latitude": 40.7610877,
        "longitude": -73.9515677,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-16T15:31:10.698Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 384069796,
        "latitude": 40.7623524,
        "longitude": -73.9498314,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-15T20:41:28.126Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 384065320,
        "latitude": 40.7579483,
        "longitude": -73.9546478,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-05-15T20:32:54.293Z",
        "type": "Periodical"
    },
    {
        "id": 384056586,
        "latitude": 40.7597262,
        "longitude": -73.9515857,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-05-15T20:17:12.185Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 383626024,
        "latitude": 40.7628107,
        "longitude": -73.9492733,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-15T02:15:28.676Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 383620620,
        "latitude": 40.7638807,
        "longitude": -73.9486156,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-05-15T02:04:38.980Z",
        "type": "Periodical"
    },
    {
        "id": 383600039,
        "latitude": 40.790369,
        "longitude": -73.9730118,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-15T01:25:23.759Z",
        "type": "Periodical"
    },
    {
        "id": 383600023,
        "latitude": 40.7903646,
        "longitude": -73.9730391,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-15T00:57:54.761Z",
        "type": "Periodical"
    },
    {
        "id": 383600022,
        "latitude": 40.7903646,
        "longitude": -73.9730391,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-15T00:37:54.760Z",
        "type": "Periodical"
    },
    {
        "id": 383561988,
        "latitude": 40.7903728,
        "longitude": -73.9729773,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-15T00:10:03.861Z",
        "type": "Periodical"
    },
    {
        "id": 383561987,
        "latitude": 40.7903728,
        "longitude": -73.9729773,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-14T23:30:03.859Z",
        "type": "Periodical"
    },
    {
        "id": 383529251,
        "latitude": 40.7836816,
        "longitude": -73.9585564,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-14T23:08:33.272Z",
        "type": "Periodical"
    },
    {
        "id": 383529162,
        "latitude": 40.7929807,
        "longitude": -73.9189867,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-05-14T22:35:33.218Z",
        "type": "Periodical"
    },
    {
        "id": 383529161,
        "latitude": 40.7977818,
        "longitude": -73.916303,
        "accuracy": 586,
        "source": "Fused",
        "ts": "2024-05-14T21:59:22.012Z",
        "type": "Periodical"
    },
    {
        "id": 383529160,
        "latitude": 40.8019802,
        "longitude": -73.9166929,
        "accuracy": 600,
        "source": "Fused",
        "ts": "2024-05-14T21:33:27.332Z",
        "type": "Periodical"
    },
    {
        "id": 383459627,
        "latitude": 40.7977821,
        "longitude": -73.9163023,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-05-14T20:58:58.120Z",
        "type": "Periodical"
    },
    {
        "id": 383457247,
        "latitude": 40.7929805,
        "longitude": -73.9189819,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-14T20:18:50.615Z",
        "type": "Periodical"
    },
    {
        "id": 383457246,
        "latitude": 40.7929805,
        "longitude": -73.9189819,
        "accuracy": 72,
        "source": "Fused",
        "ts": "2024-05-14T20:12:12.612Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 382991204,
        "latitude": 40.7619452,
        "longitude": -73.9499555,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-14T01:16:12.039Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 382978419,
        "latitude": 40.7657332,
        "longitude": -73.9672116,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-14T00:51:07.260Z",
        "type": "Periodical"
    },
    {
        "id": 382978422,
        "latitude": 40.7657329,
        "longitude": -73.9672107,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-13T23:55:32.191Z",
        "type": "Periodical"
    },
    {
        "id": 382978421,
        "latitude": 40.7657329,
        "longitude": -73.9672107,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-13T23:22:48.143Z",
        "type": "Periodical"
    },
    {
        "id": 382916308,
        "latitude": 40.7657241,
        "longitude": -73.9672081,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-13T22:52:11.421Z",
        "type": "Periodical"
    },
    {
        "id": 382906239,
        "latitude": 40.7612119,
        "longitude": -73.95057,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-13T22:33:00.206Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 382672361,
        "latitude": 40.7619576,
        "longitude": -73.9510059,
        "accuracy": 39,
        "source": "Fused",
        "ts": "2024-05-13T13:38:20.299Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 382668986,
        "latitude": 40.7587584,
        "longitude": -73.9533126,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-13T13:28:53.634Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 382241011,
        "latitude": 40.7624906,
        "longitude": -73.9497016,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-12T20:15:08.785Z",
        "type": "Periodical"
    },
    {
        "id": 382241279,
        "latitude": 40.7624906,
        "longitude": -73.9497016,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-12T20:15:08.785Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 382233776,
        "latitude": 40.7611648,
        "longitude": -73.9643409,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-05-12T20:00:02.262Z",
        "type": "Periodical"
    },
    {
        "id": 382217213,
        "latitude": 40.7606,
        "longitude": -73.9583801,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-05-12T19:25:04.183Z",
        "type": "Periodical"
    },
    {
        "id": 382213071,
        "latitude": 40.7602855,
        "longitude": -73.952028,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-12T19:16:03.673Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 381629846,
        "latitude": 40.7626535,
        "longitude": -73.9497389,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-11T20:57:51.283Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 381622810,
        "latitude": 40.7655609,
        "longitude": -73.9475947,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-11T20:19:52.740Z",
        "type": "Periodical"
    },
    {
        "id": 381601731,
        "latitude": 40.7654557,
        "longitude": -73.9475496,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-11T20:00:00.085Z",
        "type": "Periodical"
    },
    {
        "id": 381596510,
        "latitude": 40.7643535,
        "longitude": -73.9478231,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-11T19:24:42.846Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 381465435,
        "latitude": 40.7619844,
        "longitude": -73.9497458,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-11T15:13:09.244Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 381464548,
        "latitude": 40.7641002,
        "longitude": -73.9478293,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-11T15:11:16.705Z",
        "type": "Periodical"
    },
    {
        "id": 381452429,
        "latitude": 40.7447415,
        "longitude": -73.918294,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-11T14:45:50.798Z",
        "type": "Periodical"
    },
    {
        "id": 381438920,
        "latitude": 40.7449905,
        "longitude": -73.9180589,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-11T14:15:50.797Z",
        "type": "Periodical"
    },
    {
        "id": 381425955,
        "latitude": 40.7448336,
        "longitude": -73.9180454,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-11T13:46:50.173Z",
        "type": "Periodical"
    },
    {
        "id": 381414599,
        "latitude": 40.7447962,
        "longitude": -73.9181478,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-11T13:19:25.398Z",
        "type": "Periodical"
    },
    {
        "id": 381402598,
        "latitude": 40.7447866,
        "longitude": -73.9182546,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-11T12:50:10.295Z",
        "type": "Periodical"
    },
    {
        "id": 381392575,
        "latitude": 40.7447844,
        "longitude": -73.9182694,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-11T12:23:13.185Z",
        "type": "Periodical"
    },
    {
        "id": 381373977,
        "latitude": 40.7604022,
        "longitude": -73.9218445,
        "accuracy": 259,
        "source": "Fused",
        "ts": "2024-05-11T11:34:50.959Z",
        "type": "Periodical"
    },
    {
        "id": 381371475,
        "latitude": 40.7620074,
        "longitude": -73.9423405,
        "accuracy": 38,
        "source": "Fused",
        "ts": "2024-05-11T11:22:01.475Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 380985861,
        "latitude": 40.7624905,
        "longitude": -73.9497121,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-10T20:53:45.708Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 380985710,
        "latitude": 40.7587861,
        "longitude": -73.953359,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-10T20:45:35.073Z",
        "type": "Periodical"
    },
    {
        "id": 380966274,
        "latitude": 40.7600183,
        "longitude": -73.9519812,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-05-10T20:16:59.782Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 380369468,
        "latitude": 40.762813,
        "longitude": -73.9493866,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-09T20:40:45.601Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 380366148,
        "latitude": 40.7643504,
        "longitude": -73.9474787,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-09T20:37:23.835Z",
        "type": "Periodical"
    },
    {
        "id": 380355919,
        "latitude": 40.757105,
        "longitude": -73.9209079,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-09T20:17:33.606Z",
        "type": "Periodical"
    },
    {
        "id": 380337471,
        "latitude": 40.75707,
        "longitude": -73.9208197,
        "accuracy": 388,
        "source": "Fused",
        "ts": "2024-05-09T19:39:58.126Z",
        "type": "Periodical"
    },
    {
        "id": 380319133,
        "latitude": 40.7636178,
        "longitude": -73.9150576,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-09T18:59:33.217Z",
        "type": "Periodical"
    },
    {
        "id": 380310974,
        "latitude": 40.7636092,
        "longitude": -73.9150266,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-09T18:39:04.411Z",
        "type": "Periodical"
    },
    {
        "id": 380305564,
        "latitude": 40.7588094,
        "longitude": -73.9191111,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-05-09T18:20:03.847Z",
        "type": "Periodical"
    },
    {
        "id": 380292355,
        "latitude": 40.7447925,
        "longitude": -73.9181879,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-09T17:47:33.362Z",
        "type": "Periodical"
    },
    {
        "id": 380280980,
        "latitude": 40.7447438,
        "longitude": -73.9182224,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-09T17:16:10.489Z",
        "type": "Periodical"
    },
    {
        "id": 380272343,
        "latitude": 40.7447644,
        "longitude": -73.9182449,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-09T16:52:23.190Z",
        "type": "Periodical"
    },
    {
        "id": 380264631,
        "latitude": 40.7447719,
        "longitude": -73.918246,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-09T16:32:44.007Z",
        "type": "Periodical"
    },
    {
        "id": 380255712,
        "latitude": 40.7447328,
        "longitude": -73.9183029,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-09T16:09:39.136Z",
        "type": "Periodical"
    },
    {
        "id": 380248290,
        "latitude": 40.7447529,
        "longitude": -73.9182742,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-09T15:49:39.134Z",
        "type": "Periodical"
    },
    {
        "id": 380230339,
        "latitude": 40.7431093,
        "longitude": -73.9181681,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-09T15:02:25.984Z",
        "type": "Periodical"
    },
    {
        "id": 380219475,
        "latitude": 40.761224,
        "longitude": -73.9506719,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-09T14:34:41.015Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 379879832,
        "latitude": 40.762411,
        "longitude": -73.9497813,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-09T00:07:32.294Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 379877878,
        "latitude": 40.7617749,
        "longitude": -73.9497936,
        "accuracy": 361,
        "source": "Fused",
        "ts": "2024-05-09T00:03:28.341Z",
        "type": "Periodical"
    },
    {
        "id": 379874986,
        "latitude": 40.7662429,
        "longitude": -73.9604123,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-05-08T23:55:43.118Z",
        "type": "Periodical"
    },
    {
        "id": 379866213,
        "latitude": 40.7146959,
        "longitude": -74.0081914,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-05-08T23:33:00.584Z",
        "type": "Periodical"
    },
    {
        "id": 379856867,
        "latitude": 40.6816579,
        "longitude": -73.9562479,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-08T23:19:02.399Z",
        "type": "Periodical"
    },
    {
        "id": 379836087,
        "latitude": 40.681639,
        "longitude": -73.9570924,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-08T22:37:30.735Z",
        "type": "Periodical"
    },
    {
        "id": 379819844,
        "latitude": 40.6816359,
        "longitude": -73.957124,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-05-08T22:00:34.829Z",
        "type": "Periodical"
    },
    {
        "id": 379799383,
        "latitude": 40.6813072,
        "longitude": -73.9568725,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-05-08T21:28:01.253Z",
        "type": "Periodical"
    },
    {
        "id": 379802131,
        "latitude": 40.6813072,
        "longitude": -73.9568725,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-05-08T21:28:01.253Z",
        "type": "Periodical"
    },
    {
        "id": 379799142,
        "latitude": 40.6812364,
        "longitude": -73.956238,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-08T21:27:23.383Z",
        "type": "Periodical"
    },
    {
        "id": 379795062,
        "latitude": 40.6814043,
        "longitude": -73.9563521,
        "accuracy": 37,
        "source": "Fused",
        "ts": "2024-05-08T21:20:04.054Z",
        "type": "Periodical"
    },
    {
        "id": 379771073,
        "latitude": 40.7376983,
        "longitude": -73.9964253,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-05-08T20:33:51.587Z",
        "type": "Periodical"
    },
    {
        "id": 379759973,
        "latitude": 40.7609564,
        "longitude": -73.9517868,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-05-08T20:10:50.573Z",
        "type": "Periodical"
    },
    {
        "id": 379759056,
        "latitude": 40.7617839,
        "longitude": -73.9514587,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-08T20:09:28.979Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 379702312,
        "latitude": 40.7626314,
        "longitude": -73.949647,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-08T18:00:53.391Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 379693217,
        "latitude": 40.7505223,
        "longitude": -73.9890933,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-05-08T17:36:20.857Z",
        "type": "Periodical"
    },
    {
        "id": 379679580,
        "latitude": 40.7495885,
        "longitude": -73.9997882,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-08T16:59:12.352Z",
        "type": "Periodical"
    },
    {
        "id": 379678541,
        "latitude": 40.749545,
        "longitude": -73.9997572,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-08T16:50:04.139Z",
        "type": "Periodical"
    },
    {
        "id": 379667579,
        "latitude": 40.7496155,
        "longitude": -73.999745,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-05-08T16:20:19.258Z",
        "type": "Periodical"
    },
    {
        "id": 379667578,
        "latitude": 40.7495714,
        "longitude": -73.9997655,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-08T15:25:00.433Z",
        "type": "Periodical"
    },
    {
        "id": 379633644,
        "latitude": 40.7483436,
        "longitude": -73.9999816,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-05-08T14:54:52.564Z",
        "type": "Periodical"
    },
    {
        "id": 379621654,
        "latitude": 40.7550783,
        "longitude": -73.9849983,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-08T14:17:47.284Z",
        "type": "Periodical"
    },
    {
        "id": 379613594,
        "latitude": 40.7594931,
        "longitude": -73.9529751,
        "accuracy": 63,
        "source": "Fused",
        "ts": "2024-05-08T14:00:02.422Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 379279101,
        "latitude": 40.7630695,
        "longitude": -73.9501728,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-05-08T00:26:23.772Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 379277705,
        "latitude": 40.7647246,
        "longitude": -73.9483885,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-05-08T00:07:10.802Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 379089942,
        "latitude": 40.7625753,
        "longitude": -73.9497097,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-07T17:58:06.762Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 379084247,
        "latitude": 40.7556473,
        "longitude": -73.9563574,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-07T17:42:14.668Z",
        "type": "Periodical"
    },
    {
        "id": 379079031,
        "latitude": 40.7554896,
        "longitude": -73.9565275,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-07T17:21:16.138Z",
        "type": "Periodical"
    },
    {
        "id": 379065381,
        "latitude": 40.760259,
        "longitude": -73.9519876,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-07T16:49:19.475Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 378697852,
        "latitude": 40.7617589,
        "longitude": -73.9499015,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-07T01:07:03.329Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 378688960,
        "latitude": 40.7649294,
        "longitude": -73.9667037,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-05-07T00:50:16.296Z",
        "type": "Periodical"
    },
    {
        "id": 378672168,
        "latitude": 40.7657169,
        "longitude": -73.9671801,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-07T00:17:12.588Z",
        "type": "Periodical"
    },
    {
        "id": 378682704,
        "latitude": 40.7657169,
        "longitude": -73.9671801,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-07T00:17:12.588Z",
        "type": "Periodical"
    },
    {
        "id": 378672159,
        "latitude": 40.7657164,
        "longitude": -73.9671907,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-06T23:47:15.798Z",
        "type": "Periodical"
    },
    {
        "id": 378641569,
        "latitude": 40.7657151,
        "longitude": -73.9671787,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-05-06T23:17:15.795Z",
        "type": "Periodical"
    },
    {
        "id": 378622819,
        "latitude": 40.7590315,
        "longitude": -73.9530417,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-06T22:37:06.148Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 378622820,
        "latitude": 40.7590315,
        "longitude": -73.9530417,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-06T22:37:06.148Z",
        "type": "Periodical"
    },
    {
        "id": 377357244,
        "latitude": 40.7618852,
        "longitude": -73.9502268,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-04T20:48:28.227Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 377357240,
        "latitude": 40.7618852,
        "longitude": -73.9502268,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-04T20:48:28.227Z",
        "type": "Periodical"
    },
    {
        "id": 377338886,
        "latitude": 40.7554959,
        "longitude": -73.9562199,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-04T20:10:06.121Z",
        "type": "Periodical"
    },
    {
        "id": 377325523,
        "latitude": 40.7554932,
        "longitude": -73.9562473,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-04T19:43:16.546Z",
        "type": "Periodical"
    },
    {
        "id": 377317881,
        "latitude": 40.7595092,
        "longitude": -73.9528052,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-05-04T19:26:07.742Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 377223209,
        "latitude": 40.7632108,
        "longitude": -73.9487358,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-04T16:15:27.193Z",
        "type": "Periodical"
    },
    {
        "id": 377223210,
        "latitude": 40.7632108,
        "longitude": -73.9487358,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-05-04T16:15:27.193Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 377220454,
        "latitude": 40.7628663,
        "longitude": -73.9477298,
        "accuracy": 6,
        "source": "Fused",
        "ts": "2024-05-04T16:09:47.945Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 376025290,
        "latitude": 40.7625702,
        "longitude": -73.9500952,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-02T18:35:19.666Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 376025035,
        "latitude": 40.7597055,
        "longitude": -73.9512798,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-05-02T18:34:45.434Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 376024999,
        "latitude": 40.7626066,
        "longitude": -73.950209,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-02T18:34:35.599Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 376024455,
        "latitude": 40.7591393,
        "longitude": -73.9511706,
        "accuracy": 96,
        "source": "Fused",
        "ts": "2024-05-02T18:33:11.428Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 376024409,
        "latitude": 40.7626937,
        "longitude": -73.9498756,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-05-02T18:33:00.565Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 376023709,
        "latitude": 40.759186,
        "longitude": -73.9512335,
        "accuracy": 7,
        "source": "Fused",
        "ts": "2024-05-02T18:31:18.028Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 376017365,
        "latitude": 40.7616735,
        "longitude": -73.9499845,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-02T18:13:45.463Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 376012499,
        "latitude": 40.755491,
        "longitude": -73.9565433,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-02T18:00:30.460Z",
        "type": "Periodical"
    },
    {
        "id": 375997546,
        "latitude": 40.7554706,
        "longitude": -73.9565318,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-02T17:20:45.634Z",
        "type": "Periodical"
    },
    {
        "id": 375983097,
        "latitude": 40.7554935,
        "longitude": -73.9565401,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-05-02T16:40:13.428Z",
        "type": "Periodical"
    },
    {
        "id": 375972574,
        "latitude": 40.7554732,
        "longitude": -73.9565146,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-02T16:11:07.256Z",
        "type": "Periodical"
    },
    {
        "id": 375961938,
        "latitude": 40.7586282,
        "longitude": -73.9540112,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-05-02T15:41:10.620Z",
        "type": "Periodical"
    },
    {
        "id": 375960918,
        "latitude": 40.7598347,
        "longitude": -73.952321,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-05-02T15:38:58.567Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 375960801,
        "latitude": 40.7614917,
        "longitude": -73.950545,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-05-02T15:38:36.940Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 375960776,
        "latitude": 40.7601683,
        "longitude": -73.9519519,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-02T15:38:31.411Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 375960452,
        "latitude": 40.7615326,
        "longitude": -73.9504967,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-05-02T15:37:43.865Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 375960186,
        "latitude": 40.7609572,
        "longitude": -73.9511328,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-02T15:36:59.304Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 375619055,
        "latitude": 40.7628539,
        "longitude": -73.9494562,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-05-02T01:07:52.613Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 375612087,
        "latitude": 40.7570965,
        "longitude": -73.9346731,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-05-02T00:56:12.352Z",
        "type": "Periodical"
    },
    {
        "id": 375601643,
        "latitude": 40.7447768,
        "longitude": -73.9182626,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-02T00:36:44.930Z",
        "type": "Periodical"
    },
    {
        "id": 375589891,
        "latitude": 40.7447468,
        "longitude": -73.9182933,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-05-02T00:13:39.045Z",
        "type": "Periodical"
    },
    {
        "id": 375581330,
        "latitude": 40.7447382,
        "longitude": -73.9182883,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-01T23:57:32.018Z",
        "type": "Periodical"
    },
    {
        "id": 375566028,
        "latitude": 40.7447382,
        "longitude": -73.9182883,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-01T23:27:32.015Z",
        "type": "Periodical"
    },
    {
        "id": 375552605,
        "latitude": 40.7447382,
        "longitude": -73.9182883,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-01T23:01:59.405Z",
        "type": "Periodical"
    },
    {
        "id": 375523330,
        "latitude": 40.7447918,
        "longitude": -73.918264,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-05-01T22:05:13.387Z",
        "type": "Periodical"
    },
    {
        "id": 375508633,
        "latitude": 40.7620723,
        "longitude": -73.9427409,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-05-01T21:35:21.746Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 375027767,
        "latitude": 40.7622061,
        "longitude": -73.9493915,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-01T01:45:05.672Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 375025804,
        "latitude": 40.7642586,
        "longitude": -73.9475863,
        "accuracy": 63,
        "source": "Fused",
        "ts": "2024-05-01T01:39:05.656Z",
        "type": "Periodical"
    },
    {
        "id": 375007294,
        "latitude": 40.7903662,
        "longitude": -73.972998,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-01T01:02:15.467Z",
        "type": "Periodical"
    },
    {
        "id": 375007293,
        "latitude": 40.7903662,
        "longitude": -73.972998,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-05-01T00:32:15.468Z",
        "type": "Periodical"
    },
    {
        "id": 375007292,
        "latitude": 40.7903662,
        "longitude": -73.972998,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T23:52:15.465Z",
        "type": "Periodical"
    },
    {
        "id": 375007291,
        "latitude": 40.7903723,
        "longitude": -73.9729834,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-30T23:19:10.106Z",
        "type": "Periodical"
    },
    {
        "id": 374941881,
        "latitude": 40.7906588,
        "longitude": -73.9693511,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-04-30T22:50:08.902Z",
        "type": "Periodical"
    },
    {
        "id": 374919846,
        "latitude": 40.7642999,
        "longitude": -73.9652251,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-30T22:17:48.432Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 374919847,
        "latitude": 40.7642999,
        "longitude": -73.9652251,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-30T22:17:48.432Z",
        "type": "Periodical"
    },
    {
        "id": 374737315,
        "latitude": 40.7613612,
        "longitude": -73.950271,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-30T15:22:48.101Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 374734887,
        "latitude": 40.7590384,
        "longitude": -73.9533179,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-30T15:17:41.084Z",
        "type": "Periodical"
    },
    {
        "id": 374722638,
        "latitude": 40.7657568,
        "longitude": -73.9551759,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-04-30T14:44:55.149Z",
        "type": "Periodical"
    },
    {
        "id": 374713106,
        "latitude": 40.7657576,
        "longitude": -73.9551835,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-30T14:19:48.021Z",
        "type": "Periodical"
    },
    {
        "id": 374705775,
        "latitude": 40.7658359,
        "longitude": -73.9551379,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-04-30T14:00:38.524Z",
        "type": "Periodical"
    },
    {
        "id": 374700965,
        "latitude": 40.7656646,
        "longitude": -73.9551108,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T13:37:31.452Z",
        "type": "Periodical"
    },
    {
        "id": 374700964,
        "latitude": 40.7656646,
        "longitude": -73.9551108,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-30T13:18:00.428Z",
        "type": "Periodical"
    },
    {
        "id": 374672827,
        "latitude": 40.7657713,
        "longitude": -73.9550149,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-30T12:35:55.514Z",
        "type": "Periodical"
    },
    {
        "id": 374658136,
        "latitude": 40.7678521,
        "longitude": -73.9619187,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-30T11:58:49.499Z",
        "type": "Periodical"
    },
    {
        "id": 374658092,
        "latitude": 40.7678674,
        "longitude": -73.9619494,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-30T11:58:35.486Z",
        "type": "Periodical"
    },
    {
        "id": 374658074,
        "latitude": 40.7678698,
        "longitude": -73.9619501,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-30T11:58:21.503Z",
        "type": "Periodical"
    },
    {
        "id": 374658027,
        "latitude": 40.7679336,
        "longitude": -73.9621255,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-04-30T11:58:07.509Z",
        "type": "Periodical"
    },
    {
        "id": 374658004,
        "latitude": 40.767891,
        "longitude": -73.961764,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-30T11:57:53.516Z",
        "type": "Periodical"
    },
    {
        "id": 374657992,
        "latitude": 40.7679194,
        "longitude": -73.961642,
        "accuracy": 1,
        "source": "Fused",
        "ts": "2024-04-30T11:57:39.699Z",
        "type": "Periodical"
    },
    {
        "id": 374647002,
        "latitude": 40.754991,
        "longitude": -73.986626,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-04-30T11:30:30.362Z",
        "type": "Periodical"
    },
    {
        "id": 374635292,
        "latitude": 40.7720284,
        "longitude": -74.0316647,
        "accuracy": 500,
        "source": "Fused",
        "ts": "2024-04-30T11:01:27.846Z",
        "type": "Periodical"
    },
    {
        "id": 374635250,
        "latitude": 40.8935657,
        "longitude": -74.2888238,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-04-30T10:41:47.098Z",
        "type": "Periodical"
    },
    {
        "id": 374635249,
        "latitude": 40.9173505,
        "longitude": -74.8030064,
        "accuracy": 3099,
        "source": "Fused",
        "ts": "2024-04-30T10:11:20.490Z",
        "type": "Periodical"
    },
    {
        "id": 374613126,
        "latitude": 40.9040932,
        "longitude": -74.8237254,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-04-30T10:01:48.910Z",
        "type": "Periodical"
    },
    {
        "id": 374599883,
        "latitude": 41.0007867,
        "longitude": -75.2728661,
        "accuracy": 62,
        "source": "Fused",
        "ts": "2024-04-30T09:22:19.642Z",
        "type": "Periodical"
    },
    {
        "id": 374591838,
        "latitude": 41.1178431,
        "longitude": -75.3811131,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-30T08:49:00.623Z",
        "type": "Periodical"
    },
    {
        "id": 374576682,
        "latitude": 41.0912132,
        "longitude": -75.4231055,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T08:12:12.808Z",
        "type": "Periodical"
    },
    {
        "id": 374569528,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T07:52:12.807Z",
        "type": "Periodical"
    },
    {
        "id": 374556307,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T07:12:12.805Z",
        "type": "Periodical"
    },
    {
        "id": 374549066,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T06:52:12.804Z",
        "type": "Periodical"
    },
    {
        "id": 374538461,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T06:22:12.803Z",
        "type": "Periodical"
    },
    {
        "id": 374527461,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T05:52:12.801Z",
        "type": "Periodical"
    },
    {
        "id": 374516329,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T05:22:12.800Z",
        "type": "Periodical"
    },
    {
        "id": 374504504,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T04:52:12.798Z",
        "type": "Periodical"
    },
    {
        "id": 374492093,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T04:22:12.797Z",
        "type": "Periodical"
    },
    {
        "id": 374480354,
        "latitude": 41.0912156,
        "longitude": -75.4231136,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-30T03:55:00.741Z",
        "type": "Periodical"
    },
    {
        "id": 374470229,
        "latitude": 41.0912157,
        "longitude": -75.4231087,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T03:32:12.794Z",
        "type": "Periodical"
    },
    {
        "id": 374456804,
        "latitude": 41.0912157,
        "longitude": -75.4231087,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T03:02:12.793Z",
        "type": "Periodical"
    },
    {
        "id": 374437332,
        "latitude": 41.0912157,
        "longitude": -75.4231087,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T02:22:12.791Z",
        "type": "Periodical"
    },
    {
        "id": 374416351,
        "latitude": 41.0912157,
        "longitude": -75.4231087,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T01:42:12.789Z",
        "type": "Periodical"
    },
    {
        "id": 374396812,
        "latitude": 41.0912151,
        "longitude": -75.4231171,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-30T01:05:56.870Z",
        "type": "Periodical"
    },
    {
        "id": 374382351,
        "latitude": 41.0913812,
        "longitude": -75.4229054,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-04-30T00:38:53.248Z",
        "type": "Periodical"
    },
    {
        "id": 374360044,
        "latitude": 41.0912161,
        "longitude": -75.4231132,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T23:57:57.864Z",
        "type": "Periodical"
    },
    {
        "id": 374339509,
        "latitude": 41.0912133,
        "longitude": -75.4231108,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T23:19:05.908Z",
        "type": "Periodical"
    },
    {
        "id": 374320700,
        "latitude": 41.1071006,
        "longitude": -75.3925621,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-04-29T22:43:44.933Z",
        "type": "Periodical"
    },
    {
        "id": 374306383,
        "latitude": 40.9783921,
        "longitude": -75.2608609,
        "accuracy": 2466,
        "source": "Fused",
        "ts": "2024-04-29T22:11:25.612Z",
        "type": "Periodical"
    },
    {
        "id": 374284990,
        "latitude": 41.0469323,
        "longitude": -75.3116882,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-04-29T21:35:45.872Z",
        "type": "Periodical"
    },
    {
        "id": 374275455,
        "latitude": 41.0493013,
        "longitude": -75.314315,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-04-29T21:09:38.989Z",
        "type": "Periodical"
    },
    {
        "id": 374264769,
        "latitude": 41.0494693,
        "longitude": -75.3141054,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-29T20:48:16.963Z",
        "type": "Periodical"
    },
    {
        "id": 374245051,
        "latitude": 41.0501097,
        "longitude": -75.3145741,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-29T20:16:53.108Z",
        "type": "Periodical"
    },
    {
        "id": 374235854,
        "latitude": 41.0479631,
        "longitude": -75.3122009,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T19:57:11.402Z",
        "type": "Periodical"
    },
    {
        "id": 374223330,
        "latitude": 41.0025017,
        "longitude": -75.2786679,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-04-29T19:30:23.486Z",
        "type": "Periodical"
    },
    {
        "id": 374213636,
        "latitude": 40.9000297,
        "longitude": -75.4100195,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-04-29T18:54:28.356Z",
        "type": "Periodical"
    },
    {
        "id": 374204834,
        "latitude": 40.9000654,
        "longitude": -75.4103068,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T18:34:41.813Z",
        "type": "Periodical"
    },
    {
        "id": 374197005,
        "latitude": 40.9000489,
        "longitude": -75.4103475,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T18:14:41.812Z",
        "type": "Periodical"
    },
    {
        "id": 374197004,
        "latitude": 40.9000489,
        "longitude": -75.4103475,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T17:54:41.812Z",
        "type": "Periodical"
    },
    {
        "id": 374174328,
        "latitude": 40.9000708,
        "longitude": -75.4103211,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T17:24:41.810Z",
        "type": "Periodical"
    },
    {
        "id": 374158671,
        "latitude": 40.9001025,
        "longitude": -75.409989,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-04-29T16:48:14.799Z",
        "type": "Periodical"
    },
    {
        "id": 374146203,
        "latitude": 41.091227,
        "longitude": -75.4231188,
        "accuracy": 56,
        "source": "Fused",
        "ts": "2024-04-29T16:13:31.897Z",
        "type": "Periodical"
    },
    {
        "id": 374132084,
        "latitude": 41.0912099,
        "longitude": -75.4231132,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T15:35:39.151Z",
        "type": "Periodical"
    },
    {
        "id": 374123732,
        "latitude": 41.0912151,
        "longitude": -75.4231061,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T15:13:24.300Z",
        "type": "Periodical"
    },
    {
        "id": 374121732,
        "latitude": 41.0912163,
        "longitude": -75.4231084,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T14:41:45.480Z",
        "type": "Periodical"
    },
    {
        "id": 374111404,
        "latitude": 41.0912174,
        "longitude": -75.4231097,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T14:41:14.507Z",
        "type": "Periodical"
    },
    {
        "id": 374111344,
        "latitude": 41.0912008,
        "longitude": -75.4231157,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T11:54:27.416Z",
        "type": "Periodical"
    },
    {
        "id": 374028756,
        "latitude": 41.091213,
        "longitude": -75.4231217,
        "accuracy": 60,
        "source": "Fused",
        "ts": "2024-04-29T11:14:53.319Z",
        "type": "Periodical"
    },
    {
        "id": 374025628,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T10:52:10.513Z",
        "type": "Periodical"
    },
    {
        "id": 374025627,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T10:32:10.512Z",
        "type": "Periodical"
    },
    {
        "id": 374025626,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T09:52:10.512Z",
        "type": "Periodical"
    },
    {
        "id": 374025625,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T09:12:10.507Z",
        "type": "Periodical"
    },
    {
        "id": 374025624,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T08:52:10.507Z",
        "type": "Periodical"
    },
    {
        "id": 374025623,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T08:32:10.504Z",
        "type": "Periodical"
    },
    {
        "id": 374025622,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T08:02:10.502Z",
        "type": "Periodical"
    },
    {
        "id": 374025621,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T07:22:10.501Z",
        "type": "Periodical"
    },
    {
        "id": 374025620,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T06:52:10.503Z",
        "type": "Periodical"
    },
    {
        "id": 374025619,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T06:22:10.499Z",
        "type": "Periodical"
    },
    {
        "id": 374025618,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T05:52:10.498Z",
        "type": "Periodical"
    },
    {
        "id": 374025617,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T05:32:10.498Z",
        "type": "Periodical"
    },
    {
        "id": 374025616,
        "latitude": 41.091212,
        "longitude": -75.4231032,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T05:02:10.496Z",
        "type": "Periodical"
    },
    {
        "id": 374025615,
        "latitude": 41.0912167,
        "longitude": -75.4231093,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T04:40:04.053Z",
        "type": "Periodical"
    },
    {
        "id": 374025614,
        "latitude": 41.0912167,
        "longitude": -75.4231093,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T04:20:04.052Z",
        "type": "Periodical"
    },
    {
        "id": 374025613,
        "latitude": 41.0912167,
        "longitude": -75.4231093,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T03:40:04.050Z",
        "type": "Periodical"
    },
    {
        "id": 374025612,
        "latitude": 41.0912167,
        "longitude": -75.4231093,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T03:20:04.050Z",
        "type": "Periodical"
    },
    {
        "id": 374025611,
        "latitude": 41.0912167,
        "longitude": -75.4231093,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T03:00:04.049Z",
        "type": "Periodical"
    },
    {
        "id": 373835401,
        "latitude": 41.0912168,
        "longitude": -75.4231093,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T02:30:10.572Z",
        "type": "Periodical"
    },
    {
        "id": 373835400,
        "latitude": 41.0912158,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T01:58:31.310Z",
        "type": "Periodical"
    },
    {
        "id": 373835399,
        "latitude": 41.0912158,
        "longitude": -75.4231136,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T01:38:31.309Z",
        "type": "Periodical"
    },
    {
        "id": 373790209,
        "latitude": 41.0912162,
        "longitude": -75.4231124,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-29T01:18:06.698Z",
        "type": "Periodical"
    },
    {
        "id": 373790155,
        "latitude": 41.0912104,
        "longitude": -75.4231057,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T00:50:30.647Z",
        "type": "Periodical"
    },
    {
        "id": 373758344,
        "latitude": 41.0912159,
        "longitude": -75.4231074,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-29T00:10:30.645Z",
        "type": "Periodical"
    },
    {
        "id": 373753185,
        "latitude": 41.0912126,
        "longitude": -75.42311,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-28T23:41:47.224Z",
        "type": "Periodical"
    },
    {
        "id": 373729249,
        "latitude": 41.0912144,
        "longitude": -75.4231086,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-28T23:19:49.880Z",
        "type": "Periodical"
    },
    {
        "id": 373729228,
        "latitude": 41.0912152,
        "longitude": -75.4231119,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-28T22:51:38.633Z",
        "type": "Periodical"
    },
    {
        "id": 373706092,
        "latitude": 41.0912152,
        "longitude": -75.4231097,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-28T22:26:12.440Z",
        "type": "Periodical"
    },
    {
        "id": 373680490,
        "latitude": 41.0911953,
        "longitude": -75.423111,
        "accuracy": 57,
        "source": "Fused",
        "ts": "2024-04-28T21:35:01.146Z",
        "type": "Periodical"
    },
    {
        "id": 373666748,
        "latitude": 41.0912202,
        "longitude": -75.4231078,
        "accuracy": 54,
        "source": "Fused",
        "ts": "2024-04-28T21:09:10.695Z",
        "type": "Periodical"
    },
    {
        "id": 373651304,
        "latitude": 40.9244993,
        "longitude": -75.0665997,
        "accuracy": 9,
        "source": "Fused",
        "ts": "2024-04-28T20:29:18.443Z",
        "type": "Periodical"
    },
    {
        "id": 373626939,
        "latitude": 40.8866688,
        "longitude": -74.3200867,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-28T19:55:36.582Z",
        "type": "Periodical"
    },
    {
        "id": 373613612,
        "latitude": 40.7541173,
        "longitude": -73.9974919,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-28T19:29:25.458Z",
        "type": "Periodical"
    },
    {
        "id": 373605903,
        "latitude": 40.7474305,
        "longitude": -73.9801201,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-28T19:13:05.225Z",
        "type": "Periodical"
    },
    {
        "id": 373590475,
        "latitude": 40.7584546,
        "longitude": -73.9541069,
        "accuracy": 52,
        "source": "Fused",
        "ts": "2024-04-28T18:41:35.436Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 372441483,
        "latitude": 40.7628502,
        "longitude": -73.9495288,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-26T22:38:32.211Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 372440616,
        "latitude": 40.7618666,
        "longitude": -73.9425369,
        "accuracy": 64,
        "source": "Fused",
        "ts": "2024-04-26T22:28:26.677Z",
        "type": "Periodical"
    },
    {
        "id": 372416279,
        "latitude": 40.7447903,
        "longitude": -73.9182533,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-26T21:53:32.366Z",
        "type": "Periodical"
    },
    {
        "id": 372393388,
        "latitude": 40.7447631,
        "longitude": -73.9182759,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-26T21:11:16.591Z",
        "type": "Periodical"
    },
    {
        "id": 372378173,
        "latitude": 40.7464265,
        "longitude": -73.9179408,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-26T20:43:13.387Z",
        "type": "Periodical"
    },
    {
        "id": 372355972,
        "latitude": 40.7447314,
        "longitude": -73.9183005,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-26T20:00:32.518Z",
        "type": "Periodical"
    },
    {
        "id": 372355973,
        "latitude": 40.7447451,
        "longitude": -73.9182526,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-04-26T19:58:35.365Z",
        "type": "Periodical"
    },
    {
        "id": 372353429,
        "latitude": 40.7447328,
        "longitude": -73.9182882,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-26T19:55:35.284Z",
        "type": "Periodical"
    },
    {
        "id": 372336283,
        "latitude": 40.7447432,
        "longitude": -73.9182675,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-04-26T19:22:12.975Z",
        "type": "Periodical"
    },
    {
        "id": 372336246,
        "latitude": 40.7447791,
        "longitude": -73.9182414,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-04-26T18:59:47.096Z",
        "type": "Periodical"
    },
    {
        "id": 372314336,
        "latitude": 40.7447545,
        "longitude": -73.9182642,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-04-26T18:35:27.436Z",
        "type": "Periodical"
    },
    {
        "id": 372314303,
        "latitude": 40.7447393,
        "longitude": -73.9182907,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-26T18:09:16.027Z",
        "type": "Periodical"
    },
    {
        "id": 372290115,
        "latitude": 40.7448201,
        "longitude": -73.9182399,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-26T17:25:10.236Z",
        "type": "Periodical"
    },
    {
        "id": 372278206,
        "latitude": 40.7447555,
        "longitude": -73.9182931,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-26T17:02:42.197Z",
        "type": "Periodical"
    },
    {
        "id": 372278205,
        "latitude": 40.7447555,
        "longitude": -73.9182931,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-26T16:47:25.493Z",
        "type": "Periodical"
    },
    {
        "id": 372236555,
        "latitude": 40.7447501,
        "longitude": -73.9182738,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-04-26T15:19:10.349Z",
        "type": "Periodical"
    },
    {
        "id": 372236556,
        "latitude": 40.7447817,
        "longitude": -73.9182375,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-26T15:04:35.843Z",
        "type": "Periodical"
    },
    {
        "id": 372219553,
        "latitude": 40.7447839,
        "longitude": -73.9182325,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-26T14:36:05.156Z",
        "type": "Periodical"
    },
    {
        "id": 372208220,
        "latitude": 40.7447967,
        "longitude": -73.918142,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-26T14:06:51.448Z",
        "type": "Periodical"
    },
    {
        "id": 372208172,
        "latitude": 40.7448252,
        "longitude": -73.9179683,
        "accuracy": 18,
        "source": "Fused",
        "ts": "2024-04-26T14:06:19.982Z",
        "type": "Periodical"
    },
    {
        "id": 372202563,
        "latitude": 40.7600756,
        "longitude": -73.9181103,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-26T13:52:45.887Z",
        "type": "Periodical"
    },
    {
        "id": 372189080,
        "latitude": 40.7616204,
        "longitude": -73.9247433,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-26T13:18:52.204Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 372189155,
        "latitude": 40.7616204,
        "longitude": -73.9247433,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-26T13:18:52.204Z",
        "type": "Periodical"
    },
    {
        "id": 371750329,
        "latitude": 40.7632495,
        "longitude": -73.9487756,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-25T20:37:42.579Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 371743988,
        "latitude": 40.7649665,
        "longitude": -73.9474238,
        "accuracy": 49,
        "source": "Fused",
        "ts": "2024-04-25T20:28:47.880Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 371655378,
        "latitude": 40.7614328,
        "longitude": -73.9500844,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-25T16:46:47.974Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 371649408,
        "latitude": 40.7554599,
        "longitude": -73.9563244,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-04-25T16:29:25.174Z",
        "type": "Periodical"
    },
    {
        "id": 371640194,
        "latitude": 40.7555045,
        "longitude": -73.9565464,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-25T16:02:45.117Z",
        "type": "Periodical"
    },
    {
        "id": 371632401,
        "latitude": 40.7562011,
        "longitude": -73.955646,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-25T15:40:53.169Z",
        "type": "Periodical"
    },
    {
        "id": 371629958,
        "latitude": 40.7602253,
        "longitude": -73.9517035,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-04-25T15:33:45.716Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 371212889,
        "latitude": 40.7628445,
        "longitude": -73.949222,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-24T22:56:29.353Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 371213841,
        "latitude": 40.7603271,
        "longitude": -73.93739,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-04-24T22:52:29.453Z",
        "type": "Periodical"
    },
    {
        "id": 371206902,
        "latitude": 40.7755572,
        "longitude": -73.8734236,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-24T22:22:51.952Z",
        "type": "Periodical"
    },
    {
        "id": 371206903,
        "latitude": 40.7755572,
        "longitude": -73.8734236,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-24T22:22:51.952Z",
        "type": "Periodical"
    },
    {
        "id": 371206901,
        "latitude": 32.8746981,
        "longitude": -97.0288422,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-04-24T22:03:00.503Z",
        "type": "Periodical"
    },
    {
        "id": 371189074,
        "latitude": 32.8746981,
        "longitude": -97.0288422,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-04-24T21:50:02.158Z",
        "type": "Periodical"
    },
    {
        "id": 371189073,
        "latitude": 32.8746981,
        "longitude": -97.0288422,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-04-24T20:55:26.989Z",
        "type": "Periodical"
    },
    {
        "id": 371189072,
        "latitude": 32.8746981,
        "longitude": -97.0288422,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-04-24T20:35:41.896Z",
        "type": "Periodical"
    },
    {
        "id": 371189071,
        "latitude": 32.8746981,
        "longitude": -97.0288422,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-04-24T20:14:04.705Z",
        "type": "Periodical"
    },
    {
        "id": 371189070,
        "latitude": 32.8746981,
        "longitude": -97.0288422,
        "accuracy": 1200,
        "source": "Fused",
        "ts": "2024-04-24T19:34:48.957Z",
        "type": "Periodical"
    },
    {
        "id": 371091563,
        "latitude": 32.8981592,
        "longitude": -97.0355621,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-24T18:55:59.948Z",
        "type": "Periodical"
    },
    {
        "id": 371076053,
        "latitude": 32.8992916,
        "longitude": -97.0370328,
        "accuracy": 46,
        "source": "Fused",
        "ts": "2024-04-24T18:17:29.919Z",
        "type": "Periodical"
    },
    {
        "id": 371071283,
        "latitude": 32.897303,
        "longitude": -97.0357082,
        "accuracy": 22,
        "source": "Fused",
        "ts": "2024-04-24T18:04:42.976Z",
        "type": "Periodical"
    },
    {
        "id": 371067832,
        "latitude": 32.8972837,
        "longitude": -97.0356311,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-24T17:55:47.723Z",
        "type": "Periodical"
    },
    {
        "id": 371059934,
        "latitude": 32.897234,
        "longitude": -97.0356305,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-04-24T17:34:49.076Z",
        "type": "Periodical"
    },
    {
        "id": 371048957,
        "latitude": 32.8997942,
        "longitude": -97.0376811,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-24T17:05:00.489Z",
        "type": "Periodical"
    },
    {
        "id": 371040364,
        "latitude": 32.8999752,
        "longitude": -97.0380064,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-24T16:41:51.118Z",
        "type": "Periodical"
    },
    {
        "id": 371030039,
        "latitude": 32.8971131,
        "longitude": -97.0360581,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-24T16:13:08.692Z",
        "type": "Periodical"
    },
    {
        "id": 371016326,
        "latitude": 32.9236802,
        "longitude": -96.7766705,
        "accuracy": 699,
        "source": "Fused",
        "ts": "2024-04-24T15:35:27.806Z",
        "type": "Periodical"
    },
    {
        "id": 371000551,
        "latitude": 32.9025913,
        "longitude": -96.0645987,
        "accuracy": 1735,
        "source": "Fused",
        "ts": "2024-04-24T14:53:59.474Z",
        "type": "Periodical"
    },
    {
        "id": 370990424,
        "latitude": 32.9029486,
        "longitude": -95.9633691,
        "accuracy": 41,
        "source": "Fused",
        "ts": "2024-04-24T14:26:42.915Z",
        "type": "Periodical"
    },
    {
        "id": 370979013,
        "latitude": 32.9030752,
        "longitude": -95.9642324,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-04-24T13:56:13.407Z",
        "type": "Periodical"
    },
    {
        "id": 370968401,
        "latitude": 32.9031879,
        "longitude": -95.964226,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T13:29:08.040Z",
        "type": "Periodical"
    },
    {
        "id": 370956174,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T12:59:08.039Z",
        "type": "Periodical"
    },
    {
        "id": 370939867,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T12:19:08.035Z",
        "type": "Periodical"
    },
    {
        "id": 370927447,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T11:49:08.033Z",
        "type": "Periodical"
    },
    {
        "id": 370919330,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T11:29:08.032Z",
        "type": "Periodical"
    },
    {
        "id": 370902736,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T10:49:08.028Z",
        "type": "Periodical"
    },
    {
        "id": 370888382,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T10:09:08.027Z",
        "type": "Periodical"
    },
    {
        "id": 370881208,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T09:49:08.025Z",
        "type": "Periodical"
    },
    {
        "id": 370871102,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T09:19:08.024Z",
        "type": "Periodical"
    },
    {
        "id": 370860908,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T08:49:08.023Z",
        "type": "Periodical"
    },
    {
        "id": 370854416,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T08:29:08.020Z",
        "type": "Periodical"
    },
    {
        "id": 370847847,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T08:09:08.020Z",
        "type": "Periodical"
    },
    {
        "id": 370837329,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T07:39:08.018Z",
        "type": "Periodical"
    },
    {
        "id": 370830656,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T07:19:08.017Z",
        "type": "Periodical"
    },
    {
        "id": 370816580,
        "latitude": 32.903065,
        "longitude": -95.9642419,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-04-24T06:39:08.015Z",
        "type": "Periodical"
    },
    {
        "id": 370808105,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T06:14:26.966Z",
        "type": "Periodical"
    },
    {
        "id": 370800616,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T05:54:26.965Z",
        "type": "Periodical"
    },
    {
        "id": 370789214,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T05:24:26.964Z",
        "type": "Periodical"
    },
    {
        "id": 370772719,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T04:44:26.961Z",
        "type": "Periodical"
    },
    {
        "id": 370759676,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T04:14:26.959Z",
        "type": "Periodical"
    },
    {
        "id": 370750926,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T03:54:26.967Z",
        "type": "Periodical"
    },
    {
        "id": 370737096,
        "latitude": 32.9030758,
        "longitude": -95.9642503,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T03:24:26.952Z",
        "type": "Periodical"
    },
    {
        "id": 370723983,
        "latitude": 32.9031821,
        "longitude": -95.9642066,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T02:55:48.834Z",
        "type": "Periodical"
    },
    {
        "id": 370718885,
        "latitude": 32.9028009,
        "longitude": -95.9638988,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-24T02:44:53.808Z",
        "type": "Periodical"
    },
    {
        "id": 370692090,
        "latitude": 32.9028014,
        "longitude": -95.9638997,
        "accuracy": 16,
        "source": "Fused",
        "ts": "2024-04-24T01:52:21.183Z",
        "type": "Periodical"
    },
    {
        "id": 370670671,
        "latitude": 32.9028071,
        "longitude": -95.9639024,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-24T01:14:32.442Z",
        "type": "Periodical"
    },
    {
        "id": 370651778,
        "latitude": 32.8995262,
        "longitude": -96.035992,
        "accuracy": 2400,
        "source": "Fused",
        "ts": "2024-04-24T00:38:33.796Z",
        "type": "Periodical"
    },
    {
        "id": 370636281,
        "latitude": 32.9028048,
        "longitude": -95.9639035,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-24T00:10:00.518Z",
        "type": "Periodical"
    },
    {
        "id": 370617631,
        "latitude": 32.9028016,
        "longitude": -95.9639075,
        "accuracy": 36,
        "source": "Fused",
        "ts": "2024-04-23T23:34:53.541Z",
        "type": "Periodical"
    },
    {
        "id": 370592951,
        "latitude": 32.9028029,
        "longitude": -95.963902,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T22:50:03.814Z",
        "type": "Periodical"
    },
    {
        "id": 370577572,
        "latitude": 32.9027978,
        "longitude": -95.9638997,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T22:20:30.520Z",
        "type": "Periodical"
    },
    {
        "id": 370551149,
        "latitude": 32.9028003,
        "longitude": -95.9639181,
        "accuracy": 20,
        "source": "Fused",
        "ts": "2024-04-23T21:32:04.887Z",
        "type": "Periodical"
    },
    {
        "id": 370533055,
        "latitude": 32.902868,
        "longitude": -95.9639127,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-04-23T20:58:42.309Z",
        "type": "Periodical"
    },
    {
        "id": 370512852,
        "latitude": 32.9028022,
        "longitude": -95.9638986,
        "accuracy": 43,
        "source": "Fused",
        "ts": "2024-04-23T20:18:04.916Z",
        "type": "Periodical"
    },
    {
        "id": 370501566,
        "latitude": 32.9028026,
        "longitude": -95.9639163,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-04-23T19:55:04.817Z",
        "type": "Periodical"
    },
    {
        "id": 370488604,
        "latitude": 32.8995262,
        "longitude": -96.035992,
        "accuracy": 2400,
        "source": "Fused",
        "ts": "2024-04-23T19:27:01.048Z",
        "type": "Periodical"
    },
    {
        "id": 370472562,
        "latitude": 32.872033,
        "longitude": -96.5278096,
        "accuracy": 1500,
        "source": "Fused",
        "ts": "2024-04-23T18:50:10.070Z",
        "type": "Periodical"
    },
    {
        "id": 370464424,
        "latitude": 33.0127147,
        "longitude": -96.8275631,
        "accuracy": 699,
        "source": "Fused",
        "ts": "2024-04-23T18:30:06.097Z",
        "type": "Periodical"
    },
    {
        "id": 370448466,
        "latitude": 33.152687,
        "longitude": -96.8866697,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-23T17:48:24.782Z",
        "type": "Periodical"
    },
    {
        "id": 370436930,
        "latitude": 33.1503828,
        "longitude": -96.8889784,
        "accuracy": 33,
        "source": "Fused",
        "ts": "2024-04-23T17:15:57.778Z",
        "type": "Periodical"
    },
    {
        "id": 370436922,
        "latitude": 33.1503828,
        "longitude": -96.8889784,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-23T17:15:43.126Z",
        "type": "Periodical"
    },
    {
        "id": 370436253,
        "latitude": 33.1504309,
        "longitude": -96.8889564,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-23T17:14:11.843Z",
        "type": "Periodical"
    },
    {
        "id": 370427794,
        "latitude": 33.1504439,
        "longitude": -96.8890011,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-23T16:50:55.097Z",
        "type": "Periodical"
    },
    {
        "id": 370415622,
        "latitude": 33.1534744,
        "longitude": -96.8898936,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-23T16:16:39.127Z",
        "type": "Periodical"
    },
    {
        "id": 370407656,
        "latitude": 33.0914667,
        "longitude": -96.8215342,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-23T15:55:55.149Z",
        "type": "Periodical"
    },
    {
        "id": 370396780,
        "latitude": 32.9162762,
        "longitude": -96.4185513,
        "accuracy": 2400,
        "source": "Fused",
        "ts": "2024-04-23T15:25:34.063Z",
        "type": "Periodical"
    },
    {
        "id": 370386911,
        "latitude": 32.9035964,
        "longitude": -96.0281276,
        "accuracy": 5,
        "source": "Fused",
        "ts": "2024-04-23T14:58:18.789Z",
        "type": "Periodical"
    },
    {
        "id": 370371403,
        "latitude": 32.903084,
        "longitude": -95.9642524,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-04-23T14:16:35.654Z",
        "type": "Periodical"
    },
    {
        "id": 370357567,
        "latitude": 32.9030842,
        "longitude": -95.964231,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T13:39:50.263Z",
        "type": "Periodical"
    },
    {
        "id": 370343012,
        "latitude": 32.9031219,
        "longitude": -95.964227,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-04-23T13:01:54.087Z",
        "type": "Periodical"
    },
    {
        "id": 370331544,
        "latitude": 32.9031694,
        "longitude": -95.9642008,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T12:34:11.104Z",
        "type": "Periodical"
    },
    {
        "id": 370314565,
        "latitude": 32.9032683,
        "longitude": -95.9641823,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T11:54:11.101Z",
        "type": "Periodical"
    },
    {
        "id": 370306537,
        "latitude": 32.9032683,
        "longitude": -95.9641823,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T11:34:11.101Z",
        "type": "Periodical"
    },
    {
        "id": 370294536,
        "latitude": 32.9032683,
        "longitude": -95.9641823,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T11:04:11.097Z",
        "type": "Periodical"
    },
    {
        "id": 370281659,
        "latitude": 32.9032683,
        "longitude": -95.9641823,
        "accuracy": 35,
        "source": "Fused",
        "ts": "2024-04-23T10:30:05.739Z",
        "type": "Periodical"
    },
    {
        "id": 370272690,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T10:04:11.094Z",
        "type": "Periodical"
    },
    {
        "id": 370262660,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T09:34:11.092Z",
        "type": "Periodical"
    },
    {
        "id": 370249332,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T08:54:11.090Z",
        "type": "Periodical"
    },
    {
        "id": 370236723,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T08:14:11.088Z",
        "type": "Periodical"
    },
    {
        "id": 370229665,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T07:54:11.088Z",
        "type": "Periodical"
    },
    {
        "id": 370219695,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T07:24:11.086Z",
        "type": "Periodical"
    },
    {
        "id": 370209376,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T06:54:11.084Z",
        "type": "Periodical"
    },
    {
        "id": 370199258,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T06:24:11.083Z",
        "type": "Periodical"
    },
    {
        "id": 370184980,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T05:44:11.082Z",
        "type": "Periodical"
    },
    {
        "id": 370173822,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T05:14:11.080Z",
        "type": "Periodical"
    },
    {
        "id": 370162022,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T04:44:11.078Z",
        "type": "Periodical"
    },
    {
        "id": 370149974,
        "latitude": 32.9033783,
        "longitude": -95.9641504,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-04-23T04:14:11.074Z",
        "type": "Periodical"
    },
    {
        "id": 370130460,
        "latitude": 32.903087,
        "longitude": -95.9642081,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T03:29:20.441Z",
        "type": "Periodical"
    },
    {
        "id": 370112500,
        "latitude": 32.9030232,
        "longitude": -95.9641873,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T02:51:41.662Z",
        "type": "Periodical"
    },
    {
        "id": 370097599,
        "latitude": 32.9031806,
        "longitude": -95.9642418,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T02:21:41.661Z",
        "type": "Periodical"
    },
    {
        "id": 370082757,
        "latitude": 32.9031806,
        "longitude": -95.9642418,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T01:51:41.659Z",
        "type": "Periodical"
    },
    {
        "id": 370067847,
        "latitude": 32.9031806,
        "longitude": -95.9642418,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-23T01:21:41.658Z",
        "type": "Periodical"
    },
    {
        "id": 370050769,
        "latitude": 32.9036207,
        "longitude": -95.9636728,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-04-23T00:49:05.991Z",
        "type": "Periodical"
    },
    {
        "id": 370033500,
        "latitude": 32.8318906,
        "longitude": -95.9815578,
        "accuracy": 40,
        "source": "Fused",
        "ts": "2024-04-23T00:15:22.214Z",
        "type": "Periodical"
    },
    {
        "id": 370022463,
        "latitude": 32.8987923,
        "longitude": -96.0366535,
        "accuracy": 2700,
        "source": "Fused",
        "ts": "2024-04-22T23:54:34.972Z",
        "type": "Periodical"
    },
    {
        "id": 370007751,
        "latitude": 32.909629,
        "longitude": -95.9569902,
        "accuracy": 2799,
        "source": "Fused",
        "ts": "2024-04-22T23:24:56.088Z",
        "type": "Periodical"
    },
    {
        "id": 369992644,
        "latitude": 32.909629,
        "longitude": -95.9569902,
        "accuracy": 2799,
        "source": "Fused",
        "ts": "2024-04-22T22:54:55.807Z",
        "type": "Periodical"
    },
    {
        "id": 369977394,
        "latitude": 32.909629,
        "longitude": -95.9569902,
        "accuracy": 2799,
        "source": "Fused",
        "ts": "2024-04-22T22:24:55.538Z",
        "type": "Periodical"
    },
    {
        "id": 369964542,
        "latitude": 32.8357421,
        "longitude": -95.9067103,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-04-22T21:59:43.675Z",
        "type": "Periodical"
    },
    {
        "id": 369955825,
        "latitude": 32.8351223,
        "longitude": -95.9069228,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-22T21:43:12.673Z",
        "type": "Periodical"
    },
    {
        "id": 369937635,
        "latitude": 32.833735,
        "longitude": -95.9801133,
        "accuracy": 27,
        "source": "Fused",
        "ts": "2024-04-22T21:10:06.601Z",
        "type": "Periodical"
    },
    {
        "id": 369924078,
        "latitude": 32.909629,
        "longitude": -95.9569902,
        "accuracy": 2799,
        "source": "Fused",
        "ts": "2024-04-22T20:43:42.542Z",
        "type": "Periodical"
    },
    {
        "id": 369913877,
        "latitude": 32.909629,
        "longitude": -95.9569902,
        "accuracy": 2799,
        "source": "Fused",
        "ts": "2024-04-22T20:23:42.355Z",
        "type": "Periodical"
    },
    {
        "id": 369891607,
        "latitude": 32.8336973,
        "longitude": -95.9801068,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-04-22T19:39:42.634Z",
        "type": "Periodical"
    },
    {
        "id": 369883060,
        "latitude": 32.833606,
        "longitude": -95.980071,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-04-22T19:22:06.268Z",
        "type": "Periodical"
    },
    {
        "id": 369861253,
        "latitude": 32.9027483,
        "longitude": -95.9639293,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-04-22T18:36:13.481Z",
        "type": "Periodical"
    },
    {
        "id": 369848471,
        "latitude": 32.9027509,
        "longitude": -95.9639338,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T18:05:08.126Z",
        "type": "Periodical"
    },
    {
        "id": 369836201,
        "latitude": 32.9027509,
        "longitude": -95.9639338,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-04-22T17:35:08.124Z",
        "type": "Periodical"
    },
    {
        "id": 369828984,
        "latitude": 32.9030799,
        "longitude": -95.9642269,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-04-22T17:15:59.995Z",
        "type": "Periodical"
    },
    {
        "id": 369817606,
        "latitude": 32.9028229,
        "longitude": -95.9638843,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-04-22T16:46:32.181Z",
        "type": "Periodical"
    },
    {
        "id": 369808289,
        "latitude": 32.9027598,
        "longitude": -95.9639238,
        "accuracy": 296,
        "source": "Fused",
        "ts": "2024-04-22T16:22:11.931Z",
        "type": "Periodical"
    },
    {
        "id": 369799215,
        "latitude": 32.9018673,
        "longitude": -95.9647563,
        "accuracy": 162,
        "source": "Fused",
        "ts": "2024-04-22T15:57:26.293Z",
        "type": "Periodical"
    },
    {
        "id": 369786551,
        "latitude": 32.9028177,
        "longitude": -95.9638616,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-04-22T15:24:47.495Z",
        "type": "Periodical"
    },
    {
        "id": 369786631,
        "latitude": 32.9028177,
        "longitude": -95.9638616,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-04-22T15:24:47.495Z",
        "type": "Periodical"
    },
    {
        "id": 369786706,
        "latitude": 32.9028177,
        "longitude": -95.9638616,
        "accuracy": 29,
        "source": "Fused",
        "ts": "2024-04-22T15:24:47.495Z",
        "type": "Periodical"
    },
    {
        "id": 369786321,
        "latitude": 32.9028294,
        "longitude": -95.9639168,
        "accuracy": 21,
        "source": "Fused",
        "ts": "2024-04-22T15:24:16.340Z",
        "type": "Periodical"
    },
    {
        "id": 369777447,
        "latitude": 32.9034471,
        "longitude": -95.96407,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T15:01:50.861Z",
        "type": "Periodical"
    },
    {
        "id": 369760966,
        "latitude": 32.9031959,
        "longitude": -95.9641924,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-04-22T14:19:12.080Z",
        "type": "Periodical"
    },
    {
        "id": 369749273,
        "latitude": 32.9030774,
        "longitude": -95.9642149,
        "accuracy": 23,
        "source": "Fused",
        "ts": "2024-04-22T13:48:53.675Z",
        "type": "Periodical"
    },
    {
        "id": 369738922,
        "latitude": 32.9031785,
        "longitude": -95.9642242,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T13:21:50.916Z",
        "type": "Periodical"
    },
    {
        "id": 369726159,
        "latitude": 32.9032968,
        "longitude": -95.9642078,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T12:49:40.475Z",
        "type": "Periodical"
    },
    {
        "id": 369718092,
        "latitude": 32.9031919,
        "longitude": -95.9642206,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T12:29:40.475Z",
        "type": "Periodical"
    },
    {
        "id": 369705502,
        "latitude": 32.9031919,
        "longitude": -95.9642206,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T11:59:40.473Z",
        "type": "Periodical"
    },
    {
        "id": 369693278,
        "latitude": 32.9032608,
        "longitude": -95.9642094,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T11:29:40.472Z",
        "type": "Periodical"
    },
    {
        "id": 369677270,
        "latitude": 32.9032608,
        "longitude": -95.9642094,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T10:49:40.470Z",
        "type": "Periodical"
    },
    {
        "id": 369669820,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T10:29:40.469Z",
        "type": "Periodical"
    },
    {
        "id": 369659211,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T09:59:40.469Z",
        "type": "Periodical"
    },
    {
        "id": 369652485,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T09:39:40.467Z",
        "type": "Periodical"
    },
    {
        "id": 369642671,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T09:09:40.465Z",
        "type": "Periodical"
    },
    {
        "id": 369632603,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T08:39:40.464Z",
        "type": "Periodical"
    },
    {
        "id": 369622862,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T08:09:40.463Z",
        "type": "Periodical"
    },
    {
        "id": 369612524,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T07:39:40.462Z",
        "type": "Periodical"
    },
    {
        "id": 369605878,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T07:19:40.461Z",
        "type": "Periodical"
    },
    {
        "id": 369598901,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T06:59:40.461Z",
        "type": "Periodical"
    },
    {
        "id": 369584777,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T06:19:40.455Z",
        "type": "Periodical"
    },
    {
        "id": 369577502,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T05:59:40.454Z",
        "type": "Periodical"
    },
    {
        "id": 369566054,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T05:29:40.453Z",
        "type": "Periodical"
    },
    {
        "id": 369554325,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T04:59:40.450Z",
        "type": "Periodical"
    },
    {
        "id": 369537757,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T04:19:40.449Z",
        "type": "Periodical"
    },
    {
        "id": 369528559,
        "latitude": 32.90317,
        "longitude": -95.9641964,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T03:59:40.446Z",
        "type": "Periodical"
    },
    {
        "id": 369517895,
        "latitude": 32.9033448,
        "longitude": -95.9641655,
        "accuracy": 30,
        "source": "Fused",
        "ts": "2024-04-22T03:35:46.806Z",
        "type": "Periodical"
    },
    {
        "id": 369506256,
        "latitude": 32.9030939,
        "longitude": -95.9642324,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-22T03:09:47.097Z",
        "type": "Periodical"
    },
    {
        "id": 369487534,
        "latitude": 32.9030939,
        "longitude": -95.9642324,
        "accuracy": 32,
        "source": "Fused",
        "ts": "2024-04-22T02:29:47.094Z",
        "type": "Periodical"
    },
    {
        "id": 369472763,
        "latitude": 32.9027932,
        "longitude": -95.963908,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-22T02:00:08.144Z",
        "type": "Periodical"
    },
    {
        "id": 369457542,
        "latitude": 32.9072193,
        "longitude": -96.4179005,
        "accuracy": 119,
        "source": "Fused",
        "ts": "2024-04-22T01:29:35.297Z",
        "type": "Periodical"
    },
    {
        "id": 369447541,
        "latitude": 32.8270694,
        "longitude": -96.6273732,
        "accuracy": 77,
        "source": "Fused",
        "ts": "2024-04-22T01:09:33.425Z",
        "type": "Periodical"
    },
    {
        "id": 369431898,
        "latitude": 32.8570955,
        "longitude": -97.0357025,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-22T00:39:30.916Z",
        "type": "Periodical"
    },
    {
        "id": 369415406,
        "latitude": 32.8962158,
        "longitude": -97.0361229,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-22T00:07:29.093Z",
        "type": "Periodical"
    },
    {
        "id": 369406449,
        "latitude": 32.9068086,
        "longitude": -97.0375782,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-21T23:48:46.992Z",
        "type": "Periodical"
    },
    {
        "id": 369403112,
        "latitude": 32.9057382,
        "longitude": -97.0379143,
        "accuracy": 1000,
        "source": "Fused",
        "ts": "2024-04-21T23:41:54.976Z",
        "type": "Periodical"
    },
    {
        "id": 369402521,
        "latitude": 32.9037865,
        "longitude": -97.0352261,
        "accuracy": 300,
        "source": "Fused",
        "ts": "2024-04-21T23:40:32.760Z",
        "type": "Periodical"
    },
    {
        "id": 369400879,
        "latitude": 32.8883816,
        "longitude": -97.0348901,
        "accuracy": 1299,
        "source": "Fused",
        "ts": "2024-04-21T23:36:38.422Z",
        "type": "Periodical"
    },
    {
        "id": 369399793,
        "latitude": 40.779242,
        "longitude": -73.8935433,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-21T22:45:58.833Z",
        "type": "Periodical"
    },
    {
        "id": 369399792,
        "latitude": 40.779242,
        "longitude": -73.8935433,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-21T22:27:56.937Z",
        "type": "Periodical"
    },
    {
        "id": 369399791,
        "latitude": 40.779242,
        "longitude": -73.8935433,
        "accuracy": 800,
        "source": "Fused",
        "ts": "2024-04-21T21:13:29.082Z",
        "type": "Periodical"
    },
    {
        "id": 369295185,
        "latitude": 40.7764586,
        "longitude": -73.8740662,
        "accuracy": 600,
        "source": "Fused",
        "ts": "2024-04-21T20:03:03.028Z",
        "type": "Periodical"
    },
    {
        "id": 369291835,
        "latitude": 40.7752899,
        "longitude": -73.8738568,
        "accuracy": 48,
        "source": "Fused",
        "ts": "2024-04-21T19:41:39.789Z",
        "type": "Periodical"
    },
    {
        "id": 369273839,
        "latitude": 40.7755112,
        "longitude": -73.8733435,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-21T19:19:52.753Z",
        "type": "Periodical"
    },
    {
        "id": 369261281,
        "latitude": 40.7753877,
        "longitude": -73.8728195,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-21T18:53:37.421Z",
        "type": "Periodical"
    },
    {
        "id": 369247960,
        "latitude": 40.7736439,
        "longitude": -73.8721558,
        "accuracy": 25,
        "source": "Fused",
        "ts": "2024-04-21T18:25:25.081Z",
        "type": "Periodical"
    },
    {
        "id": 369231551,
        "latitude": 40.7466698,
        "longitude": -73.8914429,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-04-21T17:49:05.939Z",
        "type": "Periodical"
    },
    {
        "id": 369222022,
        "latitude": 40.7589262,
        "longitude": -73.9527249,
        "accuracy": 24,
        "source": "Fused",
        "ts": "2024-04-21T17:31:17.598Z",
        "type": "Periodical"
    },
    {
        "id": 369218081,
        "latitude": 40.7606215,
        "longitude": -73.9510191,
        "accuracy": 28,
        "source": "Fused",
        "ts": "2024-04-21T17:23:18.383Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 368647408,
        "latitude": 40.7626386,
        "longitude": -73.950808,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-20T19:43:07.019Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 368647089,
        "latitude": 40.7605348,
        "longitude": -73.9524334,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-20T19:42:26.023Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 367992117,
        "latitude": 40.7618545,
        "longitude": -73.9498414,
        "accuracy": 17,
        "source": "Fused",
        "ts": "2024-04-19T19:50:46.331Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 367984356,
        "latitude": 40.7612637,
        "longitude": -73.9642669,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-19T19:35:10.943Z",
        "type": "Periodical"
    },
    {
        "id": 367968386,
        "latitude": 40.7167627,
        "longitude": -74.0027113,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-19T19:02:54.687Z",
        "type": "Periodical"
    },
    {
        "id": 367953396,
        "latitude": 40.7167625,
        "longitude": -74.0027066,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-19T18:28:27.341Z",
        "type": "Periodical"
    },
    {
        "id": 367941115,
        "latitude": 40.7167775,
        "longitude": -74.0027055,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-19T17:58:27.337Z",
        "type": "Periodical"
    },
    {
        "id": 367929092,
        "latitude": 40.7181848,
        "longitude": -74.0050061,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-19T17:28:09.865Z",
        "type": "Periodical"
    },
    {
        "id": 367919787,
        "latitude": 40.7193225,
        "longitude": -74.0042373,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-19T17:05:01.370Z",
        "type": "Periodical"
    },
    {
        "id": 367915672,
        "latitude": 40.7193211,
        "longitude": -74.0042434,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-19T16:55:00.543Z",
        "type": "Periodical"
    },
    {
        "id": 367899125,
        "latitude": 40.7165491,
        "longitude": -74.0027548,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-19T16:14:15.849Z",
        "type": "Periodical"
    },
    {
        "id": 367886588,
        "latitude": 40.7165454,
        "longitude": -74.0027836,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-19T15:42:35.207Z",
        "type": "Periodical"
    },
    {
        "id": 367873367,
        "latitude": 40.7165577,
        "longitude": -74.0028322,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-19T15:10:23.826Z",
        "type": "Periodical"
    },
    {
        "id": 367857396,
        "latitude": 40.7159024,
        "longitude": -74.0010451,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-19T14:31:31.098Z",
        "type": "Periodical"
    },
    {
        "id": 367842330,
        "latitude": 40.7616861,
        "longitude": -73.969795,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-19T13:54:44.575Z",
        "type": "Periodical"
    },
    {
        "id": 367830286,
        "latitude": 40.7616752,
        "longitude": -73.969805,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-19T13:34:02.018Z",
        "type": "Periodical"
    },
    {
        "id": 367815070,
        "latitude": 40.761715,
        "longitude": -73.9697956,
        "accuracy": 100,
        "source": "Fused",
        "ts": "2024-04-19T12:57:44.317Z",
        "type": "Periodical"
    },
    {
        "id": 367806067,
        "latitude": 40.7617005,
        "longitude": -73.9697927,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-19T12:35:55.878Z",
        "type": "Periodical"
    },
    {
        "id": 367796929,
        "latitude": 40.7577184,
        "longitude": -73.9540486,
        "accuracy": 31,
        "source": "Fused",
        "ts": "2024-04-19T12:13:16.980Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 367393270,
        "latitude": 40.7623982,
        "longitude": -73.9497626,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T21:19:19.902Z",
        "type": "Geofence",
        "geofenceDirection": "Enter",
        "savedLocationId": "727"
    },
    {
        "id": 367384856,
        "latitude": 40.7629777,
        "longitude": -73.9338641,
        "accuracy": 26,
        "source": "Fused",
        "ts": "2024-04-18T21:03:46.897Z",
        "type": "Periodical"
    },
    {
        "id": 367363417,
        "latitude": 40.8541835,
        "longitude": -73.9658169,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-18T20:25:57.111Z",
        "type": "Periodical"
    },
    {
        "id": 367343587,
        "latitude": 40.8559668,
        "longitude": -74.4168108,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-04-18T19:46:47.017Z",
        "type": "Periodical"
    },
    {
        "id": 367339415,
        "latitude": 40.8562216,
        "longitude": -74.4166586,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T19:37:56.687Z",
        "type": "Periodical"
    },
    {
        "id": 367328405,
        "latitude": 40.8563086,
        "longitude": -74.4167483,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T19:14:21.052Z",
        "type": "Periodical"
    },
    {
        "id": 367328393,
        "latitude": 40.8563135,
        "longitude": -74.4166985,
        "accuracy": 15,
        "source": "Fused",
        "ts": "2024-04-18T19:14:07.235Z",
        "type": "Periodical"
    },
    {
        "id": 367325467,
        "latitude": 40.8559958,
        "longitude": -74.4165951,
        "accuracy": 19,
        "source": "Fused",
        "ts": "2024-04-18T19:08:22.488Z",
        "type": "Periodical"
    },
    {
        "id": 367310236,
        "latitude": 40.8561099,
        "longitude": -74.4167411,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-18T18:33:22.376Z",
        "type": "Periodical"
    },
    {
        "id": 367309968,
        "latitude": 40.8561532,
        "longitude": -74.4167384,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-18T18:32:32.371Z",
        "type": "Periodical"
    },
    {
        "id": 367301251,
        "latitude": 40.8561077,
        "longitude": -74.4167161,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-18T18:09:19.482Z",
        "type": "Periodical"
    },
    {
        "id": 367287102,
        "latitude": 40.8561643,
        "longitude": -74.4166977,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T17:31:40.136Z",
        "type": "Periodical"
    },
    {
        "id": 367277728,
        "latitude": 40.8561302,
        "longitude": -74.4167052,
        "accuracy": 13,
        "source": "Fused",
        "ts": "2024-04-18T17:06:27.317Z",
        "type": "Periodical"
    },
    {
        "id": 367267778,
        "latitude": 40.8561456,
        "longitude": -74.4168962,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T16:40:27.424Z",
        "type": "Periodical"
    },
    {
        "id": 367257217,
        "latitude": 40.8561589,
        "longitude": -74.4166974,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T16:12:23.060Z",
        "type": "Periodical"
    },
    {
        "id": 367242275,
        "latitude": 40.8561436,
        "longitude": -74.4168239,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-18T15:34:27.178Z",
        "type": "Periodical"
    },
    {
        "id": 367230432,
        "latitude": 40.8561761,
        "longitude": -74.4167262,
        "accuracy": 10,
        "source": "Fused",
        "ts": "2024-04-18T15:03:29.049Z",
        "type": "Periodical"
    },
    {
        "id": 367227459,
        "latitude": 40.8561763,
        "longitude": -74.4168019,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-18T14:55:42.875Z",
        "type": "Periodical"
    },
    {
        "id": 367219995,
        "latitude": 40.8561286,
        "longitude": -74.416783,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-18T14:36:35.300Z",
        "type": "Periodical"
    },
    {
        "id": 367207905,
        "latitude": 40.8560938,
        "longitude": -74.4168095,
        "accuracy": 14,
        "source": "Fused",
        "ts": "2024-04-18T14:05:42.676Z",
        "type": "Periodical"
    },
    {
        "id": 367195338,
        "latitude": 40.8872862,
        "longitude": -74.2149352,
        "accuracy": 4,
        "source": "Fused",
        "ts": "2024-04-18T13:34:52.536Z",
        "type": "Periodical"
    },
    {
        "id": 367179622,
        "latitude": 40.8870892,
        "longitude": -74.2159537,
        "accuracy": 93,
        "source": "Fused",
        "ts": "2024-04-18T12:55:42.561Z",
        "type": "Periodical"
    },
    {
        "id": 367169961,
        "latitude": 40.8924636,
        "longitude": -74.2236219,
        "accuracy": 34,
        "source": "Fused",
        "ts": "2024-04-18T12:31:42.615Z",
        "type": "Periodical"
    },
    {
        "id": 367162044,
        "latitude": 40.8735263,
        "longitude": -74.0616429,
        "accuracy": 87,
        "source": "Fused",
        "ts": "2024-04-18T12:12:42.400Z",
        "type": "Periodical"
    },
    {
        "id": 367153505,
        "latitude": 40.7832172,
        "longitude": -73.9292048,
        "accuracy": 3,
        "source": "Fused",
        "ts": "2024-04-18T11:47:52.553Z",
        "type": "Periodical"
    },
    {
        "id": 367143128,
        "latitude": 40.7617702,
        "longitude": -73.9425991,
        "accuracy": 97,
        "source": "Fused",
        "ts": "2024-04-18T11:28:00.584Z",
        "type": "Geofence",
        "geofenceDirection": "Exit",
        "savedLocationId": "727"
    },
    {
        "id": 366778894,
        "latitude": 40.7623649,
        "longitude": -73.949782,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-17T21:53:24.600Z",
        "type": "Periodical"
    },
    {
        "id": 366758553,
        "latitude": 40.7623685,
        "longitude": -73.9498051,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-17T21:17:26.707Z",
        "type": "Periodical"
    },
    {
        "id": 366739053,
        "latitude": 40.7623683,
        "longitude": -73.9497982,
        "accuracy": 12,
        "source": "Fused",
        "ts": "2024-04-17T20:41:26.539Z",
        "type": "Periodical"
    },
    {
        "id": 366724120,
        "latitude": 40.7623744,
        "longitude": -73.9497625,
        "accuracy": 11,
        "source": "Fused",
        "ts": "2024-04-17T20:14:10.882Z",
        "type": "Periodical"
    }
]
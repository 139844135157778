import rangeSliderView from 'bundle-text:../views/range-slider.html'
import moment from 'moment';
import {heatLayer} from "../map";
import {heatData, normalizedData} from "../data";

export const FORMAT_STRING = 'MMM Do, h:mm a';

console.log(rangeSliderView);
export class RangeSlider extends HTMLElement {
  constructor() {
    super();
    this.start = normalizedData[0].ts;
    this.end = normalizedData[normalizedData.length - 1].ts;
  }

  connectedCallback(){
    this.innerHTML = rangeSliderView;
    const minRange = this.querySelector('#min-range');
    const maxRange = this.querySelector('#max-range');
    const minValue = this.querySelector('#min-value');
    const maxValue = this.querySelector('#max-value');
    
    minValue.textContent = moment(this.start).format(FORMAT_STRING);
    maxValue.textContent = moment(this.end).format(FORMAT_STRING);
    minRange.setAttribute('max', normalizedData.length);
    maxRange.setAttribute('max', normalizedData.length - 1);
    minRange.setAttribute('value', 0);
    maxRange.setAttribute('value', normalizedData.length - 1);
    minRange.addEventListener('input', (event) => {
      this.start = normalizedData[event.target.value].ts;
      minValue.textContent = moment(this.start).format(FORMAT_STRING);
      heatLayer.setLatLngs(heatData(
          normalizedData[event.target.value].ts,
          normalizedData[maxRange.value].ts
      ));
    });
    maxRange.addEventListener('input', (event) => {
      this.end = normalizedData[event.target.value].ts;
      maxValue.textContent = moment(this.end).format(FORMAT_STRING);
      heatLayer.setLatLngs(heatData(
          normalizedData[minRange.value].ts,
          normalizedData[event.target.value].ts
      ));
    });
  }
}

customElements.define("range-slider", RangeSlider);

export const rangeSliderInstance = new RangeSlider();

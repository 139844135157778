import leaflet from "leaflet";
import "leaflet.heat";
import 'leaflet/dist/leaflet.css';

export const L = leaflet;

//Initialize the map
export const map = L.map('map').fitBounds([
    [21.879341082799026, -102.31876373291017],
    [43.78497553389676, -69.18399810791017,]
]);

// Set up the OSM layer
export const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
    maxNativeZoom:19,
    maxZoom:21
});
tiles.addTo(map);

// Create the heat layer
export const heatLayer = L.heatLayer([], {
        radius: 12,      // Controls the size of each "point"
        blur: 7,        // Adjusts the blur of each point
        maxZoom: 12,     // Max zoom level for intensity adjustment
        max: 1         // Maximum intensity
    });
heatLayer.addTo(map);

import moment from "moment";
import {map} from "./map";
import {normalizedData, minDate, maxDate, filterByDate} from "./data";
import {rangeSliderInstance, FORMAT_STRING} from "./web-components/range-slider";

export function findClosest(reference, start = minDate, end = maxDate) {
    let closestPosition = null;
    let closestDistance = Number.MAX_VALUE;

    filterByDate(start, end).forEach((record) => {
        const distance = map.distance(record.loc, reference);
        if (distance < closestDistance) {
            closestPosition = record.loc;
            closestDistance = distance;
        }
    });

    return closestPosition;
}

const popup = L.popup();

map.on('click', e => {
    map.closePopup(popup);
    const closest = findClosest(e.latlng, rangeSliderInstance.start, rangeSliderInstance.end);

    if (closest !== null) {
        const matching =
            normalizedData.filter(record => {
                return record.loc.lat === closest.lat && record.loc.lng === closest.lng;
            })
            .map(record =>`${moment(record.ts).format(FORMAT_STRING)} - ${Math.round(record.accuracy * 3.28084)} ft`);

        popup.setLatLng(closest);

        popup.setContent(generateContent(matching));

        popup.openOn(map);
    }
});

function generateContent(timestamps) {
    let content = '';
    if (timestamps.length === 1) {
        content = timestamps[0];
    } else {
        const items = timestamps.map(ts => `<li>${ts}</li>`);
        content = `<ul class="popup-content-items">${items.join('\n')}</ul>`;
    }  
    return `<div class="popup-content">${content}</div>`; 
}